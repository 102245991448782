export function playAudioFromResponse(
  response,
  audioRef,
  onAudioLoaded,
  onIsPlayingChange,
  onIsLoadingChange,
  playElevenlabsAlignments
) {
  if (!MediaSource.isTypeSupported('audio/mpeg')) {
    console.log('Unsupported MIME type or codec: audio/mpeg')
    throw new Error('Unsupported MIME type or codec: audio/mpeg')
  }

  const mediaSource = new MediaSource()
  const audio = new Audio()
  audio.src = URL.createObjectURL(mediaSource)
  audioRef.current = audio

  mediaSource.addEventListener('sourceopen', () => {
    const sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg')
    onAudioLoaded()
    readAudioChunks(response.body.getReader(), sourceBuffer, mediaSource, playElevenlabsAlignments)
    onIsLoadingChange(false)
    onIsPlayingChange(true)
    audio.play()
  })

  audio.onended = () => {
    onIsPlayingChange(false)
    onIsLoadingChange(false)
  }

  audio.addEventListener('error', e => {
    console.error('Error playing audio', e)
  })
}

function readAudioChunks(
  reader,
  sourceBuffer,
  mediaSource,
  playElevenlabsAlignments
) {
  let queue = []
  let isAppendingInProgress = false
  let isReadingDone = false // New flag to track if reading is done

  function processQueue() {
    if (queue.length > 0 && !sourceBuffer.updating) {
      sourceBuffer.appendBuffer(queue.shift())
    } else if (isReadingDone && !sourceBuffer.updating) { // Check if reading is done and sourceBuffer is not updating
      mediaSource.endOfStream()
    }
  }

  function push() {
    reader.read().then(({ done, value }) => {
      if (done) {
        isReadingDone = true // Set the flag when reading is done
        processQueue() // Attempt to end the stream if the queue is empty and sourceBuffer is not updating
        return
      }
      const text = new TextDecoder().decode(value)
      const jsonObjects = text.split('\n').filter(Boolean).map(line => {
        try {
          console.log('--Elevenlabs JSON line--\n', line);
          return JSON.parse(line)
        } catch (e) {
          console.error('Error parsing JSON', e, line)
          return null
        }
      }).filter(Boolean) // Filter out null values from failed parses
      jsonObjects.forEach(json => {
        // console.log(json)
        const audioData = Uint8Array.from(atob(json.audio_base64.replace(/\s/g, '')), c => c.charCodeAt(0))
        queue.push(audioData)
        if (json.alignment) {
          playElevenlabsAlignments(json.agent_id, json.alignment) // Pass agent_id as a parameter
        }
      })
      if (!isAppendingInProgress) {
        isAppendingInProgress = true
        processQueue()
      }
      push()
    })
  }

  sourceBuffer.addEventListener('updateend', () => {
    isAppendingInProgress = false
    processQueue()
  })

  push()
}
