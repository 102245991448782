import React, { useState, useRef } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import useStore from '../../../store';

import { 
  StyledModalBody, 
  SelectableElement,
  ContentColumn,
  SideColumn,
} from './Content.styles';

import Chat from '../../Chat/Chat';
import ChatForm from '../ChatForm';

const getTextContent = (children) => {
  if (!children) return '';
  
  if (typeof children === 'string') return children;
  
  if (Array.isArray(children)) {
    return children.map(child => getTextContent(child)).join('');
  }
  
  // Handle React elements
  if (children?.props?.children) {
    return getTextContent(children.props.children);
  }
  
  return String(children);
};


const Content = ({ content, loading = false }) => {
  const [selectedElement, setSelectedElement] = useState(null);
  const [partial, setPartial] = useState('');
  const [currentContent, setCurrentContent] = useState(content);
  const [loadingElementId, setLoadingElementId] = useState(null);
  const chatContainerRef = useRef(null);
  const { subTopic } = useStore((state) => ({ 
    subTopic: state.subTopic
  }));

  const createSelectableComponent = (Component) => {
    return ({ node, children, ...props }) => {
      const elementId = `${Component}-${node.position?.start.line || Math.random()}`;
      
      // Get the original markdown text from the node's position
      const originalText = node.position 
        ? currentContent.slice(node.position.start.offset, node.position.end.offset)
        : getTextContent(children);
      
      // Get the rendered text (without markdown)
      const renderedText = getTextContent(children);
      
      return (
        <SelectableElement
          onClick={(e) => {
            e.stopPropagation();
            setSelectedElement(elementId);
            setPartial({
              raw: originalText,      // Contains markdown
              formatted: renderedText // Plain text after rendering
            });
          }}
          isSelected={selectedElement === elementId}
        >
          <div style={{ position: 'relative' }}>
            {loadingElementId === elementId && (
              <span className="spinner-border spinner-border-sm position-absolute" style={{ top: '5px', right: '5px', zIndex: 1000 }} role="status" aria-hidden="true" />
            )}
            <Component {...props}>{children}</Component>
          </div>
        </SelectableElement>
      );
    };
  };

  const handleUpdatePartial = (response) => {
    // Update the full message
    setCurrentContent({
      ...currentContent,
      message: response.edited_text
    });

    // Update the partial selection
    setPartial(response.partial);
  };

  const components = {
    h1: createSelectableComponent('h1'),
    h2: createSelectableComponent('h2'),
    h3: createSelectableComponent('h3'),
    h4: createSelectableComponent('h4'),
    h5: createSelectableComponent('h5'),
    h6: createSelectableComponent('h6'),
    p: createSelectableComponent('p'),
    img: createSelectableComponent('img'),
    ul: createSelectableComponent('ul'),
    li: createSelectableComponent('li'),
    table: createSelectableComponent('table'),
  };

  const scrollChatToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  return (
    <StyledModalBody>
      <ContentColumn onClick={() => {
        setSelectedElement(null);
        setPartial('');  // Reset partial when deselecting
      }}>
        <Markdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
          components={components}
        >
          {content}
        </Markdown>
      </ContentColumn>
      <SideColumn>
        <div 
          ref={chatContainerRef} 
          className="modal-chat-container"
          style={{
            height: '100%',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {loading ? (
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
          ) : (
            <>
              <div className='flex-grow-1'>
                {subTopic && <Chat topic={subTopic} scrollToBottom={scrollChatToBottom} />}
              </div>
              <ChatForm 
                message={currentContent} 
                partial={partial} 
                updatePartialFn={handleUpdatePartial}
                setLoadingElement={setLoadingElementId}
                selectedElementId={selectedElement}
              />
            </>
          )}
        </div>
      </SideColumn>
    </StyledModalBody>
  );
};

export default Content;