import React from 'react';
import { useTranslation } from 'react-i18next';

const WorkflowsWidget = ({ workflows, showCreateWorkflowModal, className, title = undefined, displayTitle = true }) => {
  const { t } = useTranslation();
  if (!workflows || workflows.length === 0) {
    return (
      <div className={`workflows-widget ${className}`}>
        <p><strong>{title ? title : t('Workflows')}</strong></p>
        <p>{t('No workflows found')}</p>
        {/*toggleCreateWorkflowModal && <Button outline size="sm" onClick={toggleCreateWorkflowModal}>{t('Add workflow')}</Button>*/}
      </div>
    );
  }

  // if workflows is an array of objects, render the workflows
  if (Array.isArray(workflows) && workflows.every(item => typeof item === 'object')) {
    return (
      <div className={`workflows-widget ${className}`}>
        {displayTitle && <p><strong>{title ? title : t('Workflows')}</strong></p>}
        {workflows.map(({id, title}, index) => (
          <p
            key={`workflow-${index}`}
            style={{ listStyleType: "none", cursor: 'pointer' }}
            onClick={() => showCreateWorkflowModal(title, false, 'CUSTOM_WORKFLOW_MANUAL', id)}
            className="text-truncate b5"
          >
            <i className="bi bi-gear"></i> {title}
          </p>
        ))}
        {/*toggleCreateWorkflowModal && <Button outline size="sm" onClick={toggleCreateWorkflowModal}>{t('Add workflow')}</Button>*/}
      </div>
    );
  }
  return (
    <div className={`workflows-widget ${className}`}>
      {displayTitle && <p><strong>{title ? title : t('Workflows')}</strong></p>}
      {workflows.map((title, index) => (
        <p
          key={`workflow-${index}`}
          style={{ listStyleType: "none", cursor: 'pointer' }}
          onClick={() => showCreateWorkflowModal(title)}
          className="text-truncate b5"
        >
          <i className="bi bi-gear"></i> {title}
        </p>
      ))}
      {/*toggleCreateWorkflowModal && <Button outline size="sm" onClick={toggleCreateWorkflowModal}>{t('Add workflow')}</Button>*/}
    </div>
  );
};

export default WorkflowsWidget;
