import styled from 'styled-components';
import { Button } from 'reactstrap';


export const StyledButton = styled(Button)`
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  color: #999;
  padding: 0 0.3em;
  margin: 0;
  font-size: 1.0em;
  &:hover {
    background-color: #888;
    border-color: #777;
  }
  height: 1.8em;
  width: 1.8em;
`;
