import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, CardBody, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import apiService from '../../utils/apiService';
import {
  TeamCard, TeamText,
  AgentGrid, AgentBox,
  StyledDropdown, StarButton, NumberOfAgents } from './Team.styles.js';
import useStore from '../../store';
import { getFeatureFlag } from '../../utils/featureFlags';

const Team = ({
  team,
  handleDelete,
  handleStartMeeting,
  showHeader = true, showDropDown = true}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { agents, workspace } = useStore((state) => ({
    agents: state.agents,
    workspace: state.workspace,
  }));
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  const handlePromoteTeam = async (teamId, isPromote) => {
    if (!teamId) {
      console.error(t('Team ID is missing:'), teamId);
      return;
    }
    try {
      const response = await apiService.post(`/api/teams/${teamId}/${isPromote ? 'promote' : 'demote'}/`);
      if (!response.success) {
        console.error(t('Operation failed:'), response.message);
        return;
      }
    } catch (error) {
      console.error(t('handlePromoteTeam error'), error);
    }
  };

  return (
    <TeamCard className="card mb-3">
      {(handleDelete) && showDropDown && (
        <StyledDropdown>
          <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
              <i className="bi bi-three-dots-vertical"></i>
            </DropdownToggle>
            <DropdownMenu end>
              {handleDelete && <DropdownItem onClick={() => handleDelete(team.id)}>{t('Delete')}</DropdownItem>}
            </DropdownMenu>
          </Dropdown>
        </StyledDropdown>
      )}
      <Row className="g-0">
        <Col sm="12" md={{ size: 3 }}>
          <AgentGrid>
            {agents && team.agents.slice(0, 4).map((agentId, index) => {
              const agent = agents.find(a => a.id === agentId);
              if (index === 3 && team.agents.length > 4) {
                // This is the fourth box and there are more than four agents
                return (
                  <AgentBox
                    key={agentId}
                    style={{ backgroundImage: `url(${agent?.image_url})` }}
                    onClick={() => navigate(`/${workspace.id}/team/${team.id}`)}
                  >
                    <NumberOfAgents>+{team.agents.length - 4}</NumberOfAgents>
                  </AgentBox>
                );
              } else {
                // Normal agent box
                return (
                  <AgentBox
                    key={agentId}
                    style={{ backgroundImage: `url(${agent?.image_url})` }}
                    onClick={() => navigate(`/${workspace.id}/team/${team.id}`)}
                  />
                );
              }
            })}
          </AgentGrid>
        </Col>
        <Col>
          <CardBody className='p-3'>
            {showHeader &&
              <h5 className='p-0 m-0'>
                {team.name}
                &nbsp;
                <StarButton className="star-button" size="sm" onClick={() => handlePromoteTeam(team.id, !team.promoted)}>
                {team.promoted ? (
                  <span className="bi bi-star-fill"></span>
                ) : (
                  <span className="bi bi-star"></span>
                )}
              </StarButton>
              </h5>}
              <TeamText>
              <p>{team.description}</p>
              {handleStartMeeting && getFeatureFlag('MEETINGS') && (
                <Button size="sm" color="primary" onClick={() => handleStartMeeting()}>
                  <i className="bi bi-telephone-fill"></i> {t('Meet')}
                </Button>
              )}
            </TeamText>
          </CardBody>
        </Col>
      </Row>
    </TeamCard>
  );
};

export default Team;
