import styled, { keyframes } from 'styled-components';
import Markdown from 'react-markdown';
import { Button, AccordionHeader } from 'reactstrap';

export const AgentImage = styled.div`
  width: 40px;
  height: 40px;
  background-image: url(${props => props.$imageURL}); // Use transient prop with $
  background-size: cover;
  background-position: center;
  border-radius: 50%; // Makes the image round
`;

export const CustomMarkdown = styled(Markdown)`
  ul {
    margin-bottom: 0;
  }
`;

export const ReferencesMarkdown = styled(Markdown)`
  blockquote {
    border-left: 4px solid #ccc;
    padding-left: 1em;
    color: #666;
    margin: 0.5em 0;
  }
`;

export const SmallButton = styled(Button)`
  font-size: 12px;
  padding: 4px;
`;

export const TextButton = styled(Button)`
  padding: 4px;
  background: none !important; // Remove button background
  border: none !important; // Remove button border
  color: var(--bs-primary) !important; // Use Bootstrap primary color for text
  text-decoration: none !important; // Remove underline
  cursor: pointer; // Ensure it still looks clickable
  &:hover {
    color: var(--bs-primary-dark) !important; // Use a darker shade for hover, if defined
    background: none !important; // Ensure no background on hover
  }
  &:focus {
    outline: none !important; // Remove focus outline
    box-shadow: none !important; // Remove focus box-shadow
  }
  &:active {
    background: none !important; // Ensure no background on active state
  }
`;

export const MarkdownCustom = styled.div`
  p {
    margin-bottom: 0;
  }
`;

const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

export const BlinkingCursor = styled.span`
  animation: ${blink} 1s step-end infinite;
  font-weight: bold;
  display: inline-block;
  margin-left: 2px;
`;

export const CustomAccordionHeader = styled(AccordionHeader)`
  button {
    padding: 0.75em;
  }
`;

export const AccordianStatusText = styled.span`
  font-size: small;
`;
