import React from 'react';
import {
  Form,
} from 'reactstrap';
// import AutoResizeTextarea from '../../AutoResizeTextarea/AutoResizeTextarea';
import apiService from '../../../utils/apiService';
import DocumentFormButton from '../../DocumentFormButton/DocumentFormButton';
import Chat from '../../Chat/Chat';
import ChatForm from '../../ChatForm/ChatForm';

function ChatBot({ projectId, document:doc, scrollToBottom }) {
  const documentId=doc.id;
  const handleGenerateDocumentSubmit = async (e) => {
    e.preventDefault();
    doc.is_generating = true; // Set generating state to true at the start of the operation
    const endpoint = `/api/document/${documentId}/generate/`;
    const formData ={};
    try {
      const response = await apiService.post(endpoint, formData);
      if (response.success) {
        const loadedJob = response.data.job;
        console.log('Document generation initiated successfully', loadedJob);
      } else {
        console.error('Operation failed:', response.message);
        doc.is_generating = false;
      }
    } catch (error) {
      console.error('Error during operation:', error);
      doc.is_generating = false;
    }
  };

  return (
    <>
    <Chat scrollToBottom={scrollToBottom} />
    <ChatForm />
    <Form onSubmit={(e) => handleGenerateDocumentSubmit(e)}>
      <DocumentFormButton document={doc} />
    </Form>
    </>
  );
}

export default ChatBot;
