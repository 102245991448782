import styled from 'styled-components';

export const StyledCard = styled.div`
  position: relative;
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  &:hover {
    background-color: #f0f0f0; /* Change this color as needed */
    cursor: pointer;
  }
`;
export const StyledDropdown = styled.div`
  position: absolute;
  top: 0.25em;
  right: 0.25em;
  transform: translate(-10%, 10%);
  cursor: pointer;
`;
