import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input, Row, Col, FormFeedback } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import apiService from '../../utils/apiService';
import useStore from '../../store';

function ProjectDetailsPage({ is_workflow = false }) {
  const { t } = useTranslation();
  const { id: projectId } = useParams();
  const { projects, addProject, updateProject, isSuperUser, language, workspace } = useStore((state) => ({
    addProject: state.addProject,
    updateProject: state.updateProject,
    projects: state.projects,
    isSuperUser: state.isSuperUser,
    language: state.language,
    workspace: state.workspace,
  }));
  const [projectData, setProjectData] = useState({
    name: '',
    icon: 'bi-rocket',
    super_type: is_workflow ? 'WORKFLOW' : 'PROJECT',
    type: is_workflow ? 'CUSTOM_WORKFLOW_MANUAL' : 'IDEA_DIAMOND_V2',
    language: language,
    tonality: 'PROFESSIONAL_FORMAL',
    'is_workflow': is_workflow,
  });
  const [error, setError] = useState('');


  const navigate = useNavigate();
  const project_types = useMemo(() => {
    if (isSuperUser) {
      return is_workflow ? window.workflow_types_superuser || [] : window.project_types_superuser || [];
    }
    return is_workflow ? window.workflow_types || [] : window.project_types || [];
  }, [isSuperUser, is_workflow]);
  const languageOptions = useMemo(() => window.languageChoices || [], []);
  const tonalityOptions = useMemo(() => window.tonalityChoices || [], []);
  const baseFolder = is_workflow ? 'workflow' : 'project';


  useEffect(() => {
    if (!projectId) {
      return; // If there's no project ID, we're creating a new project, so no need to navigate
    }
    const project = projects.find((p) => p.id.toString() === projectId);
    if (!project) {
      console.error('Session not found in store');
    } else {
      setProjectData({
        name: project.name,
        type: project.type,
        language: project.language,
        tonality: project.tonality,
      });
    }
  }, [baseFolder, navigate, projectId, projects]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProjectData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const validateForm = () => {
    if (!projectData.name.trim()) {
      setError('Name is required');
      return false;
    }
    setError('');
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return; // Stop submission if validation fails
    const isNewProject = !projectId;
    const endpoint = isNewProject ? `/api/${baseFolder}s/create/` : `/api/${baseFolder}s/edit/${projectId}/`;
    try {
      const response = await apiService.post(endpoint, {...projectData, workspace_id: workspace?.id});
      if (response.success) {
        // console.log(isNewProject ? 'Project created successfully' : 'Project updated successfully');
        if (isNewProject) {
          addProject(response.data.project);
          navigate(`/${baseFolder}/${response.data.project.id}/document/${response.data.project.first_document_id}`);
        } else {
          updateProject(projectId, projectData);
          navigate(`/${workspace?.id}/${baseFolder}s`);
        }
      } else {
        console.error('Operation failed:', response.message);
      }
    } catch (error) {
      console.error('Error during operation:', error);
    }
  };

  return (
    <div>
      {!is_workflow &&<h2>{projectId === undefined ? t('Create Session') : t('Edit Session')}</h2>}
      {is_workflow &&<h2>{projectId === undefined ? t('Create Workflow') : t('Edit Workflow')}</h2>}
      <Row>
        <Col sm="12" md={{ size: 9 }}>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="name" className='fw-bold'>{t('Name')}</Label>
              <Input
                type="text"
                name="name"
                id="name"
                placeholder={is_workflow ? t('Workflow name') : t('Session name')}
                value={projectData.name}
                onChange={handleChange}
                invalid={!!error}
              />
              <FormFeedback>
                {error}
              </FormFeedback>
            </FormGroup>
            {projectId === undefined && (
              <FormGroup>
                <Label for="type" className='fw-bold'>{t('Type')}</Label>
                <Input type="select" name="type" id="type" value={projectData.type} onChange={handleChange}>
                  {project_types.map((option) => (
                    <option key={option.value} value={option.value}>{t(option.label)}</option>
                  ))}
                </Input>
              </FormGroup>
            )}
            <FormGroup>
              <Label for="language" className='fw-bold'>{t('Language')}</Label>
              <Input type="select" name="language" id="language" value={projectData.language} onChange={handleChange}>
                {languageOptions.map((option) => (
                  <option key={option.value} value={option.value}>{t(option.label)}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="tonality" className='fw-bold'>{t('Tonality')}</Label>
              <Input type="select" name="tonality" id="tonality" value={projectData.tonality} onChange={handleChange}>
                {tonalityOptions.map((option) => (
                  <option key={option.value} value={option.value}>{t(option.label)}</option>
                ))}
              </Input>
            </FormGroup>
            <Button color="primary" type="submit">{projectId === undefined ? t('Create') : t('Update')}</Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default ProjectDetailsPage;
