import React, { useState } from 'react';
import {
  Accordion,
  AccordionBody,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { StyledAccordionItem, StyledAccordionHeader } from './DocumentAccordian.styles';
import DocumentTemplate from '../../components/DocumentTemplate/DocumentTemplate';

function DocumentAccordian({ projectId, document:doc }) {
  const { t } = useTranslation();
  const isGenerating = doc.is_generating;

  const [open, setOpen] = useState(
    !isGenerating && doc.content ? '' : '1'
  );

  const toggle = (id) => {
    if (open === id) { setOpen('');
    } else { setOpen(id); }
  };

  return (
    <Accordion open={open} toggle={toggle} className='mb-4'>
      <StyledAccordionItem>
        <StyledAccordionHeader targetId="1">
          {t('Provide your answers')}
        </StyledAccordionHeader>
        <AccordionBody accordionId="1">
          <DocumentTemplate projectId={projectId} document={doc} />
        </AccordionBody>
      </StyledAccordionItem>
    </Accordion>
  );
}

export default DocumentAccordian;
