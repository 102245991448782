import React from 'react';
import useStore from '../../../store';

function UserMessage({ message }) {
  const { members } = useStore((state) => ({ members: state.members }));

  const getMemberName = (name, memberId) => {
    if (!members || !memberId) return name;
    const member = members.find(member => member.id === memberId);
    return member ? member.username : name;
  };

  const getMemberImage = (memberId) => {
    if (!members || !memberId) return null;
    const member = members.find(member => member.id === memberId);
    const bgColor = member ? member.background_color : '#ccc';
    const icon = member ? member.icon : 'bi-person';
    return (
      <div className="rounded-circle d-flex align-items-center justify-content-center text-white fs-3" style={{ width: '40px', height: '40px', backgroundColor: bgColor }}>
        <i className={`bi ${icon}`}></i>
      </div>
    );
  };

  return (
    <>
      <div className="flex-shrink-0">
        {getMemberImage(message.user_id)}
      </div>
      <div className="flex-grow-1 ms-3">
        <div className='mb-1'><strong>{getMemberName(message.name, message.user_id)}</strong></div>
        <p>{message.message}</p>
      </div>
    </>
  );
}

export default UserMessage;