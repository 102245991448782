import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import apiService from '../../utils/apiService';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
// import { MilkdownEditorWrapper } from '../../components/MilkDownEditor/MilkDownEditor';

function StagePage() {
  const { stageId } = useParams();
  const [stage, setStage] = useState(null);

  useEffect(() => {
    const fetchStage = async () => {
      try {
        const response = await apiService.get(`/api/stage/${stageId}/`);
        if (response.success) {
          const loadedStage = response.data.stage;
          console.log('Load Stage successful:', loadedStage);
          setStage(loadedStage);
        } else {
          console.error('Load Stage failed:', response);
        }
      } catch (error) {
        console.error('Error during Load Stage:', error);
      }
    };

    fetchStage();
  }, [stageId]);

  return (
    <Row>
      <Col sm="12" md={{ size: 9 }}>
        {stage ?
          <>
            <h1>{stage.title}</h1>
            <Markdown remarkPlugins={[[remarkGfm]]}>{stage.description}</Markdown>
          </>
          : <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        }
      </Col>
    </Row>
  );
}

export default StagePage;
