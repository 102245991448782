import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import { Button, Spinner } from 'reactstrap';
import useStore from '../../../store';
import { useMicVAD } from '@ricky0123/vad-react';
import useDeepgram from '../../../hooks/useDeepgram';
import PlayerStream from '../Players/PlayerStream';
import apiService from '../../../utils/apiService';

const VadDeepgramElevenLabsMicStream = ({
  isOpen,
  playElevenlabsAlignments,
  setTranscript,
  stopTalking,
}) => {
  const { workspace, topic, language, meeting, setMeeting } = useStore((state) => ({
    workspace: state.workspace,
    topic: state.topic,
    language: state.language,
    meeting: state.meeting,
    setMeeting: state.setMeeting,
  }));
  const [micEnabled, setMicEnabled] = useState(true);
  const vadIsTalking = useRef(false);
  const vadSession = useRef({ id: 0, fetching: false, text: '', phrase: ''});

  // Use a ref to always have the latest meeting value
  const meetingRef = useRef(meeting); // NOTE: Since player is a ref, this also needs to be a ref

  // Update the ref whenever meeting changes
  useEffect(() => {
    meetingRef.current = meeting;
  }, [meeting]);

  const handleFinalizeResponse = useCallback(async (userInput, aiResponse) => {
    try {
      const currentMeeting = meetingRef.current;
      console.log('handleFinalizeResponse', currentMeeting);
      if (!currentMeeting || !currentMeeting.topic_ids || !currentMeeting.topic_ids.length) {
        console.error('No valid meeting or topic_id available');
        return;
      }
      const topicId = currentMeeting.topic_ids[currentMeeting.topic_ids.length - 1];
      const response = await apiService.post(`/api/topic/${topicId}/finalize-response/`, {
        user_input: userInput,
        ai_response: aiResponse
      });

      if (!response.success) {
        console.error('Operation failed:', response.message);
        return;
      }
    } catch (error) {
      console.error('handleFinalizeResponse error', error);
    }
  }, []); // Remove meeting from the dependency array

  const player = useRef(null);

  useEffect(() => {
    if (!player.current) {
      player.current = new PlayerStream(topic.id, playElevenlabsAlignments, stopTalking, (userInput, aiResponse) => {
        handleFinalizeResponse(userInput, aiResponse);
      });
    }
  }, [topic.id, playElevenlabsAlignments, stopTalking, handleFinalizeResponse]);

  const [isCreatingMeeting, setIsCreatingMeeting] = useState(false);

  const handleNewMeeting = useCallback(async () => {
    if (isCreatingMeeting) return;
    setIsCreatingMeeting(true);
    try {
      const response = await apiService.post(`/api/meetings/new/`, {
        workspace_id: workspace.id
      });
      if (!response.success) {
        console.error('Operation failed:', response.message);
        return;
      }
      console.log('Meeting created', response.data.meeting);
      setMeeting(response.data.meeting); // Also has topic_id and resource_id
      meetingRef.current = response.data.meeting; // Update the ref with the new meeting
    } catch (error) {
      console.error('handleNewMeeting error', error);
    } finally {
      setIsCreatingMeeting(false);
    }
  }, [setMeeting, workspace, isCreatingMeeting]);

  useEffect(() => {
    if (!meeting && !isCreatingMeeting) handleNewMeeting();
  }, [handleNewMeeting, meeting, isCreatingMeeting]);


  // Purpose of VAD is to more properly finalize speech, and also interrupt the agent
  const vad = useMicVAD({
    startOnLoad: true,
    positiveSpeechThreshold: 0.3,
    negativeSpeechThreshold: 0.15,
    preSpeechPadFrames: 20,
    onSpeechStart: async () => {
      const id = Math.random().toString(36).substring(2, 15);
      vadSession.current = {id, fetching: false, text: '', phrase: ''}; // Sessions ensure that partial phrases belong to current session
      console.log(`VAD [${vadSession.current.id}]: User started talking.`);
      player.current.stop();
      stopTalking();
      player.current.newSession(id);
      vadIsTalking.current = true;
    },
    onSpeechEnd: async () => {
      if (!micEnabled || vad.loading)  return;
      vadIsTalking.current = false;
      setTimeout(() => {
        if (dgIsTalking.current) {
          finalize();
        }
      }, 1000);
    },
    onVADMisfire: () => {
      vadIsTalking.current = false;
    },
  });

  const PHASE_COMPLETING = 2;
  const isLoading = useMemo(() => player.current && player.current.phase === PHASE_COMPLETING && !player.current.isPlaying, []);

  const processTranscript = useCallback((composite, is_final) => {
    if(!player.current) return;
    const countWords = composite.split(' ').length;
    if (countWords > 1) player.current.onWord(composite); // at least 2 words
  }, []);

  const { finalize, dgIsTalking } = useDeepgram({
    micEnabled,
    language,
    isUserSpeaking: () => vadIsTalking.current,
    isAgentSpeaking: () => player.current && player.current.isPlaying,
    vadLoading: vad.loading,
    setTranscript,
    processTranscript,
    onFinal: (composite) => {
      console.log('Yup we got final', composite);
      player.current.startCompletion(composite)
    },
  });

  const toggleMic = () => {
    setMicEnabled(!micEnabled);
  };
  const stop = () => {
    if(player.current) player.current.stop();
  };

  useEffect(() => {
    console.log('VadDeepgramElevenLabsMicStream is initialized');
  }, []);

  return (
    <>
      {/*player.current && <>
      <Button onClick={() => {
        player.current.stream('Hej. Hur mår du idag då?');
      }}>
        prePhrase
      </Button>
      <Button onClick={() => {
         const text = `Björnen sover, björnen sover, i sitt lugna bo. Han är inte farlig, bara man är varlig. Men man kan dock, men man kan dock, honom aldrig tro.
         Imse vimse spindel klättra uppför trån. Ner faller regnet och spola spindeln bort. Upp stiger solen och torka bort allt regn. Imse vimse spindel klättra upp igen.`;
        player.current.stream(text);
      }}>
        streamElevenLabsAudio
      </Button>
      <Button onClick={async () => {
        await player.current.stop();
      }}>
        Stop Stream
      </Button>
      </>
      
      <Button onClick={() => stop()}>Stop</Button>*/}

      <div className="d-flex justify-content-center align-items-center w-100">
        {isLoading && (
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        )}
        {player.current && player.current.isPlaying && <Spinner color="primary" type="grow"></Spinner>}
        {player.current && !player.current.isPlaying && !isLoading && !vad.loading && (
          <Button color="danger" onClick={toggleMic}>
            {micEnabled ? (
              <span className="bi bi-mic"></span>
            ) : (
              <span className="bi bi-mic-mute"></span>
            )}
          </Button>
        )}
      </div>
      {/* <p>VAD: {vadIsTalking.current ? 'Yes' : 'No'}</p>
      <p>DG: {dgIsTalking.current ? 'Yes' : 'No'}</p>
      <p>isPlaying: {player.current && player.current.isPlaying ? 'Yes' : 'No'}</p> */}
    </>
  );
};

export default VadDeepgramElevenLabsMicStream;
