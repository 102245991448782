import React, { createContext, useState, useContext, useCallback } from 'react';
import ModalEditor from '../components/ModalEditor/ModalEditor';

const ModalEditorContext = createContext();

export const ModalEditorProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState({ title: '', message: {} });

  const showModal = useCallback((title, message) => {
    setModalProps({ title, message });
    setIsOpen(true);
  }, []);

  const hideModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <ModalEditorContext.Provider value={{ showModal, hideModal }}>
      {children}
      <ModalEditor isOpen={isOpen} toggle={hideModal} {...modalProps} />
    </ModalEditorContext.Provider>
  );
};

export const useModalEditor = () => useContext(ModalEditorContext);
