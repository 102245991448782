import React, { createContext, useState, useContext, useCallback } from 'react';
import ModalTakeNotes from '../components/ModalTakeNotes/ModalTakeNotes';

const ModalTakeNotesContext = createContext();

export const ModalTakeNotesProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState({ title: '', agents: [] });

  const showModal = useCallback((title, agents) => {
    setModalProps({ title, agents });
    setIsOpen(true);
  }, []);

  const hideModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <ModalTakeNotesContext.Provider value={{ showModal, hideModal }}>
      {children}
      <ModalTakeNotes isOpen={isOpen} toggle={hideModal} {...modalProps} />
    </ModalTakeNotesContext.Provider>
  );
};

export const useModalTakeNotes = () => useContext(ModalTakeNotesContext);
