import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    fallbackLng: window.default_language || 'en',
    debug: false,
    backend: {
      loadPath: '/static/svava_react/public/locales/{{lng}}/translation.json', // Update to match your file structure
    },
    whitelist: ['en', 'sv'], // Only allow these languages
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
