import React from 'react';
import { Spinner } from 'reactstrap';

const FlexLoader = () => {
  return (
    <div className='d-flex align-items-center justify-content-center w-100 h-100'>
      <Spinner
        color="primary"
        style={{
          height: '3rem',
          width: '3rem'
        }}
        type="grow"
      ></Spinner>
    </div>
  );
};

export default FlexLoader;
