import styled from 'styled-components';
import { Button } from 'reactstrap';

export const AgentCard = styled.div`
  margin-bottom: 1rem;
  .row {
    margin-right: 0;
    margin-left: 0;
  }
`;

export const AgentProgress = styled.div`
  border: 1px solid darkmagenta;
  background-color: cornsilk;
  height: 0.8em;
  margin: 0.5em 0;
  display: flex;
`;

export const AgentProgressSegment = styled.div`
  height: 0.8em;
  border: 1px solid lightgray;
  background-color: lightgray;
  flex: 1;
`;

export const AgentProgressSegmentDone = styled(AgentProgressSegment)`
  background-color: darkmagenta;
`;

export const AgentText = styled.div`
  small {
    font-size: smaller !important;
  }
`;

export const StyledDropdown = styled.div`
  position: absolute;
  top: 0.25em;
  right: 0.25em;
  transform: translate(-10%, 10%);
  cursor: pointer;
  font-weight: smaller;
`;

export const StarButton = styled(Button)`
  border: none;
  background: transparent;
  color: inherit;
  &:hover, &:focus {
    outline: none;
    box-shadow: none;
  }
`;
