import { useEffect, useCallback, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useParams, useNavigate } from 'react-router-dom';
import apiService from '../utils/apiService';
import { useWSManager } from '../contexts/WSManagerContext';
import useStore from '../store';

export const TopicDataSync = () => {
  const navigate = useNavigate();
  const { teamId, agentId, documentId, topicId } = useParams();
  const { loggedIn, topic, setTopic, workspace } = useStore((state) => ({
    topic: state.topic,
    setTopic: state.setTopic,
    loggedIn: state.loggedIn,
    workspace: state.workspace,
  }));
  const { manageSubscriptions } = useWSManager();
  const [previousTopicId, setPreviousTopicId] = useState(null);
  const componentId = useRef(uuidv4()); // Unique ID for this component instance

  const fetchTopic = useCallback(async (teamId, agentId, documentId, topicId) => {
    if (!loggedIn) return;
    if (!teamId && !agentId && !documentId && !topicId) return;
    const endpoint = `/api/topic/find/`;
    const formData = {};
    if (topicId) {
      formData.id = topicId;
    } else {
      if (teamId) { formData.team_id = teamId; formData.type = 'TEAM'; }
      if (agentId) { formData.agent_id = agentId; formData.type = 'AGENT'; }
      if (documentId) { formData.document_id = documentId; formData.type = 'PROJECT_DOCUMENT'; }
    }
    try {
      const response = await apiService.post(endpoint, formData);
      if (response.success) {
        console.log('Chat history loaded successfully', response.data, formData);
        setTopic(response.data);
      } else {
        console.error('Operation failed:', response.message);
      }
    } catch (error) {
      console.error('Error during operation:', error);
      // Topic may have been deleted, attempt to navigate to the parent page
      if (topicId && agentId) navigate(`/${workspace.id}/coworker/${agentId}`);
      else if (topicId && teamId) navigate(`/${workspace.id}/team/${teamId}`);
      else navigate(`/${workspace.id}/resources`);
    }
  }, [loggedIn, setTopic, navigate, workspace?.id]);

  useEffect(() => {
    if (teamId || agentId || documentId) {
      fetchTopic(teamId, agentId, documentId, topicId);
    }
  }, [agentId, fetchTopic, teamId, documentId, topicId]);

  const setTopicChatHistory = useStore((state) => state.setTopicChatHistory);

  const updateChatHistory = useCallback((message) => {
    console.log('Updating chat history with message:', message);
    setTopicChatHistory((currentChatHistory) => {
      const updatedChatHistory = [...currentChatHistory];
      const existingIndex = updatedChatHistory.findIndex(m => m.id === message.id);
      
      if (existingIndex !== -1) {
        updatedChatHistory[existingIndex] = { ...updatedChatHistory[existingIndex], ...message };
      } else {
        updatedChatHistory.push(message);
      }

      return updatedChatHistory;
    });
  }, [setTopicChatHistory]);

  useEffect(() => {
    if (!topic?.id) return;
    if (topic.id === previousTopicId) return;
    const handleMessage = (event) => {
      const obj = JSON.parse(event.data);
      const type = obj.type;
      const data = obj.data;
      if (type === 'UPDATE_MESSAGE_STREAM') {
        console.log('New SSE stream [TopicDataSync]:', type, obj);
        updateChatHistory(data);
      } else if (type === 'UPDATE_TOPIC') {
        console.log('New SSE stream [TopicDataSync]:', type, obj);
        fetchTopic(teamId, agentId, documentId, topicId);
      }
    };

    const subscriptionChanges = [];
    if (previousTopicId) subscriptionChanges.push({ channelName: `topic-${previousTopicId}`, componentId: componentId.current });
    subscriptionChanges.push({ channelName: `topic-${topic.id}`, handleMessage, componentId: componentId.current });
    manageSubscriptions(subscriptionChanges);

    setPreviousTopicId(topic.id);
  }, [topic?.id, manageSubscriptions, previousTopicId, fetchTopic, teamId, agentId, documentId, topicId, updateChatHistory]);

  return null;
};

export default TopicDataSync;
