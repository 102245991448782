import styled from 'styled-components';

export const StyledOr = styled.div`
  text-align: center;
  margin: 1rem 0;
  position: relative;
  font-weight: bold;
  font-style: italic;
  font-size: 1.2rem;
  padding: 0 1rem;
  max-width: 500px;
  
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 45%;
    height: 1px;
    background-color: #ccc;
  }
  
  &::before {
    left: 0;
  }
  
  &::after {
    right: 0;
  }
`;