import React from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';
import { NavLink as StrapNavLink, Button } from 'reactstrap';
import styled from 'styled-components';

const CustomStyledNavLink = styled(StrapNavLink)`
  color: white;
  font-weight: 800;
  font-size: 1.1em;
  &:hover {
    color: orange;
  }
  &:focus {
    color: orange;
  }
`;

export const StyledNavLink = ({ to, children, ...rest }) => {
  return (
    <CustomStyledNavLink {...rest} tag={RRNavLink} to={to}>
      {children}
    </CustomStyledNavLink>
  );
};

const CustomStyledNavLinkDocument = styled(StrapNavLink)`
  color: ${props => props.isactive === 'true' ? 'orange' : 'white'};
  font-size: 0.95em;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    color: orange;
    .star-button {
      color: gold; // Show the button on hover
    }
  }
  &:focus {
    color: orange;
  }
`;

export const StyledNavLinkDocument = ({ to, isactive, children, ...rest }) => {
  return (
    <CustomStyledNavLinkDocument {...rest} isactive={isactive} tag={RRNavLink} to={to}>
      {children}
    </CustomStyledNavLinkDocument>
  );
};

export const StarButton = styled(Button)`
  background: transparent;
  border: none;
  color: transparent; // Initially hidden
  cursor: pointer;
  transition: color 0.3s;
  padding: 0.1em;
  margin-left: auto; // Align to the right
`;
