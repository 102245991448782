import React, { useState, useMemo, useCallback } from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import apiService from '../../../../utils/apiService';
import useStore from '../../../../store';
import EditCustomAgentModal from '../../../../components/EditCustomAgentModal/EditCustomAgentModal';
import DesignCustomAgentModal from '../../../../components/DesignCustomAgentModal/DesignCustomAgentModal.js';
import FlexLoader from '../../../../components/FlexLoader/FlexLoader';
import SharedAgent from './SharedAgent';

function SharedAgentsList() {
  const { t } = useTranslation();
  const { meeting, remAgent, agents, workspace } = useStore((state) => ({
     meeting: state.meeting,
     remAgent: state.remAgent,
     agents: state.agents,
     workspace: state.workspace
  }));
  
  const handleDelete  = useCallback( async (agentId) => {
    console.log('Deleting agent with id:', agentId);
    try {
      const response = await apiService.delete(`/api/agents/${agentId}/delete/`);
      if (!response.success) {
        console.error('Operation failed:', response.message);
        return;
      }
      console.log('Agent deleted successfully');
      remAgent(agentId);
    } catch (error) {
      console.error('Error during operation:', error);
    }
  }, [remAgent]);

  const handleAddToMeeting = useCallback(async (agentId) => {
    if (!meeting) {
      console.error('No meeting found');
      return;
    }
    try {
      const meetingId = meeting.id;
      console.log('Adding agent with id:', agentId, 'to meeting', meetingId);
      const response = await apiService.post(`/api/meetings/${meetingId}/add-agent/`, {
        agent_id: agentId
      });
      if (!response.success) {
        console.error('Operation failed:', response.message);
        return;
      }
      
      /*const agent = agents.find(a => a.id === agentId);
      if (agent) {
        addAgent({ 
          ...agent, 
          project_ids: [...(agent.project_ids || []), projectId] 
        });
      }*/
      console.log('Agent added successfully!');
    } catch (error) {
      console.error(error);
    }
  }, [meeting]);

  const handleRemFromMeeting = useCallback(async (agentId) => {
    if (!meeting) { console.error('No meeting found'); return; }
    console.log('Removing agent with id:', agentId);
    try {
      const response = await apiService.post(`/api/meetings/${meeting.id}/remove-agent/`, {
        agent_id: agentId
      });
      if (!response.success) {
        console.error('Operation failed:', response.message);
        return;
      }
      console.log('Agent removed successfully');
      /*const agent = agents.find(a => a.id === agentId);
      addAgent({ 
        ...agent, 
        project_ids: agent.project_ids.filter(id => id !== project.id) 
      });*/
    } catch (error) {
      console.error('Error during operation:', error);
    }
  }, [meeting]);

  const agentsInMeeting = useMemo(() => {
    if (!agents || !meeting || !meeting.agent_ids) return [];
    return agents.filter((a) => {
      if (!a) return false;
      return meeting.agent_ids.includes(a.id);
    });
  }, [agents, meeting]);

  const agentsNotInMeeting = useMemo(() => {
    if (!agents || !meeting || !workspace || !meeting.agent_ids) return [];
    return agents.filter((a) => 
      a && 
      a.workspace_id === workspace.id && 
      !meeting.agent_ids.includes(a.id)
    );
  }, [agents, meeting, workspace]);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const toggleEditModal = () => setEditModalOpen(!editModalOpen);
  const [editModalAgent, setEditModalAgent] = useState(undefined);

  const [designModalOpen, setDesignModalOpen] = useState(false);
  const toggleDesignModal = useCallback(() => setDesignModalOpen(!designModalOpen), [designModalOpen]);
  const [designModalCounter, setDesignModalCounter] = useState(0);
  
  /*const handleDesignCoWorker = useCallback(() => {
    setDesignModalCounter(designModalCounter + 1);
    toggleDesignModal();
  }, [designModalCounter, toggleDesignModal]);*/
  const handleEditCoWorker = (agentId) => {
    const mAgent = agents.find((a) => a.id === agentId);
    setEditModalAgent(mAgent);
    toggleEditModal();
  };

  if(!agents) return (<FlexLoader />);

  return (<>
    <Row>
      <Col sm="12">
        <h1>{t('Invite co-worker')}</h1>
      </Col>
    </Row>
    {meeting && <>
      {/*<div className='d-flex'>
        <Button className="add-member-button btn-primary btn-sm mb-4" onClick={() => handleDesignCoWorker()}>
          <i className="bi bi-person-plus-fill"></i> {t('Design co-worker')}
        </Button>
      </div>*/}
      {agentsInMeeting && agentsInMeeting.length === 0 && (<p>{t('The call is empty.')}</p>)}
      <Row>
        {agentsInMeeting && agentsInMeeting.map(agent => (
          <Col sm="12" key={`agent-in-project-${agent.id}`}>
            {agent.type === 'CUSTOM_AGENT' ? (
              agent.enabled && (
                <SharedAgent 
                  agent={agent}
                  handleDelete={handleDelete}
                  handleRemFromMeeting={handleRemFromMeeting}
                  handleEditCoWorker={handleEditCoWorker}
                />
              )
            ) : (
              <SharedAgent 
                agent={agent}
                handleDelete={handleDelete}
                handleRemFromMeeting={handleRemFromMeeting}
                handleEditCoWorker={handleEditCoWorker}
              />
            )}
          </Col>
        ))}
      </Row>
    </>}
    {agentsNotInMeeting && agentsNotInMeeting.length > 0 && <>
      <Row>
        {agentsNotInMeeting.map(agent => (
          <Col sm="12" key={`agent-not-in-project-${agent.id}`}>
            {agent.type === 'CUSTOM_AGENT' ? (
              agent.enabled && (
                <SharedAgent 
                  agent={agent}
                  handleDelete={handleDelete}
                  handleAddToMeeting={handleAddToMeeting}
                  handleEditCoWorker={handleEditCoWorker}
                />
              )
            ) : (
              <SharedAgent 
                agent={agent}
                handleDelete={handleDelete}
                handleAddToMeeting={handleAddToMeeting}
                handleEditCoWorker={handleEditCoWorker}
              />
            )}
          </Col>
        ))}
      </Row>
    </>}
    <EditCustomAgentModal isOpen={editModalOpen} toggle={toggleEditModal} agent={editModalAgent} />
    <DesignCustomAgentModal key={`design-custom-agent-modal-${designModalCounter}`} isOpen={designModalOpen} toggle={toggleDesignModal} />
  </>);
}

export default SharedAgentsList;
