import PlayerCue from './PlayerCue';
import {
  splitSentences,
  fetchElevenLabsAudio,
  fetchLLMPhrase,
  fetchLLMCompletion
} from './PlayerHelpers';

const PHASES = {
  PREDICTING: 1,
  COMPLETING: 2,
}

class Player {
  isPlaying = false; // As soon as we move to prediction phase we are considered playing
  cue = new PlayerCue(() => {this.isPlaying = false;});
  phase = PHASES.PREDICTING; //  1: predicting, 2: completing
  topicId = null;
  sessionId = null; 
  orderNr= 0;
  latestUtterance = ''
  latestPhrase = '' // Latest starting Phrase from LLM
  latestPhraseOrderNr = 0;
  latestTTSOrderNr = 0;
  latestTTSPhrase = ''; // Latest starting Phrase to have Audio
  latestTTSAudio = undefined;
  latestTTSAlignment = undefined;
  latestFinalizedUterrance = '';
  pendingTTS = [];
  
  // A new VAD session has started
  constructor(topicId) {
    this.topicId = topicId;
  }
  newSession(sessionId) {
    this.cue = new PlayerCue(() => {this.isPlaying = false;});
    this.phase = PHASES.PREDICTING;
    this.sessionId = sessionId;
    this.orderNr = 0;
    this.latestUtterance = '';
    this.latestPhrase = '';
    this.latestPhraseOrderNr = 0;
    this.latestTTSOrderNr = 0;
    this.latestTTSPhrase = '';
    this.latestTTSAudio = undefined;
    this.latestTTSAlignment = undefined;
    this.latestUtterance = '';
    this.pendingTTS = [];
  }
  getSnapshot() {
    return {
      phase: this.phase,
      sessionId: this.sessionId,
      orderNr: this.orderNr,
      latestUtterance: this.latestUtterance,
      latestPhrase: this.latestPhrase,
      latestPhraseOrderNr: this.latestPhraseOrderNr,
      latestTTSPhrase: this.latestTTSPhrase,
      latestTTSAudio: this.latestTTSAudio,
      pendingTTS: this.pendingTTS,
    }
  }
  verifySnapshot(snapshot) {
    if (snapshot.phase !== this.phase) return false;
    if (snapshot.sessionId !== this.sessionId) return false;
    return true;
  }
  // -----------------------------------------
  // Phase 1: Predicting phrase
  // -----------------------------------------
  // We received new words from DG
  onWord(uterrance) {
    if(!uterrance) return; 
    if(this.latestUtterance === uterrance) return; // Same utterance as last time
    // We may want to start when there are at least 2 words
    this.latestUtterance = uterrance;
    this.orderNr++;
    const snapshot = this.getSnapshot();
    this.fetchPhrase(snapshot, uterrance);
  }
  async fetchPhrase(snapshot, uterrance) {
    if(!this.verifySnapshot(snapshot)) return;
    // Call LLM for phrase prediction
    try {
      const { phrase } = await fetchLLMPhrase(this.topicId, this.sessionId, this.latestPhrase, uterrance);
     
      if(!this.verifySnapshot(snapshot)) return;
      this.onPhrase(snapshot, phrase);
    } catch (error) {
      console.error('Error in fetchPhrase:', error);
    }
  }
  // We received a phrase prediction from LLM
  onPhrase(snapshot, phrase) {
    if(!this.verifySnapshot(snapshot)) return;
    if(snapshot.orderNr <= this.latestPhraseOrderNr) return; // outdated prediction, a later beat us to it
    this.latestPhrase = phrase;
    this.latestPhraseOrderNr = snapshot.orderNr;
    // Request latest phrase from 11-labs
    this.fetchPhraseTTS(snapshot, phrase);
  }
  async fetchPhraseTTS(snapshot, phrase) {
    if(!this.verifySnapshot(snapshot)) return;
    const fetchId = Math.random().toString(36).substring(2, 15);
    this.pendingTTS.push(fetchId)
    // Call TTS
    try {
      // eslint-disable-next-line no-unused-vars
      const { id, text, audioUrl, alignment } = await this.fetchAudio(fetchId, phrase); // id and text should be echoes...
      this.onTTS(snapshot, phrase, audioUrl, alignment);
    } catch (error) {
      console.error('Error in fetchPhraseTTS:', error);
    } finally {
      this.pendingTTS = this.pendingTTS.filter((id) => id !== fetchId);
    }
  }
  onTTS(snapshot, text, audioUrl, alignment) {
    // Only verifying session id, because phase may have moved on
    if (snapshot.sessionId !== this.sessionId) return false;
    if(snapshot.orderNr <= this.latestTTSOrderNr) return; // outdated prediction, a later beat us to it
    console.log('onTTS text', text);
    console.log('onTTS audioUrl', audioUrl);
    console.log('onTTS alignment', alignment);
    this.latestTTSOrderNr = snapshot.orderNr;
    this.latestTTSPhrase = text;
    this.latestTTSAudio = audioUrl;
    this.latestTTSAlignment = alignment;
    this.onWaitTTSDone(this.getSnapshot());
  }

  // -----------------------------------------
  // Phase 2: Completing phrase
  // -----------------------------------------

  startCompletion(composite) {
    this.phase = PHASES.COMPLETING;
    this.isPlaying = true;
    this.latestFinalizedUterrance = composite;
    this.waitingForTTS = false;

    console.log('--COMPLETION ANALYSIS--');
    console.log('latestTTSPhrase', this.latestTTSPhrase);
    console.log('latestTTSAudio', this.latestTTSAudio);
    console.log('pendingTTS', this.pendingTTS);

    const snapshot = this.getSnapshot();
    // Three options
    // 1. We have a phrase, but no audio yet
    // 2. We have a phrase and audio
    // 3. We have no phrase
    if (this.latestTTSPhrase && !this.latestTTSAudio && this.pendingTTS.length > 0) {
      // We have a phrase, no audio, and pending TTS. Lets wait
      this.waitingForTTS = true;
      setTimeout(() => this.onWaitTTSDone(snapshot), 1000);
    } else if (this.latestTTSPhrase && this.latestTTSAudio) {
      // We have a phrase and audio, lets complete the phrase
      this.fetchCompletion(snapshot, this.latestFinalizedUterrance);
    } else {
      // We dont have anything, lets fetch a full response
      this.fetchFullResponse(snapshot, this.latestFinalizedUterrance);
    }
  }
  onWaitTTSDone(snapshot) {
    if(!this.verifySnapshot(snapshot)) return;
    if(!this.waitingForTTS) return;
    this.waitingForTTS = false; // Ensure this only happens once
    // Check if we have a phrase and audio
    if (this.latestTTSPhrase && this.latestTTSAudio) {
      // Move on to complete the phrase
      this.fetchCompletion(snapshot, this.latestFinalizedUterrance);
    } else {
      // We don't have a phrase and audio, so we need to fetch complete response
      this.fetchFullResponse(snapshot, this.latestFinalizedUterrance);
    }
  }
  // ALT 1: Fetch completion to our initial phrase
  async fetchCompletion(snapshot, composite) {
    if(!this.verifySnapshot(snapshot)) return;
    // TODO: Start playing our this.latestTTSAudio
    const id = Math.random().toString(36).substring(2, 15);
    this.cue.playPhraseAudio(id, this.latestTTSPhrase, this.latestTTSAudio, this.latestTTSAlignment);
    // Fetch completion from LLM
    const { phrase } = await fetchLLMCompletion(this.topicId, this.sessionId, this.latestPhrase, composite);
    if(!this.verifySnapshot(snapshot)) return;
    this.onCompletionAudios(snapshot, phrase);
  }
  // ALT 2: Fetch full response
  async fetchFullResponse(snapshot, composite) {
    if(!this.verifySnapshot(snapshot)) return;
    // Fetch iniial phrase
    const { phrase: initialPhrase } = await fetchLLMPhrase(this.topicId, this.sessionId, this.latestPhrase, composite);
    if(!this.verifySnapshot(snapshot)) return;

    const fetchId = Math.random().toString(36).substring(2, 15);
    const { id, text, audioUrl, alignment } = await this.fetchAudio(fetchId, initialPhrase);
    if(!this.verifySnapshot(snapshot)) return;
    this.cue.playPhraseAudio(id, text, audioUrl, alignment);

    // Fetch completion from LLM
    const { phrase: endPhrase } = await fetchLLMCompletion(this.topicId, this.sessionId, initialPhrase, composite);
    if(!this.verifySnapshot(snapshot)) return;
    this.onCompletionAudios(snapshot, endPhrase);
  }
  
  async onCompletionAudios(snapshot, texts) {
    if(!this.verifySnapshot(snapshot)) return;
    const audios = splitSentences(texts);
    this.cue.setupCompletionAudios(audios);
    const batchSize = 3;
    // Create batches
    const batches = audios.reduce((acc, _, index) => {
      if (index % batchSize === 0) {
        acc.push(audios.slice(index, index + batchSize));
      }
      return acc;
    }, []);
    // Process batches
    await batches.reduce(async (memo, batch) => {
      await memo;
      console.log('Running batch', batch);
      await Promise.all(batch.map(async (audio) => {
        if(!this.verifySnapshot(snapshot)) return;
        const result = await this.fetchAudio(audio.id, audio.text);
        if(!this.verifySnapshot(snapshot)) return;
        this.cue.setCompletionAudio(result.id, result.audioUrl, result.alignment);
      }));
      return Promise.resolve();
    }, Promise.resolve());
  }

  fetchAudio(id, text) {
    const voiceId = '21m00Tcm4TlvDq8ikWAM';
    const apiKey = 'sk_8bdc2915bb59a3f77f29443f6a5e7cd61db2f479cdd75e0b';
    return fetchElevenLabsAudio(apiKey, voiceId, id, text);
  }

  stop() {
    this.cue.stop();
  }
}

module.exports = Player;