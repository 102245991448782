import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Container } from './Dropzone.styles';
import { useTranslation } from 'react-i18next';

function Dropzone({ onDrop }) {
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({ current: 0, total: 0 });

  const handleDrop = useCallback(async (acceptedFiles) => {
    setIsUploading(true);
    setUploadProgress({ current: 0, total: acceptedFiles.length });

    for (let i = 0; i < acceptedFiles.length; i++) {
      await onDrop([acceptedFiles[i]]);
      setUploadProgress(prev => ({ ...prev, current: i + 1 }));
    }

    setIsUploading(false);
    setUploadProgress({ current: 0, total: 0 });
  }, [onDrop]);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({ 
    onDrop: handleDrop,
    disabled: isUploading,
    multiple: true // Allow selecting multiple files, but we'll handle them sequentially
  });

  // Apply conditional styling or classes based on the props
  const containerStyle = {
    borderColor: isUploading ? 'orange' : 
                 isFocused ? 'blue' : 
                 isDragAccept ? 'green' : 
                 isDragReject ? 'red' : 'gray',
    opacity: isUploading ? 0.6 : 1,
    cursor: isUploading ? 'not-allowed' : 'pointer'
  };

  return (
    <div className="container">
      <Container {...getRootProps()} style={containerStyle}>
        <input {...getInputProps()} />
          {isUploading ? (
          <p>{t('Uploading file')}{uploadProgress.current}/{uploadProgress.total}</p>
        ) : (
          <p>{t('Drag \'n\' drop some files here, or click to select files')}</p>
        )}
      </Container>
    </div>
  );
}

export default Dropzone;