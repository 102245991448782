import React from 'react';
import { ModalConferenceProvider } from './ModalConferenceContext';
import { ModalVideoCallProvider } from './ModalVideoCallContext';
import { ModalTakeNotesProvider } from './ModalTakeNotesContext';
import { ModalSelectRolesProvider } from './ModalSelectRolesContext';
import { ModalToolProvider } from './ModalToolContext';
import { ModalCreateWorkflowProvider } from './ModalCreateWorkflowContext';
import { ModalEditorProvider } from './ModalEditorContext';
import { ModalWorkflowEditorProvider } from './ModalWorkflowEditorContext';

export const ModalsWrapper = ({ children }) => {
  return (
    <ModalConferenceProvider>
      <ModalVideoCallProvider>
        <ModalTakeNotesProvider>
          <ModalSelectRolesProvider>
            <ModalToolProvider>
              <ModalCreateWorkflowProvider>
                <ModalEditorProvider>
                  <ModalWorkflowEditorProvider>
                    {children}
                  </ModalWorkflowEditorProvider>
                </ModalEditorProvider>
              </ModalCreateWorkflowProvider>
            </ModalToolProvider>
          </ModalSelectRolesProvider>
        </ModalTakeNotesProvider>
      </ModalVideoCallProvider>
    </ModalConferenceProvider>
  );
};