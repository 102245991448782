import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, CardBody, Button } from 'reactstrap';
    
import { CustomCard, AgentText, AgentImage } from './AgentCard.styles.js';

const AgentCard = ({ role, image, handleAddToTeam, isRoleAdded, maxDescriptionLength=200}) => {
  const { name, description } = role;
  const { image_id, image_url } = image;
  const { t } = useTranslation();

  return (
    <CustomCard className="mb-3">
    <Row className="g-0">
      <Col sm="12" md={{ size: 3 }}>
        <AgentImage
          style={{
            backgroundImage: `url(${image_url})`,
          }}
          className="rounded"
          alt={name}
        />
      </Col>
      <Col>
        <CardBody className='p-3'>
          <h5 className='p-0 m-0'>{name}</h5>
          <AgentText>
            <div className='mb-2'>
              <small>{description.length > maxDescriptionLength ? `${description.slice(0, maxDescriptionLength)}...` : description}</small>
            </div>
            <Button size="sm" color="primary" onClick={() => handleAddToTeam({...role, image_id})} disabled={isRoleAdded}>
              <i className="bi bi-person-plus-fill"></i> {t('Add')}
            </Button>
          </AgentText>
        </CardBody>
      </Col>
    </Row>
  </CustomCard>
  );
};

export default AgentCard;
