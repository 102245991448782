import { useEffect, useCallback, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useWSManager } from '../contexts/WSManagerContext';
import apiService from '../utils/apiService';
import useStore from '../store';

export const DocumentDataSync = () => {
  const { documentId } = useParams();
  const { setDocument } = useStore((state) => ({
    setDocument: state.setDocument,
  }));
  const { manageSubscriptions } = useWSManager();
  const [previousDocumentId, setPreviousDocumentId] = useState(null);
  const componentId = useRef(uuidv4()); // Unique ID for this component instance

  // const [isLoading, setIsLoading] = useState(false);
  // const [error, setError] = useState(null);

  const fetchDocument = useCallback(async (documentId) => {
    // if (!loggedIn) return;
    // console.log('Fetching document for ID:', documentId);
    // setIsLoading(true);
    // setError(null);
    try {
      const response = await apiService.get(`/api/document/${documentId}/?_=${new Date().getTime()}`);
      if (response.success) {
        console.log('Load Document successful:', response.data.document);
        setDocument(response.data.document);
       // wen to scroll window.scrollTo(0, 0);
      } else {
        // setError('Failed to load document');
      }
    } catch (error) {
      // setError('An error occurred while fetching the document');
    } finally {
      // setIsLoading(false);
    }
  }, [setDocument]); // csrfToken,

  useEffect(() => {
    if (documentId) fetchDocument(documentId);
  }, [documentId, fetchDocument]);

  /*
  useEffect(() => {
    let intervalId;
    if (document?.streaming_response_id) {
      intervalId = setInterval(fetchDocument, 500);
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [document?.streaming_response_id, fetchDocument]);*/

  useEffect(() => {
    if (!documentId) return;
    if (documentId === previousDocumentId) return;
    // const channelName = `document-${documentId}`;
    // console.log('[DocumentDataSync] #1 Subscribing to channel:', channelName, 'Previous:', previousDocumentId, 'Current:', documentId);
    const handleMessage = (event) => {
      const obj = JSON.parse(event.data);
      const type = obj.type;
      if (type === 'UPDATE_DOCUMENT_CONTENT') {
        console.log('New SSE stream [DocumentDataSync]:', type, obj);
        fetchDocument(documentId);
      }
    };

    const subscriptionChanges = [];
    if (previousDocumentId) subscriptionChanges.push({ channelName: `document-${previousDocumentId}`, componentId: componentId.current });
    subscriptionChanges.push({ channelName: `document-${documentId}`, handleMessage, componentId: componentId.current });
    manageSubscriptions(subscriptionChanges);

    setPreviousDocumentId(documentId);
  }, [documentId, manageSubscriptions, previousDocumentId, fetchDocument]);


  return null;
};

export default DocumentDataSync;
