import styled from 'styled-components';

export const StyledTextArea = styled.textarea`
  resize: none;
  overflow: hidden;
  transition: height 0.2s ease;
  box-sizing: border-box; // Ensure padding and border are included in the height calculation
  font-size: 16px; // Adjust based on your design
  line-height: 1.5; // Adjust based on your design
  min-height: calc(16px * 1.5); // Example calculation for min-height based on font-size and line-height
  padding: 8px; // Adjust based on your design
  border: 1px solid #ccc; // Adjust based on your design
  // Reset any other inherited styles that might affect height
`;
