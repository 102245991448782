import styled, { css } from 'styled-components';

const AVATAR_DIMENSION = 300;
export const AvatarContainer = styled.div`
  position: relative;
  width: ${AVATAR_DIMENSION}px;
  height: ${AVATAR_DIMENSION}px;
  margin: 0 auto;
`;

export const Emoji = styled.div`
  visibility: ${props => props.show === "true" ? 'visible' : 'hidden'};
  transition: opacity 0.5s ease-in-out;
  opacity: ${props => props.show === "true" ? 1 : 0};
  position: absolute;
  bottom: 10px;
  right: 10px;

  ${props => props.show === "true" ? css`
    animation: fadeIn 0.5s;
  ` : css`
    animation: fadeOut 0.5s;
  `}

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  @keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
  }
`;
