import React, { useMemo, useState, useCallback } from 'react';
import { NavItem, Collapse } from 'reactstrap';

import { StyledNavLink, StyledSubNavLink } from './NavItems.styles.js';
import DocumentLink from '../DocumentLink/DocumentLink';
import AgentLink from '../AgentLink/AgentLink';
import TeamLink from '../TeamLink/TeamLink';
import useStore from '../../store';
import { SpinnerGrowMini } from '../DocumentLink/DocumentLink.styles.js';
import apiService from '../../utils/apiService';
import EditProfileModal from '../EditProfileModal/EditProfileModal';
import { useTranslation } from 'react-i18next';
import { getFeatureFlag } from '../../utils/featureFlags';
import WorkspaceItems from './WorkspaceItems/WorkspaceItems';

const NavItems = ({ toggle = () => {} }) => {
  const { t } = useTranslation();

  const { loggedIn, loading, projects, project, agents, teams, organization, workspace, workspaces, isVerified, daysLeftOnTrial, subscribed } = useStore((state) => ({
    loggedIn: state.loggedIn,
    loading: state.loading,
    projects: state.projects,
    project: state.project,
    agents: state.agents,
    teams: state.teams,
    organization: state.organization,
    workspace: state.workspace,
    workspaces: state.workspaces,
    isVerified: state.isVerified,
    daysLeftOnTrial: state.daysLeftOnTrial,
    subscribed: state.subscribed,
  }));

  const [isChatOpen, setIsAgentsOpen] = useState(true);
  const toggleChat = () => setIsAgentsOpen(!isChatOpen);

  const [isTeamsOpen, setIsTeamsOpen] = useState(true);
  const toggleTeams = () => setIsTeamsOpen(!isTeamsOpen);

  const [isProjectAgentsOpen, setIsProjectAgentsOpen] = useState(false);
  const toggleProjectAgents = () => setIsProjectAgentsOpen(!isProjectAgentsOpen);

  const [isSessionsOpen, setIsSessionsOpen] = useState(true);
  const toggleSessions = () => setIsSessionsOpen(!isSessionsOpen);

  const [isWorkflowsOpen, setIsWorkflowsOpen] = useState(true);
  const toggleWorkflows = () => setIsWorkflowsOpen(!isWorkflowsOpen);

  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const toggleProfile = () => setIsProfileOpen(!isProfileOpen);

  // Functions for Basic Modal
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const agentsInProject = useMemo(() => agents && agents.filter((a) => {
    if (a === undefined || a === null) {console.log('OOPS UNDEF AGENT', agents);return false;}
    return project && project.id && a.project_ids  && a.project_ids.length && a.project_ids.includes(project.id);
  }), [agents, project]);
  const generalGPT = useMemo(() => agents && agents.find((a) => a.roles && a.roles.includes("GENERAL_GPT")), [agents]);
  
  const projectsInStore = useMemo(() => projects.filter((p) => p.super_type === 'PROJECT' && p.workspace_id === workspace?.id), [projects, workspace]);
  const workflowsInStore = useMemo(() => projects.filter((p) => p.super_type === 'WORKFLOW' && p.workspace_id === workspace?.id), [projects, workspace]);

  
  
  // Create a useCallback which will return string with ellipsis if its longer than 10 characters
  const truncateString = useCallback((str) => {
    if (str.length > 30) {
      return str.slice(0, 30) + '...';
    }
    return str;
  }, []);

  const handleDemoteAgent = async (agentId) => {
    if (!agentId) {
      console.error('Agent ID is missing:', agentId);
      return;
    }
    try {
      const response = await apiService.post(`/api/agents/${agentId}/demote/`);
      if (!response.success) {
        console.error('Operation failed:', response.message);
        return;
      }
      // Assuming you want to update the agent's status in your local state as well
      // updateAgentStatus(agentId, 'started'); // Implement this function based on your state management
    } catch (error) {
      console.error('handleDemoteAgent error', error);
    }
  };

  const handleDemoteTeam = async (teamId) => {
    if (!teamId) {
      console.error('Team ID is missing:', teamId);
      return;
    }
    try {
      const response = await apiService.post(`/api/teams/${teamId}/demote/`);
      if (!response.success) {
        console.error('Operation failed:', response.message);
        return;
      }
    } catch (error) {
      console.error('handleDemoteTeam error', error);
    }
  };

  const hasSubscriptions = useMemo(()=> {
    if (!getFeatureFlag("SUBSCRIPTIONS")) return false;
    // If organization is not admin or billing contact, hide billing
    if (organization && !organization.is_admin && !organization.is_billing_contact) {
      return false;
    }
    return true;
  }, [organization])

  const workspacePrefix = useMemo(() => workspace && workspace.id ? `${workspace.id}/` : '', [workspace]);
  const isUserVerified = useMemo(() => !getFeatureFlag("NEW REGISTRANTS MUST BE VERFIED") || isVerified, [isVerified]);
  
  if (loading) return null;

  if (!loggedIn) {
    return (
      <>
        <div>
          <NavItem>
            <StyledNavLink onClick={toggle} to="/">{t('Home')}</StyledNavLink>
          </NavItem>
          <NavItem>
            <StyledNavLink onClick={toggle} to="/login/">{t('Login')}</StyledNavLink>
          </NavItem>
        </div>
      </>
    );
  }

  // If user is not verified, or not in a workspace and not in a project, show only logout
  if (!isUserVerified || !(workspace || project)) {
    return (
      <>
        <div>
          {isUserVerified && getFeatureFlag("WORKSPACES") && workspaces && <WorkspaceItems />}
          {isUserVerified && !getFeatureFlag("WORKSPACES") && workspaces && workspaces.length && <NavItem>
            <StyledNavLink onClick={toggle} to={`/${workspaces[0].id}/members/`}><i className="bi bi-arrow-left"></i>&nbsp;{t('Back')}</StyledNavLink>
          </NavItem>}
          <NavItem>
            <StyledNavLink onClick={toggle} to="/logout/">{t('Logout')}</StyledNavLink>
          </NavItem>
        </div>
      </>
    );
  }

  // If user has subscriptions and is on trial, show a link to the billing page
  if (hasSubscriptions && loggedIn && !subscribed && daysLeftOnTrial === 0) {
    return (
      <>
        <div>
          <NavItem>
            <StyledSubNavLink onClick={toggle} to={`/${workspacePrefix}members/`}>
              <i className="bi bi-people"></i>&nbsp;
              {t('Members')}
            </StyledSubNavLink>
          </NavItem>
          <NavItem>
            <StyledSubNavLink onClick={toggle} to={`/${workspacePrefix}billing/`}>
              <i className="bi bi-box-arrow-right"></i>&nbsp;
              {t('Subscriptions')}
            </StyledSubNavLink>
          </NavItem>
          <NavItem>
            <StyledNavLink onClick={toggle} to="/logout/">{t('Logout')}</StyledNavLink>
          </NavItem>
        </div>
      </>
    );
  }

  if (!workspace && !project) return null;

  return (
    <>
      <div className='d-print-none'>
        {(!project || !project.id) && (
          <>
          {getFeatureFlag("WORKSPACES") && workspaces ?
            <WorkspaceItems />
            : <NavItem>
              <StyledNavLink onClick={toggle} to="/">{t('Home')}</StyledNavLink>
            </NavItem>}
          <NavItem>
            <StyledNavLink onClick={toggleChat}>
              {isChatOpen ? '▼' : '▶'}  {t('Chat')}
            </StyledNavLink>
          </NavItem>
          <Collapse isOpen={isChatOpen} className='mb-4'>
            <div>
              {generalGPT && <AgentLink key={`agent-${generalGPT.id}`} workspace={workspace} agent={generalGPT} customIcon="bi-chat" />}
              {agents && agents.length > 0 && agents.filter((agent) => agent.promoted && agent.workspace_id === workspace?.id && !(agent.roles && agent.roles.includes("GENERAL_GPT"))).map((agent) =>
                <AgentLink onClick={toggle} key={`agent-${agent.id}`} workspace={workspace} project={project} agent={agent} handleDemoteAgent={getFeatureFlag("CO-WORKER PROMOTE") && handleDemoteAgent } />
              )}
              {getFeatureFlag("ADD CO-WORKER") && (<StyledSubNavLink onClick={toggle}to={`/${workspacePrefix}coworkers/`}>
                <i className="bi bi-plus"></i>&nbsp;
                {t('Add co-worker')}
              </StyledSubNavLink>)}
            </div>
          </Collapse>
          {getFeatureFlag("TEAMS") && <>
            <NavItem>
              <StyledNavLink onClick={toggleTeams}>
                {isTeamsOpen ? '▼' : '▶'}  {t('Teams')}
              </StyledNavLink>
            </NavItem>
            <Collapse isOpen={isTeamsOpen} className='mb-4'>
                {teams && teams.length > 0 && teams.filter((team) => team.promoted && team.workspace_id === workspace?.id).map((team) =>
                  <TeamLink onClick={toggle} key={`team-${team.id}`} workspace={workspace} team={team} handleDemoteTeam={handleDemoteTeam} />
                )}
              <NavItem>
                <StyledSubNavLink onClick={toggle}to={`/${workspacePrefix}team/`}>
                  <i className="bi bi-plus"></i>&nbsp;
                  {t('Add team')}
                </StyledSubNavLink>
              </NavItem>
            </Collapse>
          </>}
          {getFeatureFlag("SESSIONS") && <>
            <NavItem>
              <StyledNavLink onClick={toggleSessions}>
                {isSessionsOpen ? '▼' : '▶'} {t('Coaching')}
              </StyledNavLink>
            </NavItem>
            <Collapse isOpen={isSessionsOpen} className='mb-4'>
              {projectsInStore.map(session => (
                <NavItem key={session.id}>
                  <StyledSubNavLink onClick={toggle}to={session.first_document_id ? `/project/${session.id}/document/${session.first_document_id}` : `/project/${session.id}`}>
                  <i className="bi bi-gem"></i>&nbsp;
                  {truncateString(session.name)}</StyledSubNavLink>
                </NavItem>
              ))}
              <NavItem className='mb-4'>
                <StyledSubNavLink onClick={toggle}to={`/${workspacePrefix}projects`}>
                  <i className="bi bi-plus"></i>&nbsp;
                  {t('Add guided session')}
                </StyledSubNavLink>
              </NavItem>
            </Collapse>
          </>}
          {getFeatureFlag("WORKFLOWS") && <>
            <NavItem>
              <StyledNavLink to={`/${workspacePrefix}workflows`} onClick={toggleWorkflows}>
                {isWorkflowsOpen ? '▼' : '▶'} {t('Workflows')}
              </StyledNavLink>
            </NavItem>
            <Collapse isOpen={isWorkflowsOpen} className='mb-4'>
              {workflowsInStore.map(workflow => (
                <NavItem key={workflow.id}>
                  <StyledSubNavLink onClick={toggle}to={ workflow.first_document_id ? `/project/${workflow.id}/document/${workflow.first_document_id}` : `/project/${workflow.id}`}>
                  <i className="bi bi-gear"></i>&nbsp;
                  {truncateString(workflow.name)}</StyledSubNavLink>
                </NavItem>
              ))}
              <NavItem>
                <StyledSubNavLink onClick={toggle}to={`/${workspacePrefix}workflows`}>
                  <i className="bi bi-plus"></i>&nbsp;
                  {t('Add workflow')}
                </StyledSubNavLink>
              </NavItem>
            </Collapse>
          </>}
          {hasSubscriptions && <NavItem>
            <StyledSubNavLink onClick={toggle}to={`/${workspacePrefix}members/`}>
              <i className="bi bi-people"></i>&nbsp;
              {t('Members')}
            </StyledSubNavLink>
          </NavItem>}
          </>
        )}
        {project && project.id && (
          <>
          <NavItem>
            <StyledNavLink onClick={toggle} to={project.super_type === "WORKFLOW" ? `/${project.workspace_id}/workflows` : `/${project.workspace_id}/projects`}><i className="bi bi-arrow-left"></i>&nbsp;</StyledNavLink>
          </NavItem>
          <NavItem>
            <StyledSubNavLink onClick={toggle}to={`/project/${project.id}`}>{project.name}</StyledSubNavLink>
          </NavItem>
          {!project.is_generating && project.generated && (
            <>
            <NavItem>
              <StyledNavLink onClick={toggleProjectAgents}>
               {isProjectAgentsOpen ? '▼' : '▶'}  {t('Co-workers')}
              </StyledNavLink>
            </NavItem>
            <Collapse isOpen={isProjectAgentsOpen} className='mb-4'>
                {agentsInProject && agentsInProject.length > 0 && agentsInProject.map((agent) =>
                  <AgentLink onClick={toggle} key={`agent-${agent.id}`} workspace={workspace} project={project} agent={agent} />
                )}
              {/*<NavItem>
                <StyledSubNavLink onClick={toggle}to={`/project/${project.id}/coworkers/`}>
                  <i className="bi bi-plus"></i>&nbsp;
                  {t('Add co-worker')}
                </StyledSubNavLink>
              </NavItem>*/}
            </Collapse>
            </>)
          }
          </>
        )}
        {project && project.super_type === "PROJECT" && project.stages && project.stages.map((stage, index) => (
          <div className="mb-3" key={`stage-${stage.id}-${index}`}>
            <NavItem key={stage.id}>
              <StyledNavLink onClick={toggle} to={`/project/${project.id}/stage/${stage.id}/`}>{stage.title}</StyledNavLink>
            </NavItem>
            {stage && stage.documents && stage.documents.map((document) => (
              <DocumentLink key={document.id} project={project} document={document} />
            ))}
          </div>
        ))}
        {project && project.super_type === "WORKFLOW" && project.stages && project.stages.map((stage, index) => (
          <div key={`stage-${stage.id}-${index}`}>
            {stage && stage.documents && stage.documents.map((document) => (
              <DocumentLink key={document.id} project={project} document={document} doneStrikethrough={document.template_type !== 'FINAL_RESULT' && document.is_done} />
            ))}
          </div>
        ))}
        {project && project.is_generating && (
          <div>
            <NavItem className='text-white'>
              <StyledNavLink onClick={toggle} to="#">{t('Generating')}</StyledNavLink>
              <div className='mx-3'>
                <SpinnerGrowMini className="spinner-grow" role="status"></SpinnerGrowMini>
                <SpinnerGrowMini className="spinner-grow" role="status"></SpinnerGrowMini>
                <SpinnerGrowMini className="spinner-grow" role="status"></SpinnerGrowMini>
              </div>
            </NavItem>
          </div>
        )}
      {/*</div>
      <div className='d-print-none'>*/}
        <NavItem>
          <StyledNavLink onClick={toggleProfile}>
            {isProfileOpen ? '▼' : '▶'} {t('Profile')}
          </StyledNavLink>
        </NavItem>
        <Collapse isOpen={isProfileOpen} className='mb-4'>
          <NavItem>
            <StyledSubNavLink onClick={toggleModal}>
              <i className="bi bi-wrench-adjustable"></i>&nbsp;
              {t('Settings')}
            </StyledSubNavLink>
          </NavItem>
          <NavItem>
            <StyledSubNavLink onClick={() => window.open('mailto:elia.morling@svava.ai', '_blank')}>
              <i className="bi bi-send"></i>&nbsp;
              {t('Support')}
            </StyledSubNavLink>
          </NavItem>
          {hasSubscriptions && <NavItem>
            <StyledSubNavLink onClick={toggle} to={`/${workspacePrefix}billing/`}>
              <i className="bi bi-box-arrow-right"></i>&nbsp;
              {t('Subscriptions')}
            </StyledSubNavLink>
          </NavItem>}
          <NavItem>
            <StyledSubNavLink onClick={toggle} to="/logout/">
              <i className="bi bi-box-arrow-right"></i>&nbsp;
              {t('Logout')}
            </StyledSubNavLink>
          </NavItem>
        </Collapse>
      </div>
      <EditProfileModal isOpen={modal} toggle={toggleModal}/>
    </>
  );
};

export default NavItems;
