import { useTranslation } from 'react-i18next';

export const useAgentRoleLabel = () => {
  const { t } = useTranslation();
  
  const getAgentRoleLabel = (role) => {
    const roleCategoryLabels = {
      "ORGANIZER": t('Organizer'),
      "PERSONA_STAKEHOLDER": t('Persona/Stakeholder'),
      "EXPERT": t('Expert'),
      "RESEARCHER": t('Researcher')
    };
    return roleCategoryLabels[role] || role;
  };
  
  return getAgentRoleLabel;
};