import React from 'react';
import { Navbar, Nav } from 'reactstrap';
import NavItems from '../NavItems/NavItems';

const Sidebar = () => { 
  return (
    <Navbar expand="md" className='p-0'>
      <Nav vertical className="d-flex flex-column" style={{height: '100vh', overflowY: 'auto', paddingBottom: '2rem', paddingTop: '1rem'}}>
        <NavItems />
      </Nav>
    </Navbar>
  );
};

export default Sidebar;
