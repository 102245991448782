import React, { createContext, useContext, useState } from 'react';

const MeetingContext = createContext(null);

export const MeetingProvider = ({ children }) => {
  const [activeSpeakerId, setActiveSpeakerId] = useState(null);
  const [isChangingSpeaker, setIsChangingSpeaker] = useState(false);

  return (
    <MeetingContext.Provider value={{ activeSpeakerId, setActiveSpeakerId, isChangingSpeaker, setIsChangingSpeaker }}>
      {children}
    </MeetingContext.Provider>
  );
};

export const useMeeting = () => {
  const context = useContext(MeetingContext);
  if (!context) {
    throw new Error('useMeeting must be used within a MeetingProvider');
  }
  return context;
};