import React, { useImperativeHandle, forwardRef, useState, useRef } from 'react';
import { AvatarContainer, Emoji } from './AvatarAgent2D.styles';
import { useMeeting } from '../MeetingContext';
import AudioVisualization from './AudioVisualization';

const AvatarAgent2D = forwardRef(({
    agent,
    dimension = 300,
  }, ref) => {
  const [showHand, setShowHand] = useState(false);
  const { activeSpeakerId, isChangingSpeaker } = useMeeting();
  
  console.log('AvatarAgent2D render:', {
    activeSpeakerId,
    agentId: agent.id,
    isActive: agent.id === activeSpeakerId
  });

  const isActive = agent.id === activeSpeakerId;

  const audioAnalyzerRef = useRef(null);

  // Visualization state
  const [visualizationEnabled, setVisualizationEnabled] = useState(true);
  const [visualizationOpacity, setVisualizationOpacity] = useState(0.7);
  const [visualizationColor, setVisualizationColor] = useState({
    start: 'rgba(0, 255, 255, 0.2)',
    end: 'rgba(0, 255, 255, 0.8)'
  });

  // Visualization controls
  const visualizationControls = {
    enable: () => setVisualizationEnabled(true),
    disable: () => setVisualizationEnabled(false),
    toggle: () => setVisualizationEnabled(prev => !prev),
    setOpacity: (opacity) => setVisualizationOpacity(opacity),
    setColors: (startColor, endColor) => {
      setVisualizationColor({
        start: startColor,
        end: endColor
      });
    },
    getState: () => ({
      enabled: visualizationEnabled,
      opacity: visualizationOpacity,
      colors: visualizationColor
    }),
    setAnalyzer: (analyzer) => {
      if (audioAnalyzerRef.current) {
        audioAnalyzerRef.current.disconnect();
      }
      audioAnalyzerRef.current = analyzer;
    },
  };

  // Expose controls via ref
  useImperativeHandle(ref, () => ({
    setShowHand: (show) => setShowHand(show),
    visualization: visualizationControls,
    stopTalking: () => {
      console.log('🔵 stopTalking called');
    }
  }));


  return (
    <div>
      <AvatarContainer 
        $dimension={dimension}
        $isActive={isActive}
      >
        <AudioVisualization
          dimension={dimension}
          analyzer={audioAnalyzerRef.current}
          enabled={visualizationEnabled}
          opacity={visualizationOpacity}
          colors={visualizationColor}
        />

        {/* Avatar Image */}
        <div 
          style={{ 
            width: '100%',
            height: '100%',
            backgroundImage: `url(${agent.image_url})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1,
            borderRadius: '50%',
            overflow: 'hidden',
          }}
          className='d-flex justify-content-center align-items-center'
        >
          {isActive && isChangingSpeaker && <span className="spinner-border" role="status" aria-hidden="true"></span>}
        </div>
        {/* Hand Emoji */}
        <Emoji $show={showHand ? "true" : "false"}>
          ✋
        </Emoji>
      </AvatarContainer>
      <div className='text-center my-2'><strong>{agent.name}</strong></div>
    </div>
  );
});

export default AvatarAgent2D;
