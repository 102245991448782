import styled from 'styled-components';
import { Button } from 'reactstrap';

export const TeamCard = styled.div`
  margin-bottom: 1rem;
  .row {
    margin-right: 0;
    margin-left: 0;
  }
`;

export const TeamText = styled.div`
  small {
    font-size: smaller !important;
  }
`;

export const StyledDropdown = styled.div`
  position: absolute;
  top: 0.25em;
  right: 0.25em;
  transform: translate(-10%, 10%);
  cursor: pointer;
  font-weight: smaller;
`;

export const StarButton = styled(Button)`
  border: none;
  background: transparent;
  color: inherit;
  &:hover, &:focus {
    outline: none;
    box-shadow: none;
  }
`;

export const NumberOfAgents = styled.div`
  background-color: black;
  color: white;
  padding: 0.5em;
  font-size: smaller;
  width: fit-content;
  margin-left: auto;
`;

export const AgentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1px;
  width: 100%;
  height: 10em; // Adjust based on your design needs
`;

export const AgentBox = styled.div`
  background-size: cover;
  background-position: center;
  height: 100%;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;
