import React, { useRef, useEffect } from 'react';
import { Button } from 'reactstrap';
import { RealtimeClient } from '@openai/realtime-api-beta';

const VadOpenaiStream = ({ isOpen, setTranscript, stopTalking, onAudioAnalysis }) => {
  const clientRef = useRef(new RealtimeClient({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowAPIKeyInBrowser: true,
  }));
  
  const mediaStreamRef = useRef(null);
  const audioContextRef = useRef(null);
  const processorRef = useRef(null);
  const micEnabledRef = useRef(true);
  const isConnectedRef = useRef(false);
  const mountedRef = useRef(true);
  const playbackContextRef = useRef(null);
  const nextPlayTimeRef = useRef(0);
  const recentTranscriptRef = useRef('');
  const analyzerRef = useRef(null);

  useEffect(() => {
    const client = clientRef.current;
    const mounted = mountedRef.current;

    async function init() {
      try {
        // Set up client parameters
        // client.updateSession({ instructions: 'You are a great, upbeat friend.' });
        client.updateSession({ instructions: 'You are sitting in a multi-agent conference call. Only answer questions directed to you. Your name is John.' });
        client.updateSession({ voice: 'alloy' });
        client.updateSession({
          turn_detection: { type: 'server_vad' },
          input_audio_transcription: { model: 'whisper-1' },
        });

        // Connect to Realtime API
        await client.connect();
        isConnectedRef.current = true;
        console.log('Connected to OpenAI Realtime API');

        // Create a single AudioContext with exact sample rate
        playbackContextRef.current = new (window.AudioContext || window.webkitAudioContext)({
          sampleRate: 24000  // Must match OpenAI's sample rate
        });

        // Add analyzer node setup
        analyzerRef.current = playbackContextRef.current.createAnalyser();
        analyzerRef.current.fftSize = 2048;
        analyzerRef.current.connect(playbackContextRef.current.destination);

        // Send analyzer to AvatarAgent via callback
        if (onAudioAnalysis) {
          onAudioAnalysis(analyzerRef.current);
        }

        // Set up event handlers
        client.on('conversation.updated', (updated) => {
          if (!mounted) return;
          
          const { item, delta } = updated;
          // console.log('Conversation updated:', updated);
          
          if (delta?.audio) {
            // console.log('Received audio chunk:', delta.audio.length);
            const audioContext = playbackContextRef.current;
            const currentTime = audioContext.currentTime;
            
            // Convert Int16Array to Float32Array for Web Audio API
            const float32Data = new Float32Array(delta.audio.length);
            for (let i = 0; i < delta.audio.length; i++) {
              float32Data[i] = delta.audio[i] / 32768.0;  // Convert from Int16 to Float32
            }
            
            const audioBuffer = audioContext.createBuffer(1, float32Data.length, 24000);
            audioBuffer.getChannelData(0).set(float32Data);
            
            const source = audioContext.createBufferSource();
            source.buffer = audioBuffer;
            source.connect(analyzerRef.current);
            
            // Schedule the next chunk to play immediately after the previous one
            const startTime = Math.max(currentTime, nextPlayTimeRef.current);
            source.start(startTime);
            nextPlayTimeRef.current = startTime + audioBuffer.duration;
          }

          if (delta?.transcript) {
            // console.log('Transcript update:', delta.transcript, 'from:', item.role);
            
            if (item.role === 'assistant') {
              // Check if this is the start of a new sentence (starts with capital letter)
              if (delta.transcript.match(/^[A-ZÅÄÖ]/)) {
                // New sentence starting - clear the old one
                recentTranscriptRef.current = delta.transcript;
                setTranscript(recentTranscriptRef.current);
              } else {
                // Continue current sentence
                recentTranscriptRef.current += delta.transcript;
                setTranscript(recentTranscriptRef.current);
              }
            }
          }
        });

        client.on('error', (error) => {
          console.error('OpenAI client error:', error);
        });

        client.on('conversation.interrupted', () => {
          console.log('Conversation interrupted');
          recentTranscriptRef.current = '';
          setTranscript('');
        });

        // Add weather tool
        client.addTool(
          {
            name: 'get_weather',
            description: 'Retrieves the weather for a given lat, lng coordinate pair.',
            parameters: {
              type: 'object',
              properties: {
                lat: { type: 'number', description: 'Latitude' },
                lng: { type: 'number', description: 'Longitude' },
                location: { type: 'string', description: 'Name of the location' }
              },
              required: ['lat', 'lng', 'location']
            }
          },
          async ({ lat, lng, location }) => {
            console.log('Weather tool called with:', { lat, lng, location });
            try {
              const result = await fetch(
                `https://api.open-meteo.com/v1/forecast?latitude=${lat}&longitude=${lng}&current=temperature_2m,wind_speed_10m`
              );
              const json = await result.json();
              console.log('Weather data received:', json);
              return json;
            } catch (error) {
              console.error('Weather tool error:', error);
              throw error;
            }
          }
        );

        // Add tool event handlers
        client.on('conversation.item.completed', ({ item }) => {
          if (item.type === 'function_call') {
            console.log('Function call completed:', item);
          }
        });

        // Get microphone stream
        const stream = await navigator.mediaDevices.getUserMedia({ 
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true
          } 
        });
        mediaStreamRef.current = stream;
        console.log('Microphone initialized');

        // Set up audio context and processor
        const context = new (window.AudioContext || window.webkitAudioContext)({
          sampleRate: 24000
        });
        audioContextRef.current = context;
        
        const source = context.createMediaStreamSource(stream);
        const processor = context.createScriptProcessor(2048, 1, 1);
        processorRef.current = processor;

        // Handle audio processing
        processor.onaudioprocess = (e) => {
          if (!micEnabledRef.current || !isConnectedRef.current) return;

          try {
            const inputData = e.inputBuffer.getChannelData(0);
            // const volume = Math.max(...inputData.map(Math.abs));
            /*if (volume > 0.01) {
              console.log('Speaking detected:', volume.toFixed(4));
            }*/

            const int16Data = new Int16Array(inputData.length);
            for (let i = 0; i < inputData.length; i++) {
              int16Data[i] = Math.max(-32768, Math.min(32767, inputData[i] * 32768));
            }
            
            client.appendInputAudio(int16Data);
          } catch (error) {
            console.error('Audio processing error:', error);
          }
        };

        // Connect audio nodes
        source.connect(processor);
        processor.connect(context.destination);
        console.log('Audio pipeline ready');

        // Test the connection immediately
        /*console.log('Testing connection...');
        await client.sendUserMessageContent([{ 
          type: 'input_text', 
          text: 'Hello, can you hear me?' 
        }]);
        console.log('Test message sent');*/

        // Add audio stream handler with more logging
        client.on('audio_stream', (audioChunk) => {
          console.log('Received audio stream chunk, length:', audioChunk.length);
          if (!mounted) return;
          
          try {
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const audioBuffer = audioContext.createBuffer(1, audioChunk.length, 24000);
            audioBuffer.getChannelData(0).set(new Float32Array(audioChunk));
            
            const source = audioContext.createBufferSource();
            source.buffer = audioBuffer;
            source.connect(audioContext.destination);
            source.start(0);
            console.log('Started playing audio chunk');
          } catch (error) {
            console.error('Error playing audio chunk:', error);
          }
        });

        // Add error and message handlers
        client.on('error', (error) => {
          console.error('OpenAI client error:', error);
        });

        client.on('message', (message) => {
          console.log('Received message:', message);
        });

        client.on('disconnect', () => {
          isConnectedRef.current = false;
          console.log('Client disconnected');
        });

        console.log('Testing weather tool...');
        await client.sendUserMessageContent([{ 
          type: 'input_text', 
          text: 'What is the current weather in New York City? Use the weather tool with coordinates 40.7128, -74.0060.' 
        }]);

      } catch (error) {
        console.error('Error initializing:', error);
        isConnectedRef.current = false;
      }
    }

    init();

    // Clean up on unmount
    return () => {
      mountedRef.current = false;
      const cleanup = async () => {
        if (processorRef.current) {
          processorRef.current.disconnect();
        }
        if (mediaStreamRef.current) {
          mediaStreamRef.current.getTracks().forEach(track => track.stop());
        }
        if (audioContextRef.current?.state !== 'closed') {
          await audioContextRef.current?.close();
        }
        if (client?.isConnected) {
          await client.disconnect();
        }
        if (playbackContextRef.current?.state !== 'closed') {
          await playbackContextRef.current?.close();
        }
        isConnectedRef.current = false;
      };
      cleanup();
    };
  }, [setTranscript]);

  // Clear transcript when component unmounts or conversation stops
  useEffect(() => {
    return () => {
      setTranscript([]);  // Clear on unmount
    };
  }, [setTranscript]);

  const toggleMic = () => {
    micEnabledRef.current = !micEnabledRef.current;
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getAudioTracks().forEach(track => {
        track.enabled = micEnabledRef.current;
      });
    }
  };

  const stop = async () => {
    if (clientRef.current?.isConnected) {
      await clientRef.current.disconnect();
    }
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach(track => track.stop());
    }
    if (processorRef.current) {
      processorRef.current.disconnect();
    }
    if (audioContextRef.current) {
      await audioContextRef.current.close();
    }
    isConnectedRef.current = false;
    setTranscript([]); // Clear transcript when stopping
    stopTalking();
  };

  // Optional: Add a clear method if needed
  const clearTranscript = () => {
    setTranscript([]);
  };

  return (
    <div className="d-flex flex-column align-items-center w-100">
      <div className="d-flex justify-content-center align-items-center">
        <Button color="danger" onClick={toggleMic}>
          <span className={`bi bi-mic${micEnabledRef.current ? '' : '-mute'}`}></span>
        </Button>
        <Button onClick={stop}>Stop</Button>
        <Button onClick={clearTranscript}>Clear Transcript</Button>
      </div>
    </div>
  );
};

export default VadOpenaiStream;
