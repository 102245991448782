import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { StyledDropdown } from './GeneralDropDown.styles';

/* Example:
const dropdownItems = [
  { label: 'Resync Resources', onClick: resyncResources },
  { label: 'Another Action', onClick: anotherAction },
  // Add more items as needed
];*/

function GeneralDropDown({ dropdownItems }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => { setDropdownOpen(prevState => !prevState); };

  return (
    <>
      <StyledDropdown>
        <Dropdown isOpen={dropdownOpen} toggle={(e) => { e.stopPropagation(); toggle(); }}>
          <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen} onClick={(e) => e.stopPropagation()}>
            <i className="bi bi-three-dots-vertical"></i>
          </DropdownToggle>
          <DropdownMenu end>
            {dropdownItems.map((item, index) => (
              <DropdownItem key={index} onClick={item.onClick}>
                {item.label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </StyledDropdown>
    </>
  );
}

export default GeneralDropDown;
