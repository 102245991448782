import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import apiService from '../../utils/apiService';
import useStore from '../../store';

function CreateWorkflowModal({ isOpen, toggle, workflow_template_name, isCustom = false, type = 'CUSTOM_WORKFLOW_MANUAL' }) {
  const { t } = useTranslation();

  const { addProject, language, workspace } = useStore((state) => ({
    addProject: state.addProject,
    language: state.language,
    workspace: state.workspace,
  }));
  const [projectData, setProjectData] = useState({
    name: '',
    icon: 'bi-gear',
    super_type: 'WORKFLOW',
    type: type, // CUSTOM_WORKFLOW_MANUAL Or CUSTOM_WORKFLOW_AUTOMATIC
    language: language,
    tonality: 'PROFESSIONAL_FORMAL',
    workflow_template_name: workflow_template_name,
    check_for_hallucinations: false,
    output_creativity: 1,
  });

  const [error, setError] = useState('');
  const [savingWorkflow, setSavingWorkflow] = useState(false);

  // const languageOptions = useMemo(() => window.languageChoices || [], []);
  // const tonalityOptions = useMemo(() => window.tonalityChoices || [], []);
  

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProjectData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  useEffect(() => {
    setProjectData((prevData) => ({
      ...prevData,
      name: workflow_template_name ? `${workflow_template_name} (New)` : '',
      workflow_template_name: workflow_template_name,
      type: type,
    }));
  }, [workflow_template_name, type]);

  const validateForm = () => {
    if (!projectData.name.trim()) {
      setError('Name is required');
      return false;
    }
    setError('');
    return true;
  };

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return; // Stop submission if validation fails
    setSavingWorkflow(true);
    try {
      const data = {
        ...projectData,
        // workflow_template_name: workflow_template_name,
        workspace_id: workspace?.id,
      };
      const response = await apiService.post('/api/workflows/generate/', data);
      if (response.success) {
        // toggle();
        console.log('Workflow created successfully:', response.data);
        const project_id = response.data.project.id;
        // navigate(`/${baseFolder}s`); // Redirect if project not found
        addProject(response.data.project);
        navigate(`/project/${project_id}`)
        // navigate(`/workflows/`);
      } else {
        console.error('Operation failed:', response.message);
      }
    } catch (error) {
      console.error('Error during operation:', error);
    } finally {
      setSavingWorkflow(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{t('Create Workflow')}: {workflow_template_name}</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="name" className='fw-bold'>{t('Name')}</Label>
            <Input
              type="text"
              name="name"
              id="name"
              placeholder={t('Workflow Name')}
              value={projectData.name}
              onChange={handleChange}
              invalid={!!error}
            />
            <FormFeedback>
              {error}
            </FormFeedback>
          </FormGroup>
          {isCustom && (
            <FormGroup>
              <Label for="workflow_template_name" className='fw-bold'>{t('What do you want help with?')}</Label>
              <Input
                type="text"
                name="workflow_template_name"
                id="workflow_template_name"
                placeholder={t('Enter Workflow Template Name')}
                value={projectData.workflow_template_name || ''}
                onChange={handleChange}
              />
            </FormGroup>
          )}
          {/*<FormGroup>
            <Label for="language" className='fw-bold'>{t('Language')}</Label>
            <Input type="select" name="language" id="language" value={projectData.language} onChange={handleChange}>
              {languageOptions.map((option) => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </Input>
          </FormGroup>*7}
          {/*<FormGroup>
            <Label for="tonality" className='fw-bold'>{t('Tonality')}</Label>
            <Input type="select" name="tonality" id="tonality" value={projectData.tonality} onChange={handleChange}>
              {tonalityOptions.map((option) => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))} 
            </Input>
          </FormGroup>*/}
          <FormGroup>
            <Label for="type" className='fw-bold'>{t('Run mode')}</Label>
            <Input 
              type="select" 
              name="type" 
              id="type" 
              value={projectData.type} 
              onChange={handleChange}
            >
              <option value="CUSTOM_WORKFLOW_MANUAL">{t('Manual: approve each task')}</option>
              <option value="CUSTOM_WORKFLOW_AUTOMATIC">{t('Automatic: run all tasks non stop')}</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label className='fw-bold'>{t('Output')}</Label>
            <Input 
              type="select" 
              name="output_creativity" 
              id="output_creativity" 
              value={projectData.output_creativity} 
              onChange={handleChange}
            >
              <option value={0.5}>{t('Predictable (0.5)')}</option>
              <option value={1.0}>{t('Balanced Creativity (1.0)')}</option>
              <option value={1.5}>{t('High Creativity (1.5)')}</option>
            </Input>
            <div className="mt-2">
              <Input
                type="checkbox"
                id="check_for_hallucinations"
                name="check_for_hallucinations"
                checked={projectData.check_for_hallucinations}
                onChange={handleChange}
              />
              <Label for="check_for_hallucinations" check className="ms-2">
                {t('Auto-fix hallucinations')}
              </Label>
            </div>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter className='d-flex justify-content-end'>
        <Button color="primary" disabled={savingWorkflow} onClick={handleSubmit}>
          {savingWorkflow && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>} {t('Create workflow')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default CreateWorkflowModal;
