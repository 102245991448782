export const getFeatureFlag = (feature) => {
  if (window.feature_flags && window.feature_flags[feature]) {
    return !!window.feature_flags[feature];
  }
  return false;
};
export const getClientSetting = (feature) => {
  if (window.client_settings && window.client_settings[feature]) {
    return window.client_settings[feature];
  }
  return false;
};
