import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const ModalConfirm = ({ isOpen, toggle, title, children, size = 'lg', onConfirm, confirmButtonColor = 'primary' }) => {
  const handleConfirm = () => {
    onConfirm();
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size={size}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody id="modal-content">
        {children}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
        <Button color={confirmButtonColor} onClick={handleConfirm}>OK</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalConfirm;