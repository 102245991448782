import { useRef } from 'react';

export const useAudioStream = (sendBlob, timeSlice = 500, overlap = 500) => {
  const mediaStreamRef = useRef(null);
  const audioContextRef = useRef(null);
  const scriptProcessorRef = useRef(null);
  const audioDataRef = useRef([]);
  const intervalIdRef = useRef(null);

  const captureUserAudio = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      mediaStreamRef.current = stream;
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      const sampleRate = audioContextRef.current.sampleRate; // Capture the sample rate
      // console.log('sampleRate', sampleRate);
      const source = audioContextRef.current.createMediaStreamSource(stream);
      scriptProcessorRef.current = audioContextRef.current.createScriptProcessor(4096, 1, 1);

      scriptProcessorRef.current.onaudioprocess = (audioProcessingEvent) => {
        const inputBuffer = audioProcessingEvent.inputBuffer;
        const float32Array = inputBuffer.getChannelData(0); // Assuming mono channel
        audioDataRef.current.push(...float32Array);
      };

      source.connect(scriptProcessorRef.current);
      scriptProcessorRef.current.connect(audioContextRef.current.destination);

      // Set up interval to send audio data
      intervalIdRef.current = setInterval(() => {
        if (audioDataRef.current.length > 0) {
          const audioSlice = new Float32Array(audioDataRef.current);
          const resampledAudio = resampleAudio(audioSlice, sampleRate, 16000); // Resample to 16 kHz
          sendBlob(resampledAudio, 16000); // Send resampled audio

          // Retain the last `overlap` ms of audio data
          const overlapSamples = Math.floor((overlap / 1000) * sampleRate);
          audioDataRef.current = audioDataRef.current.slice(-overlapSamples);
        }
      }, timeSlice);
    }).catch((error) => {
      console.error('Error capturing user audio:', error);
    });
  };

  const startStream = () => {
    captureUserAudio();
  };

  const stopStream = () => {
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => {
        if (track.readyState === 'live' && track.kind === 'audio') {
          track.stop();
        }
      });
    }
    if (scriptProcessorRef.current) {
      scriptProcessorRef.current.disconnect();
    }
    if (audioContextRef.current) {
      audioContextRef.current.close();
    }
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }
  };

  return {
    startStream,
    stopStream,
  };
};

// Resample audio from original sample rate to target sample rate
const resampleAudio = (audioData, originalSampleRate, targetSampleRate) => {
  const ratio = originalSampleRate / targetSampleRate;
  const newLength = Math.round(audioData.length / ratio);
  const resampledAudio = new Float32Array(newLength);

  for (let i = 0; i < newLength; i++) {
    resampledAudio[i] = audioData[Math.round(i * ratio)];
  }

  return resampledAudio;
};
