import React, { createContext, useState, useContext, useCallback } from 'react';
import ModalConference from '../components/ModalConference/ModalConference';

const ModalConferenceContext = createContext();
 
export const ModalConferenceProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState({ title: '', agents: [] });

  const showModal = useCallback((title, agents) => {
    setModalProps({ title, agents });
    setIsOpen(true);
  }, []);
 
  const hideModal = useCallback(() => {
    setIsOpen(false);
  }, []); 

  return (
    <ModalConferenceContext.Provider value={{ showModal, hideModal }}>
      {children}
      <ModalConference isOpen={isOpen} toggle={hideModal} {...modalProps} />
    </ModalConferenceContext.Provider>
  );
};

export const useModalConference = () => useContext(ModalConferenceContext);
