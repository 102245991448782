import useStore from '../store'; // Adjust the path as necessary

const apiService = {
  async fetch(url, method = 'GET', payload = null, willStoreMessages = true) {
    const csrfToken = useStore.getState().csrfToken;

    const options = {
      method,
      headers: {
        // Conditionally set Content-Type only if payload is not FormData
        ...( !(payload instanceof FormData) && { 'Content-Type': 'application/json' }),
        ...(method !== 'GET' && { 'X-CSRFToken': csrfToken }),
      },
    };

    // Do not stringify payload if it is FormData
    if (payload instanceof FormData) {
      options.body = payload;
    } else if (payload) {
      options.body = JSON.stringify(payload);
    }

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (!response.ok) {
        if (data.messages && willStoreMessages) {
          useStore.getState().setMessages(data.messages);
        }
        throw new Error(data.messages.error || 'Something went wrong');
      }
      if (data.messages && willStoreMessages) {
        useStore.getState().setMessages(data.messages);
      }
      if (data.data && data.data.csrf_token) {
        useStore.getState().setCsrfToken(data.data.csrf_token);
      }
      return data;
    } catch (error) {
      throw error;
    }
  },

  get(url) {
    return this.fetch(url);
  },

  post(url, payload = {}, willStoreMessages = true) {
    return this.fetch(url, 'POST', payload, willStoreMessages);
  },

  put(url, payload = {}, willStoreMessages = true) {
    return this.fetch(url, 'PUT', payload, willStoreMessages);
  },

  delete(url, payload = {}, willStoreMessages = true) {
    return this.fetch(url, 'DELETE', payload, willStoreMessages);
  },
};

export default apiService;
