import { useRef, useEffect, useCallback } from 'react';

const useDeepgram = ({
  micEnabled,
  language,
  isUserSpeaking,
  isAgentSpeaking,
  vadLoading,
  setTranscript,
  processTranscript,
  onFinal,
}) => {
  const deepgramRef = useRef({});
  const bufferTranscript = useRef([]);
  const dgIsTalking = useRef(false);

  const sendKeepAlive = useCallback(() => {
    if (
      deepgramRef.current.socketDeepgram &&
      deepgramRef.current.socketDeepgram.readyState === 1
    ) {
      const keepAliveMsg = JSON.stringify({ type: 'KeepAlive' });
      deepgramRef.current.socketDeepgram.send(keepAliveMsg);
      // console.log('KeepAlive message sent');
    }
  }, []);

  const keepAliveIntervalRef = useRef(null);
  useEffect(() => {
    if (!keepAliveIntervalRef.current)
      keepAliveIntervalRef.current = setInterval(sendKeepAlive, 5000);
    return () => {
      if (keepAliveIntervalRef.current) {
        clearInterval(keepAliveIntervalRef.current);
        keepAliveIntervalRef.current = null;
      }
    };
  }, [sendKeepAlive]);

  const closeDeepgram = useCallback(() => {
    if (deepgramRef.current.mediaStream) {
      deepgramRef.current.mediaStream
        .getTracks()
        .forEach((track) => track.stop());
    }
    if (deepgramRef.current.mediaRecorder) {
      deepgramRef.current.mediaRecorder.stop();
    }
    if (deepgramRef.current.socketDeepgram) {
      deepgramRef.current.socketDeepgram.close();
    }
    deepgramRef.current = {};
    bufferTranscript.current = [];
    setTranscript('');
  }, [setTranscript]);

  const initDeepgram = useCallback(() => {
    if (vadLoading) {
      console.log('VAD is still loading, waiting...');
      setTimeout(initDeepgram, 100); // Retry after 100ms
      return;
    }

    if (deepgramRef.current.socketDeepgram) {
      closeDeepgram();
      return;
    }
    if (!MediaRecorder.isTypeSupported('audio/webm')) {
      alert('Browser not supported');
      return;
    }
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(async (stream) => {
        console.log('initDeepgram');
        const wsUrl =
          language === 'en'
            ? 'wss://api.deepgram.com/v1/listen?model=nova-2-conversationalai&punctuate=true&smart_format=true&interim_results=true&utterance_end_ms=1200&vad_events=true'
            : 'wss://api.deepgram.com/v1/listen?model=nova-2&punctuate=true&smart_format=true&interim_results=true&utterance_end_ms=1200&vad_events=true&language=sv';
        const socketDeepgram = new WebSocket(wsUrl, [
          'token',
          process.env.REACT_APP_DEEPGRAM_API_KEY,
        ]);
        const mediaRecorder = new MediaRecorder(stream, {
          mimeType: 'audio/webm',
        });

        socketDeepgram.onopen = () => {
          mediaRecorder.addEventListener('dataavailable', async (event) => {
            // DISABLE FOR NO!!! if (!isUserSpeaking() && !dgIsTalking.current) return; // Don't send data if the user is NOT talking
            if (isAgentSpeaking()) return; // Don't send data if the agent is talking
            /*console.log(
              'Deepgram dataavailable',
              event.data.size,
              socketDeepgram.readyState
            );*/
            if (event.data.size > 0 && socketDeepgram.readyState === 1) {
             // console.log('Sending data to Deepgram');
              socketDeepgram.send(event.data);
            }
          });
          mediaRecorder.start(1000);
        };

        socketDeepgram.onmessage = (message) => {
          const received = JSON.parse(message.data);
          // console.log('DG message', received);
          const { is_final, channel } = received;
          if (!channel) {
            console.error(
              'Channel is undefined in the received message:',
              received
            );
            return;
          }
          const { alternatives } = channel;
          const currTranscript = alternatives
            ? alternatives[0].transcript.trim()
            : '';
          if (!is_final && currTranscript.length === 0) return; // Final must always pass beyond this point

          let composite = '';
          if (is_final) {
            if (currTranscript.length> 0) bufferTranscript.current.push(currTranscript);
            composite = bufferTranscript.current.join(' ');
          } else {
            composite = `${bufferTranscript.current.join(' ')} ${currTranscript}`;
          }
          setTranscript(composite);
          processTranscript(composite, is_final);
          
          dgIsTalking.current = true;
          if (is_final) {
           if (isUserSpeaking()) {
            console.log('VAD prevented FINAL!!!', composite);
           } else {
              dgIsTalking.current = false;
              if (composite.length === 0) return; // Just empty transcript, ignore
              bufferTranscript.current = [];
              setTimeout(() => {
                setTranscript(bufferTranscript.current.join(' '));
              }, 500);
              if (onFinal) onFinal(composite);
            }
          }
        };

        deepgramRef.current = {
          socketDeepgram,
          mediaRecorder,
          mediaStream: stream,
        };
      })
      .catch((error) => {
        console.error('Error accessing microphone:', error);
      });
    // Do NOT add isAgentSpeaking to the dependency list, because they change too often
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeDeepgram, isUserSpeaking, isAgentSpeaking, language, setTranscript, vadLoading]);

  useEffect(() => {
    if (micEnabled && !deepgramRef.current.socketDeepgram && !vadLoading) {
      initDeepgram(); // Initialize Deepgram when mic is enabled
    } else if (!micEnabled) {
      closeDeepgram();
      bufferTranscript.current = [];
      setTranscript('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [micEnabled, closeDeepgram, vadLoading]);

  const finalize = useCallback(() => {
    if (
      deepgramRef.current.socketDeepgram &&
      deepgramRef.current.socketDeepgram.readyState === 1
    ) {
      const finalizeMsg = JSON.stringify({ type: 'Finalize' });
      deepgramRef.current.socketDeepgram.send(finalizeMsg);
      console.log('Finalize message sent to Deepgram');
    }
  }, []);

  return {
    initDeepgram,
    closeDeepgram,
    finalize, // Add the new finalize function to the returned object
    dgIsTalking,
  };
};

export default useDeepgram;
