import { useEffect, useCallback, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useWSManager } from '../contexts/WSManagerContext';
import apiService from '../utils/apiService';
import useStore from '../store';

const setWorkspaceById = (workspaceId) => {
  if (!workspaceId) return;
  const workspaces = useStore.getState().workspaces;
  const workspace = workspaces.find(w => w.id === workspaceId);
  if (workspace) {
    useStore.getState().setWorkspace(workspace);
    //console.log('setWorkspaceById SUCCESS', workspace);
    return true;
  } 
  //console.log('setWorkspaceById FAIL');
  return false;
};

export const fetchWorkspaceData = async (workspaceId) => {
  if (!workspaceId) return;
  try {
    const response = await apiService.post(`/api/workspaces/${workspaceId}/load/`);
    if (response.success) {
      const { resources, agents, teams, projects, members } = response.data;
      useStore.getState().setResources(resources);
      useStore.getState().setAgents(agents);
      useStore.getState().setTeams(teams);
      useStore.getState().setProjects(projects);
      useStore.getState().setMembers(members);
    }
  } catch (error) {
    console.error('fetchWorkspaceData error', error, 'workspaceId:', workspaceId);
  }
};

export const fetchWorkspaceResources = async (workspaceId) => {
  if (!workspaceId) return;
  try {
    const response = await apiService.post(`/api/workspaces/${workspaceId}/resources/`);
    if (response.success) useStore.getState().setResources(response.data.resources);
  } catch (error) {
    console.error('fetchResources error', error);
  }
};
export const fetchWorkspaceAgents = async (workspaceId) => {
  if (!workspaceId) return;
  try {
    const response = await apiService.post(`/api/workspaces/${workspaceId}/agents/`);
    if (response.success) {
      useStore.getState().setAgents(response.data.agents);
    }
  } catch (error) {
    console.error('fetchAgents error', error, 'workspaceId:', workspaceId);
  }
};

export const fetchWorkspaceTeams = async (workspaceId) => {
  if (!workspaceId) return;
  try {
    const response = await apiService.post(`/api/workspaces/${workspaceId}/teams/`);
    if (response.success) useStore.getState().setTeams(response.data.teams);
  } catch (error) {
    console.error('fetchTeams error', error, 'workspaceId:', workspaceId);
  }
};

export const fetchWorkspaceProjects = async (workspaceId) => {
  if (!workspaceId) return;
  try {
    const response = await apiService.post(`/api/workspaces/${workspaceId}/projects/`);
    if (response.success) useStore.getState().setProjects(response.data.projects);
  } catch (error) {
    console.error('fetchProjects error', error, 'workspaceId:', workspaceId);
  }
};

export const fetchWorkspaceMembers = async (workspaceId) => {
  if (!workspaceId) return;
  try {
    const response = await apiService.post(`/api/workspaces/${workspaceId}/members/`);
    if (response.success) {
      useStore.getState().setMembers(response.data.members);
      console.log('members', response.data.members);
    }
  } catch (error) {
    console.error('fetchMembers error', error, 'workspaceId:', workspaceId);
  }
};

export const WorkspaceDataSync = () => {
  const navigate = useNavigate();
  const { workspaceId, agentId } = useParams();
  const [internalWorkspaceId, setInternalWorkspaceId] = useState(null);
  const { loggedIn, workspaces, project, agents, projects } = useStore((state) => ({
    agents: state.agents,
    loggedIn: state.loggedIn,
    workspaces: state.workspaces,
    project: state.project,
    projects: state.projects,
  }));
  const { manageSubscriptions } = useWSManager();
  const [previousWorkspaceId, setPreviousWorkspaceId] = useState(null);
  const componentId = useRef(uuidv4()); // Unique ID for this component instance

  // This is used in cases where agent is deleted
  useEffect(() => {
    if (agentId && agents && !agents.find(a => a.id === agentId)) { 
        navigate(`/${workspaceId}/coworkers`);
    } 
  }, [agentId, agents, navigate, workspaceId]);
  // This is used when project is deleted
  useEffect(() => {
    if (project && !projects.find(p => p.id === project.id)) { 
        navigate(`/${project.workspace_id}/${project.super_type === "WORKFLOW" ? 'workflows' : 'projects'}`);
    } 
  }, [project, projects, navigate, workspaceId]);
  
  // NOTE: Tried refactor this, had infinite loop
  const fetchWorkspaceCallback = useCallback(async ()=> {
    if (!loggedIn || !workspaces || !internalWorkspaceId) return;
    await fetchWorkspaceData(internalWorkspaceId);
  }, [loggedIn, workspaces, internalWorkspaceId]);
  const fetchWorkspaceResourcesCallback = useCallback(async ()=> {
    if (!loggedIn || !workspaces || !internalWorkspaceId) return;
    await fetchWorkspaceResources(internalWorkspaceId);
  }, [loggedIn, workspaces, internalWorkspaceId]);
  const fetchWorkspaceAgentsCallback = useCallback(async ()=> {
    if (!loggedIn || !workspaces || !internalWorkspaceId) return;
    await fetchWorkspaceAgents(internalWorkspaceId);
  }, [loggedIn, workspaces, internalWorkspaceId]);
  const fetchWorkspaceTeamsCallback = useCallback(async ()=> {
    if (!loggedIn || !workspaces || !internalWorkspaceId) return;
    await fetchWorkspaceTeams(internalWorkspaceId);
  }, [loggedIn, workspaces, internalWorkspaceId]);
  const fetchWorkspaceProjectsCallback = useCallback(async ()=> {
    if (!loggedIn || !workspaces || !internalWorkspaceId) return;
    await fetchWorkspaceProjects(internalWorkspaceId);
  }, [loggedIn, workspaces, internalWorkspaceId]);
  const fetchWorkspaceMembersCallback = useCallback(async ()=> {
    if (!loggedIn || !workspaces || !internalWorkspaceId) return;
    await fetchWorkspaceMembers(internalWorkspaceId);
  }, [loggedIn, workspaces, internalWorkspaceId]);

  // -------------------------------------------------------------------------------------------------
  // This is how it works:
  // if workspaceId, project exists we set internalWorkspaceId
  // If internalWorkspaceId changes we fetch the workspace data and subscribe to the new workspace
  // -------------------------------------------------------------------------------------------------

  useEffect(() => {
    if (workspaceId) { setInternalWorkspaceId(workspaceId); return; }
    if (project) setInternalWorkspaceId(project.workspace_id);
  }, [project, workspaceId]);

  useEffect(() => {
    if (!loggedIn || !workspaces) return;
    if (internalWorkspaceId === previousWorkspaceId) return;
    fetchWorkspaceCallback();
  }, [fetchWorkspaceCallback, loggedIn, previousWorkspaceId, internalWorkspaceId, workspaces]);

  useEffect(() => {
    if (!loggedIn || !workspaces || !internalWorkspaceId) return;
    if (internalWorkspaceId === previousWorkspaceId) return;
    const success = setWorkspaceById(internalWorkspaceId);
    if(!success) return;
    const handleMessage = (event) => {
      const obj = JSON.parse(event.data);
      const type = obj.type;
      if (type === 'UPDATE_RESOURCES') {
        console.log('New SSE stream [WorkspaceDataSync]:', type, obj);
        fetchWorkspaceResourcesCallback();
      } else if (type === 'UPDATE_AGENTS') {
        console.log('New SSE stream [WorkspaceDataSync]:', type, obj);
        fetchWorkspaceAgentsCallback();
      } else if (type === 'UPDATE_TEAMS') {
        console.log('New SSE stream [WorkspaceDataSync]:', type, obj);
        fetchWorkspaceTeamsCallback();
      } else if (type === 'UPDATE_PROJECTS') {
        console.log('New SSE stream [WorkspaceDataSync]:', type, obj);
        fetchWorkspaceProjectsCallback();
      } else if (type === 'UPDATE_MEMBERS') {
        console.log('New SSE stream [WorkspaceDataSync]:', type, obj);
        fetchWorkspaceMembersCallback();
      }
    };

    const subscriptionChanges = [];
    if (previousWorkspaceId) subscriptionChanges.push({ channelName: `workspace-${previousWorkspaceId}`, componentId: componentId.current });
    subscriptionChanges.push({ channelName: `workspace-${internalWorkspaceId}`, handleMessage, componentId: componentId.current });
    // console.log('Subscribing to WorkspaceDataSync', subscriptionChanges);
    manageSubscriptions(subscriptionChanges);
    setPreviousWorkspaceId(internalWorkspaceId);
  }, [fetchWorkspaceAgentsCallback, fetchWorkspaceProjectsCallback, fetchWorkspaceResourcesCallback, fetchWorkspaceTeamsCallback, fetchWorkspaceMembersCallback, loggedIn, manageSubscriptions, previousWorkspaceId, internalWorkspaceId, workspaces]);

  // If we logout we need to set previousWorkspaceId to null
  useEffect(() => {
    if (!loggedIn) setPreviousWorkspaceId(null);
  }, [loggedIn]);

  return null;
};

export default WorkspaceDataSync;
