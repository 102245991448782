import React, { useState, useCallback } from 'react';
import { NavItem, Dropdown, DropdownMenu, DropdownItem } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useStore from '../../../store';
import CreateWorkspaceModal from '../../CreateWorkspaceModal/CreateWorkspaceModal';
import ModalConfirm from '../../ModalConfirm/ModalConfirm';
import apiService from '../../../utils/apiService';
import { StyledSubNavLink, CustomDropdownToggle } from './WorkspaceItems.styles.js';
import { fetchWorkspaces } from '../../../datasync/WorkspacesDataSync';
const WorkspaceItems = ({ toggle = () => {} }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { workspace, workspaces } = useStore((state) => ({
    workspace: state.workspace,
    workspaces: state.workspaces,
  }));

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const [isCreateWorkspaceOpen, setIsCreateWorkspaceOpen] = useState(false);
  const toggleCreateWorkspace = () => {setIsCreateWorkspaceOpen(!isCreateWorkspaceOpen);}
  const [isEditingWorkspace, setIsEditingWorkspace] = useState(false);

  // Functions for Confirm Modal
  const [confirmModal, setConfirmModal] = useState(false);
  const toggleConfirmModal = () => {
    setConfirmModal(!confirmModal);
  };

  const handleWorkspaceChange = useCallback((newWorkspace) => {
    navigate(`/${newWorkspace.id}/resources`);
  }, [navigate]);

  const handleDeleteWorkspace = useCallback( async () => {
    console.log('Deleting workspace with id:', workspace.id);
    if (workspaces.length === 1) { // Must have at least one workspace
      console.log('Must have at least one workspace');
      return;
    }
    try {
      const response = await apiService.delete(`/api/workspaces/${workspace.id}/delete/`);
      if (!response.success) {
        console.error('Operation failed:', response.message);
        return;
      }
      console.log('Workspace deleted successfully');
      const workspacesFiltered = workspaces.filter(w => w.id !== workspace.id);
      // remWorkspace(workspace.id);
      await fetchWorkspaces();
      handleWorkspaceChange(workspacesFiltered[0]);
    } catch (error) {
      console.error('Error during operation:', error);
    }
  }, [handleWorkspaceChange, workspace?.id, workspaces]);

  return (
    <>
    <div className='mb-2'>
      <div className='d-flex flex-row align-items-center'>
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
          <CustomDropdownToggle caret>
            {workspace ? (
              <>
                <span className='mb-4'>{workspace.name}</span>
                {workspace.visibility === 'PRIVATE' && <span style={{fontSize: 'smaller'}}>&nbsp;<i className="bi bi-lock"></i></span>}
              </>
            ) : t('Select workspace')}
          </CustomDropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => {setIsEditingWorkspace(true); toggleCreateWorkspace();}}>{t('Edit workspace')}</DropdownItem>
            {workspace && workspaces.length > 1 && <DropdownItem onClick={toggleConfirmModal}>{t('Delete workspace')}</DropdownItem>}
            <DropdownItem divider />
            <DropdownItem key="new-workspace" onClick={() => {setIsEditingWorkspace(false); toggleCreateWorkspace();}}>
              {t('Create new workspace')}
            </DropdownItem>
            <DropdownItem divider />
            {workspaces.map((workspace) => (
              <DropdownItem key={workspace.id} onClick={() => handleWorkspaceChange(workspace)}>
                {workspace.visibility === 'PRIVATE' ?
                  <i className="bi bi-lock"></i>
                  :
                  <i className="bi bi-people"></i>
                }
                &nbsp;
                {workspace.name}
              </DropdownItem>
            ))}
            </DropdownMenu>
          </Dropdown>
        </div>
        
        <NavItem>
          <StyledSubNavLink onClick={toggle} to={workspace ? `/${workspace.id}/resources` : `/resources`}>
            <i className="bi bi-files"></i>&nbsp;
            {t('Resources')}
          </StyledSubNavLink>
        </NavItem>
      </div>
      <CreateWorkspaceModal isOpen={isCreateWorkspaceOpen} toggle={toggleCreateWorkspace} isEditing={isEditingWorkspace}/>
      <ModalConfirm isOpen={confirmModal} toggle={toggleConfirmModal} title={t('Delete Workspace')} onConfirm={handleDeleteWorkspace} confirmButtonColor='danger'>
        <strong>{t('Are you sure you want to delete this workspace?')}</strong>
        <br/>
        <br/>
        {workspace && workspace.name}
      </ModalConfirm>
    </>
  );
};

export default WorkspaceItems;
