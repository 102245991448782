import { base64ToArrayBuffer } from '../Players/PlayerHelpers';

class ElevenLabsAudioStreamer {
  constructor(apiKey, voiceId, onAlignment, onStop) {
    this.apiKey = apiKey;
    this.voiceId =
      typeof voiceId === 'string' ? voiceId : JSON.stringify(voiceId);
    this.audio = new Audio();
    this.onAlignment = onAlignment;
    this.onStop = onStop;
    this.abortController = null; // Initialize as null
    this.mediaSource = null;
    this.sourceBuffer = null;
    this.reader = null;
    this.audioQueue = [];
    this.isMediaSourceOpen = false;
    this.isSourceBufferValid = true;
    this.isEnded = false;
    this.audioUrl = null;
    this.eventListeners = {};
    this.playbackStarted = false;
    this.textQueue = []; // For queuing texts
    this.isStreaming = false; // Indicates if streaming is in progress
    this.prefetchQueue = []; // Stores pre-fetched audio Blobs
    this.isPrefetching = false; // Indicates if prefetching is in progress
  }

  resetState() {
    this.isMediaSourceOpen = false;
    this.isSourceBufferValid = true;
    this.isEnded = false;
    this.audioQueue = [];
    this.playbackStarted = false;

    // Clean up event listeners
    this.cleanupEventListeners();

    // Revoke previous audio URL
    if (this.audioUrl) {
      URL.revokeObjectURL(this.audioUrl);
      this.audioUrl = null;
    }

    // Reset media source and source buffer
    this.mediaSource = null;
    this.sourceBuffer = null;
    this.reader = null;

    // Reset abort controller
    if (this.abortController) {
      this.abortController.abort(); // Abort any existing requests
      this.abortController = null;
    }
  }

  stream(text) {
    // Add text to queue
    this.textQueue.push(text);

    // If not currently streaming, start processing the queue
    if (!this.isStreaming) {
      this.processQueue();
    }
  }

  async processQueue() {
    if (this.textQueue.length === 0 && this.prefetchQueue.length === 0) {
      this.isStreaming = false;
      // Wait for current audio to finish before calling onStop
      await this.waitForAudioToFinish();
      return;
    }

    this.isStreaming = true;

    // Check if there's a pre-fetched audio
    if (this.prefetchQueue.length > 0) {
      // Play pre-fetched audio
      const audioBlob = this.prefetchQueue.shift();
      await this.playPrefetchedAudio(audioBlob);
      // Proceed to next in queue
      this.processQueue();
    } else if (this.textQueue.length > 0) {
      const text = this.textQueue.shift();

      // Start prefetching the next text, if any
      const nextText = this.textQueue[0];
      if (nextText && !this.isPrefetching) {
        this.isPrefetching = true;
        this.prefetchAudio(nextText)
          .then(() => {
            this.isPrefetching = false;
          })
          .catch((error) => {
            console.error('Error prefetching audio:', error);
            this.isPrefetching = false;
          });
      }

      try {
        await this.streamText(text);
        // When streaming completes, proceed to next in queue
        this.processQueue();
      } catch (error) {
        console.error('Error streaming text:', error);
        // Proceed to next in queue even if there was an error
        this.processQueue();
      }
    }
  }

  waitForAudioToFinish() {
    return new Promise((resolve) => {
      if (!this.audio.paused && this.audio.duration > 0) {
        const onEnded = () => {
          this.audio.removeEventListener('ended', onEnded);
          if (typeof this.onStop === 'function') this.onStop();
          resolve();
        };
        this.audio.addEventListener('ended', onEnded);
      } else {
        if (typeof this.onStop === 'function') this.onStop();
        resolve();
      }
    });
  }

  async prefetchAudio(text) {
    // Fetch the audio in non-streaming mode
    const baseUrl = 'https://api.elevenlabs.io/v1/text-to-speech';
    const headers = {
      'Content-Type': 'application/json',
      'xi-api-key': this.apiKey,
    };
    const requestBody = {
      text,
      model_id: 'eleven_multilingual_v2',
      voice_settings: { stability: 0.5, similarity_boost: 0.5 },
    };

    const response = await fetch(`${baseUrl}/${this.voiceId}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(requestBody),
    });

    if (response.status === 200) {
      const audioBlob = await response.blob();
      this.prefetchQueue.push(audioBlob);
    } else {
      const errorMessage = await response.text();
      throw new Error(
        `Error fetching audio: ${response.status} - ${errorMessage}`
      );
    }
  }

  async playPrefetchedAudio(audioBlob) {
    // Revoke the old audio URL if any
    if (this.audioUrl) {
      URL.revokeObjectURL(this.audioUrl);
    }

    // Set up the new audio source
    this.audioUrl = URL.createObjectURL(audioBlob);
    this.audio.src = this.audioUrl;

    // Remove any existing 'ended' event listener to avoid multiple triggers
    this.removeAudioEventListener('ended');

    this.addAudioEventListener('ended', () => {
      this.onAudioEnded();
    });

    // Play the audio
    try {
      await this.audio.play();
      console.log('Playback of pre-fetched audio started');
    } catch (error) {
      console.error('Error playing pre-fetched audio:', error);
    }

    // Start prefetching the next text in the queue, if any
    const nextText = this.textQueue[0];
    if (nextText && !this.isPrefetching) {
      this.isPrefetching = true;
      this.prefetchAudio(nextText)
        .then(() => {
          this.isPrefetching = false;
        })
        .catch((error) => {
          console.error('Error prefetching audio:', error);
          this.isPrefetching = false;
        });
    }
  }

  async streamText(text) {
    return new Promise((resolve, reject) => {
      this.resetState();
      this.setupAbortHandler();

      try {
        console.log(`\n\nElevenlabs streaming...`, text);

        // Reset the audio element
        this.resetAudioElement();

        // Create a new MediaSource
        this.mediaSource = new MediaSource();
        this.audioUrl = URL.createObjectURL(this.mediaSource);
        this.audio.src = this.audioUrl;

        this.addAudioEventListener('ended', () => {
          this.onAudioEnded();
          resolve(); // Resolve when audio ends
        });

        this.mediaSource.addEventListener('sourceopen', () => {
          this.isMediaSourceOpen = true;
          this.sourceBuffer =
            this.mediaSource.addSourceBuffer('audio/mpeg');

          this.addSourceBufferEventListener(
            'updateend',
            this.onSourceBufferUpdateEnd.bind(this)
          );

          // Start fetching and processing audio without awaiting
          this.fetchAndProcessAudio(text).catch((error) => {
            reject(error);
          });

          // Do not start playback here; wait until first buffer is appended
        });

        this.mediaSource.addEventListener('sourceclose', () => {
          this.isMediaSourceOpen = false;
        });
      } catch (error) {
        if (error.name === 'AbortError') {
          // Handle abort error silently
        } else {
          console.error('Error: Unable to preload audio.', error);
          reject(
            new Error(
              `Error: Unable to fetch ElevenLabs audio. ${error.message}`
            )
          );
        }
      }
    });
  }

  setupAbortHandler() {
    // Initialize a new AbortController if not present
    if (!this.abortController) {
      this.abortController = new AbortController();
    }

    this.abortController.signal.addEventListener('abort', () => {
      // Stop audio playback and reset audio element
      this.resetAudioElement();

      // Close MediaSource if needed
      if (this.mediaSource && this.mediaSource.readyState === 'open') {
        this.mediaSource.endOfStream();
        this.isMediaSourceOpen = false;
      }

      // Cancel the reader
      if (this.reader) {
        this.reader.cancel().catch((error) => {
          console.error('Error cancelling reader:', error);
        });
      }

      // Remove event listeners
      this.cleanupEventListeners();

      // Revoke object URL
      if (this.audioUrl) {
        URL.revokeObjectURL(this.audioUrl);
        this.audioUrl = null;
      }

      // Reset abortController
      this.abortController = null;
    }, { once: true }); // Ensure the event listener is only called once
  }

  resetAudioElement() {
    this.audio.pause();
    this.audio.currentTime = 0;
    this.audio.src = '';
  }

  addAudioEventListener(event, handler) {
    this.audio.addEventListener(event, handler);
    this.eventListeners[event] = handler;
  }

  removeAudioEventListener(event) {
    const handler = this.eventListeners[event];
    if (handler) {
      this.audio.removeEventListener(event, handler);
      delete this.eventListeners[event];
    }
  }

  addSourceBufferEventListener(event, handler) {
    if (this.sourceBuffer) {
      this.sourceBuffer.addEventListener(event, handler);
      this.eventListeners[event] = handler;
    }
  }

  removeSourceBufferEventListener(event) {
    const handler = this.eventListeners[event];
    if (handler && this.sourceBuffer) {
      this.sourceBuffer.removeEventListener(event, handler);
      delete this.eventListeners[event];
    }
  }

  async fetchAndProcessAudio(text) {
    const baseUrl = 'https://api.elevenlabs.io/v1/text-to-speech';
    const headers = {
      'Content-Type': 'application/json',
      'xi-api-key': this.apiKey,
    };
    const requestBody = {
      text,
      model_id: 'eleven_multilingual_v2',
      voice_settings: { stability: 0.5, similarity_boost: 0.5 },
    };

    const response = await fetch(
      `${baseUrl}/${this.voiceId}/stream/with-timestamps?output_format=mp3_22050_32&optimize_streaming_latency=3`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify(requestBody),
        signal: this.abortController.signal,
      }
    );

    if (response.status === 200) {
      this.reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let buffer = '';

      const processChunk = async ({ done, value }) => {
        if (done) {
          this.isEnded = true;
          if (
            !this.sourceBuffer.updating &&
            this.audioQueue.length === 0
          ) {
            this.endStream();
          }
          return;
        }

        const chunkValue = decoder.decode(value, { stream: true });
        buffer += chunkValue;

        let lines = buffer.split('\n');
        buffer = lines.pop(); // Leave last partial line in buffer

        for (let line of lines) {
          if (line.trim()) {
            try {
              const responseData = JSON.parse(line);
              const { audio_base64, alignment } = responseData;

              if (audio_base64) {
                const audioBuffer = base64ToArrayBuffer(audio_base64);
                if (!this.sourceBuffer.updating && this.isMediaSourceOpen && this.mediaSource.readyState === 'open') {
                  this.appendNextAudioBuffer(audioBuffer);
                } else {
                  this.audioQueue.push(audioBuffer);
                }
              }

              if (
                alignment &&
                typeof this.onAlignment === 'function'
              ) {
                this.onAlignment(alignment);
              }
            } catch (e) {
              console.error('Error parsing JSON:', e);
            }
          }
        }

        this.reader
          .read()
          .then(processChunk)
          .catch((error) => {
            if (this.abortController.signal.aborted) {
              // Handle abort silently
            } else {
              console.error('Error reading stream:', error);
            }
          });
      };

      this.reader
        .read()
        .then(processChunk)
        .catch((error) => {
          if (this.abortController.signal.aborted) {
            // Handle abort silently
          } else {
            console.error('Error reading stream:', error);
          }
        });
    } else {
      const errorMessage = await response.text();
      throw new Error(
        `Error fetching audio: ${response.status} - ${errorMessage}`
      );
    }
  }

  appendNextAudioBuffer(buffer = null) {
    if (!this.isMediaSourceOpen || !this.isSourceBufferValid) return;
    if (!this.sourceBuffer.updating) {
      const nextBuffer = buffer || this.audioQueue.shift();
      if (nextBuffer) {
        try {
          if (this.mediaSource.readyState === 'open') {
            this.sourceBuffer.appendBuffer(nextBuffer);

            // Start playback if it hasn't started yet
            if (!this.playbackStarted) {
              this.playbackStarted = true;
              console.log(
                'First chunk received. Attempting to start playback...',
                new Date().toISOString()
              );
              this.audio
                .play()
                .then(() => {
                  console.log(
                    'Playback started successfully',
                    new Date().toISOString()
                  );
                })
                .catch((error) => {
                  console.error(
                    'Error playing audio:',
                    error,
                    new Date().toISOString()
                  );
                });
            }
          }
        } catch (e) {
          console.error('Error appending buffer:', e);
          this.isSourceBufferValid = false;
          this.endStream();
        }
      }
    }
  }

  endStream() {
    if (
      this.mediaSource &&
      this.mediaSource.readyState === 'open'
    ) {
      try {
        this.mediaSource.endOfStream();
      } catch (e) {
        console.error('Error ending MediaSource stream:', e);
      }
      this.isMediaSourceOpen = false;
    }
    this.removeSourceBufferEventListener('updateend');
  }

  onAudioEnded() {
    // Revoke the object URL to free up memory
    if (this.audioUrl) {
      URL.revokeObjectURL(this.audioUrl);
      this.audioUrl = null;
    }
    this.removeAudioEventListener('ended');

    // No need to proceed to next in queue here; processQueue handles it
  }

  onSourceBufferUpdateEnd() {
    if (!this.isMediaSourceOpen || !this.isSourceBufferValid) return;
    if (this.audioQueue.length > 0) {
      this.appendNextAudioBuffer();
    } else if (this.isEnded) {
      this.endStream();
    }
  }

  cleanupEventListeners() {
    // Remove all event listeners
    this.removeAudioEventListener('ended');
    this.removeSourceBufferEventListener('updateend');
  }

  stop() {
    return new Promise((resolve) => {
      // Reset the queue
      this.textQueue = [];
      this.prefetchQueue = [];
      this.isStreaming = false;
      this.isPrefetching = false;

      // Clean up immediately
      this.resetAudioElement();
      this.cleanupEventListeners();
      if (this.audioUrl) {
        URL.revokeObjectURL(this.audioUrl);
        this.audioUrl = null;
      }
      if (
        this.mediaSource &&
        this.mediaSource.readyState === 'open'
      ) {
        try {
          this.mediaSource.endOfStream();
        } catch (e) {
          console.warn('Error ending MediaSource stream:', e);
        }
      }

      // Abort any ongoing fetches
      if (this.abortController) {
        this.abortController.abort();
        this.abortController = null;
      }

      if (typeof this.onStop === 'function') this.onStop();

      resolve();
    });
  }
}

module.exports = ElevenLabsAudioStreamer;