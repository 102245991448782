import React, { useEffect, useState, useMemo } from 'react';
import { Row, Col, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Alert } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import apiService from '../../utils/apiService';
import useStore from '../../store';
import { StyledCard, StyledDropdown } from './ProjectsPage.styles'; 

function ProjectsPage({ is_workflow = false }) {
  const { t } = useTranslation();
  const { projects, remProject, addProject, setProject, workspace, resources } = useStore((state) => ({
    projects: state.projects,
    remProject: state.remProject,
    addProject: state.addProject,
    setProject: state.setProject,
    workspace: state.workspace,
    resources: state.resources,
  }));
  const navigate = useNavigate();

  const baseFolder = is_workflow ? 'workflow' : 'project';
  const projectsInWorkspace = useMemo(() => projects && workspace && projects.filter((p) => p.workspace_id === workspace?.id), [projects, workspace]);
  const filteredProjects = useMemo(() => projectsInWorkspace && projectsInWorkspace.filter((project) => is_workflow ? project.super_type === "WORKFLOW" : project.super_type === "PROJECT"), [projectsInWorkspace, is_workflow]);

  useEffect(() => {
    setProject(null);
  }, [setProject])

  const handleCreateNewProject = () => {
    navigate(`/${workspace.id}/${baseFolder}s/new`);
  };

  // Navigate to the edit page
  const handleEditProject = (id) => {
    navigate(`/${workspace.id}/${baseFolder}-details/${id}`);
  };

  // Placeholder function for deleting a project
  // TODO: Are you sure? YES/NO
  const handleDeleteProject = async (id) => {
    console.log('Deleting with id:', id);
    try {
      const response = await apiService.delete(`/api/${baseFolder}s/delete/${id}/`);
      if (!response.success) { console.error('Operation failed:', response.message); return; }
      console.log('Project deleted successfully');
      remProject(id);
    } catch (error) {
      console.error('Error during operation:', error);
    }
  };

  // Placeholder function for duplicating a project
  const handleDuplicateProject = async (id) => {
    console.log('Duplicating project with id:', id);
    try {
      const response = await apiService.post(`/api/${baseFolder}s/duplicate/${id}/`);
      if (!response.success) { console.error('Operation failed:', response.message); return; }
      console.log('Project duplicated successfully');
      addProject(response.data.project);
    } catch (error) {
      console.error('Error during operation:', error);
    }
  };

  const handleViewProject = (project) => {
    // <StyledSubNavLink onClick={toggle}to={session.first_document_id ? `/project/${session.id}/document/${session.first_document_id}` : `/project/${session.id}`}>
    navigate(project.first_document_id ? `/${baseFolder}/${project.id}/document/${project.first_document_id}/` : `/${baseFolder}/${project.id}/`);
  };

  // Function to handle dropdown toggle
  const [dropdownOpen, setDropdownOpen] = useState({});
  const toggle = (id) => {
    setDropdownOpen(prevState => ({ ...prevState, [id]: !prevState[id] }));
  };

  return (
    <>
      <div>
        {is_workflow ? <h2>{t('Our Workflows')}</h2> : <h2>{t('Our Sessions')}</h2>}
        {filteredProjects && filteredProjects.length > 0 ? (
          <Row>
            {filteredProjects.map((project) => (
              <Col sm="12" md="6" lg="4" key={project.id}>
                <StyledCard onClick={() => handleViewProject(project)}>
                  <StyledDropdown>
                    <Dropdown isOpen={dropdownOpen[project.id]} toggle={(e) => { e.stopPropagation(); toggle(project.id); }}>
                      <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen[project.id]} onClick={(e) => e.stopPropagation()}>
                        <i className="bi bi-three-dots-vertical"></i>
                      </DropdownToggle>
                      <DropdownMenu end>
                        <DropdownItem onClick={() => handleEditProject(project.id)}>{t('Edit')}</DropdownItem>
                        <DropdownItem onClick={() => handleDeleteProject(project.id)}>{t('Delete')}</DropdownItem>
                        <DropdownItem onClick={() => handleDuplicateProject(project.id)}>{t('Duplicate')}</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </StyledDropdown>
                  <h5><i className={`bi ${project.icon}`}></i> {project.name}</h5>
                  {project.current_stage && (<small>{t('Stage')}: {project.current_stage}</small>)}<br />
                  <small className="text-secondary"><i className="bi bi-calendar-fill"></i> {project.created_at}</small>
                </StyledCard>
              </Col>
            ))}
          </Row>
        ) : (
          is_workflow ? <p>{t('No workflows available.')}</p> : <p>{t('No sessions available.')}</p>
        )}
        <div className='d-flex gap-1'>
          {!is_workflow && <Button color="primary" onClick={handleCreateNewProject}>
            {t('Create new session')}
          </Button>}
          {is_workflow && (
            resources && !resources.length ? (
              <Alert color="danger">
                {t('You must add at least one resource to the workspace to create a workflow.')}
              </Alert>
            ) : (
              <Button color="primary" onClick={() => navigate(`/${workspace.id}/workflows/select`)}>
                {t('Create new workflow')}
              </Button>
            )
          )}
        </div>
      </div>
    </>
  );
}
            
export default ProjectsPage;
