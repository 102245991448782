import React, { useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import useStore from '../../store';
import FlexLoader from '../../components/FlexLoader/FlexLoader';
import ResourcesTable from '../../components/ResourcesTable/ResourcesTable';
import apiService from '../../utils/apiService';
import Dropzone from '../../components/Dropzone/Dropzone';
import { StyledHeader } from './ResourcesPage.styles';
import GeneralDropDown from '../../components/GeneralDropDown/GeneralDropDown';


function ResourcesPage() {
  const { t } = useTranslation();
  const { loggedIn, workspace, resources } = useStore((state) => ({
    loggedIn: state.loggedIn,
    workspace: state.workspace,
    resources: state.resources,
  }));

  const onDrop = async (acceptedFiles) => {
    const metadata = {
      workspace_id: workspace.id,
    };
  
    for (const file of acceptedFiles) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('title', file.name);
      formData.append('metadata', JSON.stringify(metadata));
      console.log('Uploading file title:', file.name);
      console.log('Uploading file metadata:', metadata);
  
      try {
        const response = await apiService.post('/api/resource/upload/qdrant/', formData);
        if (response.success) {
          console.log(`File ${file.name} uploaded successfully`, response.data);
        } else {
          console.error(`Upload failed for ${file.name}:`, response.message);
        }
      } catch (error) {
        console.error(`Error during upload of ${file.name}:`, error);
      }
    }
  };

  const pageTitle = useMemo(() => {
    const resourcesLabel = t('Resources');
    if(!workspace) return resourcesLabel;
    return `${resourcesLabel} - ${workspace.name}`;
  }, [t, workspace]);

  /*
  const resyncResources = useCallback(async () => {
    try {
      const response = await apiService.post(`/api/workspaces/${workspace.id}/resources/resync/nuclia/`, {});
      if (response.success) {
        console.log('Workspace resources resynced successfully', response.data);
      } else {
        console.error('Resync failed:', response.message);
      }
    } catch (error) {
      console.error('Error during workspace resources resync:', error);
    }
  }, [workspace]);

  const dropdownItems = useMemo(() => [
    { label: 'Resync Resources', onClick: resyncResources },
  ], [resyncResources]);*/



  const dropdownItems = undefined;


  if(!loggedIn) return (<Row><Col><h2>{pageTitle}</h2><FlexLoader /></Col></Row>);

  return (
    <Row>
      <Col sm="12">
        <StyledHeader className='d-flex align-items-center justify-content-between'>
          <h2>{pageTitle}</h2>
          {dropdownItems && dropdownItems.length && <GeneralDropDown dropdownItems={dropdownItems} />}
        </StyledHeader>
        <Dropzone onDrop={onDrop} />
        {resources && resources.length > 0 && <ResourcesTable resources={resources} />}
      </Col>
    </Row>
  );
}

export default ResourcesPage;
