import React, { useCallback } from 'react';
import {
  Form,
} from 'reactstrap';
import apiService from '../../../utils/apiService';
import DocumentFormButton from '../../DocumentFormButton/DocumentFormButton';

function FillInTheBlanks({ projectId, document: doc }) {
  const documentId=doc.id;

  const handleSubmit = async (e) => {
    e.preventDefault();
    doc.is_generating = true; // Set generating state to true at the start of the operation
    const endpoint = `/api/document/${documentId}/generate-with-template/`;

    // Initialize the fill_in_the_blanks string
    let fill_in_the_blanks = '';
    // Get all input elements
    const inputs = document.querySelectorAll('input[type="text"]');

    let inputCounter = 0; // Initialize a counter to keep track of the current input field

    // Iterate over each line of the original text
    doc.template.fill_in_the_blanks.split(/\n+/).forEach((line, lineIndex) => {
      // Split the line by underscores to find parts
      const parts = line.split(/_+/);
      parts.forEach((part, partIndex) => {
        // Add the text part
        fill_in_the_blanks += part;
        // If there's an input for this part, add its value or "(missing)" if empty
        if (partIndex < parts.length - 1) {
          const inputValue = inputs[inputCounter] && inputs[inputCounter].value.trim() !== '' ? inputs[inputCounter].value : "<missing>";
          fill_in_the_blanks += inputValue;
          inputCounter++; // Move to the next input field
        }
      });
      // Add a newline character after each line, except the last one
      if (lineIndex < doc.template.fill_in_the_blanks.split(/\n+/).length - 1) {
        fill_in_the_blanks += '\n';
      }
    });

    // Construct formData with the generated fill_in_the_blanks string
    const formData = {
      user_input: fill_in_the_blanks
    };

    try {
      const response = await apiService.post(endpoint, formData);
      if (response.success) {
        console.log('Document generation initiated successfully');
      } else {
        console.error('Operation failed:', response.message);
        doc.is_generating = false;
      }
    } catch (error) {
      console.error('Error during operation:', error);
      doc.is_generating = false;
    }
  };

  const generateFormFromText = useCallback((text) => {
    // First, split the entire text by new lines to get individual lines
    const lines = text.split(/\n+/);
    // Then, process each line
    return lines.map((line, lineIndex) => {
          // For each line, split by one or more consecutive underscores to find parts and blanks
          const parts = line.split(/_+/);
          return (
            <p key={lineIndex}>
              {parts.map((part, partIndex) => (
                <React.Fragment key={partIndex}>
                  {part}
                  {/* Insert an input field for each blank, except after the last part of the line */}
                  {partIndex < parts.length - 1 && (
                    <input type="text" placeholder={'Detalj'} style={{margin: '0 5px'}} />
                  )}
                </React.Fragment>
              ))}
            </p>
          );
        });
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      {doc.template.fill_in_the_blanks && generateFormFromText(doc.template.fill_in_the_blanks)}
      <DocumentFormButton document={doc} />
    </Form>
  );
}

export default FillInTheBlanks;
