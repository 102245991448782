import styled from 'styled-components';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

export const StyledModal = styled(Modal)`
  max-width: 90vw;
  width: 90vw;
  height: 90vh;
  background-color: #303030;
  color: white;

  .modal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: inherit;
    color: inherit;
  }
`;

export const StyledModalHeader = styled(ModalHeader)`
  .btn-close {
    color: #FFF;
    opacity: 1;

    &:hover {
      color: #CCC;
    }
  }
`;

export const StyledModalBody = styled(ModalBody)`
  display: flex;
  padding: 0;
  max-height: 80vh;
  overflow: hidden;
  border-radius: 0.5rem;
  flex: 1;
`;

export const SelectableElement = styled.div`
  border: 2px solid transparent;
  padding: 4px;
  margin: 4px 0;
  cursor: pointer;
  border-radius: 4px;
  transition: border-color 0.2s ease;

  ${props => props.isSelected && `
    border-color: #007bff;
  `}

  &:hover {
    ${props => !props.isSelected && `
      border-color: rgba(0, 123, 255, 0.3);
    `}
  }
`;

export const ContentColumn = styled.div`
  flex: 3;
  padding: 1rem;
  overflow-y: auto;
  border-right: 1px solid #444;
`;

export const SideColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  overflow-y: auto;
  padding: 1rem;
`;
