import React, { useImperativeHandle, forwardRef, useState, useMemo } from 'react';
import { AvatarContainer, Emoji, BusyOverlay } from './AvatarAgent2D.styles';
import { useMeeting } from '../MeetingContext';
import AudioVisualization from './AudioVisualization';

const AvatarAgent2D = forwardRef(({
    agent,
    dimension = 300,
  }, ref) => {
  const [showHand, setShowHand] = useState(false);
  const { 
    activeSpeakerId,
    setActiveSpeakerId,
    initializedActiveSpeakerId,
    audioAnalyzerRef
  } = useMeeting();

  const isActive = agent.id === activeSpeakerId;

  // Expose controls via ref
  useImperativeHandle(ref, () => ({
    setShowHand: (show) => setShowHand(show),
    stopTalking: () => {
      console.log('🔵 stopTalking called');
    }
  }));

  const visualizationEnabled = useMemo(() => {
    return isActive && activeSpeakerId === initializedActiveSpeakerId;
  }, [isActive, activeSpeakerId, initializedActiveSpeakerId]);

  console.log('agent.is_simple_tasking', agent.is_simple_tasking, typeof agent.is_simple_tasking);
  return (
    <div className="d-flex flex-column align-items-center">
      <AvatarContainer 
        $dimension={dimension}
        $isActive={isActive}
        onClick={() => setActiveSpeakerId(agent.id)}
      >
        <AudioVisualization
          dimension={dimension}
          analyzer={audioAnalyzerRef.current}
          enabled={visualizationEnabled}
        />
        <div
          style={{
            width: '100%',
            height: '100%',
            backgroundImage: `url(${agent.image_url})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1,
            borderRadius: '50%',
            overflow: 'hidden',
          }}
          className='d-flex justify-content-center align-items-center'
        >
          {isActive && activeSpeakerId !== initializedActiveSpeakerId && <span className="spinner-border" role="status" aria-hidden="true"></span>}
        </div>
        {/* Hand Emoji */}
        <Emoji $show={showHand ? "true" : "false"}>
          ✋
        </Emoji>
        {agent?.is_simple_tasking && <BusyOverlay />}
      </AvatarContainer>
      <div className='text-center my-2'><strong>{agent.name}</strong></div>
      <div>
        
      </div>
    </div>
  );
});

export default AvatarAgent2D;


/*<p>isActive: {isActive ? 'true' : 'false'}</p>
        <p>agentId: {agent.id}</p>
        <p>activeSpeakerId: {activeSpeakerId}</p>*/