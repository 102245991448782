import React from 'react';
import {
  Button,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

function DocumentFormButton({ document: doc, color = 'primary' }) {
  const { t } = useTranslation();
  const isGenerating = doc.is_generating;
  const isSummarizing = doc.is_summarizing;
  /* const isVectorizing = doc.is_vectorizing; 
  isVectorizing ? (
        <>
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {` ${t('Vectorizing...')}`}
        </>
      ) */

  return (
    <Button color={color} type="submit" disabled={isGenerating || isSummarizing} className='mt-4'>
      {isGenerating ? (
        <>
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {` ${t('Generating...')}`}
        </>
      ) : isSummarizing ? (
        <>
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {` ${t('Summarizing...')}`}
        </>
      ) : t('Generate this step')}
    </Button>
  );
}

export default DocumentFormButton;
