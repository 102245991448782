import styled from 'styled-components';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

export const StyledModal = styled(Modal)`
  max-width: 90vw;
  width: 90vw;
  height: 90vh;
  background-color: #303030;
  color: white;

  .modal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: inherit;
    color: inherit;
  }
`;

export const StyledModalHeader = styled(ModalHeader)`
  .btn-close {
    color: #FFF;
    opacity: 1;

    &:hover {
      color: #CCC;
    }
  }
`;

export const StyledModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #303030;
  color: white;
  border-top: 1px solid #444;

  .steps-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    margin: 0 2rem;
  }

  .step-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 8px;
  }

  .step-circle {
    width: 12px;
    height: 12px;
    border: 2px solid #007bff;
    border-radius: 50%;
    margin-bottom: 4px;
    transition: all 0.2s ease;
  }

  .step-circle.active {
    background-color: #007bff;
  }

  button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #0056b3;
    }
  }

  span {
    flex-grow: 1;
    text-align: center;
  }
`;
