import React from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';
import { NavLink as StrapNavLink, Button } from 'reactstrap';
import styled, { css } from 'styled-components';

const CustomStyledNavLink = styled(StrapNavLink)`
  color: white;
  font-weight: 800;
  font-size: 1.1em;
  &:hover {
    color: orange;
  }
  &:focus {
    color: orange;
  }
`;

export const StyledNavLink = ({ to, children, ...rest }) => {
  return (
    <CustomStyledNavLink {...rest} tag={RRNavLink} to={to}>
      {children}
    </CustomStyledNavLink>
  );
};

const CustomStyledNavLinkDocument = styled(({ hasStar, ...props }) => <StrapNavLink {...props} />)`
  color: ${props => props.isactive === 'true' ? 'orange' : 'white'};
  font-size: 0.95em;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  display: flex;
  align-items: center;
  justify-content: ${props => props.hasStar ? 'space-between' : 'start'};
  &:hover {
    color: orange;
    .star-button {
      color: gold; // Show the button on hover
    }
  }
  &:focus {
    color: orange;
  }
`;

export const StyledNavLinkDocument = ({ to, isactive, hasStar, children, ...rest }) => {
  return (
    <CustomStyledNavLinkDocument {...rest} isactive={isactive} tag={RRNavLink} to={to}>
      {children}
    </CustomStyledNavLinkDocument>
  );
};

export const StarButton = styled(Button)`
  background: transparent;
  border: none;
  color: transparent; // Initially hidden
  cursor: pointer;
  transition: color 0.3s;
  padding: 0.1em;
  margin-left: auto; // Align to the right
`;
const spinnerGrowMiniStyles = css`
  --bs-spinner-width: 0.7rem;
  --bs-spinner-height: 0.7rem;
  --bs-spinner-border-width: 0.15em;
  color: yellow;
`;

export const SpinnerGrowMini = styled.span`
  ${spinnerGrowMiniStyles}
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  border-width: var(--bs-spinner-border-width);
  // Add any additional styles you need for the spinner
`;