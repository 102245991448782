import axios from "axios";
import apiService from '../../../utils/apiService';

function base64ToBlob(base64, mimeType) {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
};

function fetchElevenLabsAudio(apiKey, voiceId, id, text) {
  return new Promise(async (resolve, reject) => {
    const baseUrl = "https://api.elevenlabs.io/v1/text-to-speech";
    const headers = {
      "Content-Type": "application/json",
      "xi-api-key": apiKey,
    };
    const requestBody = {
      text,
      model_id: "eleven_multilingual_v2",
      voice_settings: { stability: 0.5, similarity_boost: 0.5 },
    };
    const voiceIdString =  typeof voiceId === "string" ? voiceId : JSON.stringify(voiceId);
    try {
      console.log(`\n\nElevenlabs fetching...`, text);
      const response = await axios.post(
        `${baseUrl}/${voiceIdString}/with-timestamps`,
        requestBody,
        { headers }
      );
      if (response.status === 200) {
        const { audio_base64, alignment } = response.data;
        const audioBlob = base64ToBlob(audio_base64, "audio/mpeg");
        const audioUrl = URL.createObjectURL(audioBlob);
        resolve({ id, text, audioUrl, alignment });
      } else {
        console.log(`Error: Unable to preload audio. Status: ${response.status}`);
        reject(new Error(`Error: Unable to fetch elevenlabs audio. ${response.status}`));
      }
    } catch (error) {
      console.error(`Error: Unable to preload audio.`, error);
      reject(new Error(`Error: Unable to fetch elevenlabs audio. ${error.message}`));
    }
  });
}

function fetchLLMPhrase(topicID, sessionId, previousPhrase, text) {
  return doLLMPrompt(topicID, sessionId, previousPhrase, text, false);
}
function fetchLLMCompletion(topicID, sessionId, previousPhrase, text) {
  return doLLMPrompt(topicID, sessionId, previousPhrase, text, true);
}

// TODO: Consider if text is same as last time
// TODO: Consider if we want to fetch while this is fetching?
async function doLLMPrompt(topicID, sessionId, previousPhrase, text, finalize = false) {
  return new Promise(async (resolve, reject) => {
    if (!text || text.length === 0) {console.log('doLLMPrompt: STOP! No text');return reject('No text');}
    // console.log('LLM: Fetching...', text,'finalize:', finalize);
    const endpoint = finalize ? `/api/topic/${topicID}/finalize-response/` : `/api/topic/${topicID}/predict-response/`;
    const formData = {
      user_input: text,
      previous_phrase: previousPhrase,
      session_id: sessionId,
    };
    try {
      const response = await apiService.post(endpoint, formData);
      const phrase = response.data.data;
      const session_id = response.data.session_id;
      console.log(
        `\nLLM [${session_id}]:`,
        phrase,
        '\n',response.data
      );
      resolve({session_id, phrase});
    } catch (error) {
      console.error('Error in doLLMPrompt:', error);
      reject(error);
    }
  });
};

function splitSentences(texts) {
  console.log('splitSentences:', texts);
  // Split texts into sentences (by examining .?!)
  const sentences = texts.split(/(?<=[.?!])\s+/)
      .filter(sentence => sentence.trim().length > 0);
  const audios = sentences.map((sentence) => {
    const id = Math.random().toString(36).substring(2, 15);
    return {
      id,
      text: sentence,
      audio: undefined
    }
  });
  return audios;
}

// -------------------------------------------------
// Elevenlabs streaming with timestamps
// -------------------------------------------------
/*
function streamElevenLabsAudio(apiKey, voiceId, audio, text, onAlignment) {
  const abortController = new AbortController();
  const promise = new Promise(async (resolve, reject) => {
    const baseUrl = "https://api.elevenlabs.io/v1/text-to-speech";
    const headers = {
      "Content-Type": "application/json",
      "xi-api-key": apiKey,
    };
    const requestBody = {
      text,
      model_id: "eleven_multilingual_v2",
      voice_settings: { stability: 0.5, similarity_boost: 0.5 },
    };
    const voiceIdString =
      typeof voiceId === "string" ? voiceId : JSON.stringify(voiceId);

    let mediaSource;
    let reader;
    let isMediaSourceOpen = true;
    let isSourceBufferValid = true;
    let audioUrl;
    let sourceBuffer;

    // Define event listener functions to manage them properly
    let onAudioEnded;
    let onSourceBufferUpdateEnd;

    abortController.signal.addEventListener("abort", () => {
      // Stop audio playback
      audio.pause();
      audio.currentTime = 0;
      audio.src = "";

      // Close MediaSource if needed
      if (mediaSource && mediaSource.readyState === "open") {
        mediaSource.endOfStream();
        isMediaSourceOpen = false;
      }

      // Cancel the reader
      if (reader) {
        reader.cancel().catch((error) => {
          console.error("Error cancelling reader:", error);
        });
      }

      // Remove event listeners
      if (sourceBuffer && onSourceBufferUpdateEnd) {
        sourceBuffer.removeEventListener(
          "updateend",
          onSourceBufferUpdateEnd
        );
      }
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }
      if (audio && onAudioEnded) {
        audio.removeEventListener("ended", onAudioEnded);
      }

      // Reject the promise
      reject(new Error("Streaming aborted"));
    });

    try {
      console.log(`\n\nElevenlabs streaming...`, text);

      // Before assigning a new src, reset the audio element
      audio.pause();
      audio.currentTime = 0;
      audio.src = "";

      // Create a MediaSource object
      mediaSource = new MediaSource();
      audioUrl = URL.createObjectURL(mediaSource);
      audio.src = audioUrl;

      onAudioEnded = function () {
        // Revoke the object URL to free up memory
        URL.revokeObjectURL(audioUrl);
        audio.removeEventListener("ended", onAudioEnded);
      };

      audio.addEventListener("ended", onAudioEnded);

      let characters = [];
      let characterStartTimesSeconds = [];
      let characterEndTimesSeconds = [];

      let audioQueue = [];
      let isEnded = false;

      mediaSource.addEventListener("sourceopen", async () => {
        sourceBuffer = mediaSource.addSourceBuffer("audio/mpeg");

        onSourceBufferUpdateEnd = function () {
          if (!isMediaSourceOpen || !isSourceBufferValid) return;
          if (audioQueue.length > 0) {
            appendNextAudioBuffer();
          } else if (isEnded) {
            endStream();
          }
        };

        sourceBuffer.addEventListener("updateend", onSourceBufferUpdateEnd);

        const response = await fetch(
          `${baseUrl}/${voiceIdString}/stream/with-timestamps?output_format=mp3_44100_128`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(requestBody),
            signal: abortController.signal,
          }
        );

        if (response.status === 200) {
          reader = response.body.getReader();
          const decoder = new TextDecoder("utf-8");
          let buffer = "";

          const processChunk = async ({ done, value }) => {
            console.log("processChunk:", done, value);
            if (done) {
              isEnded = true;
              if (!sourceBuffer.updating && audioQueue.length === 0) {
                endStream();
              }
              return;
            }

            const chunkValue = decoder.decode(value, { stream: true });
            buffer += chunkValue;

            let lines = buffer.split("\n");
            buffer = lines.pop(); // Leave last partial line in buffer

            for (let line of lines) {
              if (line.trim()) {
                try {
                  const responseData = JSON.parse(line);
                  const { audio_base64, alignment } = responseData;

                  if (audio_base64) {
                    const audioBuffer = base64ToArrayBuffer(audio_base64);
                    audioQueue.push(audioBuffer);
                    if (
                      !sourceBuffer.updating &&
                      isMediaSourceOpen &&
                      mediaSource.readyState === "open"
                    ) {
                      appendNextAudioBuffer();
                    }
                  }

                  if (alignment) {
                    if (alignment.characters)
                      characters.push(...alignment.characters);
                    if (alignment.character_start_times_seconds)
                      characterStartTimesSeconds.push(
                        ...alignment.character_start_times_seconds
                      );
                    if (alignment.character_end_times_seconds)
                      characterEndTimesSeconds.push(
                        ...alignment.character_end_times_seconds
                      );

                    // Call the onAlignment callback with the new data
                    if (typeof onAlignment === "function") {
                      onAlignment({
                        characters: alignment.characters,
                        character_start_times_seconds:
                          alignment.character_start_times_seconds,
                        character_end_times_seconds:
                          alignment.character_end_times_seconds,
                      });
                    }
                  }
                } catch (e) {
                  console.error("Error parsing JSON:", e);
                }
              }
            }

            reader
              .read()
              .then(processChunk)
              .catch((error) => {
                if (abortController.signal.aborted) {
                  // Handle abort silently
                } else {
                  console.error("Error reading stream:", error);
                  reject(error);
                }
              });
          };

          reader
            .read()
            .then(processChunk)
            .catch((error) => {
              if (abortController.signal.aborted) {
                // Handle abort silently
              } else {
                console.error("Error reading stream:", error);
                reject(error);
              }
            });

          // Start playing as soon as the media source is ready
          audio.play().catch((error) => {
            console.error("Error playing audio:", error);
          });
        } else {
          const errorMessage = await response.text();
          console.error(
            `Error fetching audio: ${response.status} - ${errorMessage}`
          );
          reject(
            new Error(
              `Error fetching audio: ${response.status} - ${errorMessage}`
            )
          );
        }
      });

      function appendNextAudioBuffer() {
        if (!isMediaSourceOpen || !isSourceBufferValid) return;
        if (audioQueue.length > 0 && !sourceBuffer.updating) {
          const nextBuffer = audioQueue.shift();
          try {
            if (mediaSource.readyState === "open") {
              sourceBuffer.appendBuffer(nextBuffer);
            }
          } catch (e) {
            console.error("Error appending buffer:", e);
            isSourceBufferValid = false;
            endStream();
          }
        }
      }

      function endStream() {
        if (mediaSource.readyState === "open") {
          try {
            mediaSource.endOfStream();
          } catch (e) {
            console.error("Error ending MediaSource stream:", e);
          }
          isMediaSourceOpen = false;
        }
        if (sourceBuffer && onSourceBufferUpdateEnd) {
          sourceBuffer.removeEventListener(
            "updateend",
            onSourceBufferUpdateEnd
          );
        }
        resolve();
      }
    } catch (error) {
      if (error.name === "AbortError") {
        // Handle abort error silently
      } else {
        console.error("Error: Unable to preload audio.", error);
        reject(
          new Error(`Error: Unable to fetch ElevenLabs audio. ${error.message}`)
        );
      }
    }
  });
  return { promise, abortController };
}

async function readLines(reader, onLine) {
  const decoder = new TextDecoder('utf-8');
  let buffer = '';
  let done = false;

  while (!done) {
    const { value, done: doneReading } = await reader.read();
    done = doneReading;
    const chunkValue = decoder.decode(value || new Uint8Array(), { stream: !done });
    buffer += chunkValue;

    let lines = buffer.split('\n');
    buffer = lines.pop(); // Leave the last partial line in buffer

    for (let line of lines) {
      onLine(line);
    }
  }

  if (buffer.length > 0) {
    onLine(buffer);
  }
}*/

function base64ToArrayBuffer(base64) {
  const binary_string = atob(base64);
  const len = binary_string.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}



module.exports = {
  fetchElevenLabsAudio,
  splitSentences,
  fetchLLMPhrase,
  fetchLLMCompletion,
  base64ToArrayBuffer
}