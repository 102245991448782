import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { AgentContainer, StyledModal, StyledModalHeader, StyledModalBody, StyledModalFooter, SharedScreenContainer, AvatarColumn } from './ModalVideoCall.styles';
import AvatarAgent from './AvatarAgent/AvatarAgent';
// import VadGoogleMicStream from './Mics/VadGoogleMicStream';
// import VadElevenLabsMicStream from './Mics/VadElevenLabsMicStream';
// import ElevenLabsMicStream from './Mics/ElevenLabsMicStream';
import VadGoogleMic from './Mics/VadGoogleMic';
import DeepgramElevenLabsMic from './Mics/DeepgramElevenLabsMic';
import VadDeepgramElevenLabsMic from './Mics/VadDeepgramElevenLabsMic';
import VadDeepgramElevenLabsMicStream from './Mics/VadDeepgramElevenLabsMicStream';
import MillisMic from './Mics/MillisMic';
import useStore from '../../store';
import { getClientSetting } from '../../utils/featureFlags';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

import apiService from '../../utils/apiService';
import VadOpenaiStream from './Mics/VadOpenaiStream';

const ModalVideoCall = ({ isOpen, toggle, title, agents }) => {
  // eslint-disable-next-line no-undef
  const [transcript, setTranscript] = useState('');
  const { language } = useStore((state) => ({ language: state.language }));
  const { meeting } = useStore((state) => ({ meeting: state.meeting }));
  const [isSharedScreenActive, setIsSharedScreenActive] = useState(false);
  const [sharedContent, setSharedContent] = useState(null);

  const modalRefs = useMemo(() => {
    const refs = {};
    const configs = window.agent_configs || [];
    agents.forEach(agent => {
      refs[agent.id] = React.createRef();
      // Set voices
      const config = configs.find((c) => c.id === agent.image_id);
      if (config.voice === 'F' && getClientSetting('MODAL_CONFERENCE') === 'GOOGLE') agent.voice = 'Ruth';
      // Elevenlabs
      if (config.voice === 'M' && getClientSetting('MODAL_CONFERENCE') === 'ELEVENLABS_STREAM' && language === 'sv') agent.voice = 'x0u3EW21dbrORJzOq1m9'; // Adam
      if (config.voice === 'F' && getClientSetting('MODAL_CONFERENCE') === 'ELEVENLABS_STREAM' && language === 'sv') agent.voice = '4xkUqaR9MYOJHoaC1Nak'; // Sanna
      if (config.voice === 'M' && getClientSetting('MODAL_CONFERENCE') === 'ELEVENLABS_STREAM' && language === 'en') agent.voice = 'RPEIZnKMqlQiZyZd1Dae'; // Cristopher
      if (config.voice === 'F' && getClientSetting('MODAL_CONFERENCE') === 'ELEVENLABS_STREAM' && language === 'en') agent.voice = 'CoJTolOgtIv629HNctrE'; // Nancy
      // Millis
      const ELEVENLABS_MALES = [
        "29vD33N1CtxCmqQRPOHJ", "5Q0t7uMcjvnagumLfvZi", "CYw3kZ02Hs0563khs1Fj", "ErXwobaYiN019PkySvjV",
        "IKne3meq5aSn9XLyUdCD", "JBFqnCBsd6RMkjVDRZzb",
        /* NOT TESTED: "MF3mGyEYCl7XYWbV9V6O", "N2lVS1w4EtoT3dr4eOWO", "ODq5zmih8GrVes37Dizd",
        "SOYHLrjzK2X1ezoPC6cr", "TX3LPaxmHKxFdv7VOQHJ", "ThT5KcBeYPX3keUQqHPh", "TxGEqnHWrfWFTfGW9XjX",
        "VR6AewLTigWG4xSOukaG", "Xb7hH8MSUJpSbSDYk0k2", "ZQe5CZNOzWyzPSCn5a3c", "Zlb1dXrM653N07WRdFW3",
        "bVMeCyTHy58xNoL34h3p", "flq6f7yk4E4fJM5XTYuZ", "g5CIjZEefAph4nQFvHAz", "iP95p4xoKVk53GoZ742B",
        "nPczCjzI2devNBz1zQrb", "onwK4e9ZLuTAKqWW03F9", "pNInz6obpgDQGcFmaJgB", "pqHfZKP75CvOlQylNhV4",
        "yoZ06aMxZJJ28mfd3POQ", "zcAOhNBS3c14rBihAFp1" */
      ]; // "2EiwWnXFnvU5JabPnv8n","D38z5RcWu1voky8WS1ja",  "GBv7mTt0atIp3Br8iCZE",
      const ELEVENLABS_FEMALES = [
        "21m00Tcm4TlvDq8ikWAM", "EXAVITQu4vr4xnSDxMaL", "XrExE9yKIg1WjnnlVkGX",
        "pFZP5JQG7iQjIQuC4Bku", "z9fAnlkpzviPz146aGWa"
      ]; // "t0jbNlBVZ17f02VDIeMI", "pMsXgVXv3BLzUgSXRplE", "zrHiDhphv9ZnVXBqCLjz", "AZnzlk1XvdvUeBnXmlld",  "LcfcDJNUP1GQjkzn1xUU", "jBpfuIE2acCO8z3wKNLl",
      // "jsCqWAovK2LkecY7zXl4","oWAxZDx7w5VEj9dCyTzz",  "piTKgcLEGmPE4e6mEKli",
      if (config.voice === 'M' && getClientSetting('MODAL_CONFERENCE') === 'MILLIS') {
        const randomIndex = Math.floor(Math.random() * ELEVENLABS_MALES.length);
        agent.voice = ELEVENLABS_MALES[randomIndex];
      }
      if (config.voice === 'F' && getClientSetting('MODAL_CONFERENCE') === 'MILLIS') {
        const randomIndex = Math.floor(Math.random() * ELEVENLABS_FEMALES.length);
        agent.voice = ELEVENLABS_FEMALES[randomIndex];
      }
      // NOTE: Google current handles voice in view, TODO: move here
      console.log('Agent voice selected', agent.voice);
    });
    return refs;
  }, [agents, language]);

  const playElevenlabsAlignments = useCallback((agent_id, alignments) => {
    const agentId = agent_id || Object.keys(modalRefs)[0];
    const agentRef = modalRefs[agentId];
    if (agentRef && agentRef.current) {
      console.log('playElevenlabsAlignments', alignments);
      agentRef.current.playElevenlabsAlignments(alignments);
    }
  }, [modalRefs]);

  const onTalkDone = useCallback(() => {
    console.log('onTalkDone called');
    // Your onTalkDone logic here
  }, []);

  const playGoogleMessage = useCallback((agent_id, audioUrl, visemes) => {
    const agentRef = modalRefs[agent_id];
    if (agentRef && agentRef.current) {
      agentRef.current.playGoogleMessage(audioUrl, visemes);
    }
  }, [modalRefs]);

  const playMillisMessage = useCallback((agent_id, alignments) => {
    const agentId = agent_id || Object.keys(modalRefs)[0];
    const agentRef = modalRefs[agentId];
    if (agentRef && agentRef.current) {
      agentRef.current.playMillisMessage(alignments);
    }
  }, [modalRefs]);

  const stopTalking = useCallback((agent_id) => {
    const agentId = agent_id || Object.keys(modalRefs)[0];
    const agentRef = modalRefs[agentId];
    if (agentRef && agentRef.current) {
      console.log('STOP TALKING', agentRef);
      agentRef.current.stopTalking();
    }
  }, [modalRefs]);

  useEffect(() => {
    if (meeting) {
      setSharedContent(meeting?.shared_content);
    }
  }, [meeting]);

  const handleExecuteSharedScreen = useCallback(async () => {
    if (!meeting) {
      console.error('Meeting is missing:', meeting);
      return;
    }
    try {
      const response = await apiService.post(`/api/meetings/${meeting.id}/execute-shared-screen/`);
      if (!response.success) {
        console.error('Operation failed:', response.message);
        return;
      }
    } catch (error) {
      console.error('handleStartAgent error', error);
    }
  }, [meeting]);


  // Add handleAudioAnalysis callback
  const handleAudioAnalysis = useCallback((agent_id, analyzerNode) => {
    const agentRef = modalRefs[agent_id];
    if (agentRef?.current?.visualization) {
      console.log('Setting analyzer for agent:', agent_id);
      agentRef.current.visualization.setAnalyzer(analyzerNode);
    }
  }, [modalRefs]);

  // Add visualization configuration effect
  useEffect(() => {
    const firstAgent = agents[0];
    if (firstAgent?.id && modalRefs[firstAgent.id]?.current) {
      modalRefs[firstAgent.id].current.visualization.configure({
        opacity: 0.6,
        colors: {
          start: 'rgba(0, 255, 255, 0.2)',
          end: 'rgba(0, 255, 255, 0.8)'
        },
        barCount: 180,
        scaleFactor: 50,
        innerRadiusOffset: 60,
        type: 'circular'
      });
    }
  }, [agents, modalRefs]);

  const firstAgent = agents[0];
  if (!firstAgent)  return null;

  return (
    <StyledModal isOpen={isOpen} toggle={toggle} isSharedScreenActive={isSharedScreenActive}>
      <StyledModalHeader toggle={toggle}>{title}</StyledModalHeader>
      <StyledModalBody isSharedScreenActive={isSharedScreenActive}>
        {isSharedScreenActive ? (
          <>
            <SharedScreenContainer>
              {sharedContent?.content ? (
                sharedContent.type === 'MARKDOWN' ? (
                  <Markdown
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                  >
                    {sharedContent.content}
                  </Markdown>
                ) : sharedContent.type === 'HTML' ? (
                  <iframe
                    srcDoc={sharedContent.content}
                    title="Shared Content"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allowFullScreen
                    sandbox="allow-scripts allow-same-origin allow-forms"
                    style={{ backgroundColor: 'white' }}
                  />
                ) : (
                  <div>{sharedContent.content}</div>
                )
              ) : (
                <p>No content is being shared</p>
              )}
            </SharedScreenContainer>
            <AvatarColumn>
              {agents && agents.map((agent, index) => (
                <AvatarAgent
                  key={`avatar-${agent.id}`}
                  agent={agent}
                  ref={modalRefs[agent.id]}
                  onTalkDone={onTalkDone}
                />
              ))}
            </AvatarColumn>
          </>
        ) : (
          <AgentContainer>
            {agents && agents.map((agent, index) => (
              <AvatarAgent
                key={`avatar-${agent.id}`}
                agent={agent}
                ref={modalRefs[agent.id]}
                onTalkDone={onTalkDone}
              />
            ))}
          </AgentContainer>
        )}
      </StyledModalBody>
      <StyledModalFooter>
        {/* {getClientSetting('GOOGLE_STREAM && <VadGoogleMicStream isOpen={isOpen} setTranscript={setTranscript} playGoogleMessage={playGoogleMessage} stopTalking={stopTalking} />} */}
        {/* {getClientSetting('VAD_ELEVENLABS_STREAM && <VadElevenLabsMicStream isOpen={isOpen} setTranscript={setTranscript} stopTalking={stopTalking} playElevenlabsAlignments={playElevenlabsAlignments} />} */}
        {/* getClientSetting('ELEVENLABS_STREAM && <ElevenLabsMicStream isOpen={isOpen} setTranscript={setTranscript} stopTalking={stopTalking} playElevenlabsAlignments={playElevenlabsAlignments} />} */}
        {getClientSetting('MODAL_CONFERENCE') === 'GOOGLE' && <VadGoogleMic isOpen={isOpen} setTranscript={setTranscript} playGoogleMessage={playGoogleMessage} stopTalking={stopTalking} />}
        {getClientSetting('MODAL_CONFERENCE') === 'DEEPGRAM_ELEVENLABS' && <DeepgramElevenLabsMic isOpen={isOpen} setTranscript={setTranscript} stopTalking={stopTalking} playElevenlabsAlignments={playElevenlabsAlignments} />}
        {getClientSetting('MODAL_CONFERENCE') === 'VAD_DEEPGRAM_ELEVENLABS' && <VadDeepgramElevenLabsMic agent={firstAgent} isOpen={isOpen} setTranscript={setTranscript} stopTalking={stopTalking} playElevenlabsAlignments={playElevenlabsAlignments} />}
        {getClientSetting('MODAL_CONFERENCE') === 'VAD_DEEPGRAM_ELEVENLABS_STREAM' && (
          <VadDeepgramElevenLabsMicStream
            isOpen={isOpen}
            setTranscript={setTranscript}
            stopTalking={stopTalking}
            playElevenlabsAlignments={(alignment) => playElevenlabsAlignments(firstAgent.id, alignment)}
          />
        )}
        {getClientSetting('MODAL_CONFERENCE') === 'MILLIS' && <MillisMic agent={firstAgent} isOpen={isOpen} setTranscript={setTranscript} stopTalking={stopTalking} playMillisMessage={playMillisMessage} />}
        {getClientSetting('MODAL_CONFERENCE') === 'VAD_OPENAI_STREAM' && (
          <VadOpenaiStream 
            isOpen={isOpen} 
            setTranscript={setTranscript} 
            stopTalking={stopTalking} 
            playElevenlabsAlignments={(alignment) => playElevenlabsAlignments(firstAgent.id, alignment)}
            onAudioAnalysis={(analyzer) => {
              console.log('Audio analyzer created');
              handleAudioAnalysis(firstAgent.id, analyzer);
            }}
          />
        )}
        <div className='d-flex w-100 justify-content-center align-items-center text-center'>{transcript}</div>
        <button onClick={() => setIsSharedScreenActive(!isSharedScreenActive)}>
          {isSharedScreenActive ? 'Hide Shared Screen' : 'Show Shared Screen'}
        </button>
        <button onClick={handleExecuteSharedScreen}>Execute</button>
      </StyledModalFooter>
    </StyledModal>
  );
};

export default ModalVideoCall;