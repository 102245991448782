import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; // Add this import at the top
import { useTranslation } from 'react-i18next';
import { Row, Col, CardBody, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from 'reactstrap';

import apiService from '../../utils/apiService';
import { AgentCard, AgentProgress, AgentText, StyledDropdown, AgentProgressSegment, AgentProgressSegmentDone, StarButton } from './Agent.styles.js';
import useStore from '../../store';
import { getFeatureFlag } from '../../utils/featureFlags';
import { useAgentRoleLabel } from '../../utils/agentRoles';


// TODO: handleDelete

const Agent = ({
  agent, workspace, project,
  handleDelete, handleRemFromTeam, handleEditCoWorker,
  handleAddToTeam, handleStartMeeting,
  enableRun = true, showHeader = true, showDropDown = true}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { projects } = useStore((state) => ({
    projects: state.projects,
  }));

  const isRunning = agent.status === 'running';
  // const progressPercentage = agent.total_documents ? ((agent.progress / agent.total_documents) * 100) : 0;
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  const inProject = useMemo(() => {
    const currProjectId = (project && project.id) || null;
    const projectLinks = agent && agent.project_ids
      ? projects.filter((p) => currProjectId !== p.id && agent.project_ids.includes(p.id)).map((p) =>
          <Link key={p.id} to={`/project/${p.id}/`}>{p.name}</Link>
        )
      : [];
    if (project && project.id) {
      if (projectLinks.length === 0) return null;
      return <div className='mb-2'><small>{t('Other projects:')} {projectLinks}</small></div>;
    }
    return <div className='mb-2'><small>{t('Projects:')} {projectLinks}</small></div>;
  }, [project, agent, projects, t]);


  const handleStartAgent = async (projectId, agentId) => {
    if (!projectId || !agentId) {
      console.error('Project ID or Agent ID is missing:', projectId, agentId);
      return;
    }
    try {
      const response = await apiService.post(`/api/agents/${projectId}/start/${agentId}/`);
      if (!response.success) {
        console.error('Operation failed:', response.message);
        return;
      }
      // Assuming you want to update the agent's status in your local state as well
      // updateAgentStatus(agentId, 'started'); // Implement this function based on your state management
    } catch (error) {
      console.error('handleStartAgent error', error);
    }
  };

  const handleStopAgent = async (agentId) => {
    if (!agentId) {
      console.error('Agent ID is missing:', agentId);
      return;
    }
    try {
      const response = await apiService.post(`/api/agents/${agentId}/stop/`);
      if (!response.success) {
        console.error('Operation failed:', response.message);
        return;
      }
      // Assuming you want to update the agent's status in your local state as well
      // updateAgentStatus(agentId, 'stopped'); // Implement this function based on your state management
    } catch (error) {
      console.error('handleStopAgent error', error);
    }
  };

  const handlePromoteAgent = async (agentId, isPromote) => {
    if (!agentId) {
      console.error('Agent ID is missing:', agentId);
      return;
    }
    try {
      const response = await apiService.post(`/api/agents/${agentId}/${isPromote ? 'promote' : 'demote'}/`);
      if (!response.success) {
        console.error('Operation failed:', response.message);
        return;
      }
    } catch (error) {
      console.error('handlePromoteAgent error', error);
    }
  };

  const progressSegments = [];
  for (let i = 0; i < agent.total_documents; i++) {
    const Segment = i < agent.progress ? AgentProgressSegmentDone : AgentProgressSegment;
    progressSegments.push(<Segment key={i} />);
  }

  const getAgentRoleLabel = useAgentRoleLabel();

  return (
    <AgentCard className="card mb-3">
    {(handleRemFromTeam || handleEditCoWorker || handleDelete) && showDropDown && (
      <StyledDropdown>
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
          <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
            <i className="bi bi-three-dots-vertical"></i>
          </DropdownToggle>
          <DropdownMenu end>
            {handleEditCoWorker && <DropdownItem onClick={() => handleEditCoWorker(agent.id)}>{t('Edit')}</DropdownItem>}
            {project && project.id && handleRemFromTeam && <DropdownItem onClick={() => handleRemFromTeam(agent.id)}>{t('Remove')}</DropdownItem>}
            {handleDelete && <DropdownItem onClick={() => handleDelete(agent.id)}>{t('Delete')}</DropdownItem>}
          </DropdownMenu>
        </Dropdown>
      </StyledDropdown>
    )}
    <Row className="g-0">
      <Col sm="12" md={{ size: 3 }}>
        <div
          style={{
            backgroundImage: `url(${agent.image_url})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100%', // Adjust the height as needed
            width: '100%', // Adjust the width as needed
            minHeight: '150px',
            cursor: 'pointer', // Make it visually obvious it's clickable
          }}
          className="rounded-start"
          alt={agent.name}
          onClick={() => {
            if (project) {
              navigate(`/project/${project.id}/coworker/${agent.id}/`);
            } else {
              navigate(`/${workspace.id}/coworker/${agent.id}`);
            }
          }}
        ></div>
      </Col>
      <Col>
        <CardBody className='p-3'>
          {showHeader &&
            <h5 className='p-0 m-0'>
              {agent.name}
              &nbsp;
              <StarButton className="star-button" size="sm" onClick={() => handlePromoteAgent(agent.id, !agent.promoted)}>
              {agent.promoted ? (
                <span className="bi bi-star-fill"></span>
              ) : (
                <span className="bi bi-star"></span>
              )}
            </StarButton>
            </h5>}
          <AgentText>
            {isRunning ? (
              <>
                <div><small>{agent.doing}</small></div>
                <AgentProgress>
                  {progressSegments}
                </AgentProgress>
                {getFeatureFlag("CO-WORKER IN PROJECT") && inProject}
                {/* agent?.resources && agent?.resources?.length > 0 && (<div className='mb-2'><small>Resources: {agent?.resources?.join(', ')}</small></div>) */}
                <Button size="sm" color="danger" onClick={() => handleStopAgent(agent.id)}>
                  <i className="bi bi-stop-fill"></i> {t('Stop')}
                </Button>
              </>
            ) : (
              <>
                <div className='mb-2'>
                  <small>{agent?.description?.length > 200 ? `${agent.description.slice(0, 200)}...` : agent?.description}</small>
                </div>
                {agent?.roles && agent?.roles?.length > 0 && (
                  <div className='d-flex gap-1'>
                    {agent?.roles?.map((role, index) => (
                      <Badge key={index} color="secondary">{getAgentRoleLabel(role)}</Badge>
                    ))}
                  </div>
                )}
                {getFeatureFlag("CO-WORKER IN PROJECT") && inProject}
                {/* {agent?.resources && agent?.resources?.length > 0 && (<div className='mb-2'><small>Resources: {agent?.resources?.join(', ')}</small></div>)} */}
                {agent?.enabled && project && project.id && enableRun &&
                <Button size="sm" color="success" onClick={() => handleStartAgent(project.id, agent.id)}>
                  <i className="bi bi-play-fill"></i> {t('Start')}
                </Button>}
              </>
            )}
            {handleAddToTeam && project && project.id && (
              <Button size="sm" color="secondary" onClick={() => handleAddToTeam(agent.id)}>
                <i className="bi bi-person-plus-fill"></i> {t('Add')}
              </Button>
            )}
            {handleStartMeeting && getFeatureFlag('MEETINGS') && (
              <>
              <Button size="sm" color="primary" onClick={() => handleStartMeeting()}>
                <i className="bi bi-telephone-fill"></i> {t('Meet')}
              </Button>
            </>
            )}
          </AgentText>
        </CardBody>
      </Col>
    </Row>
  </AgentCard>
  );
};

export default Agent;
