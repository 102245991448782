import { useState } from 'react';
// import AWS from 'aws-sdk';

/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

// Initialize the Amazon Cognito credentials provider
AWS.config.region = 'eu-central-1';
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: 'eu-central-1:cc9c8d08-2a66-48a6-9a36-a2674f6d529e',
});
const polly = new AWS.Polly({ apiVersion: '2016-06-10' });

function usePolly() {
  const [error, setError] = useState(null);

  // Function to synthesize speech and return audio blob
  const fetchTextToSpeech = (speechParams) => {
    return new Promise((resolve, reject) => {
      polly.synthesizeSpeech(speechParams, (err, data) => {
        if (err) {
          console.error('Error details:', err);
          setError(err);
          reject(err);
        } else {
          const blob = new Blob([new Uint8Array(data.AudioStream)], { type: 'audio/mp3' });
          resolve(blob);
        }
      });
    });
  };

  // Function to get viseme information
  const fetchVisemeInformation = (visemeParams) => {
    return new Promise((resolve, reject) => {
      polly.synthesizeSpeech(visemeParams, (err, data) => {
        if (err) {
          setError(err);
          reject(err);
        } else {
          const lines = data.AudioStream.toString('utf-8').trim().split('\n');
          const jsonOutput = lines.map((line) => JSON.parse(line));
          resolve(jsonOutput);
        }
      });
    });
  };

  return { fetchTextToSpeech, fetchVisemeInformation, error };
}

export default usePolly;
