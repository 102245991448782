import styled from 'styled-components';

export const StyledCarouselCaption = styled.div`
  background: linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(0,0,0,0.6) 70%, rgba(0,0,0,0) 100%) !important;
  right: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  padding: 1rem;
  color: white;
`;
