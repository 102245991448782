import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const ModalBasic = ({ isOpen, toggle, title, children, size = 'lg', footerButtons }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size={size}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody id="modal-content">
        {children}
      </ModalBody>
      <ModalFooter>
        {footerButtons ? (
          footerButtons
        ) : (
          <Button color="primary" onClick={toggle}>OK</Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ModalBasic;