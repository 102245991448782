import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Button, Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import useStore from '../../../store';
import useElevenLabsJSON from '../../../hooks/useElevenLabsJSON';

const DeepgramElevenLabsMic = ({ isOpen, playElevenlabsAlignments, setTranscript, handleNewFinal }) => {
  const { t } = useTranslation();
  const [internalTranscript, setInternalTranscript] = useState('');
  const [initialized, setInitialized] = useState(false);
  const [micOn, setMicOn] = useState(false);
  const { language } = useStore((state) => ({
    language: state.language,
  }));

  const deepgramRef = useRef({});
  const audioRef = useRef(null);
  const { isPlaying, isLoading } = useElevenLabsJSON(audioRef); //respond, 
  const bufferTranscript = useRef([]);

  const doTTS = useCallback(async (text) => {
    if(!text || text.length === 0) return;
    // respond(topic.id, text, playElevenlabsAlignments);
  }, []);

  const closeDeepgram = useCallback(() => {
    if (deepgramRef.current.mediaStream) { deepgramRef.current.mediaStream.getTracks().forEach((track) => track.stop()); }
    if (deepgramRef.current.mediaRecorder) { deepgramRef.current.mediaRecorder.stop(); }
    if (deepgramRef.current.socketDeepgram) { deepgramRef.current.socketDeepgram.close(); }
    deepgramRef.current = {};
    doTTS(internalTranscript);
    setInternalTranscript('');
    setTranscript('');
  }, [doTTS, internalTranscript, setTranscript]);


  const wsUrl = useMemo(() => {
    const baseParams = {
      model: 'nova-2',
      punctuate: true,
      smart_format: true,
      interim_results: true,
      vad_events: false,
      diarize: true,
    };

    const languageSpecificParams = language === 'en'
      ? {
          model: 'nova-2-conversationalai',
          utterance_end_ms: 1500,
        }
      : {
          language: 'sv',
          utterance_end_ms: 1000,
        };

    const params = new URLSearchParams({
      ...baseParams,
      ...languageSpecificParams,
    });

    return `wss://api.deepgram.com/v1/listen?${params.toString()}`;
  }, [language]);


  const initDeepgram = useCallback(() => {
    console.log('!!!! initDeepgram');
    if (deepgramRef.current.socketDeepgram) {
      closeDeepgram();
      return;
    }
    if (!MediaRecorder.isTypeSupported('audio/webm')) {
      alert('Browser not supported');
      return;
    }
    navigator.mediaDevices.getUserMedia({ audio: true }).then(async (stream) => {
      const socketDeepgram = new WebSocket(wsUrl, [
        'token',
        process.env.REACT_APP_DEEPGRAM_API_KEY,
      ]);
      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: 'audio/webm',
      });

      socketDeepgram.onopen = () => {
        mediaRecorder.addEventListener('dataavailable', async (event) => {
          if (event.data.size > 0 && socketDeepgram.readyState === 1) {
            socketDeepgram.send(event.data);
          }
        });
        mediaRecorder.start(1000);
      };

      /*
      const completed = [];
      let ongoing = ''; // Track the ongoing interim internalTranscript
      socketDeepgram.onmessage = (message) => {
        const received = JSON.parse(message.data);
        const { type, channel, is_final } = received;
        const { alternatives } = channel;
        const currTranscript = alternatives ? alternatives[0].transcript.trim() : '';
        console.log('DG message', type, is_final, received);
        if (currTranscript.length === 0) return;

        if (!is_final) {
          ongoing = currTranscript; // Update the interim internalTranscript
          setInternalTranscript(currTranscript);
          setTranscript(currTranscript);
        } else {
          // completed.push(currTranscript);
          console.log('!!!! DG final', currTranscript);
          ongoing = '';
          setInternalTranscript(`FINAL: ${currTranscript}`);
          setTranscript(`FINAL: ${currTranscript}`);
        }
      };*/

      socketDeepgram.onmessage = (message) => {
        const received = JSON.parse(message.data);
        console.log('>>>', received);
        const { is_final, channel } = received;
        if (!channel) {
          console.error('Channel is undefined in the received message:', received);
          return;
        }
        const { alternatives } = channel;
        if (!alternatives || alternatives.length === 0) return;

        const { words } = alternatives[0];
        if (!words || words.length === 0) return;

        let currTranscript = '';
        let currentSpeaker = null;
        let speakerUtterance = '';

        words.forEach((word, index) => {
          if (word.speaker !== currentSpeaker) {
            if (currentSpeaker !== null) {
              currTranscript += `[Speaker ${currentSpeaker + 1}]: ${speakerUtterance.trim()}\n\n`;
              speakerUtterance = '';
            }
            currentSpeaker = word.speaker;
          }
          speakerUtterance += word.word + ' ';

          // Add the last speaker's utterance
          if (index === words.length - 1) {
            currTranscript += `[Speaker ${currentSpeaker + 1}]: ${speakerUtterance.trim()}`;
          }
        });

        currTranscript = currTranscript.trim();
        if (currTranscript.length === 0) return;

        let composite = '';
        if (is_final) {
          bufferTranscript.current.push(currTranscript);
          composite = bufferTranscript.current.join('\n');
        } else {
          composite = `${bufferTranscript.current.join('\n')}\n${currTranscript}`;
        }
        setTranscript(composite);
        if (is_final) {
          console.log('FINAL!!!', composite);
          handleNewFinal(composite);
          bufferTranscript.current = [];
          setTimeout(() => {
            setTranscript(bufferTranscript.current.join('\n'));
          }, 500);
          doTTS(composite);
        }
      };


      deepgramRef.current = { socketDeepgram, mediaRecorder, mediaStream: stream };
    }).catch((error) => {
      console.error('Error accessing microphone:', error);
    });
    // NOTE: Do NOT add handleNewFinal to the dependency array, it will cause a re-render of the component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeDeepgram, doTTS, language, setTranscript]);


  const handleMic = useCallback(() => {
    if (deepgramRef.current.socketDeepgram) {
      closeDeepgram();
    } else {
      console.log('initDeepgram handleMic!!');
      initDeepgram();
    }
    // toggle setMicOn
    setMicOn(!micOn);
  }, [closeDeepgram, initDeepgram, micOn]);

  // Ensure closeDeepgram and initDeepgram are stable with useCallback
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && isOpen) {
        handleMic();
      }
    };
    window.addEventListener('keypress', handleKeyPress);
    return () => window.removeEventListener('keypress', handleKeyPress);
  }, [handleMic, isOpen]); // Depends on closeDeepgram and initDeepgram if they are stable

  /**/
  useEffect(() => {
    if(!initialized) {
      setInitialized(true);
      setMicOn(true);
      if (!deepgramRef.current.socketDeepgram) initDeepgram();
    }
  }, [initDeepgram, initialized]);

  return (
    <>
      <audio ref={audioRef} />
      <div className="d-flex justify-content-center align-items-center w-100">
        {isLoading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
        {isPlaying && <Spinner color="primary" type="grow"></Spinner>}
        {!isPlaying && !isLoading && <Button color="danger" onClick={handleMic}>
          {micOn ? (<span className="bi bi-mic"></span>) : (<span className="bi bi-mic-mute"></span>)}
          {micOn? t('Stop') : t('Start')}
        </Button>}
      </div>
    </>
  );
};

export default DeepgramElevenLabsMic;
