import React from 'react';
import { useMeeting } from './MeetingContext';
import { AgentContainer, StyledModal, StyledModalHeader, StyledModalBody, StyledModalFooter, SharedScreenContainer, AvatarColumn } from './ModalVideoCall.styles';
import AvatarAgent2D from './AvatarAgent/AvatarAgent2D';
import { getClientSetting } from '../../utils/featureFlags';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import VadOpenaiStream from './Mics/VadOpenaiStream';
import SharedAgentsList from './SharedScreens/SharedAgentsList/SharedAgentsList';

import { MeetingProvider } from './MeetingContext';

const ModalVideoCall = (props) => {
  return (
    <MeetingProvider>
      <ModalVideoCallContent {...props} />
    </MeetingProvider>
  );
};

const ModalVideoCallContent = ({ isOpen, toggle, title }) => {
  const { 
    agentsInMeeting,
    modalRefs,
    activeSpeakerId,
    isSharedScreenActive,
    activeSharedView,
    sharedContent,
    setIsSharedScreenActive,
    setActiveSharedView,
    handleCreateWebApp,
    transcript,
    nextSharedScreenResource
  } = useMeeting();

  return (
    <StyledModal $isSharedScreenActive={isSharedScreenActive} isOpen={isOpen} toggle={toggle}>
      <StyledModalHeader toggle={toggle}>{title}</StyledModalHeader>
      <StyledModalBody $isSharedScreenActive={isSharedScreenActive}>
        {isSharedScreenActive ? (
          <>
            <SharedScreenContainer>
              {activeSharedView === 'agents' ? (
                <SharedAgentsList />
              ) : sharedContent?.content ? (
                sharedContent.type === 'MARKDOWN' ? (
                  <Markdown
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                  >
                    {sharedContent.content}
                  </Markdown>
                ) : sharedContent.type === 'HTML' ? (
                  <iframe
                    srcDoc={sharedContent.content}
                    title="Shared Content"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allowFullScreen
                    sandbox="allow-scripts allow-same-origin allow-forms"
                    style={{ backgroundColor: 'white' }}
                  />
                ) : (
                  <div>{sharedContent.content}</div>
                )
              ) : (
                <p>No content is being shared</p>
              )}
            </SharedScreenContainer>
            <AvatarColumn>
              {agentsInMeeting.map((agent) => (
                <AvatarAgent2D
                  key={`avatar-${agent.id}`}
                  agent={agent}
                  ref={modalRefs[agent.id]}
                  dimension={isSharedScreenActive ? 150 : 300}
                  isColumnView={isSharedScreenActive}
                  isActive={agent.id === activeSpeakerId}
                  initiallyEnabled={agent.id === activeSpeakerId}
                />
              ))}
            </AvatarColumn>
          </>
        ) : (
          <AgentContainer>
            {agentsInMeeting.map((agent) => (
              <AvatarAgent2D
                key={`avatar-${agent.id}`}
                agent={agent}
                ref={modalRefs[agent.id]}
                dimension={300}
                isActive={agent.id === activeSpeakerId}
                initiallyEnabled={agent.id === agentsInMeeting[0]?.id}
              />
            ))}
          </AgentContainer>
        )}
      </StyledModalBody>
      <StyledModalFooter>
        {getClientSetting('MODAL_CONFERENCE') === 'VAD_OPENAI_STREAM' &&
          activeSpeakerId && <VadOpenaiStream 
            isOpen={isOpen}
          />
        }
        <div className='d-flex w-100 justify-content-center align-items-center text-center'>{transcript}</div>
        <button onClick={() => setIsSharedScreenActive(!isSharedScreenActive)}>
          {isSharedScreenActive ? 'Hide Shared Screen' : 'Show Shared Screen'}
        </button>
        {isSharedScreenActive && (
          <button onClick={() => setActiveSharedView(activeSharedView === 'content' ? 'agents' : 'content')}>
            {activeSharedView === 'content' ? 'Show Agents' : 'Show Content'}
          </button>
        )}
        <button onClick={() => handleCreateWebApp('Create a slideshow for content')}>Create</button>
        <button onClick={nextSharedScreenResource}>Next</button>
      </StyledModalFooter>
    </StyledModal>
  );
};

export default ModalVideoCall;