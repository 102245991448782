import styled from 'styled-components';
import { AccordionItem, AccordionHeader } from 'reactstrap';

export const StyledAccordionHeader = styled(AccordionHeader)`
  & .accordion-button {
    font-weight: bold;
    background-color: #cfe2ff;
  }
`;

export const StyledAccordionItem = styled(AccordionItem)`
  background-color: #f8f9fa;
`;
