import React, { useState, useRef, useCallback, useMemo } from 'react';
import { Button, Spinner } from 'reactstrap';
import useStore from '../../../store';
import { useMicVAD } from '@ricky0123/vad-react';
import useDeepgram from '../../../hooks/useDeepgram';
import Player from '../Players/Player';
import { streamElevenLabsAudio } from '../Players/PlayerHelpers';

const VadDeepgramElevenLabsMic = ({
  agent,
  isOpen,
  playElevenlabsAlignments,
  setTranscript,
  stopTalking,
}) => {
  const { topic, language } = useStore((state) => ({
    topic: state.topic,
    language: state.language,
  }));
  const [micEnabled, setMicEnabled] = useState(true);

  const player = useRef(new Player(topic.id));
  const vadIsTalking = useRef(false);
  const vadSession = useRef({ id: 0, fetching: false, text: '', phrase: ''});

  // Purpose of VAD is to more properly finalize speech, and also interrupt the agent
  const vad = useMicVAD({
    startOnLoad: true,
    positiveSpeechThreshold: 0.3,
    negativeSpeechThreshold: 0.15,
    preSpeechPadFrames: 20,
    onSpeechStart: async () => {
      const id = Math.random().toString(36).substring(2, 15);
      vadSession.current = {id, fetching: false, text: '', phrase: ''}; // Sessions ensure that partial phrases belong to current session
      console.log(`VAD [${vadSession.current.id}]: User started talking.`);
      player.current.stop();
      stopTalking();
      player.current.newSession(id);
      vadIsTalking.current = true;
    },
    onSpeechEnd: async () => {
      if (!micEnabled || vad.loading)  return;
      vadIsTalking.current = false;
      setTimeout(() => {
        if (dgIsTalking.current) {
          finalize();
        }
      }, 1000);
    },
    onVADMisfire: () => {
      vadIsTalking.current = false;
    },
  });

  const PHASE_COMPLETING = 2;
  const isLoading = useMemo(() => player.current.phase === PHASE_COMPLETING && !player.current.isPlaying, []);

  const processTranscript = useCallback((composite, is_final) => {
    if(!player.current) return;
    const countWords = composite.split(' ').length;
    if (countWords > 1) player.current.onWord(composite); // at least 2 words
  }, []);

  const { finalize, dgIsTalking } = useDeepgram({
    micEnabled,
    language,
    isUserSpeaking: () => vadIsTalking.current,
    isAgentSpeaking: () => player.current.isPlaying,
    vadLoading: vad.loading,
    setTranscript,
    processTranscript,
    onFinal: (composite) => {
      console.log('Yup we got final', composite);
      player.current.startCompletion(composite)
    },
  });

  const prePhrase = useCallback((text) => {
    if(!player.current) return;
    player.current.onWord(text);
  }, []);
  const endPhrase = useCallback((text) => {
    if(!player.current) return;
    player.current.startCompletion(text);
  }, []);

  const toggleMic = () => {
    setMicEnabled(!micEnabled);
  };
  const stop = () => {
    if(player.current) player.current.stop();
  };

  return (
    <>
      <Button onClick={() => {
        prePhrase('Hej. Hur mår du idag?');
      }}>
        prePhrase
      </Button>
      <Button onClick={() => {
        endPhrase('Vad ska du göra idag?');
      }}>
        endPhrase
      </Button>

      <Button onClick={() => {
         const voiceId = '21m00Tcm4TlvDq8ikWAM';
         const apiKey = 'sk_8bdc2915bb59a3f77f29443f6a5e7cd61db2f479cdd75e0b';
         const id = '1';
         const text = 'Hej. Hur mår du idag?';
        streamElevenLabsAudio(apiKey, voiceId, id, text)
          .then(({ id, text, audioUrl, alignment }) => {
            // Use the audioUrl to play the audio
            const audio = new Audio(audioUrl);
            audio.play();
            playElevenlabsAlignments(agent.id, alignment);
            // Use the alignment data as needed
            console.log(alignment);
          })
          .catch((error) => {
            console.error(error);
          });
      }}>
        fetchElevenLabsAudio
      </Button>
      <Button onClick={() => stop()}>Stop</Button>
      <div className="d-flex justify-content-center align-items-center w-100">
        {isLoading && (
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        )}
        {player.current.isPlaying && <Spinner color="primary" type="grow"></Spinner>}
        {!player.current.isPlaying && !isLoading && !vad.loading && (
          <Button color="danger" onClick={toggleMic}>
            {micEnabled ? (
              <span className="bi bi-mic"></span>
            ) : (
              <span className="bi bi-mic-mute"></span>
            )}
          </Button>
        )}
      </div>
      <p>VAD: {vadIsTalking.current ? 'Yes' : 'No'}</p>
      <p>DG: {dgIsTalking.current ? 'Yes' : 'No'}</p>
      <p>isPlaying: {player.current.isPlaying ? 'Yes' : 'No'}</p>
    </>
  );
};

export default VadDeepgramElevenLabsMic;
