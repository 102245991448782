import React, { useState, useMemo, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, FormFeedback, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import apiService from '../../utils/apiService';
import useStore from '../../store';



function EditProfileModal({ isOpen, toggle }) {
  const { t } = useTranslation();
  const { userId, username, email, language, setLanguage, backgroundColor, setBackgroundColor, icon, setIcon } = useStore((state) => ({
    userId: state.userId,
    username: state.username,
    email: state.email,
    language: state.language,
    setLanguage: state.setLanguage,
    backgroundColor: state.backgroundColor,
    setBackgroundColor: state.setBackgroundColor,
    icon: state.icon,
    setIcon: state.setIcon,
  }));
  const [userData, setUserData] = useState({
    username: '',
    email: '',
    language: language,
    backgroundColor: backgroundColor,
    icon: icon,
  });
  const [usernameError, setUsernameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [saving, setSaving] = useState(false);

  const languageOptions = useMemo(() => window.languageChoices || [], []);

  const iconOptions = useMemo(() => [
    { value: 'bi-person', label: t('Person') },
    { value: 'bi-rocket', label: t('Rocket') },
    { value: 'bi-star', label: t('Star') },
    { value: 'bi-heart', label: t('Heart') },
    { value: 'bi-lightning', label: t('Lightning') },
    { value: 'bi-gem', label: t('Gem') },
    { value: 'bi-trophy', label: t('Trophy') },
    { value: 'bi-music-note', label: t('Music Note') },
    { value: 'bi-globe', label: t('Globe') },
    { value: 'bi-emoji-smile', label: t('Smiley') },
    { value: 'bi-fire', label: t('Fire') },
    { value: 'bi-award', label: t('Award') },
    { value: 'bi-chat', label: t('Chat') },
    { value: 'bi-bell', label: t('Bell') },
    { value: 'bi-emoji-sunglasses', label: t('Cool Face') },
    { value: 'bi-shield', label: t('Shield') },
    { value: 'bi-key', label: t('Key') },
    { value: 'bi-puzzle', label: t('Puzzle') },
    { value: 'bi-flag', label: t('Flag') },
    { value: 'bi-pie-chart', label: t('Pie Chart') },
    { value: 'bi-brush', label: t('Brush') },
    { value: 'bi-compass', label: t('Compass') },
    { value: 'bi-gear', label: t('Gear') }
  ], [t]);

  
  useEffect(() => {
    setUserData({
      username: username,
      email: email,
      language: language,
      backgroundColor: backgroundColor,
      icon: icon,
    });
  }, [userId, username, email, language, backgroundColor, icon]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const validateForm = () => {
    if (!userData.username.trim()) {
      setUsernameError(t('Username is required'));
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(userData.email)) {
      setEmailError(t('Invalid email address'));
      return false;
    }
    setUsernameError('');
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return; // Stop submission if validation fails
    setSaving(true);
    setLanguage(userData.language);
    setBackgroundColor(userData.backgroundColor);
    setIcon(userData.icon);
    try {
      const response = await apiService.post('/api/save-settings/', userData);
      if (response.success) {
        console.log(t('User saved successfully:'), response.data);
        toggle();
      } else {
        console.error(t('Operation failed:'), response.message);
      }
    } catch (error) {
      console.error(t('Error during operation:'), error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{t('Settings')}</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="username" className='fw-bold'>{t('Username')}</Label>
            <Input
              type="text"
              name="username"
              id="username"
              placeholder={t('Username')}
              value={userData.username}
              onChange={handleChange}
              invalid={!!usernameError}
            />
            <FormFeedback>
              {usernameError}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="email" className='fw-bold'>{t('E-mail')}</Label>
            <Input
              type="email"
              name="email"
              id="email"
              placeholder={t('user@domain.com')}
              value={userData.email}
              onChange={handleChange}
              invalid={!!emailError}
            />
            <FormFeedback>
              {emailError}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="language" className='fw-bold'>{t('Language')}</Label>
            <Input type="select" name="language" id="language" value={userData.language} onChange={handleChange}>
              {languageOptions.map((option) => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </Input>
          </FormGroup>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="icon" className='fw-bold'>{t('Icon')}</Label>
                <Input
                  type="select"
                  name="icon"
                  id="icon"
                  value={userData.icon}
                  onChange={handleChange}
                >
                  {iconOptions.map((icon) => (
                    <option key={icon.value} value={icon.value}>
                      {icon.label}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="backgroundColor" className='fw-bold'>{t('Background Color')}</Label>
                <Input
                  type="color"
                  name="backgroundColor"
                  id="backgroundColor"
                  value={userData.backgroundColor}
                  onChange={handleChange}
                  style={{ width: '100%', height: '38px' }}
                />
              </FormGroup>
            </Col>
            <Col md={6} className="d-flex align-items-center justify-content-center">
              <div 
                className="rounded-circle d-flex align-items-center justify-content-center text-white" 
                style={{ 
                  width: '80px', 
                  height: '80px', 
                  backgroundColor: userData.backgroundColor || '#ccc'
                }}
              >
                <i className={`bi ${userData.icon || 'bi-person'} fs-1`}></i>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter className='d-flex justify-content-end'>
        <Button color="primary" disabled={saving} onClick={handleSubmit}>
          {saving && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>} {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default EditProfileModal;
