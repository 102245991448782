import React, { useEffect, useRef } from 'react'; // , { useEffect }
import { BrowserRouter, useLocation } from 'react-router-dom';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from './components/Sidebar/Sidebar';
import TopNavbar from './components/TopNavbar/TopNavbar';
import MainContent from './components/MainContent/MainContent';
import RealTimeData from './components/RealTimeData/RealTimeData';
import UpdateLocationOnNavigation from './components/UpdateLocationOnNavigation/UpdateLocationOnNavigation';
import { FilePickerProvider } from './contexts/FilePickerContext';
import { WSManagerProvider } from './contexts/WSManagerContext';
import { ModalConferenceProvider } from './contexts/ModalConferenceContext';
import { ModalVideoCallProvider } from './contexts/ModalVideoCallContext';
import { ModalTakeNotesProvider } from './contexts/ModalTakeNotesContext';
import { ModalSelectRolesProvider } from './contexts/ModalSelectRoles';
import { ModalToolProvider } from './contexts/ModalToolContext';
import TrialBanner from './components/TrialBanner/TrialBanner';

// import useStore from './store';
import { Container, Row, Col } from 'reactstrap';

function RouteLogger() {
  const location = useLocation();
  useEffect(() => {
    // console.log('Current Path:', location.pathname);
  }, [location]);

  return null; // This component does not render anything
}
function App() {

  // Scroll to bottom on new message
  const mainContentRef = useRef(null);
  const scrollToBottom = () => {
    if (mainContentRef.current) {
      setTimeout(() => {
        mainContentRef.current.scrollTo({
          top: mainContentRef.current.scrollHeight,
          behavior: 'smooth'
        });
      }, 100);
    }
  };

  return (
    <>
      <WSManagerProvider>
        <ModalConferenceProvider>
          <ModalVideoCallProvider>
            <ModalTakeNotesProvider>
              <ModalSelectRolesProvider>
                <ModalToolProvider>
                    <RealTimeData />
                    <BrowserRouter basename="/">
                  <RouteLogger />
                  <UpdateLocationOnNavigation />
                  <TrialBanner />
                  <TopNavbar />
                  <Container fluid>
                    <Row className="vh-100 h-100">
                      <Col md="3" className="d-none d-md-block sidebar bg-dark">
                        <Sidebar />
                      </Col>
                      <Col xs="12" md="9" className="main-content">
                        <div ref={mainContentRef} className="main-content-scrollable">
                          <FilePickerProvider>
                            <MainContent className="p-0 m-0" scrollToBottom={scrollToBottom}/>
                          </FilePickerProvider>
                        </div>
                      </Col>
                    </Row>
                    </Container>
                  </BrowserRouter>
                </ModalToolProvider>
              </ModalSelectRolesProvider>
            </ModalTakeNotesProvider>
          </ModalVideoCallProvider>
        </ModalConferenceProvider>
      </WSManagerProvider>
    </>
  );
}


export default App;
