import React, { useMemo } from 'react';
import { Button  } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useStore from '../../store';

function HomePage() {
  const { t } = useTranslation();
  const { loggedIn } = useStore((state) => ({loggedIn: state.loggedIn}));

  const welcomeImage = useMemo(() => window.welcome_image, []);
  const welcomeHeader = useMemo(() => window.welcome_header, []);
  const welcomeText = useMemo(() => window.welcome_text, []);

  return (
    <header className="App-header">
      <img src={welcomeImage} className="d-block w-100 mb-4" alt="Welcome" />
      <h2>{welcomeHeader}</h2>
      <p>{welcomeText}</p>
      {!loggedIn && (<Button color="primary" tag={Link} to="/login">{t('Login')}</Button>)}
    </header>
  );
}

export default HomePage;
