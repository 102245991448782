import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Container } from './Dropzone.styles';
import { useTranslation } from 'react-i18next';
import { Button, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import ModalBasic from '../../components/ModalBasic/ModalBasic';
import apiService from '../../utils/apiService';

function Dropzone({ onDrop, metadata = {} }) {
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({ current: 0, total: 0 });

  // Modal states
  const [linkModal, setLinkModal] = useState(false);
  const [textModal, setTextModal] = useState(false);
  const [linkValue, setLinkValue] = useState('');
  const [textValue, setTextValue] = useState('');

  // Error states
  const [linkError, setLinkError] = useState('');
  const [textError, setTextError] = useState('');

  // Loading states
  const [isSubmittingLink, setIsSubmittingLink] = useState(false);
  const [isSubmittingText, setIsSubmittingText] = useState(false);

  const toggleLinkModal = () => {
    setLinkModal(!linkModal);
    setLinkError('');
    setLinkValue('');
  };

  const toggleTextModal = () => {
    setTextModal(!textModal);
    setTextError('');
    setTextValue('');
  };

  // Validation functions
  const validateLinkForm = () => {
    if (!linkValue.trim()) {
      setLinkError(t('Link is required'));
      return false;
    }
    try {
      new URL(linkValue);
      setLinkError('');
      return true;
    } catch (err) {
      setLinkError(t('Please enter a valid URL'));
      return false;
    }
  };

  const validateTextForm = () => {
    if (!textValue.trim()) {
      setTextError(t('Text is required'));
      return false;
    }
    setTextError('');
    return true;
  };

  const handleDrop = useCallback(async (acceptedFiles) => {
    setIsUploading(true);
    setUploadProgress({ current: 0, total: acceptedFiles.length });

    for (let i = 0; i < acceptedFiles.length; i++) {
      await onDrop([acceptedFiles[i]]);
      setUploadProgress(prev => ({ ...prev, current: i + 1 }));
    }

    setIsUploading(false);
    setUploadProgress({ current: 0, total: 0 });
  }, [onDrop]);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({ 
    onDrop: handleDrop,
    disabled: isUploading,
    multiple: true
  });

  const containerStyle = {
    borderColor: isUploading ? 'orange' : 
                 isFocused ? 'blue' : 
                 isDragAccept ? 'green' : 
                 isDragReject ? 'red' : 'gray',
    opacity: isUploading ? 0.6 : 1,
    cursor: isUploading ? 'not-allowed' : 'pointer'
  };

  const handleLinkSubmit = async () => {
    if (!validateLinkForm()) return;
    setIsSubmittingLink(true);
    try {
      const response = await apiService.post('/api/resources/upload-link/', { 
        link: linkValue,
        metadata: metadata
      });
      if (response.success) {
        console.log('Link uploaded successfully:', response.data);
        toggleLinkModal();
      } else {
        setLinkError(response.message || t('Failed to upload link'));
      }
    } catch (error) {
      console.error('Error uploading link:', error);
      setLinkError(t('An error occurred while uploading the link'));
    } finally {
      setIsSubmittingLink(false);
    } 
  };

  const handleTextSubmit = async () => {
    if (!validateTextForm()) return;
    setIsSubmittingText(true);
    
    // Generate title from first 50 chars of text
    const autoTitle = textValue.trim().substring(0, 50) + (textValue.length > 50 ? '...' : '');
    
    try {
      const response = await apiService.post('/api/resources/upload-text/', { 
        text: textValue,
        title: autoTitle,
        metadata: metadata
      });
      
      if (response.success) {
        console.log('Text uploaded successfully:', response.data);
        toggleTextModal();
      } else {
        setTextError(response.message || t('Failed to upload text'));
      }
    } catch (error) {
      console.error('Error uploading text:', error);
      setTextError(t('An error occurred while uploading the text'));
    } finally {
      setIsSubmittingText(false);
    }
  };

  const linkModalButtons = (
    <>
      <Button color="secondary" onClick={toggleLinkModal} disabled={isSubmittingLink}>
        {t('Cancel')}
      </Button>
      <Button color="primary" onClick={handleLinkSubmit} disabled={isSubmittingLink}>
        {isSubmittingLink ? (
          <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> {t('Uploading...')}</>
        ) : (
          t('OK')
        )}
      </Button>
    </>
  );

  const textModalButtons = (
    <>
      <Button color="secondary" onClick={toggleTextModal} disabled={isSubmittingText}>
        {t('Cancel')}
      </Button>
      <Button color="primary" onClick={handleTextSubmit} disabled={isSubmittingText}>
        {isSubmittingText ? (
          <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> {t('Uploading...')}</>
        ) : (
          t('OK')
        )}
      </Button>
    </>
  );

  return (
    <div className="container p-0">
      <p className="mb-2"><small>{t('Resources let Svava base its responses on the information that matters to you. You can upload files, link to resources, or paste text.')}</small></p>
      <Container {...getRootProps()} style={containerStyle}>
        <input {...getInputProps()} />
        <div className="bi bi-upload"></div>
        {isUploading ? (
          <div>{t('Uploading file')}{uploadProgress.current}/{uploadProgress.total}</div>
        ) : (
          <div>{t('Drag & drop or choose files to upload')}</div>
        )}
      </Container>
      {/*<div className="d-flex gap-1 justify-content-end">
        <Button color="secondary" outline size="sm" onClick={toggleLinkModal}>
          <span className="bi bi-link-45deg"></span> {t('Link')}
        </Button>
        <Button color="secondary" outline size="sm" onClick={toggleTextModal}>
          <span className="bi bi-clipboard"></span> {t('Paste text')}
        </Button>
      </div>*/}

      <ModalBasic
        isOpen={linkModal}
        toggle={toggleLinkModal}
        title={t('Paste Link')}
        footerButtons={linkModalButtons}
      >
        <Form>
          <FormGroup>
            <Label for="linkInput">{t('Enter your link')}</Label>
            <Input
              type="url"
              name="link"
              id="linkInput"
              placeholder="http://example.com"
              value={linkValue}
              onChange={(e) => setLinkValue(e.target.value)}
              invalid={!!linkError}
              disabled={isSubmittingLink}
            />
            <FormFeedback>
              {linkError}
            </FormFeedback>
          </FormGroup>
        </Form>
      </ModalBasic>

      <ModalBasic
        isOpen={textModal}
        toggle={toggleTextModal}
        title={t('Paste Text')}
        footerButtons={textModalButtons}
      >
        <Form>
          <FormGroup>
            <Label for="textInput">{t('Enter your text')}</Label>
            <Input
              type="textarea"
              name="text"
              id="textInput"
              placeholder={t('Type your text here...')}
              value={textValue}
              onChange={(e) => setTextValue(e.target.value)}
              invalid={!!textError}
              disabled={isSubmittingText}
              rows="6"
            />
            <FormFeedback>
              {textError}
            </FormFeedback>
          </FormGroup>
        </Form>
      </ModalBasic>
    </div>
  );
}

export default Dropzone;