import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomButton } from './TopicButtons.styles.js';
//import { UncontrolledPopover, PopoverBody } from 'reactstrap';

function TopicButtons({ newChatTopic, viewChatTopics }) {
  const { t } = useTranslation();

  return (
    <div className='d-flex gap-1'>
      <CustomButton id="newChatTopicButton" size="sm">
        <span className="bi bi-plus" onClick={newChatTopic}></span>
      </CustomButton>
      {/*<UncontrolledPopover trigger="hover" placement="top" target="newChatTopicButton">
        <PopoverBody className='p-1'>{t('Create a new chat topic')}</PopoverBody>
      </UncontrolledPopover>*/}

      <CustomButton id="viewChatTopicsButton" size="sm">
        <span className="bi bi-arrow-counterclockwise" onClick={viewChatTopics}></span>
      </CustomButton>
      {/*<UncontrolledPopover trigger="hover" placement="top" target="viewChatTopicsButton">
        <PopoverBody className='p-1'>{t('View previous chat topics')}</PopoverBody>
      </UncontrolledPopover>*/}
    </div>
  );
}

export default TopicButtons;
