// Add connection state flag at module level
let isUpdatingVoice = false;
export const isVoiceUpdating = () => isUpdatingVoice;

export const setupTools = (client, setIsSharedScreenActive, setActiveSharedView, internetResearchFunc, handleCreateWebApp, handleUseComputer = null) => {
  // Remove existing tools first
  try {
    client.removeTool('toggle_shared_screen');
    client.removeTool('toggle_view');
    client.removeTool('internet_research');
    client.removeTool('create_web_page');
    client.removeTool('computer_use');
  } catch (error) {
    // Ignore errors if tools don't exist
    console.log('Removing tools (if they exist)');
  }

  // -----------------------------------------------------------------
  // Toggle Shared Screen
  // -----------------------------------------------------------------
  client.addTool(
    {
      name: 'toggle_shared_screen',
      description: 'Controls the visibility of the shared screen in the conference.',
      parameters: {
        type: 'object',
        properties: {
          action: { 
            type: 'string', 
            enum: ['show', 'hide'],
            description: 'Whether to show or hide the shared screen' 
          }
        },
        required: ['action']
      }
    },
    async ({ action }) => {
      console.log('Screen control tool called with:', { action });
      try {
        if (setIsSharedScreenActive) {
          const shouldShow = action === 'show';
          setIsSharedScreenActive(shouldShow);
          return { success: true, message: `Screen ${action}n successfully` };
        }
        return { success: false, message: 'Screen toggle handler not available' };
      } catch (error) {
        console.error('Screen toggle error:', error);
        throw error;
      }
    }
  );



  // -----------------------------------------------------------------
  // Toggle View (content or agents)
  // -----------------------------------------------------------------

  client.addTool(
    {
      name: 'toggle_view',
      description: 'Controls what content is displayed in the shared screen area.',
      parameters: {
        type: 'object',
        properties: {
          view: { 
            type: 'string', 
            enum: ['content', 'agents'],
            description: 'Which view to display: "content" for shared content or "agents" for the team members list' 
          }
        },
        required: ['view']
      }
    },
    async ({ view }) => {
      console.log('View control tool called with:', { view });
      try {
        if (setIsSharedScreenActive) {
          setIsSharedScreenActive(true);
        }
        if (setActiveSharedView) {
          setActiveSharedView(view); // content or agents
          return { success: true, message: `View changed to ${view} successfully` };
        }
        return { success: false, message: 'View change handler not available' };
      } catch (error) {
        console.error('View change error:', error);
        throw error;
      }
    }
  );

  // -----------------------------------------------------------------
  // Internet research tool
  // -----------------------------------------------------------------
  client.addTool(
    {
      name: 'internet_research',
      description: 'Searches the internet for information on a given topic.',
      parameters: {
        type: 'object',
        properties: {
          query: {
            type: 'string',
            description: 'The search query to look up information about'
          }
        },
        required: ['query']
      }
    },
    async ({ query }) => {
      console.log('Internet research tool called with:', { query });
      try {
        internetResearchFunc(query);
        setIsSharedScreenActive(true);
        setActiveSharedView('content');
        return {
          success: true,
          message: `Started internet search for: "${query}". It may take a few minutes to complete.`,
        };
      } catch (error) {
        console.error('Internet research error:', error);
        throw error;
      }
    }
  );

  // -----------------------------------------------------------------
  // Website Creation Tool
  // -----------------------------------------------------------------
  client.addTool(
    {
      name: 'create_web_page',
      description: 'Creates a web page based on user instructions.',
      parameters: {
        type: 'object',
        properties: {
          instruction: {
            type: 'string',
            description: 'Brief instructions for creating a web page. For example create a slideshow, create a website, create a mindmap, etc.'
          }
        },
        required: ['instruction']
      }
    },
    async ({ instruction }) => {
      console.log('Web page creation tool called with:', { instruction });
      try {
        if (handleCreateWebApp) {
          await handleCreateWebApp(instruction);
          return {
            success: true,
            message: `Creating web page based on instructions: "${instruction}"`
          };
        }
        return { success: false, message: 'Web page creation handler not available' };
      } catch (error) {
        console.error('Web page creation error:', error);
        throw error;
      }
    }
  );


  // -----------------------------------------------------------------
  // Computer use tool
  // -----------------------------------------------------------------
  client.addTool(
    {
      name: 'computer_use',
      description: 'Uses the computer to perform a task based on user instructions.',
      parameters: {
        type: 'object',
        properties: {
          instruction: {
            type: 'string',
            description: 'Brief instructions for using the computer to perform a task.'
          }
        },
        required: ['instruction']
      }
    },
    async ({ instruction }) => {
      try {
        if (handleUseComputer) {
          await handleUseComputer(instruction);
          return {
            success: true,
            message: `Using computer to perform task: "${instruction}"`
          };
        }
        return { success: false, message: 'Computer use handler not available' };
      } catch (error) {
        console.error('Computer use error:', error);
        throw error;
      }
    }
  );

};

export const getRandomSettings = () => {
  const LIST_OF_NAMES = ['John', 'Jane', 'Alice', 'Bob', 'Charlie', 'David', 'Eve', 'Mallory', 'Trent'];
  const randomName = LIST_OF_NAMES[Math.floor(Math.random() * LIST_OF_NAMES.length)];
  const LIST_OF_ROLES = ['Truck driver', 'Doctor', 'Teacher', 'Chef', 'Lawyer', 'Artist', 'Writer', 'Gardener', 'Scientist'];
  const randomRole = LIST_OF_ROLES[Math.floor(Math.random() * LIST_OF_ROLES.length)];
  const instructions = `You are sitting in a multi-agent conference call. 
  Your name is ${randomName}.
  You are a ${randomRole}.
  You can help manage the shared screen and show different views:
  - When asked about team members or the participant list, use 'toggle_view' with view='agents'
  - When asked to show shared content, use 'toggle_view' with view='content'
  - Use 'toggle_shared_screen' with action='show' or 'hide' to control screen visibility
  
  Always ensure to show the shared screen (using toggle_shared_screen) before changing views.`
  const VOICES = ['alloy', 'ash', 'ballad', 'coral', 'echo', 'sage', 'shimmer', 'verse'];
  const randomVoice = VOICES[Math.floor(Math.random() * VOICES.length)];
  const settings = { 
    instructions,
    voice: randomVoice,
    turn_detection: { type: 'server_vad' },
    input_audio_transcription: { model: 'whisper-1' }
  };
  return settings;
};

export const getAgentSettings = (agent) => {
  const instructions = `You are sitting in a conference call. The user is your boss.
  Your name is ${agent.name}.
  
  ${agent.description}.
  
  You can help manage the shared screen and show different views:
  - When asked about team members or the participant list, use 'toggle_view' with view='agents'
  - When asked to show shared content, use 'toggle_view' with view='content'
  - Use 'toggle_shared_screen' with action='show' or 'hide' to control screen visibility
  - Use 'create_web_page' to create a web page based on user instructions. Only brief instructions are required, as the server already knows the content.
  
  Always ensure to show the shared screen (using toggle_shared_screen) before changing views.`
  const settings = { 
    instructions,
    voice: agent.voice,
    turn_detection: { type: 'server_vad' },
    input_audio_transcription: { model: 'whisper-1' }
  };
  return settings;
};
