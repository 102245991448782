import React, { useState } from 'react';
import { Form} from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
// import { useTranslation } from 'react-i18next';

import apiService from '../../utils/apiService';
import AutoResizeTextareaOneLine from '../../components/AutoResizeTextareaOneLine/AutoResizeTextareaOneLine';
import useStore from '../../store';
import { InputGroupFlex, StyledInputGroup, ChatContainer } from './ChatForm.styles';

const ChatForm = ({ message, partial, updatePartialFn, setLoadingElement, selectedElementId }) => {
  /*const { t } = useTranslation();*/
  const { setSubTopic, subTopic } = useStore((state) => ({
    setSubTopic: state.setSubTopic,
    subTopic: state.subTopic,
  }));

  const [userInput, setUserInput] = useState('');
  const [isSending, setIsSending] = useState(false);

  const handleChatSubmit = async (e) => {
    e.preventDefault();
    if (!userInput) return;
    setLoadingElement(selectedElementId);
    setIsSending(true);
    const userMessageId = uuidv4();
    const aiMessageId = uuidv4();
    let endpoint = `/api/messages/${message.id}/edit-partial/`;
    let formData = { 
      user_input: userInput, 
      message: message.message, 
      partial: partial?.raw || partial || '',
      user_message_id: userMessageId, 
      ai_message_id: aiMessageId
    };

    const newSubTopic = {
      ...subTopic,
      chat_history: [...subTopic.chat_history, 
        { type: 'USER', message: userInput, id: userMessageId },
        { type: 'AI', message: '', id: aiMessageId, data: { streaming: true } } // Ready for streaming!
      ],
    }
    setSubTopic(newSubTopic);

    try {
      const response = await apiService.post(endpoint, formData);
      console.log('Response:', response);
      if (response.success) {
        console.log('Edit message sent successfully');
        setUserInput('');
        setLoadingElement(null);
        if (response.data.type === 'edit') {
          updatePartialFn(response.data.content);
        } else {
          // TODO: handle chat response
          console.log('Chat response:', response.data.content);
        }
      } else {
        console.error('Operation failed:', response.message);
      }
    } catch (error) {
      console.error('Error during operation:', error);
    } finally {
      setIsSending(false);
    }
  };

  /*const isStreaming = useMemo(() => {
    return streaming && (streaming.status === 'start' || streaming.status === 'stream');
  }, [streaming]);*/

  return (
    <ChatContainer>
      <Form onSubmit={handleChatSubmit}>
        <StyledInputGroup>
          <InputGroupFlex>
            <AutoResizeTextareaOneLine
              id="user-chat-input"
              name="user-chat-input"
              initialValue={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleChatSubmit(e);
                }
              }}
              disabled={isSending}
              autoFocus
            />
          </InputGroupFlex>
        </StyledInputGroup>
      </Form>
    </ChatContainer>
  );
};

export default ChatForm;
