import React from 'react';
import {
  Form,
} from 'reactstrap';
import apiService from '../../../utils/apiService';
import DocumentFormButton from '../../DocumentFormButton/DocumentFormButton';
import Chat from '../../Chat/Chat';
import ChatForm from '../../ChatForm/ChatForm';
import useStore from '../../../store';

function ChatBotGuided({ document:doc, scrollToBottom }) {
  const documentId=doc.id;
  const { topic } = useStore((state) => ({ topic: state.topic }));
  const handleGenerateDocumentSubmit = async (e) => {
    e.preventDefault();
    doc.is_generating = true; // Set generating state to true at the start of the operation
    const endpoint = `/api/document/${documentId}/generate/`;
    const formData ={};
    try {
      const response = await apiService.post(endpoint, formData);
      if (response.success) {
        console.log('Document generation initiated successfully');
      } else {
        console.error('Operation failed:', response.message);
        doc.is_generating = false;
      }
    } catch (error) {
      console.error('Error during operation:', error);
      doc.is_generating = false;
    }
  };

  return (
    <>
      <Chat topic={topic} scrollToBottom={scrollToBottom} />
      <ChatForm actionButtons={doc.data.actions} />
      <Form onSubmit={(e) => handleGenerateDocumentSubmit(e)}>
        <hr/>
        <DocumentFormButton document={doc} color="primary"  />
      </Form>
    </>
  );
}

export default ChatBotGuided;
