import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';

import ModalConfirm from '../../components/ModalConfirm/ModalConfirm';
import { Table, Badge } from 'reactstrap';
import apiService from '../../utils/apiService';
import useStore from '../../store';

function AgentsTable({ agents, selectedAgents=[], toggleAgentSelection=() => {} }) {
  const { t } = useTranslation();
  const { 
    remResource,
    workspace,
    project, 
  } = useStore((state) => ({ 
    remResource: state.remResource, 
    workspace: state.workspace,
    project: state.project, 
  }));
   // Functions for Basic Modal
   const [selectedAgent, setSelectedAgent] = useState(null);

  // Functions for Confirm Modal
  const [confirmModal, setConfirmModal] = useState(false);
  const toggleConfirmModal = () => {
    setConfirmModal(!confirmModal);
  };

  const handleDelete  = useCallback( async (agentId) => {
    console.log('Deleting agent with id:', agentId);
    try {
      const response = await apiService.delete(`/api/agent/${agentId}/delete/`);
      if (!response.success) {
        console.error('Operation failed:', response.message);
        return;
      }
      console.log('Resource deleted successfully');
      remResource(agentId);
    } catch (error) {
      console.error('Error during operation:', error);
    }
  }, [remResource]);

  const showDeleteConfirm = (deleteAgent) => {
    setSelectedAgent(deleteAgent);
    setConfirmModal(true);
  }

  const createTable = useCallback((title, renderAgents) => {
    return (<>
      <Table className="mt-2 table table-hover">
        <thead>
          <tr>
            <th>{title}</th>
          </tr>
        </thead>
        <tbody>
          {(!renderAgents || renderAgents.length === 0) && <tr><td colSpan="2"><small>{t('No co-workers found')}</small></td></tr>}
          {renderAgents && renderAgents.map((agent, index) => (
            <tr
              key={`agent-${index}`}
              onClick={() => {toggleAgentSelection(agent.id)}}
              style={{ cursor: 'pointer', backgroundColor: selectedAgent === agent ? '#f0f0f0' : 'transparent' }}
            >
              <td className='p-1 d-flex justify-content-between align-items-center'>
                  <input
                    type="checkbox"
                    checked={selectedAgents.includes(agent.id)}
                    onChange={(e) => {
                      e.stopPropagation();
                      toggleAgentSelection(agent.id);
                    }}
                    onClick={(e) => e.stopPropagation()}
                    readOnly
                    className='me-2'
                  />
                <small className='flex-grow-1'>
                  &nbsp;
                  {agent.name}
                </small>
                <Button color="link" size="sm" className="trash-button py-1 px-2" onClick={(e) => {e.stopPropagation();showDeleteConfirm(agent)}}>
                  <i className="bi bi-trash"></i>
                </Button>
              </td>
            </tr>
          ))}
        </tbody>  
      </Table>
    </>
  )
  }, [t, selectedAgent, selectedAgents, toggleAgentSelection]);

  const readOnlyTable = useCallback((title, renderAgents) => {
    return (<>
      <Table className="mt-2 table table-hover">
        <thead>
          <tr>
            <th>{title}</th>
          </tr>
        </thead>
        <tbody>
          {(!renderAgents || renderAgents.length === 0) && <tr><td colSpan="2"><small>{t('No co-workers found')}</small></td></tr>}
          {renderAgents && renderAgents.map((agent, index) => (
            <tr
              key={`agent-${index}`}
            >
              <td className='p-1 d-flex justify-content-between align-items-center'>
                <small className='flex-grow-1'>
                  <i className="bi bi-lock"></i>&nbsp;
                  {agent.name}
                </small>
              </td>
            </tr>
          ))}
        </tbody>  
      </Table>
    </>
  )
  }, [t]);

  const workspaceAgents = useMemo(() => {
    if(!workspace) return [];
    return agents.filter(agent => agent.origin === 'WORKSPACE');
  }, [agents, workspace]);

  const projectAgents = useMemo(() => {
    if(!project) return [];
    return agents.filter(agent => agent.origin === 'PROJECT');
  }, [agents, project]);
  const workspaceTable = useMemo(() => createTable(<span>{workspace?.name} <Badge color="secondary">{t('Workspace')}</Badge></span>, workspaceAgents), [createTable, t, workspace?.name, workspaceAgents]);
  const projectTable = useMemo(() => {
    if(!project) return null;
    return readOnlyTable(
      <span>{project.name} <Badge color="secondary">{project.super_type === "WORKFLOW" ? t('Workflow') : t('Project')}</Badge></span>,
      projectAgents,
    );
  }, [readOnlyTable, t, project, projectAgents]);

  if (!workspace) return null;

  return (
    <>  
      {workspaceTable}
      {projectTable}
      <ModalConfirm isOpen={confirmModal} toggle={toggleConfirmModal} title={t('Delete co-worker')} onConfirm={() => handleDelete(selectedAgent.id)} confirmButtonColor='danger'>
        <strong>{t('Are you sure you want to delete this co-worker?')}</strong>
        <br/>
        <br/>
        {selectedAgent && selectedAgent.title}
      </ModalConfirm>
    </>
  );
}

export default AgentsTable;
