import React, { useState, useEffect } from 'react';
import { NavItem } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import useStore from '../../store';
import { StyledNavLinkDocument, SpinnerGrowMini } from './DocumentLink.styles.js';

const DocumentLink = ({ project, document, doneStrikethrough = false }) => {
  const { agents } = useStore((state) => ({ agents: state.agents }));
  const [ agentSpinners, setAgentSpinners] = useState([]);

  const location = useLocation();
  const isActive = location.pathname === `/project/${project.id}/document/${document.id}/` ? 'true' : 'false';

  let iconElement;
  if (document.is_generating || document.is_workflowing) {
    iconElement = <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>;
  } else if (document.template_type === 'FINAL_RESULT' && document.is_done) {
    iconElement = <span className="bi bi-trophy-fill"></span>;
  } else if (document.is_done) {
    iconElement = <span className="bi bi-check-lg"></span>;
  } else if(project.super_type === "WORKFLOW" && document.task_status === 'WAITING_FOR_APPROVAL') {
    iconElement = <span className="bi bi-exclamation-triangle-fill text-warning"></span>;
  } else {
    // iconElement = <span className="bi bi-check-lg" style={{ color: 'transparent' }}></span>;
    iconElement = <span className="bi bi-dot"></span>;
  }

  useEffect(() => {
    const agentsOnDocument = agents && agents.filter((agent) => agent.document_id && agent.document_id === document.id);
    // Generate agent spinners
    const spinners = agentsOnDocument && agentsOnDocument.map((agent) =>
      <SpinnerGrowMini key={agent.id} className="spinner-grow" role="status" aria-hidden="true"></SpinnerGrowMini>
    );
    setAgentSpinners(spinners);
  }, [agents, document.id, document.title]);

  // useMemo to find first document in project.stages which is not done
  /*
  const notDoneDocument = useMemo(() => {
    for (const stage of project.stages) {
      const notDoneDoc = stage.documents.find(d => !d.is_done);
      if (notDoneDoc) return notDoneDoc;
    }
    return null;
  }, [project]);
  // {notDoneDocument && notDoneDocument.id === document.id && <span className="bi bi-exclamation-triangle-fill text-warning"></span>}
  */
 
  return (
    <NavItem>
      <StyledNavLinkDocument to={`/project/${project.id}/document/${document.id}/`} isactive={isActive}>
        {iconElement} {doneStrikethrough ? <s>{document.title}</s> : document.title} {agentSpinners}
      </StyledNavLinkDocument>
    </NavItem>
  );
};

export default DocumentLink;
