import React, { createContext, useContext, useEffect, useCallback, useRef } from 'react';
import useStore from  '../store'; // Path to your Zustand store

const WSManagerContext = createContext();

export const WSManagerProvider = ({ children }) => {
  const wsRef = useRef(null);
  const subscriptionsRef = useRef([]);
  const heartbeatIntervalRef = useRef(null);
  const { loggedIn } = useStore((state) => ({ loggedIn: state.loggedIn }));

  const sendHeartbeat = useCallback(() => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      wsRef.current.send(JSON.stringify({ type: 'ping' }));
    }
  }, []);

  const connectWebSocket = useCallback(() => {
    if (!loggedIn) {
      console.log('User is not logged in, skipping WebSocket connection.');
      return;
    }
    if (wsRef.current) {
      if (wsRef.current.readyState === WebSocket.OPEN) {
        console.log('WebSocket is already open, skipping connection.');
        return;
      } else if (wsRef.current.readyState === WebSocket.CONNECTING) {
        // console.log('WebSocket is currently connecting, skipping reconnection.');
        return;
      }
      console.log('WebSocket connection already exists, closing it.');
      wsRef.current.close(); // Close the existing connection if it exists
    }
    const url =  window.websocket_url;
    wsRef.current = new WebSocket(url);

    wsRef.current.onopen = () => {
      console.log('WebSocket connection opened');
      const channels = Array.from(new Set(subscriptionsRef.current.map(sub => sub.channelName)));
      wsRef.current.send(JSON.stringify({ type: 'subscribe', channels }));
      heartbeatIntervalRef.current = setInterval(sendHeartbeat, 30000); // Send heartbeat every 30 seconds
    };

    wsRef.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('Received WebSocket message', data);
      const channelName = data.channel_name;
      //let subscribers = 0;
      subscriptionsRef.current.forEach(sub => {
        if (sub.channelName === channelName) {
          sub.handleMessage(event);
         //subscribers++;
        }
      });
      // console.log(`Received WebSocket message for ${channelName} with ${subscribers} subscribers`);
    };

    wsRef.current.onerror = (error) => {
      console.error('WebSocket error', error);
    };

    wsRef.current.onclose = (event) => {
      console.log(`WebSocket connection closed with code: ${event.code}, reason: ${event.reason}`);
      clearInterval(heartbeatIntervalRef.current); // Clear the heartbeat interval
      if (event.code !== 1000) { // 1000 means normal closure
        setTimeout(connectWebSocket, 5000); // Retry connection after 5 seconds
      }
    };
  }, [loggedIn, sendHeartbeat]);

  const manageSubscriptions = useCallback((subscriptionChanges) => {
    // console.log('Managing subscriptions:', subscriptionChanges);
    const updatedSubscriptions = subscriptionChanges.reduce((acc, change) => {
      const index = acc.findIndex(sub => sub.channelName === change.channelName && sub.componentId === change.componentId);

      if (change.handleMessage) {
        // Add or update subscription
        if (index === -1) {
          acc.push(change);
        } else {
          acc[index] = change;
        }
      } else if (index !== -1) {
        // Remove subscription
        acc.splice(index, 1);
      }

      return acc;
    }, [...subscriptionsRef.current]);

    subscriptionsRef.current = updatedSubscriptions;

    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      const channels = updatedSubscriptions.map(sub => sub.channelName);
      wsRef.current.send(JSON.stringify({ type: 'subscribe', channels }));
    } else {
      connectWebSocket();
    }
  }, [connectWebSocket]);

  useEffect(() => {
    // console.log('WSManagerContext useEffect connectWebSocket');
    connectWebSocket(); // Initialize the WebSocket when the component mounts

    const handleOnline = () => {
      console.log('Network is online, reconnecting WebSocket...');
      connectWebSocket();
    };

    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('online', handleOnline);
      if (wsRef.current) {
        wsRef.current.close(); // Ensure the WebSocket is closed on cleanup
      }
      clearInterval(heartbeatIntervalRef.current); // Clear the heartbeat interval on cleanup
    };
  }, [connectWebSocket]);

  return (
    <WSManagerContext.Provider value={{ manageSubscriptions }}>
      {children}
    </WSManagerContext.Provider>
  );
};

export const useWSManager = () => useContext(WSManagerContext);
