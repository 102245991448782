import React from 'react';
import useStore from '../../store';
import { Container } from './TrialBanner.styles';
import { useTranslation } from 'react-i18next';
import { getFeatureFlag } from '../../utils/featureFlags';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom'; // Import useHistory

function TrialBanner() {
  const { t } = useTranslation();
  const daysLeftOnTrial = useStore((state) => state.daysLeftOnTrial);
  const subscribed = useStore((state) => state.subscribed);
  const loggedIn = useStore((state) => state.loggedIn);
  const workspace = useStore((state) => state.workspace);
  const navigate = useNavigate();

  // If the trial feature is not enabled, or the user is subscribed, or the user is on the free trial, do not show the banner
  if (!getFeatureFlag('TRIAL') || !getFeatureFlag('SUBSCRIPTIONS') || subscribed || !loggedIn) {
    return null;
  }

  const handleSubscribeClick = () => {
    navigate(`/${workspace.id}/billing/`);
  };

  return (
    <Container className={daysLeftOnTrial > 0 ? 'bg-info' : 'bg-danger'}>
      {daysLeftOnTrial > 0 && <span>⏰ {t('You have {{days}} days left on your trial.', { days: daysLeftOnTrial })}</span>}
      {daysLeftOnTrial === 0 && <span>🔥 {t('Your trial has expired. Please subscribe.')}</span>}
      &nbsp;
      <Button size='sm' outline color='light' className='py-0 text-small' onClick={handleSubscribeClick}>
        {t('Subscribe')}
      </Button>
    </Container>
  );
}

export default TrialBanner;
