import React, { useEffect, useState, useMemo } from 'react';
import { Row, Col, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import useStore from '../../store';
import WorkflowsWidget from '../../components/WorkflowsWidget/WorkflowsWidget';
import { useModalCreateWorkflow } from '../../contexts/ModalCreateWorkflowContext';
import { StyledOr } from './SelectWorkflowPage.styles';

function SelectWorkflowPage() {
  const { t } = useTranslation();
  const { workspace } = useStore((state) => ({  
    workspace: state.workspace,
  }));
  const navigate = useNavigate();
  const { showModal } = useModalCreateWorkflow();

  const baseFolder = 'workflow';

  // Update the showCreateWorkflowModal function
  const showCreateWorkflowModal = (name, isCustom = false, type = 'CUSTOM_WORKFLOW_MANUAL', generation_template_id=null) => {
    showModal({
      workflow_template_name: name,
      isCustom,
      type,
      generation_template_id
    });
  };

  // Fetch the workflow categories
  const fetcher = (...args) => fetch(...args).then(res => res.json()); // Define the fetcher function
  const { data: response } = useSWR(
    '/api/workflows/categories/',
    fetcher
  );
  const workflowCategories = useMemo(() => {
    const categories = response?.categories || [];
    return categories;
  }, [response]);

  // Change from object to single string to track active category
  const [activeCategory, setActiveCategory] = useState(null);

  // Update active category when categories are loaded
  useEffect(() => {
    if (workflowCategories && workflowCategories.length > 0) {
      setActiveCategory(workflowCategories[0].id);
    }
  }, [workflowCategories]);

  const selectedCategory = useMemo(() => {
    return workflowCategories.find(cat => cat.id === activeCategory);
  }, [workflowCategories, activeCategory]);

  // Add state for dropdown toggle
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  return (
    <>
      <div className='mb-4'>
        <h2 className='mb-4'>{t('Create a workflow')}</h2>
        <Row>
          <Col>
            <h4 className='mb-4'>1. {t('Use a workflow template')}</h4>
            <div className="mb-3">
              <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle caret color="primary" outline size="sm">
                  {activeCategory ? t(workflowCategories.find(cat => cat.id === activeCategory)?.title) : t('Select category')}
                </DropdownToggle>
                <DropdownMenu>
                  {workflowCategories && workflowCategories.map(category => (
                    <DropdownItem 
                      key={category.id}
                      active={activeCategory === category.id}
                      onClick={() => setActiveCategory(category.id)}
                    >
                      {t(category.title)}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
            {selectedCategory && (
              <Col>
                {selectedCategory.custom ? (
                  <div className="mb-4">
                    <p><strong>{t(selectedCategory.title)}</strong></p>
                    <Button 
                      outline
                      size="sm"
                      onClick={() => showCreateWorkflowModal('', true, 'CUSTOM_WORKFLOW_MANUAL')}
                    >
                      {t('Create a custom workflow')}
                    </Button>
                  </div>
                ) : (
                  <WorkflowsWidget 
                    displayTitle={false}
                    workflows={selectedCategory.workflows} 
                    showCreateWorkflowModal={showCreateWorkflowModal} 
                    className="mb-4" 
                  />
                )}
              </Col>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <StyledOr>{t('or')}</StyledOr>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4 className='mb-4'>2. {t('Create a custom workflow')}</h4>
            <Button 
              color="primary"
              size="sm"
              onClick={() => showCreateWorkflowModal('', true, 'CUSTOM_WORKFLOW_MANUAL')}
            >
              {t('Create custom')}
            </Button>
          </Col>
        </Row>
      </div>
      <div>
        <div className='d-flex gap-1'>
          <Button color="secondary" onClick={() => navigate(`/${workspace?.id}/${baseFolder}s`)}><i className="bi bi-arrow-left-circle"></i> {t('Back')}</Button>
        </div>
      </div>
    </>
  );
}
            

export default SelectWorkflowPage;
