import styled from 'styled-components';

export const StyledTextArea = styled.textarea`
  width: 100%;
  min-height: 20px;
  max-height: 50vh;
  overflow: auto;
  border: 0;
  outline: none;  // Removes the focus outline/border
  background-color: transparent;
`;
