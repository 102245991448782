import styled from 'styled-components';
import { Button } from 'reactstrap';

export const StyledButton = styled(Button)`
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  height: 40px; // Set a fixed height
  align-self: flex-end; // Align to bottom
`;
export const InputGroupFlex = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-end; // Ensure items are aligned to the bottom
`;

export const StyledActionButton = styled(Button)`
  border-radius: 1em;
  padding: 0.2em 0.75em;
  background-color: #ccc;
`;

export const ButtonFlexContainer = styled.div`
  display: flex;
  gap: 8px; /* Adjust this value for the default gap between buttons */

  @media (min-width: 768px) {
    gap: 16px; /* Larger gap on wider screens */
  }
`;

export const ChatContainer = styled.div`
  bottom: 0;
  position: sticky;
`;
