import React, { useMemo } from 'react';
// import { useTranslation } from 'react-i18next';
// import apiService from '../../../utils/apiService';
// import DocumentFormButton from '../../DocumentFormButton/DocumentFormButton';
import WorkflowAgent from '../../WorkflowAgent/WorkflowAgent';
import useStore from '../../../store';
import Chat from '../../Chat/Chat';
import ChatForm from '../../ChatForm/ChatForm';
import WorkflowTaskStatus from '../../WorkflowTaskStatus/WorkflowTaskStatus';


function Upload({ document:doc, handleNext, scrollToBottom }) {
  // const { t } = useTranslation();
  //const documentId=doc.id;
  // const [resources, setResources] = useState(doc.resources || []);

  const { project, agents, workspace } = useStore();

  /*useEffect(() => {
    setResources(doc.resources || []);
  }, [doc.resources]);*/

  // const resourceTypes = useMemo(() => window.resourceTypes || [], []);

  const taskAgent = useMemo(() => {
    return doc.current_task && doc.current_task.agent_id && agents && agents.find(agent => agent.id === doc.current_task.agent_id)
  }, [doc.current_task, agents]);

  const taskAgentGoal = useMemo(() => {
    return doc.current_task && doc.current_task.agent_id && doc.current_task.agent_goal;
  }, [doc.current_task]);


  /*
  const onDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const formData = new FormData();
    formData.append('file', file);
    formData.append('title', file.name);
    const metadata = {
      project_id: project.id,
      document_id: documentId,
    };
    formData.append('metadata', JSON.stringify(metadata));
    console.log('Uploading file title:', file.name);
    console.log('Uploading file metadata:', metadata);

    try {
      const response = await apiService.post('/api/resource/upload/qdrant/', formData);
      if (response.success) {
        console.log('File uploaded successfully', response.data);
      } else {
        console.error('Upload failed:', response.message);
      }
    } catch (error) {
      console.error('Error during file upload:', error);
    }
  };*/


  return (
    <>
      <Chat scrollToBottom={scrollToBottom} />

      {taskAgent && project &&  (<WorkflowAgent agent={taskAgent} workspace={workspace} project={project} document={doc} goal={taskAgentGoal} />)}

      {!doc.is_done && <ChatForm actionButtons={doc && doc.data.actions} />}
      {doc.is_done && <WorkflowTaskStatus project={project} document={doc} showDropDown={false} />}
      {/*<Dropzone onDrop={onDrop} />*/}
      {/*doc.resources && doc.resources.length > 0 && (
        <table className="mt-2 table table-striped table-bordered">
          <thead>
            <tr>
              <th>{t('Resource')}</th>
            </tr>
          </thead>
          <tbody>
            {resources && resources.map((resource, index) => (
              <tr key={`resource-${index}`}>
                <td>
                  <div className='mb-2'>
                    <strong>
                    {['PENDING', 'UPLOADING', 'SUMMARIZING', 'ANALYZING'].includes(resource.status) && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                    {resource.status === 'COMPLETE' && <i className="bi bi-file-text"></i>}
                    {['FAILED', 'IRRELEVANT'].includes(resource.status) && <i className="bi bi-exclamation-triangle-fill"></i>}
                    &nbsp;
                    {resource.title} {getStatusLabel(resource.status) !== '' ? `(${getStatusLabel(resource.status)})` : ''}
                    </strong>
                  </div>
                  <small>{resource.description}</small>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )
      <Button color="success" size="sm" onClick={handleNext} className='mt-4'>
        {t('START WORKING')}
      </Button>*/}
    </>
  );
}

export default Upload;
