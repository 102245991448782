import React, { useMemo, useEffect, useCallback, useState, useRef } from 'react';
import WorkflowAgent from '../../WorkflowAgent/WorkflowAgent';
import useStore from '../../../store';
import Chat from '../../Chat/Chat';
import ChatForm from '../../ChatForm/ChatForm';
import WorkflowTaskStatus from '../../WorkflowTaskStatus/WorkflowTaskStatus';
import apiService from '../../../utils/apiService';

function Workflow({ projectId, document:doc, scrollToBottom }) {
  const { project, agents, workspace, topic } = useStore();

  const taskAgent = useMemo(() => {
    return doc.current_task && doc.current_task.agent_id && agents && agents.find(agent => agent.id === doc.current_task.agent_id)
  }, [doc.current_task, agents]);

  const taskAgentGoal = useMemo(() => {
    return doc.current_task && doc.current_task.agent_id && doc.current_task.agent_goal;
  }, [doc.current_task]);

  const allDocuments = useMemo(() => {
    if(!project) return [];
    return project.stages.flatMap(stage => stage.documents);
  }, [project]);

  const activeDocument = useMemo(() => {
    if(!allDocuments.length) return null;
    // Find first document that is not done
    const firstNotDone = allDocuments.find((doc) => !doc.is_done);
    return firstNotDone;
  }, [allDocuments]);

  // Check if the current document is the last in the workflow and is done
  /*const isLastDocumentInWorkflowAndDone = useMemo(() => {
    if(!doc) return false;
    return lastDocumentInWorkflow &&
      lastDocumentInWorkflow.id === doc.id &&
      doc.is_done &&
      (doc.task_type === "GENERATE_WITHOUT_FEEDBACK" || doc.task_type === "GENERATE");
  }, [doc, lastDocumentInWorkflow]);*/
  const isLastDocumentInWorkflowAndDone = useMemo(() => {
    if(!doc) return false;
    return doc.template_type === 'FINAL_RESULT';
  }, [doc]);
  
  const [showGenerateResource, setShowGenerateResource] = useState(false);
  const [nextSteps, setNextSteps] = useState([]);
  const fetchWorkflowEnding = useCallback(async () => {
    try {
      const response = await apiService.post(`/api/workflows/${projectId}/the-end/`);
      if (response.success) {
        console.log('Ending loaded successful:', response.data);
        setShowGenerateResource(!response.data.saved);
        setNextSteps(response.data.next_steps);
      }
    } catch (error) {
      console.error('Error during Load Workflow Ending:', error);
    }
  }, [projectId]);
  
  const handleGenerateResource = useCallback(async () => {
    try {
      const response = await apiService.post(`/api/workflows/${projectId}/generate-resource/`);
      if (response.success) {
        console.log('Generate resource successful:', response);
        setShowGenerateResource(false);
        fetchWorkflowEnding();
      }
    } catch (error) {
      console.error('Error during Generate Resource:', error);
    }
  }, [projectId, fetchWorkflowEnding]);

  useEffect(() => {
    if(!project || !doc) return;
    if(isLastDocumentInWorkflowAndDone) {
      fetchWorkflowEnding();
    }
  }, [isLastDocumentInWorkflowAndDone, project, doc, fetchWorkflowEnding]);

  const chatFormRef = useRef(null);
  const handleActionSubmit = async (action) => {
    chatFormRef.current.handleActionSubmit(action);
  };

  const showChatForm = useMemo(() => {
    if(!doc) return false;
    if(doc.template_type === 'FINAL_RESULT') return true;
    return isLastDocumentInWorkflowAndDone || (!doc.is_done && activeDocument?.id === doc.id);
  }, [isLastDocumentInWorkflowAndDone, activeDocument, doc]);
  return (
    <>
      <Chat topic={topic} scrollToBottom={scrollToBottom} handleActionSubmit={handleActionSubmit} />
      {taskAgent && project &&  activeDocument?.id === doc.id && (<WorkflowAgent agent={taskAgent} workspace={workspace} project={project} document={doc} goal={taskAgentGoal} />)}
      {(!activeDocument || activeDocument.id !== doc.id) && <WorkflowTaskStatus project={project} document={doc} showDropDown={false} showGenerateResource={showGenerateResource} handleGenerateResource={handleGenerateResource} nextSteps={nextSteps} />}
      {showChatForm && <ChatForm ref={chatFormRef} actionButtons={doc && doc.data.actions} />}
    </>
  );
}

export default Workflow;
