import { useCallback, useRef, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useWSManager } from '../contexts/WSManagerContext';
import apiService from '../utils/apiService';
import useStore from '../store';
import { fetchWorkspaceResources } from './WorkspaceDataSync'; 

export const ProjectDataSync = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { project, setProject } = useStore((state) => ({
    project: state.project,
    setProject: state.setProject,
  }));
  const [isGenerating, setIsGenerating] = useState(false);
  const { manageSubscriptions } = useWSManager();
  const [previousProjectId, setPreviousProjectId] = useState(null);
  const componentId = useRef(uuidv4()); // Unique ID for this component instance

  const fetchProject = useCallback(async (projectId) => {
    if (!projectId) return null;
    try {
      const response = await apiService.get(`/api/workflows/${projectId}/?_=${new Date().getTime()}`);
      if (response.success) {
        console.log('Load Project successful:', response.data.project);
        setProject(response.data.project);
        return response.data.project; // Return the fetched project
      }
    } catch (error) {
      console.error('Error during Load Project:', error);
    }
    return null;
  }, [setProject]);

  useEffect(() => {
    if (!projectId) return;
    const loadProjectData = async () => {
      const fetchedProject = await fetchProject(projectId);
      // After fetching the project, also fetch workspace resources
      if (fetchedProject && fetchedProject.workspace_id) {
        await fetchWorkspaceResources(fetchedProject.workspace_id);
      }
    };
    loadProjectData();
  }, [projectId, fetchProject]);



  useEffect(() => {
    if (!project) return;
    if (!project.is_generating && isGenerating) {
      console.log('Project is now generated', project.stages);
      if (project.stages.length > 0) {
        const firstStage = project.stages[0];
        if (firstStage.documents.length > 0) {
          const firstDocument = firstStage.documents[0];
          navigate(`/project/${project.id}/document/${firstDocument.id}/`);
        }
      }
    }
    setIsGenerating(project.is_generating);
  }, [project, isGenerating, navigate]);

  useEffect(() => {
    if (!projectId) return;
    if (projectId === previousProjectId) return;
    // const channelName = `project-${projectId}`;
    // console.log('[ProjectDataSync] #1 Subscribing to channel:', channelName, 'Previous:', previousProjectId, 'Current:', projectId);

    const handleMessage = (event) => {
      const obj = JSON.parse(event.data);
      const type = obj.type;
      if (type === 'UPDATE_PROJECT') {
        console.log('New SSE stream [ProjectDataSync]:', type, obj);
        fetchProject(projectId);
      }
    };

    const subscriptionChanges = [];
    if (previousProjectId) subscriptionChanges.push({ channelName: `project-${previousProjectId}`, componentId: componentId.current });
    subscriptionChanges.push({ channelName: `project-${projectId}`, handleMessage, componentId: componentId.current });
    manageSubscriptions(subscriptionChanges);

    setPreviousProjectId(projectId);
  }, [projectId, manageSubscriptions, previousProjectId, fetchProject]);


  return null;
};

export default ProjectDataSync;