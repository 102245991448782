import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Button } from 'reactstrap';
import Millis from '@millisai/web-sdk';
import { useTranslation } from 'react-i18next';

import useStore from '../../../store';

const MillisMic = ({ agent, isOpen, setTranscript, playMillisMessage }) => {
  const { t } = useTranslation();
  const { topic, language } = useStore((state) => ({
    topic: state.topic,
    language: state.language,
  }));
  const [micEnabled, setMicEnabled] = useState(true);
  const millisClientRef = useRef(null);
  const audioRef = useRef(null);
  const bufferTranscript = useRef([]);

  const sendKeepAlive = useCallback(() => {
    console.log('KeepAlive message sent');
  }, []);
  const keepAliveIntervalRef = useRef(null);

  useEffect(() => {
    if (!keepAliveIntervalRef.current) keepAliveIntervalRef.current = setInterval(sendKeepAlive, 5000);
    return () => {
      if (keepAliveIntervalRef.current) {
        clearInterval(keepAliveIntervalRef.current);
        keepAliveIntervalRef.current = null;
      }
    };
  }, [sendKeepAlive]);

  const closeMillis = useCallback(() => {
    if (millisClientRef.current) {
      millisClientRef.current.stop();
      millisClientRef.current = null;
    }
    bufferTranscript.current = [];
    setTranscript('');
  }, [setTranscript]);

  
  const MILLIS_TOOL_DESCRIPTION = useMemo(() => {
    return process.env.REACT_APP_MILLIS_TOOL_DESCRIPTION || 'Hämta information som berör bygg.';
  }, []);

  const initMillis = useCallback(() => {
    if (millisClientRef.current) {
      closeMillis();
      return;
    }

    const msClient = Millis.createClient({
      publicKey: process.env.REACT_APP_MILLIS_API_KEY,
      endPoint: 'https://api-eu-west.millis.ai/millis'
    });
    millisClientRef.current = msClient;

    msClient.on("onopen", () => {
      console.log('Millis client connected');
    });

    msClient.on("onready", () => {
      console.log('Millis conversation ready');
    });

    msClient.on("onaudio", (audio) => {
      console.log('Millis audio chunk received');
    });

    msClient.on("onclose", (event) => {
      console.log('Millis connection closed', event);
    });

    msClient.on("onerror", (error) => {
      console.error('Millis error occurred:', error);
    });

    // ontranscript
    msClient.on("ontranscript", (transcript, payload) => {
      console.log('Millis transcript received:', transcript);
      // Get last sentence of transcript
      const sentences = transcript.split('.');
      const lastSentence = sentences[sentences.length - 1];
      setTranscript(lastSentence);
    });

    // onresponsetext
    msClient.on("onresponsetext", (text, payload) => {
      console.log('Millis response received:', text, payload);
      // Get last sentence of text
      const sentences = text.split('.');
      const lastSentence = sentences[sentences.length - 1];
      setTranscript(lastSentence);
    });

    msClient.on("timestamp", (_, data) => {
      // console.log('timestamp', data)
      if(data) playMillisMessage(null, data);
    });

    msClient.start({
      prompt: agent.description,
      voice: {
        provider: "elevenlabs",
        voice_id: agent.voice, // https://api-west.millis.ai:8080/voices
      },
      language: language,
      // llm: 'gpt-4o-mini',
      tools: [{
        name: "fetch_information",
        description: MILLIS_TOOL_DESCRIPTION, //Example: "Hämta information som berör bygg.",
        webhook: `${process.env.MILLIS_TOOL_WEBHOOK.replace('{topic_id}', topic.id)}`, // Example: "https://app.aibob.io/api/topic/{topic_id}/rag/"
        header: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${process.env.MILLIS_RAG_JWT}`
        },
        messages: [t("One moment")],
        params: [
          {
            name: "query",
            type: "string",
            description: "Fråga",
            required: true
          }
        ]
      }]
    });
  }, [agent.description, agent.voice, language, topic.id, t, closeMillis, setTranscript, playMillisMessage]);

  const toggleMic = () => {
    if(micEnabled) {
      closeMillis();
    } else {
      initMillis();
    }
    bufferTranscript.current = [];
    setTranscript('');
    setMicEnabled(!micEnabled);
  };

  useEffect(() => {
    if (!isOpen) {
      closeMillis();
      bufferTranscript.current = [];
      setTranscript('');
    } else {
      initMillis(); // Initialize Millis when the component is opened
    }
  }, [isOpen, closeMillis, initMillis, setTranscript]);

  return (
    <>
      <audio ref={audioRef} />
      <div className="d-flex justify-content-center align-items-center w-100">
        {<Button color="danger" onClick={toggleMic}>
          {micEnabled ? (<span className="bi bi-mic"></span>) : (<span className="bi bi-mic-mute"></span>)}
        </Button>}
      </div>
    </>
  );
};

export default MillisMic;
