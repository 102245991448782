import { useCallback, useRef, useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useWSManager } from '../contexts/WSManagerContext';
import apiService from '../utils/apiService';
import useStore from '../store';

export const MeetingDataSync = () => {
  const navigate = useNavigate();
  const { meeting, setMeeting } = useStore((state) => ({
    meeting: state.meeting,
    setMeeting: state.setMeeting,
  }));

  const { meetingId } = useMemo(() => {
    const meetingId = meeting?.id;
    // console.log('!!! NEW meetingId', meetingId);
    return { meetingId };
  }, [meeting?.id]);

  const [isGenerating, setIsGenerating] = useState(false);
  const { manageSubscriptions } = useWSManager();
  const [previousMeetingId, setPreviousMeetingId] = useState(null);
  const componentId = useRef(uuidv4()); // Unique ID for this component instance

  const fetchMeeting = useCallback(async (meetingId) => {
    if (!meetingId) return null;
    try {
      const response = await apiService.post(`/api/meetings/${meetingId}/?_=${new Date().getTime()}`);
      if (response.success) {
        console.log('Load Meeting successful:', response.data.meeting);
        if (response.data.meeting) {
          setMeeting(response.data.meeting);
          return response.data.meeting;
        }
      }
      // If we reach here, something went wrong but we didn't throw an error
      console.error('Failed to fetch meeting:', response);
    } catch (error) {
      console.error('Error during Load Meeting:', error);
    }
    // Don't set meeting to null here, just return null
    return null;
  }, [setMeeting]);

  useEffect(() => {
    if (!meetingId) return;
    const loadMeetingData = async () => {
      await fetchMeeting(meetingId);
    };
    loadMeetingData();
  }, [meetingId, fetchMeeting]);

  useEffect(() => {
    if (!meeting) return;
    if (!meeting.is_generating && isGenerating) {
      console.log('Meeting is now generated', meeting);
      // Add any navigation logic here if needed
    }
    setIsGenerating(meeting.is_generating);
  }, [meeting, isGenerating, navigate]);

  useEffect(() => {
    if (!meetingId) return;
    if (meetingId === previousMeetingId) return;

    const handleMessage = (event) => {
      const obj = JSON.parse(event.data);
      const type = obj.type;
      if (type === 'UPDATE_MEETING') {
        console.log('New SSE stream [MeetingDataSync]:', type, obj);
        fetchMeeting(meetingId);
      }
    };

    const subscriptionChanges = [];
    console.log('Subscribing to meeting-', meetingId);
    if (previousMeetingId) subscriptionChanges.push({ channelName: `meeting-${previousMeetingId}`, componentId: componentId.current });
    subscriptionChanges.push({ channelName: `meeting-${meetingId}`, handleMessage, componentId: componentId.current });
    manageSubscriptions(subscriptionChanges);

    setPreviousMeetingId(meetingId);
  }, [meetingId, manageSubscriptions, previousMeetingId, fetchMeeting]);

  return null;
};

export default MeetingDataSync;