import styled, { createGlobalStyle } from 'styled-components';

export const PrintStyles = createGlobalStyle`
  @page {
    size: A4;
    margin: 20mm;
  }

  @media print {
    body {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 12pt;
      line-height: 1.5;
      color: #000;
    }
  }
`;

export const PageBreak = styled.div`
  page-break-before: always;
  page-break-after: avoid;
`;

export const MessageHeader = styled.div`
  margin-bottom: 12px;
`;

export const MarginBottom = styled.div`
  display: flex;
  margin-bottom: 1em;
`;