import React, { useState, useCallback } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import apiService from '../../utils/apiService';
import { StyledDropdown } from './AgentDropDown.styles';
// import ModalBasic from '../../components/ModalBasic/ModalBasic';
import useStore from '../../store';
import EditCustomAgentModal from '../../components/EditCustomAgentModal/EditCustomAgentModal';
import { getFeatureFlag } from '../../utils/featureFlags';
import { useModalTool } from '../../contexts/ModalToolContext';


function AgentDropDown({ agent, startVideoCall = () => {}, startTakeNotes = () => {}, newChatTopic, viewChatTopics }) {
  const { t } = useTranslation();

  const { project, remAgent, workspace } = useStore((state) => ({ project: state.project, remAgent: state.remAgent, workspace: state.workspace }));

  // Function to handle dropdown toggle
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => { setDropdownOpen(prevState => !prevState); };
  // Functions for Basic Modal
  /*
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const toggleModal = () => setModal(!modal);*/


  const [editModalOpen, setEditModalOpen] = useState(false);
  const toggleEditModal = () => setEditModalOpen(!editModalOpen);

  const navigate = useNavigate();
  const handleDelete = useCallback( async (agentId) => {
    console.log('Deleting agent with id:', agentId);
    try {
      const response = await apiService.delete(`/api/agents/${agentId}/delete/`);
      if (!response.success) {
        console.error('Operation failed:', response.message);
        return;
      }
      console.log('Agent deleted successfully');
      // Remove the agent from the list of agents
      remAgent(agentId);
      navigate(`/${workspace.id}/team/`);
    } catch (error) {
      console.error('Error during operation:', error);
    }
  }, [navigate, remAgent, workspace?.id]);

  const handleRemFromTeam = useCallback( async (agentId) => {
    if (!project) { console.error('No project found'); return; }
    console.log('Removing agent with id:', agentId);
    try {
      const response = await apiService.delete(`/api/agents/${project.id}/remove/${agentId}/`);
      if (!response.success) {
        console.error('Operation failed:', response.message);
        return;
      }
      console.log('Agent removed successfully');
      // Remove the agent from the list of agents
      remAgent(agentId);
      navigate(`/project/${project.id}/team/`);
    } catch (error) {
      console.error('Error during operation:', error);
    }
  }, [navigate, project, remAgent]);

  const { showModal } = useModalTool();

  return (
    <>
      <StyledDropdown>
        <Dropdown isOpen={dropdownOpen} toggle={(e) => { e.stopPropagation(); toggle(); }}>
          <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen} onClick={(e) => e.stopPropagation()}>
            <i className="bi bi-three-dots-vertical"></i>
          </DropdownToggle>
          <DropdownMenu end>
            {newChatTopic && <DropdownItem onClick={newChatTopic}>{t('New Chat')}</DropdownItem>}
            {viewChatTopics && <DropdownItem onClick={viewChatTopics}>{t('Previous Chats')}</DropdownItem>}
            <DropdownItem onClick={toggleEditModal}>{t('Edit Co-worker')}</DropdownItem>
            {!project && <DropdownItem onClick={() => handleDelete(agent.id)}>{t('Delete Co-worker')}</DropdownItem>}
            {project && <DropdownItem onClick={() => handleRemFromTeam(agent.id)}>{t('Remove from project')}</DropdownItem>}
            {/*<DropdownItem onClick={startMeeting}>{t('Start meeting')}</DropdownItem>*/}
            {/*<DropdownItem onClick={handleTest}>Test Dynamic Workflow</DropdownItem>*/}
            {getFeatureFlag('MEETINGS') && (
              <>
                <DropdownItem onClick={startVideoCall}>{t('Video call')}</DropdownItem>
                <DropdownItem onClick={startTakeNotes}>{t('Take notes')}</DropdownItem>
              </>
            )}
            <DropdownItem onClick={() => showModal(t('Train using resources'), 'ONBOARDING_CO_WORKER', agent.id)}>
              {t('Train using resources')}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </StyledDropdown>

      {/*<ModalBasic isOpen={modal} toggle={toggleModal} title={modalTitle} body={modalBody} />*/}

      <EditCustomAgentModal isOpen={editModalOpen} toggle={toggleEditModal} agent={agent} />
    </>
  );
}

export default AgentDropDown;
