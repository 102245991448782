import styled from 'styled-components';
import { Button } from 'reactstrap';

export const StyledActionButton = styled(Button)`
  border-radius: 1em;
  padding: 0.2em 0.75em;
`;

export const ButtonFlexContainer = styled.div`
  display: flex;
  gap: 8px; /* Adjust this value for the default gap between buttons */

  @media (min-width: 768px) {
    gap: 16px; /* Larger gap on wider screens */
  }
`;
