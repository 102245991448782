import React from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';
import { NavLink as StrapNavLink, DropdownToggle } from 'reactstrap';
import styled from 'styled-components';

const CustomStyledSubNavLink = styled(StrapNavLink)`
  color: ${props => props.isactive === 'true' ? 'orange' : 'white'};
  font-size: 0.95em;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  &:hover {
    color: orange;
  }
  &:focus {
    color: orange;
  }
`;

export const StyledSubNavLink = ({ to, isactive, children, ...rest }) => {
  return (
    <CustomStyledSubNavLink {...rest} isactive={isactive} tag={RRNavLink} to={to}>
      {children}
    </CustomStyledSubNavLink>
  );
};


export const CustomDropdownToggle = styled(DropdownToggle)`
  background-color: transparent;
  border: none;
  font-weight: 700;
  color: white;
  font-size: 1.05em;
  &:hover {
    color: orange;
    background-color: transparent;
  }
`;
