import React, { useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { useTranslation } from 'react-i18next';

import ModalBasic from '../../components/ModalBasic/ModalBasic';
import AgentsTable from '../../components/AgentsTable/AgentsTable';
import useStore from '../../store';

const AgentsWidget = ({ agents, enableSelectedAgents = false, selectedAgents=[], toggleAgentSelection=() => {} }) => {
  const navigate = useNavigate(); // Initialize useNavigate
  const { t } = useTranslation();
  const { project, workspace } = useStore((state) => ({ project: state.project, workspace: state.workspace }));

  // Functions for Basic Modal
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  const visibleAgents = useMemo(() => {
    if (!enableSelectedAgents || !selectedAgents || !agents) return agents;
    return agents.filter((agent) => selectedAgents.includes(agent.id));
  }, [agents, enableSelectedAgents, selectedAgents]);

  const handleAgentClick = (agentId) => {
    const path = project ? `/project/${project.id}/coworker/${agentId}` : `/${workspace.id}/coworker/${agentId}`;
    navigate(path); // Navigate to the desired path
  };

  if (!agents || agents.length === 0)   return null;

  return (
    <>
      <p><strong>{t('Work group')}</strong></p>
      {visibleAgents.map((agent, index) => (
        <p
          key={`agent-widget-${index}`}
          style={{ listStyleType: "none", cursor: 'pointer' }}
          onClick={() => handleAgentClick(agent.id)}
        >
          <i className="bi bi-person"></i> {agent?.name}
        </p>
      ))}

      {enableSelectedAgents && <Button outline size="sm" onClick={toggleModal}>{t('Edit work group')}</Button>}
      <ModalBasic
        isOpen={modal}
        toggle={toggleModal}
        title={t('Workflow resources')}
      >
        {agents && <AgentsTable agents={agents} selectedAgents={selectedAgents} toggleAgentSelection={toggleAgentSelection} />}
      </ModalBasic>
    </>
  );
};

export default AgentsWidget;
