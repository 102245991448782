import React, { useCallback } from 'react';
import { Form, Label, Input, FormGroup } from 'reactstrap';

import apiService from '../../../utils/apiService';
import DocumentFormButton from '../../DocumentFormButton/DocumentFormButton';
import { StyledFormGroup } from './MultipleChoices.styles';

function MultipleChoices({ projectId, document: doc }) {
  const documentId=doc.id;

  const handleSubmit = async (e) => {
    e.preventDefault();
    doc.is_generating = true;
    const endpoint = `/api/document/${documentId}/generate-with-template/`;

    let user_input = '';
    const questions = document.querySelectorAll('.question');

    questions.forEach((questionElement, questionIndex) => {
      const questionData = doc.template.multiple_choices[questionIndex];
      const selectedOptions = [];
      questionElement.querySelectorAll(`input[name="question-${questionIndex}"]:checked`).forEach((checkbox) => {
        // Find the option object by its value (option.option)
        const optionData = questionData.options.find(option => option.option === checkbox.value);
        if (optionData) {
          selectedOptions.push(optionData.text); // Use option text instead of value
        }
      });
      // Include the question text and the selected options' texts
      user_input += `Question ${questionIndex + 1}: ${questionData.questionText}\nSelected: ${selectedOptions.join(', ')}\n\n`;
    });

    const formData = {
      user_input: user_input.trim()
    };

    console.log('formData:', formData);
    try {
      const response = await apiService.post(endpoint, formData);
      if (response.success) {
        console.log('Document generation initiated successfully', response.data.job);
      } else {
        console.error('Operation failed:', response.message);
      }
    } catch (error) {
      console.error('Error during operation:', error);
    } finally {
      doc.is_generating = false;
    }
  };

  const generateFormFromQuestions = useCallback((questions) => {
    return questions.map((question, questionIndex) => (
      // Add the 'question' class to the FormGroup that wraps each question
      <StyledFormGroup key={questionIndex} className="mb-3 question">
        <legend className="col-form-label fw-bold">{question.questionText}</legend>
        {question.options.map((option, optionIndex) => (
          <FormGroup check key={optionIndex}>
            <Input
              type="checkbox"
              id={`question-${questionIndex}-option-${optionIndex}`}
              name={`question-${questionIndex}`}
              value={option.option}
              className="form-check-input"
            />
            <Label
              for={`question-${questionIndex}-option-${optionIndex}`}
              check
              className="form-check-label"
            >
              {option.text}
            </Label>
          </FormGroup>
        ))}
      </StyledFormGroup>
    ));
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      {doc.template.multiple_choices && generateFormFromQuestions(doc.template.multiple_choices)}
      <DocumentFormButton document={doc} />
    </Form>
  );
}

export default MultipleChoices;
