import React, { useState, useEffect, useRef } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { useTranslation } from 'react-i18next';
import apiService from '../../utils/apiService';
import useStore from '../../store';

import { 
  StyledModal, 
  StyledModalHeader, 
  StyledModalBody, 
  SelectableElement,
  ContentColumn,
  SideColumn,
} from './ModalEditor.styles';

import Chat from '../Chat/Chat';
import ChatForm from './ChatForm';

const getTextContent = (children) => {
  if (!children) return '';
  
  if (typeof children === 'string') return children;
  
  if (Array.isArray(children)) {
    return children.map(child => getTextContent(child)).join('');
  }
  
  // Handle React elements
  if (children?.props?.children) {
    return getTextContent(children.props.children);
  }
  
  return String(children);
};

const ModalEditor = ({ isOpen, toggle, message, size = 'lg' }) => {
  const { t } = useTranslation();
  const [selectedElement, setSelectedElement] = useState(null);
  const [partial, setPartial] = useState('');
  const [currentMessage, setCurrentMessage] = useState(message);
  const [loadingElementId, setLoadingElementId] = useState(null);
  const chatContainerRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const { setSubTopicId, setSubTopic, subTopic } = useStore((state) => ({ 
    setSubTopicId: state.setSubTopicId,
    setSubTopic: state.setSubTopic,
    subTopic: state.subTopic
  }));

  useEffect(() => {
    setCurrentMessage(message);
  }, [message]);



  useEffect(() => {
    const fetchTopic = async () => {
      try {
        const response = await apiService.get(`/api/messages/${message.id}/topic/`);
        console.log('fetchTopic response', response);
        if (response.success) {
          setSubTopic(response.data);
          setSubTopicId(response.data.id);
        }
      } catch (error) {
        console.error('Error fetching topic:', error);
      } finally {
        setLoading(false);
      }
    };
    
    if (!isOpen) {
      console.log('CLOSING MODAL');
      setSubTopic(null);
      setSubTopicId(null);
      setSelectedElement(null);
      setPartial(null);
      setLoading(false);
    } else {
      console.log('OPENING MODAL');
      if (message.id) {
        fetchTopic();
      }
    }
  }, [isOpen, message.id, setSubTopic, setSubTopicId]);

  const createSelectableComponent = (Component) => {
    return ({ node, children, ...props }) => {
      const elementId = `${Component}-${node.position?.start.line || Math.random()}`;
      
      // Get the original markdown text from the node's position
      const originalText = node.position 
        ? currentMessage.message.slice(node.position.start.offset, node.position.end.offset)
        : getTextContent(children);
      
      // Get the rendered text (without markdown)
      const renderedText = getTextContent(children);
      
      return (
        <SelectableElement
          onClick={(e) => {
            e.stopPropagation();
            setSelectedElement(elementId);
            setPartial({
              raw: originalText,      // Contains markdown
              formatted: renderedText // Plain text after rendering
            });
          }}
          isSelected={selectedElement === elementId}
        >
          <div style={{ position: 'relative' }}>
            {loadingElementId === elementId && (
              <span className="spinner-border spinner-border-sm position-absolute" style={{ top: '5px', right: '5px', zIndex: 1000 }} role="status" aria-hidden="true" />
            )}
            <Component {...props}>{children}</Component>
          </div>
        </SelectableElement>
      );
    };
  };

  const handleUpdatePartial = (response) => {
    console.log('********************');
    console.log('handleUpdatePartial');
    console.log('********************');

    // Update the full message
    setCurrentMessage({
      ...currentMessage,
      message: response.edited_text
    });

    // Update the partial selection
    console.log('response.partial', response.partial);
    setPartial(response.partial);
  };

  const components = {
    h1: createSelectableComponent('h1'),
    h2: createSelectableComponent('h2'),
    h3: createSelectableComponent('h3'),
    h4: createSelectableComponent('h4'),
    h5: createSelectableComponent('h5'),
    h6: createSelectableComponent('h6'),
    p: createSelectableComponent('p'),
    img: createSelectableComponent('img'),
    ul: createSelectableComponent('ul'),
    li: createSelectableComponent('li'),
    table: createSelectableComponent('table'),
  };

  const scrollChatToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  return (
    <StyledModal isOpen={isOpen} toggle={toggle} size={size}>
      <StyledModalHeader toggle={toggle}>{t('Edit message')}</StyledModalHeader>
      <StyledModalBody>
        <ContentColumn onClick={() => {
          setSelectedElement(null);
          setPartial('');  // Reset partial when deselecting
        }}>
          <Markdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            components={components}
          >
            {currentMessage.message}
          </Markdown>
        </ContentColumn>
        <SideColumn>
          <div 
            ref={chatContainerRef} 
            className="modal-chat-container"
            style={{
              height: '100%',
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {loading ? (
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
            ) : (
              <>
                <div className='flex-grow-1'>
                  {subTopic && <Chat topic={subTopic} scrollToBottom={scrollChatToBottom} />}
                </div>
                <ChatForm 
                  message={currentMessage} 
                  partial={partial} 
                  updatePartialFn={handleUpdatePartial}
                  setLoadingElement={setLoadingElementId}
                  selectedElementId={selectedElement}
                />
              </>
            )}
          </div>
        </SideColumn>
      </StyledModalBody>
    </StyledModal>
  );
};

export default ModalEditor;