import styled from 'styled-components';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export const StyledModal = styled(Modal)`
  max-width: 90vw;
  width: 90vw;
  height: 90vh;
  background-color: #303030;
  color: white;

  .modal-content {
    display: flex; // Set the modal content to display as flex
    flex-direction: column; // Arrange children (header, body, footer) in a column
    height: 100%; // Ensure the modal content takes the full height of the modal
  }

  .modal-header, .modal-content {
    background-color: inherit;
    color: inherit;
  }
`;

export const StyledModalHeader = styled(ModalHeader)`
  .btn-close {
    color: #FFF; // Change this to the color you want for the close button
    opacity: 1; // Adjust if you want to change the visibility

    &:hover {
      color: #CCC; // Change this to the color you want on hover
    }
  }
`;

export const StyledModalBody = styled(ModalBody)`
  max-height: 80vh;
  overflow-y: auto;
  border-radius: 0.5rem;
  flex: 1; // Allow the body to expand and fill the available space
`;

export const StyledModalFooter = styled(ModalFooter)`
  display: flex;
  justify-content: flex-start; // Align items to the left
  flex-wrap: wrap; // Allow items to wrap onto the next line if needed
  align-items: center; // Align items vertically in the center

  > div {
    flex-grow: 1; // Allow the div to take up any remaining space
  }
`;
