import React, { useState, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import {
  Form,
  InputGroup,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import apiService from '../../utils/apiService';
import AutoResizeTextareaOneLine from '../../components/AutoResizeTextareaOneLine/AutoResizeTextareaOneLine';
import useStore from '../../store';
import { StyledButton, InputGroupFlex, StyledActionButton, ButtonFlexContainer, ChatContainer } from './ChatForm.styles';

const ChatForm = forwardRef(({ actionButtons = [] }, ref) => {
  const { t } = useTranslation();
  const { setTopic, topic, streaming, project } = useStore((state) => ({
    setTopic: state.setTopic,
    topic: state.topic,
    streaming: state.streaming,
    project: state.project,
  }));

  const [userInput, setUserInput] = useState('');
  const [isSending, setIsSending] = useState(false);

  const handleChatSubmit = async (e) => {
    e.preventDefault();
    if (!userInput) return;
    if (!topic || !topic.id) {
      console.error('Topic does not exist. Cannot send message.');
      return;
    }
    setIsSending(true);
    const userMessageId = uuidv4();
    const aiMessageId = uuidv4();
    const newTopic = {
      ...topic,
      chat_history: [...topic.chat_history, 
        { type: 'USER', message: userInput, id: userMessageId },
        { type: 'AI', message: '', id: aiMessageId, data: { streaming: true } } // Ready for streaming!
      ],
    }
    setTopic(newTopic);

    let endpoint = `/api/topic/${topic.id}/generate/`;
    let formData = { user_input: userInput, user_message_id: userMessageId, ai_message_id: aiMessageId };

    console.log('Sending chat message:', formData, 'to:', endpoint);
    try {
      const response = await apiService.post(endpoint, formData);
      console.log('Response:', response);
      if (response.success) {
        console.log('Chat message sent successfully', response.data.job);
        setUserInput('');
      } else {
        console.error('Operation failed:', response.message);
      }
    } catch (error) {
      console.error('Error during operation:', error);
    } finally {
      setIsSending(false);
    }
  };

  const handleActionSubmit = useCallback(async (action) => {
    if (!topic || !topic.id) {
      console.error('Topic does not exist. Cannot send message.');
      return;
    }
    const prompt = action.prompt || action;
    setIsSending(true);
    const userMessageId = uuidv4();
    const aiMessageId = uuidv4();
    const newTopic = {
      ...topic,
      chat_history: [...topic.chat_history, 
        { type: 'USER', message: prompt, id: userMessageId },
        { type: 'AI', message: '', id: aiMessageId, data: { streaming: true } }
      ],
    }
    setTopic(newTopic);

    const endpoint = `/api/topic/${topic.id}/generate/`;
    const formData = {
      user_input: prompt,
      user_message_id: userMessageId,
      ai_message_id: aiMessageId
    };

    console.log('Sending action message:', formData, 'to:', endpoint);
    try {
      const response = await apiService.post(endpoint, formData);
      console.log('Response:', response);
      if (response.success) {
        console.log('Action message sent successfully', response.data.job);
      } else {
        console.error('Operation failed:', response.message);
      }
    } catch (error) {
      console.error('Error during operation:', error);
    } finally {
      setIsSending(false);
    }
  }, [setTopic, topic]);

  // Expose the method via useImperativeHandle
  useImperativeHandle(ref, () => ({
    handleActionSubmit
  }), [handleActionSubmit]);

  const isStreaming = useMemo(() => {
    return streaming && (streaming.status === 'start' || streaming.status === 'stream');
  }, [streaming]);

  const isWorkflowing = useMemo(() => {
    return project && project.is_workflowing;
  }, [project]);

  return (
    <ChatContainer>
      {/*actionButtons && actionButtons.length ? <ButtonFlexContainer className='mb-3'>
        {actionButtons.map((action, index) => (
          <StyledActionButton key={`action-btn-${index}`} color="dark" outline size="sm" onClick={(e) => handleActionSubmit(e, action)}>{action.label}</StyledActionButton>
        ))}
      </ButtonFlexContainer> : null*/}
      <Form onSubmit={handleChatSubmit}>
        <InputGroup>
          <InputGroupFlex>
            <AutoResizeTextareaOneLine
              id="user-chat-input"
              name="user-chat-input"
              initialValue={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleChatSubmit(e);
                }
              }}
              disabled={isSending || isWorkflowing}
              autoFocus
            />
            <StyledButton className="btn btn-primary" disabled={isSending || isStreaming}>
              {t('Send')}
            </StyledButton>
          </InputGroupFlex>
        </InputGroup>
      </Form>
    </ChatContainer>
  );
});

export default ChatForm;
