import React, { useRef, useEffect, useCallback } from 'react';
import { Button } from 'reactstrap';
import { RealtimeClient } from '@openai/realtime-api-beta';
import { useMeeting } from '../MeetingContext';
import { getAgentSettings, setupTools } from './clientConfig';
import useStore from '../../../store';
import { v4 as uuidv4 } from 'uuid';
import apiService from '../../../utils/apiService';

const VadOpenaiStream = ({ isOpen }) => {
  const clientRef = useRef(new RealtimeClient({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowAPIKeyInBrowser: true,
    debug: false
  }));
  const { language } = useStore();
  const { 
    activeSpeakerId,
    initializedActiveSpeakerId,
    setInitializedActiveSpeakerId,
    setIsSharedScreenActive,
    setActiveSharedView,
    setTranscript,
    audioAnalyzerRef,
    agentsInMeeting,
    handleCreateWebApp,
    sharedContent,
    sharedContentVersion
  } = useMeeting();
  
  const mediaStreamRef = useRef(null);
  const audioContextRef = useRef(null); 
  const processorRef = useRef(null);
  const micEnabledRef = useRef(true);
  const isConnectedRef = useRef(false);
  const mountedRef = useRef(true);
  const playbackContextRef = useRef(null);
  const nextPlayTimeRef = useRef(0);
  const recentTranscriptRef = useRef('');
  const processingAudioRef = useRef(false);

  const setupEventHandlers = (client, mounted) => {
    client.on('error', (error) => {
      console.error('OpenAI client error:', error);
    });

    client.on('conversation.interrupted', () => {
      console.log('Conversation interrupted');
      recentTranscriptRef.current = '';
      setTranscript('');
    });

    client.on('message', (message) => {
      console.log('Received message:', message);
    });

    client.on('disconnect', () => {
      isConnectedRef.current = false;
      console.log('Client disconnected');
    });

    // Add audio stream handler
    client.on('audio_stream', (audioChunk) => {
      console.log('Received audio stream chunk, length:', audioChunk.length);
      if (!mounted) return;
      
      try {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const audioBuffer = audioContext.createBuffer(1, audioChunk.length, 24000);
        audioBuffer.getChannelData(0).set(new Float32Array(audioChunk));
        
        const source = audioContext.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(audioContext.destination);
        source.start(0);
        console.log('Started playing audio chunk');
      } catch (error) {
        console.error('Error playing audio chunk:', error);
      }
    });

    client.on('conversation.updated', (updated) => {
      if (!mounted) return;
      
      const { item, delta } = updated;
      
      if (delta?.audio) {
        const audioContext = playbackContextRef.current;
        const currentTime = audioContext.currentTime;
        
        const float32Data = new Float32Array(delta.audio.length);
        for (let i = 0; i < delta.audio.length; i++) {
          float32Data[i] = delta.audio[i] / 32768.0;
        }
        
        const audioBuffer = audioContext.createBuffer(1, float32Data.length, 24000);
        audioBuffer.getChannelData(0).set(float32Data);
        
        const source = audioContext.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(audioAnalyzerRef.current);
        
        const startTime = Math.max(currentTime, nextPlayTimeRef.current);
        source.start(startTime);
        nextPlayTimeRef.current = startTime + audioBuffer.duration;
      }

      if (delta?.transcript && item.role === 'assistant') {
        if (delta.transcript.match(/^[A-ZÅÄÖ]/)) {
          recentTranscriptRef.current = delta.transcript;
        } else {
          recentTranscriptRef.current += delta.transcript;
        }
        setTranscript(recentTranscriptRef.current);
      }
    });
  };

  const { workspace, meeting } = useStore();
  const internetSearchFunc = useCallback(async (query) => {
    console.log('Internet search:', query);
    const endpoint = '/api/simple_task/create/';
    const aiMessageId = uuidv4();
    const data = {
      name: `Internet search for ${query.substring(0, 25)}...`,
      super_type: 'SIMPLE_TASK',
      type: 'INTERNET_SEARCH',
      prompt: query,
      agent_id: activeSpeakerId,
      mode: 'quality',
      workspace_id: workspace?.id,
      // topic_id: topic?.id, // Server will set this
      ai_message_id: aiMessageId,
      meeting_id: meeting?.id
    };
    apiService.post(endpoint, data)
      .then(response => {
        if (response.success) {
          console.log('Simple task created successfully');
        } else {
          console.error('Operation failed:', response.message);
        }
      })
      .catch(error => {
        console.error('Error during operation:', error);
      })
  }, [activeSpeakerId, workspace?.id, meeting?.id]);

  const initializeClient = useCallback(async () => {
    console.log('\n********************\nInitializing client\n********************\n');
    const client = clientRef.current;
    
    // Set up client parameters
    const activeAgent = agentsInMeeting.find(agent => agent.id === activeSpeakerId);
    await client.updateSession(getAgentSettings(activeAgent));
    setupTools(client, setIsSharedScreenActive, setActiveSharedView, internetSearchFunc, handleCreateWebApp);
    await client.connect();
    
    isConnectedRef.current = true;
    processingAudioRef.current = true;
    
    // Create playback context if it doesn't exist
    if (!playbackContextRef.current) {
      playbackContextRef.current = new (window.AudioContext || window.webkitAudioContext)({
        sampleRate: 24000
      });
      
      audioAnalyzerRef.current = playbackContextRef.current.createAnalyser();
      audioAnalyzerRef.current.fftSize = 2048;
      audioAnalyzerRef.current.connect(playbackContextRef.current.destination);
    }

    // Clear current transcript
    recentTranscriptRef.current = '';
    setTranscript('');

    // Send initial message
    const greetings = [
      "Hi", "Hello", "What's up", "Ready", "Let's go", "What's up boss!",
      "Hey there!", "Good to see you!", "How can I help?",
      "At your service!", "Here to assist!",
      "Standing by!", "Ready when you are!",
      "All set to go!",
      "I'm here for you!",
      "Let's get started!", "Hello! How can I assist?", "Let's get to work!",
      "Reporting for duty!", "Ready for action!", "What's on the agenda?",
      "I'm all ears!",
      "Just say the word!", "I'm here to help!", "Let's get down to business!",
      "How can I assist?",
    ];
    const randomGreeting = greetings[Math.floor(Math.random() * greetings.length)];
    
    await client.sendUserMessageContent([{ 
      type: 'input_text', 
      text: `Say "${randomGreeting}" to start the conversation in ${language}.` 
    }]);
  }, [activeSpeakerId, agentsInMeeting, audioAnalyzerRef, handleCreateWebApp, internetSearchFunc, language, setActiveSharedView, setIsSharedScreenActive, setTranscript]);

  const setupAudioProcessing = async () => {
    // Get microphone stream
    const stream = await navigator.mediaDevices.getUserMedia({ 
      audio: {
        echoCancellation: true,
        noiseSuppression: true,
        autoGainControl: true
      } 
    });
    mediaStreamRef.current = stream;

    // Set up audio context and processor
    const context = new (window.AudioContext || window.webkitAudioContext)({
      sampleRate: 24000
    });
    audioContextRef.current = context;
    
    const source = context.createMediaStreamSource(stream);
    const processor = context.createScriptProcessor(2048, 1, 1);
    processorRef.current = processor;

    // Handle audio processing
    processor.onaudioprocess = (e) => {
      if (!micEnabledRef.current || !isConnectedRef.current || !processingAudioRef.current) return;

      try {
        const inputData = e.inputBuffer.getChannelData(0);
        const int16Data = new Int16Array(inputData.length);
        for (let i = 0; i < inputData.length; i++) {
          int16Data[i] = Math.max(-32768, Math.min(32767, inputData[i] * 32768));
        }
        
        if (clientRef.current?.isConnected()) {
          clientRef.current.appendInputAudio(int16Data);
        }
      } catch (error) {
        console.error('Audio processing error:', error);
      }
    };

    // Connect audio nodes
    source.connect(processor);
    processor.connect(context.destination);
    console.log('Audio pipeline ready');
  };

  useEffect(() => {
    const client = clientRef.current;
    const mounted = mountedRef.current;

    async function init() {
      if (initializedActiveSpeakerId) return;
      try {
        await initializeClient();
        await setupAudioProcessing();
        setupEventHandlers(client, mounted);
        setInitializedActiveSpeakerId(activeSpeakerId);
      } catch (error) {
        console.error('Error initializing:', error);
        isConnectedRef.current = false;
        processingAudioRef.current = false;
      }
    }

    init();

    // Clean up on unmount
    return () => {
      mountedRef.current = false;
      const cleanup = async () => {
        processingAudioRef.current = false;
        if (processorRef.current) {
          processorRef.current.disconnect();
        }
        if (mediaStreamRef.current) {
          mediaStreamRef.current.getTracks().forEach(track => track.stop());
        }
        if (audioContextRef.current?.state !== 'closed') {
          await audioContextRef.current?.close();
        }
        if (client?.isConnected()) {
          await client.disconnect();
        }
        if (playbackContextRef.current?.state !== 'closed') {
          await playbackContextRef.current?.close();
        }
        isConnectedRef.current = false;
      };
      cleanup();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Clear transcript when component unmounts or conversation stops
  useEffect(() => {
    return () => {
      setTranscript([]);  // Clear on unmount
    };
  }, [setTranscript]);

  const toggleMic = () => {
    micEnabledRef.current = !micEnabledRef.current;
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getAudioTracks().forEach(track => {
        track.enabled = micEnabledRef.current;
      });
    }
  };

  const stop = async () => {
    if (clientRef.current?.isConnected()) {
      await clientRef.current.disconnect();
    }
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach(track => track.stop());
    }
    if (processorRef.current) {
      processorRef.current.disconnect();
    }
    if (audioContextRef.current) {
      await audioContextRef.current.close();
    }
    isConnectedRef.current = false;
    setTranscript(''); // Clear transcript when stopping
  };



  useEffect(() => {
    const setSpeaker = async (speakerId) => {
      try {
        processingAudioRef.current = false;
        
        await clientRef.current.disconnect();
        await new Promise(resolve => setTimeout(resolve, 100));
        
        await initializeClient();
      } catch (error) {
        console.error('Error changing speaker:', error);
      } finally {
        processingAudioRef.current = true;
        isConnectedRef.current = true;
        setInitializedActiveSpeakerId(speakerId);
      }
    };
    
    if (activeSpeakerId && initializedActiveSpeakerId && activeSpeakerId !== initializedActiveSpeakerId) {
      setSpeaker(activeSpeakerId);
    }
  }, [activeSpeakerId, initializeClient, initializedActiveSpeakerId, setInitializedActiveSpeakerId]);

  // Notify speaker that their content has been updated
  useEffect(() => {
    if (Number(sharedContentVersion) === 0 || !sharedContent || !sharedContent.connect) return;
    console.log('👀 Notifying speaker that their content has been updated', sharedContent, sharedContentVersion);
    clientRef.current.sendUserMessageContent([{
      type: 'input_text',
      text: `Your shared screen has been updated with this content: ${sharedContent.content}. Let the user know in one sentence.` 
    }]);
  }, [sharedContentVersion, sharedContent]);

  return (
    <div className="d-flex flex-column align-items-center w-100">
      <div className="d-flex justify-content-center align-items-center">
        <Button color="danger" onClick={toggleMic}>
          <span className={`bi bi-mic${micEnabledRef.current ? '' : '-mute'}`}></span>
        </Button>
        <Button onClick={stop}>Stop</Button>
      </div>
    </div>
  );
};

export default VadOpenaiStream;
