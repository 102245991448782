import React from 'react';
import { Row, Col } from 'reactstrap';
import useStore from '../../store';
import FlexLoader from '../../components/FlexLoader/FlexLoader';
import { Link } from 'react-router-dom';

// Display an overview of our project
function ProjectPage() {
  const { project } = useStore((state) => ({
    project: state.project,
  }));

  if(!project) return (<FlexLoader />);

  return (
    <div>
      <h2>{project.name}</h2>
      <Row>
        <Col sm="12" md={{ size: 9 }}>
          {project && (<div>
            <p>{project.description}</p>
            {project.stages && project.stages.map((stage) => (
              <div key={stage.id}>
                <h2> {stage.title} </h2>
                <ul key={`documents-${stage.id}`}>
                  {stage.documents.map((document) => (
                    <li key={document.id}>
                      <Link to={`/project/${project.id}/document/${document.id}/`}>
                        {document.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>)}
        </Col>
      </Row>
    </div>
  );
}

export default ProjectPage;
