import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { useTranslation } from 'react-i18next';


import apiService from '../../../utils/apiService';
import useStore from '../../../store';

import { 
  StyledModalBody, 
  ContentColumn,
} from './Content.styles';

const Create = ({ isOpen, toggle, workflow_template_name, setSavingWorkflow, isCustom = false, type = 'CUSTOM_WORKFLOW_MANUAL', generation_template_id=null }) => {
  const { t } = useTranslation();
  
  const { addProject, workspace } = useStore((state) => ({
    addProject: state.addProject,
    workspace: state.workspace,
  }));
  const [projectData, setProjectData] = useState({
    name: '',
    icon: 'bi-gear',
    super_type: 'WORKFLOW',
    type: type, // CUSTOM_WORKFLOW_MANUAL Or CUSTOM_WORKFLOW_AUTOMATIC
    workflow_template_name: workflow_template_name,
    check_for_hallucinations: false,
    output_creativity: 1,
    generation_template_id: generation_template_id,
  });

  const [error, setError] = useState('');
  
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProjectData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  useEffect(() => {
    const newLabel  = t('New')
    setProjectData((prevData) => ({
      ...prevData,
      name: workflow_template_name ? `${workflow_template_name} (${newLabel})` : '',
      workflow_template_name: workflow_template_name,
      type: type,
      generation_template_id: generation_template_id,
    }));
  }, [workflow_template_name, type, t, generation_template_id]);

  const validateForm = () => {
    if (!projectData.name.trim()) {
      setError('Name is required');
      return false;
    }
    setError('');
    return true;
  };

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return; // Stop submission if validation fails
    setSavingWorkflow(true);
    try {
      const data = {
        ...projectData,
        workspace_id: workspace?.id,
      };
      const response = await apiService.post('/api/workflows/generate/', data);
      if (response.success) {
        console.log('Workflow created successfully:', response.data);
        const project_id = response.data.project.id;
        addProject(response.data.project);
        navigate(`/project/${project_id}`)
      } else {
        console.error('Operation failed:', response.message);
      }
    } catch (error) {
      console.error('Error during operation:', error);
    } finally {
      setSavingWorkflow(false);
      toggle();
    }
  };

  return (
    <StyledModalBody>
      <ContentColumn>
        <h3>✨ {t('Create')}</h3>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="name" className='fw-bold'>{t('Name')}</Label>
            <Input
              type="text"
              name="name"
              id="name"
              placeholder={t('Workflow Name')}
              value={projectData.name}
              onChange={handleChange}
              invalid={!!error}
            />
            <FormFeedback>
              {error}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
              <Label for="generation_template_id" className='fw-bold'>{t('Document Template')}</Label>
              <Input
                type="select"
                name="generation_template_id"
                id="generation_template_id"
                value={projectData.generation_template_id}
                onChange={handleChange}
              >
                {window.generation_templates_for_select?.map(template => (
                  <option key={template.id} value={template.id}>
                    {template.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            {/*
          {isCustom && (
            <FormGroup>
              <Label for="workflow_template_name" className='fw-bold'>{t('What do you want help with?')}</Label>
              <Input
                type="text"
                name="workflow_template_name"
                id="workflow_template_name"
                placeholder={t('Enter Workflow Template Name')}
                value={projectData.workflow_template_name || ''}
                onChange={handleChange}
              />
            </FormGroup>
          )}
          <FormGroup>
            <Label for="type" className='fw-bold'>{t('Run mode')}</Label>
            <Input 
              type="select" 
              name="type" 
              id="type" 
              value={projectData.type} 
              onChange={handleChange}
            >
              <option value="CUSTOM_WORKFLOW_MANUAL">{t('Manual: approve each task')}</option>
              <option value="CUSTOM_WORKFLOW_AUTOMATIC">{t('Automatic: run all tasks non stop')}</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label className='fw-bold'>{t('Output')}</Label>
            <Input 
              type="select" 
              name="output_creativity" 
              id="output_creativity" 
              value={projectData.output_creativity} 
              onChange={handleChange}
            >
              <option value={0.5}>{t('Predictable (0.5)')}</option>
              <option value={1.0}>{t('Balanced Creativity (1.0)')}</option>
              <option value={1.5}>{t('High Creativity (1.5)')}</option>
            </Input>
            <div className="mt-2">
              <Input
                type="checkbox"
                id="check_for_hallucinations"
                name="check_for_hallucinations"
                checked={projectData.check_for_hallucinations}
                onChange={handleChange}
              />
              <Label for="check_for_hallucinations" check className="ms-2">
                {t('Auto-fix hallucinations')}
              </Label>
            </div>
          </FormGroup>*/}
        </Form>
      </ContentColumn>
      <ContentColumn></ContentColumn>
    </StyledModalBody>
  );
};

export default Create;