import styled, { css } from 'styled-components';

export const AvatarContainer = styled.div`
  position: relative;
  width: ${props => props.$dimension}px;
  height: ${props => props.$dimension}px;
  max-width: 100%;
  max-height: 100%;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  transition: all 0.3s ease;
  border-radius: 50%;
  
  ${props => props.$isActive && css`
    &::before {
      content: '';
      position: absolute;
      top: -4px;
      left: -4px;
      right: -4px;
      bottom: -4px;
      border-radius: 50%;
      background: rgba(0, 255, 255, 0.4);
      z-index: 0;
    }
  `}
`;

export const BusyOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 2;
  overflow: hidden;
  opacity: 0.7;
  
  &::before {
    content: '';
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    height: 200%;
    background: repeating-linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.1) 8%,
      rgba(255, 255, 255, 0.5) 8%,
      rgba(255, 255, 255, 0.5) 16%
    );
    animation: moveStripes 4s linear infinite;
  }

  @keyframes moveStripes {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(50%);
    }
  }
`;

export const Emoji = styled.div`
  position: absolute;
  top: -20px;
  right: -20px;
  font-size: 24px;
  opacity: ${props => props.$show === "true" ? 1 : 0};
  transition: opacity 0.3s ease;
  z-index: 3;
`;
