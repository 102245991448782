import React from 'react';
import { useParams } from 'react-router-dom';
import QuestionsAndAnswers from './QuestionsAndAnswers/QuestionsAndAnswers';
import FillInTheBlanks from './FillInTheBlanks/FillInTheBlanks';
import MultipleChoices from './MultipleChoices/MultipleChoices';
import ChatBot from './ChatBot/ChatBot';
import ChatBotGuided from './ChatBotGuided/ChatBotGuided';

function DocumentTemplate({ projectId, document: doc, nextDocument }) {
  const { templateType } = useParams();
  const outputTemplateType = templateType || doc.template_type;
  if (outputTemplateType === 'FILL_IN_THE_BLANKS') {
    return <FillInTheBlanks projectId={projectId} document={doc} key={`document-template-${doc.id}`} />;
  }
  if (outputTemplateType === 'MULTIPLE_CHOICES') {
    return <MultipleChoices projectId={projectId} document={doc} key={`document-template-${doc.id}`} />;
  }
  if (outputTemplateType === 'CHATBOT') {
    return <ChatBot projectId={projectId} document={doc} />;
  }
  if (outputTemplateType === "CHATBOT_GUIDED") {
    return <ChatBotGuided projectId={projectId} document={doc} nextDocument={nextDocument} key={`document-template-${doc.id}`} />;
  }
  // QUESTIONS_AND_ANSWERS
  return <QuestionsAndAnswers projectId={projectId} document={doc} key={`document-template-${doc.id}`} />;
}

export default DocumentTemplate;
