import React, { useState, useEffect, useCallback } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import apiService from '../../utils/apiService';
import { StyledDropdown } from './TeamDropDown.styles';
// import ModalBasic from '../../components/ModalBasic/ModalBasic';
import useStore from '../../store';
// import EditCustomAgentModal from '../../components/EditCustomAgentModal/EditCustomAgentModal';


function TeamDropDown({ team, startMeeting, newChatTopic, viewChatTopics, workspace }) {
  const { t } = useTranslation();
  const { remTeam } = useStore((state) => ({ remTeam: state.remTeam }));

  // Function to handle dropdown toggle
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => { setDropdownOpen(prevState => !prevState); };
  // Functions for Basic Modal
  /*
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const toggleModal = () => setModal(!modal);*/

  /* NOTE: KEEP
  const [editModalOpen, setEditModalOpen] = useState(false);
  const toggleEditModal = () => setEditModalOpen(!editModalOpen);*/

  const navigate = useNavigate();
  const handleDelete = useCallback( async (teamId) => {
    console.log('Deleting team with id:', teamId);
    try {
      const response = await apiService.delete(`/api/teams/${teamId}/delete/`);
      if (!response.success) {
        console.error('Operation failed:', response.message);
        return;
      }
      console.log('Agent deleted successfully');
      // Remove the agent from the list of agents
      remTeam(teamId);
      navigate(`/${workspace.id}/team/`);
    } catch (error) {
      console.error('Error during operation:', error);
    }
  }, [navigate, remTeam, workspace?.id]);


  return (
    <>
      <StyledDropdown>
        <Dropdown isOpen={dropdownOpen} toggle={(e) => { e.stopPropagation(); toggle(); }}>
          <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen} onClick={(e) => e.stopPropagation()}>
            <i className="bi bi-three-dots-vertical"></i>
          </DropdownToggle>
          <DropdownMenu end>
            {/*<DropdownItem onClick={toggleEditModal}>{t('Edit Team')}</DropdownItem>*/}
            {newChatTopic && <DropdownItem onClick={newChatTopic}>{t('New Chat')}</DropdownItem>}
            {viewChatTopics && <DropdownItem onClick={viewChatTopics}>{t('Previous Chats')}</DropdownItem>}
            <DropdownItem onClick={() => handleDelete(team.id)}>{t('Delete Team')}</DropdownItem>
            {/*<DropdownItem onClick={startMeeting}>{t('Start meeting')}</DropdownItem>*/}
          </DropdownMenu>
        </Dropdown>
      </StyledDropdown>

      {/*<EditCustomAgentModal isOpen={editModalOpen} toggle={toggleEditModal} agent={agent} />*/}
    </>
  );
}

export default TeamDropDown;
