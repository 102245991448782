import { useCallback, useState } from 'react'
// import { v4 as uuidv4 } from 'uuid';
import { playAudioFromResponse } from '../utils/audioPlayerJSON'
import useStore from '../store'
// import { useWSManager } from '../contexts/WSManagerContext';

const useElevenLabsJSON = (audioRef) => {
  // const audioRef = useRef(null)
  const [audioLoaded, setAudioLoaded] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { csrfToken } = useStore((state) => ({ csrfToken: state.csrfToken }));
  // const componentId = useRef(uuidv4());
  // const { manageSubscriptions } = useWSManager();

  const stop = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.pause()
      audioRef.current.currentTime = 0
      setIsPlaying(false)
    }
  }, [audioRef])

  // 1) Subscribe to the topic-${topicId} channel
  // 2) Send a POST request to /api/topic/${topicId}/tts-elevenlabs-timestamps/ with the user_input
  // 3) Get a push message with alignment
  // 4) Pass audio response and alignment to the playAudioFromResponse function
  // Not sure if order is guaranteed
  const respond = useCallback(async (topicId, user_input, playElevenlabsAlignments) => {
    setIsLoading(true)

    console.log('Responding....', topicId, user_input);
    // Send a POST request to /api/topic/${topicId}/tts-elevenlabs-timestamps/ with the user_input
    const response = await fetch(`/api/topic/${topicId}/response-elevenlabs/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrfToken
      },
      body: JSON.stringify({ user_input })
    })
    console.log('Response', response);

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    console.log('Received response from ElevenLabs API'); // Add this log

    // Play the audio from the response
    playAudioFromResponse(
      response,
      audioRef,
      () => setAudioLoaded(true),
      isPlaying => setIsPlaying(isPlaying),
      isLoading => setIsLoading(isLoading),
      playElevenlabsAlignments
    )
    console.log('Called playAudioFromResponse');
  }, [audioRef, csrfToken])

  return { respond, stop, audioLoaded, isPlaying, isLoading }
}

export default useElevenLabsJSON
