import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import apiService from '../../utils/apiService';
import useStore from '../../store';

function DesignCustomAgentModal({ isOpen, toggle }) {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const { agents, addAgent, updateAgent, language, workspace } = useStore((state) => ({
    agents: state.agents,
    addAgent: state.addAgent,
    updateAgent: state.updateAgent,
    language: state.language,
    workspace: state.workspace,
  }));

  const [agentId, setAgentId] = useState(0);
  const [frame, setFrame] = useState(0);
  const next = () => {
    if (frame === 0) {
      if (validateTaskForm()) {
        setGenteratingTask(true);
        document.getElementById('taskForm').dispatchEvent(new Event('submit', {cancelable: true, bubbles: true}));
        setFrame(frame + 1);
      }
    } else if (frame === 1) {
      if (validateAgentForm()) {
        document.getElementById('agentForm').dispatchEvent(new Event('submit', {cancelable: true, bubbles: true}));
        setFrame(frame + 1);
      }
    } else {
      setFrame(frame + 1);
    }
  };
  const prev = () => {
    setFrame(frame - 1);
  };

  const agentImages = useMemo(() => {
    const configs = window.agent_configs || [];
    const uniqueIds = new Set();
    const uniqueConfigs = configs.filter((config) => {
      if (config.id === 'CUSTOM') return false;
      if (!uniqueIds.has(config.image_id)) {
        uniqueIds.add(config.image_id);
        return true;
      }
      return false;
    });
    return uniqueConfigs.map((config) => ({
      value: config.id,
      label: config.name,
      // url: `/static/avatars/images/${config.id}.png`
      url: `${window.cdn}/images%2F${config.id}.png`
    }));
  }, []);

  const agentPersonalityChoices = useMemo(() => window.agentPersonalityChoices || [], []);
  const [agentData, setAgentData] = useState({
    task: '',
    // name: '-',
    type: 'CUSTOM_AGENT',
    personality: agentPersonalityChoices[0].value,
    image_url: agentImages[0].url,
    language: language,
  });
  const [generatingTask, setGenteratingTask] = useState(false);
  const [taskError, setTaskError] = useState('');
  // const [nameError, setNameError] = useState('');

  /*
  useEffect(() => {
    setAgentData({
      task: '',
      // name: '-',
      type: 'CUSTOM_AGENT',
      personality: agentPersonalityChoices[0].value,
      image: agentImageTypes[0].value,
    });
  }, [agentImageTypes, agentPersonalityChoices]);*/

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAgentData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const validateTaskForm = () => {
    if (!agentData.task.trim()) {
      setTaskError('Task is required');
      return false;
    }
    setTaskError('');
    return true;
  };
  const validateAgentForm = () => {
    /*if (!agentData.name.trim() || agentData.name === '-') {
      setNameError('Name is required');
      return false;
    }
    setNameError('');
    return true;*/
    return true;
  };

  const handleTaskSubmit = async (e) => {
    e.preventDefault();
    if (!validateTaskForm()) return;
    if (generatingTask) return;
    const endpoint = projectId ? `/api/agents/${projectId}/design/` :  `/api/agents/design/`;
    try {
      const response = await apiService.post(endpoint, {...agentData, workspace_id: workspace.id});
      if (response.success) {
        console.log('Agent created successfully');
        setAgentId(response.data.id);
        addAgent(response.data);
      } else {
        console.error('Operation failed:', response);
      }
    } catch (error) {
      console.error('Error during operation:', error);
    }
  };
  const handleAgentSubmit = async (e) => {
    e.preventDefault();
    if (!validateAgentForm()) return;
    const endpoint = `/api/agents/${agentId}/edit/`;
    try {
      const response = await apiService.post(endpoint, {
        name: agentData.name,
        description: agentData.description,
        image_id: agentData.image_id,
        personality: agentData.personality,
      });
      if (response.success) {
        console.log('Agent updated successfully', response.data);
        updateAgent(agentId, response.data);
        toggle();
      } else {
        console.error('Operation failed:', response);
      }
    } catch (error) {
      console.error('Error during operation:', error);
    }
  };

  useEffect(() => {
    if (agents && agents.length) {
      const currAgent = agents.find(agent => agent.id === agentId);
      if (currAgent && currAgent.enabled && generatingTask) {
        setGenteratingTask(false);
      }
    }
  }, [agentId, agents, generatingTask]);



  const languageOptions = useMemo(() => window.languageChoices || [], []);


  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{t('Design Co-worker')}</ModalHeader>
      <ModalBody>
        {frame === 0 && (
          <Form id="taskForm" onSubmit={handleTaskSubmit}>
            <FormGroup>
              <Label for="task" className='fw-bold'>{t('What do you need help with?')}</Label>
              <Input
                type="textarea"
                name="task"
                id="task"
                placeholder={t('Please help me...')}
                value={agentData.task}
                onChange={handleChange}
                invalid={!!taskError} // This marks the input as invalid when there is an error
                disabled={generatingTask}
              />
              <FormFeedback>
                {taskError}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="language" className='fw-bold'>{t('Language')}</Label>
              <Input type="select" name="language" id="language" value={agentData.language} onChange={handleChange}>
                {languageOptions.map((option) => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </Input>
            </FormGroup>
          </Form>
        )}

        {frame === 1 && (
         <Form id="agentForm" onSubmit={handleAgentSubmit}>
            {/*<FormGroup>
              <Label for="name" className='fw-bold'>{t('Name')}</Label>
              <Input
                type="text"
                name="name"
                id="name"
                placeholder={t('Enter agent name')}
                value={agentData.name}
                onChange={handleChange}
                invalid={!!nameError} // This marks the input as invalid when there is an error
              />
              <FormFeedback>
                {nameError}
              </FormFeedback>
            </FormGroup>*/}
            <FormGroup>
              <Label for="image" className='fw-bold'>{t('Image')}</Label>
              <div className="image-carousel position-relative">
                {agentImages.length > 0 && (
                  <>
                    <Button className="position-absolute top-50 start-0 translate-middle-y" style={{ zIndex: 1 }} onClick={() => setAgentData(prev => ({
                      ...prev,
                      image_id: agentImages[(agentImages.findIndex(img => img.url === agentData.image_url) - 1 + agentImages.length) % agentImages.length].value,
                      image_url: agentImages[(agentImages.findIndex(img => img.url === agentData.image_url) - 1 + agentImages.length) % agentImages.length].url
                    }))}>{"<"}</Button>
                    <img
                      src={agentData.image_url}
                      alt={`Avatar #${agentImages.findIndex(img => img.url === agentData.image_url).id}`}
                      className="w-100"
                      style={{ height: 'auto' }}
                    />
                    <Button className="position-absolute top-50 end-0 translate-middle-y" style={{ zIndex: 1 }} onClick={() => setAgentData(prev => ({
                      ...prev,
                      image_id: agentImages[(agentImages.findIndex(img => img.url === agentData.image_url) + 1) % agentImages.length].value,
                      image_url: agentImages[(agentImages.findIndex(img => img.url === agentData.image_url) + 1) % agentImages.length].url
                    }))}>{">"}</Button>
                  </>
                )}
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="personality" className='fw-bold'>{t('Personality')}</Label>
              <Input
                type="select"
                name="personality"
                id="personality"
                value={agentData.personality}
                onChange={handleChange}
              >
                {agentPersonalityChoices.map((option) => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </Input>
            </FormGroup>
          </Form>
        )}
      </ModalBody>
      <ModalFooter className='d-flex justify-content-end'>
          {frame > 0 && <Button color="primary" onClick={prev} disabled={frame === 0}>{t('Back')}</Button>}
          {frame === 0 && <Button color="primary" onClick={next}>{t('Next')}</Button>}
          {frame === 1 && <Button color="primary" disabled={generatingTask} onClick={handleAgentSubmit}>{generatingTask ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> {t('Generating')}</> : t('Done')}</Button>}
      </ModalFooter>
    </Modal>
  );
}

export default DesignCustomAgentModal;
