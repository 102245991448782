import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { Row, Col, Carousel, CarouselItem, CarouselControl, CarouselIndicators, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { StyledCarouselCaption } from './AgentsPage.styles.js'; // Import styled components
import apiService from '../../utils/apiService';
import AgentsList from '../../components/AgentsList/AgentsList';
import useStore from '../../store';
import EditCustomAgentModal from '../../components/EditCustomAgentModal/EditCustomAgentModal';
import DesignCustomAgentModal from '../../components/DesignCustomAgentModal/DesignCustomAgentModal.js';
import FlexLoader from '../../components/FlexLoader/FlexLoader';

function AgentsPage() {
  const { t } = useTranslation();
  const { addAgent, remAgent, project, agents, workspace } = useStore((state) => ({
     addAgent: state.addAgent, remAgent: state.remAgent,
     project: state.project, agents: state.agents,
     workspace: state.workspace
  }));
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const agent_configs = useMemo(() => window.agent_configs.map((a) => ({
    ...a,
    id: a.id,
    type: a.type || 'PREDEFINED_AGENT',
    // image_url: `/static/avatars/images/${a.image_id}.png`,
    image_url: `${window.cdn}/images%2F${a.image_id}.png`,
    // image_anim_url : `/static/avatars/images-anim/${a.image_id}.mp4`
    image_anim_url: `${window.cdn}/images-anim%2F${a.image_id}.mp4`
  })), []);

  const next = () => {
    console.log('next', animating, activeIndex, agent_configs.length);
    if (animating) return;
    const nextIndex = activeIndex === agent_configs.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? agent_configs.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = useCallback((newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }, [animating]);

  const handleAddPredefined = useCallback(async (agentId) => {
    try {
      let response;
      if (project && project.id) {
        const projectId = project.id;
        response = await apiService.post(`/api/agents/${projectId}/add-predefined/${agentId}/`, { workspace_id: workspace?.id });
      } else {
        response = await apiService.post(`/api/agents/add-predefined/${agentId}/`, { workspace_id: workspace?.id });
      }
      if (!response.success) {
        console.error('Operation failed:', response.message);
        return;
      }
      const agent = response.data.agent;
      addAgent(agent);
    } catch (error) {
      console.error(error);
    }
  }, [project, addAgent, workspace?.id]);

  const handleDelete  = useCallback( async (agentId) => {
    console.log('Deleting agent with id:', agentId);
    try {
      const response = await apiService.delete(`/api/agents/${agentId}/delete/`);
      if (!response.success) {
        console.error('Operation failed:', response.message);
        return;
      }
      console.log('Agent deleted successfully');
      remAgent(agentId);
    } catch (error) {
      console.error('Error during operation:', error);
    }
  }, [remAgent]);

  const handleAddToTeam = useCallback(async (agentId) => {
    if (!project) {
      console.error('No project found');
      return;
    }
    try {
      const projectId = project.id;
      console.log('Adding agent with id:', agentId, 'to team in project', projectId);
      const response = await apiService.post(`/api/agents/${projectId}/add/${agentId}/`);
      if (!response.success) {
        console.error('Operation failed:', response.message);
        return;
      }
      console.log('Agent added successfully!');
    } catch (error) {
      console.error(error);
    }
  }, [project]);

  const handleRemFromTeam = useCallback( async (agentId) => {
    if (!project) { console.error('No project found'); return; }
    console.log('Removing agent with id:', agentId);
    try {
      const response = await apiService.delete(`/api/agents/${project.id}/remove/${agentId}/`);
      if (!response.success) {
        console.error('Operation failed:', response.message);
        return;
      }
      console.log('Agent removed successfully');
      remAgent(agentId);
    } catch (error) {
      console.error('Error during operation:', error);
    }
  }, [project, remAgent]);

  const agentsInWorkspace = useMemo(() => agents && workspace && agents.filter((a) => a.workspace_id === workspace?.id && !(a.roles && a.roles.includes("GENERAL_GPT"))), [agents, workspace]);
  const agentsInProject = useMemo(() => agents && agents.filter((a) => {
    if (a === undefined || a === null) {console.log('OOPS UNDEF AGENT', agents);return false;}
    if (a.roles && a.roles.includes("GENERAL_GPT")) return false;
    return project && project.id && a.project_ids  && a.project_ids.length && a.project_ids.includes(project.id);
  }), [agents, project]);
  const agentsNotInProject = useMemo(() => {
    if (!agents || !project) return [];
    return agents.filter((a) => 
      project && project.id && 
      (!a.project_ids.length || !a.project_ids.includes(project.id)) && 
      !(a.roles && a.roles.includes("GENERAL_GPT"))
    );
  }, [agents, project]);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const toggleEditModal = () => setEditModalOpen(!editModalOpen);
  const [editModalAgent, setEditModalAgent] = useState(undefined);

  const [designModalOpen, setDesignModalOpen] = useState(false);
  const toggleDesignModal = useCallback(() => setDesignModalOpen(!designModalOpen), [designModalOpen]);
  const [designModalCounter, setDesignModalCounter] = useState(0);
  const handleDesignCoWorker = useCallback(() => {
    setDesignModalCounter(designModalCounter + 1);
    toggleDesignModal();
  }, [designModalCounter, toggleDesignModal]);
  const handleEditCoWorker = (agentId) => {
    const mAgent = agents.find((a) => a.id === agentId);
    setEditModalAgent(mAgent);
    toggleEditModal();
  };

  const videoRefs = useRef([]);

  useEffect(() => {
    const loadVideos = () => {
      videoRefs.current.forEach((video, index) => {
        if (video && index !== activeIndex) {
          video.load();
        }
      });
    };

    loadVideos();
  }, [activeIndex]);

  const slides = useMemo(() => agent_configs.map((item, index) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={`carousel-item-${index}`}
      >
        {item.image_anim_url ? (
          <video
            ref={el => videoRefs.current[index] = el}
            src={index === activeIndex ? item.image_anim_url : ''}
            poster={item.image_url}
            alt={item.name}
            className="d-block w-100"
            autoPlay={index === activeIndex}
            loop
            muted
            playsInline
          />
        ) : (
          <img src={item.image_url} alt={item.name} className="d-block w-100" />
        )}
        <StyledCarouselCaption className="text-center gradient-bg">
          <h5>{item.name}</h5>
          <p>{item.description}</p>
          {item.type !== 'CUSTOM_AGENT' && (
            <Button className="add-member-button btn-primary btn-sm mb-4" onClick={() => handleAddPredefined(item.id)}>
              <i className="bi bi-person-plus-fill"></i> {t('Add to team')}
            </Button>
          )}
        </StyledCarouselCaption>
      </CarouselItem>
    );
  }), [agent_configs, handleAddPredefined, t, activeIndex]);

  if(!agents) return (<FlexLoader />);

  return (<>
    <Row>
      <Col sm="12">
        <h1>{t('Add co-worker')}</h1>
        <Carousel
          activeIndex={activeIndex}
          next={next}
          previous={previous}
          className='mb-4'
          ride="carousel"
          interval={false}
        >
          <CarouselIndicators items={agent_configs} activeIndex={activeIndex} onClickHandler={goToIndex} />
          {slides}
          <CarouselControl direction="prev" directionText={t('Previous')} onClickHandler={previous} />
          <CarouselControl direction="next" directionText={t('Next')} onClickHandler={next} />
        </Carousel>
      </Col>
    </Row>
    {!project && <>
      <div className='d-flex'>
        <h2 className='flex-grow-1'>{t('Digital co-workers')}</h2>
        <Button className="add-member-button btn-primary btn-sm mb-4" onClick={() => handleDesignCoWorker()}>
          <i className="bi bi-person-plus-fill"></i> {t('Design co-worker')}
        </Button>
      </div>
      {agentsInWorkspace && agentsInWorkspace.length === 0 && (<p>{t('No agents in the team yet.')}</p>)}
      <AgentsList agents={agentsInWorkspace} workspace={workspace} project={project} handleDelete={handleDelete} handleRemFromTeam={handleRemFromTeam} handleEditCoWorker={handleEditCoWorker} />
    </>}
    {project && <>
      <div className='d-flex'>
        <h2 className='flex-grow-1'>{t('Our project team')}</h2>
        <Button className="add-member-button btn-primary btn-sm mb-4" onClick={() => handleDesignCoWorker()}>
          <i className="bi bi-person-plus-fill"></i> {t('Design co-worker')}
        </Button>
      </div>
      {agentsInProject && agentsInProject.length === 0 && (<p>{t('No agents in the team yet.')}</p>)}
      <AgentsList agents={agentsInProject} workspace={workspace} project={project} handleDelete={handleDelete} handleRemFromTeam={handleRemFromTeam} handleEditCoWorker={handleEditCoWorker} />
    </>}
    {agentsNotInProject && agentsNotInProject.length > 0 && <>
      <h4>{t('Available agents')}</h4>
      <AgentsList agents={agentsNotInProject} enableRun={false} workspace={workspace} project={project} handleDelete={handleDelete} handleAddToTeam={handleAddToTeam} handleEditCoWorker={handleEditCoWorker} />
    </>}
    <EditCustomAgentModal isOpen={editModalOpen} toggle={toggleEditModal} agent={editModalAgent} />
    <DesignCustomAgentModal key={`design-custom-agent-modal-${designModalCounter}`} isOpen={designModalOpen} toggle={toggleDesignModal} />
  </>);
}

export default AgentsPage;
