import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import apiService from '../../utils/apiService';
import useStore from '../../store';

function LoginPage() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const setLoginEmail = useStore((state) => state.setLoginEmail); // Get setEmail from Zustand store
  const setIsSuperUser = useStore((state) => state.setIsSuperUser);
  const setUserId = useStore((state) => state.setUserId);
  const language = useStore((state) => state.language);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await apiService.post('/api/login/', { email, language });
      if (response.success) {
        console.log('LoginPage login successful:', response);
        setLoginEmail(email); // Update email in global state
        setIsSuperUser(response.data.is_superuser);
        setUserId(response.data.user_id);
        if (response.data.user_exists) {
          navigate('/login-pin'); } // Redirect to /login-pin
        else { navigate('/register-pin'); } // Redirect to /register-pin
      } else {
        console.error('Login failed:', response.message);
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  return (
    <div>
      <h2>Login</h2>
      <Row>
        <Col sm="12" md={{ size: 6 }}>
          <Form onSubmit={handleLogin}>
            <FormGroup>
              <Label for="email">{t('Email')}</Label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder={t('Enter your email')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
            <Button color="primary" type="submit">{t('Submit')}</Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default LoginPage;
