import React from 'react';
import { Button, Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import apiService from '../../utils/apiService';
import useStore from '../../store'; // Import useStore

function LogoutPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    setLoggedIn,
    setIsSuperUser,
    setIsVerified,
    setUserId,
    setEmail,
    setUsername,
    setOrganization,
    setSubscribed,
    setDaysLeftOnTrial,
    setWorkspace,
    setWorkspaces
  } = useStore(state => ({
    setLoggedIn: state.setLoggedIn,
    setIsSuperUser: state.setIsSuperUser,
    setIsVerified: state.setIsVerified,
    setUserId: state.setUserId,
    setEmail: state.setEmail,
    setUsername: state.setUsername,
    setOrganization: state.setOrganization,
    setSubscribed: state.setSubscribed,
    setDaysLeftOnTrial: state.setDaysLeftOnTrial,
    setWorkspace: state.setWorkspace,
    setWorkspaces: state.setWorkspaces
  }));

  const handleLogout = async () => {
    try {
      const response = await apiService.post('/api/logout/', {});
      if (response.success) {
        console.log('Logout successful:', response);
        setEmail('');
        setLoggedIn(false);
        setIsSuperUser(false);
        setUserId('');
        setIsVerified(false);
        setOrganization(null);
        setSubscribed(false);
        setDaysLeftOnTrial(0);
        setUsername('');
        setWorkspaces(null);
        setWorkspace(null);
        navigate('/'); // Redirect to home page
      } else {
        console.error('Logout failed:', response.message);
      }
    } catch (error) {
      console.error('Error during Logout:', error);
    }
  };

  return (
    <div>
      <h2>{t('Are you sure you want to logout?')}</h2>
      <Row>
        <Col sm="12">
          <Button color="danger" onClick={handleLogout}>{t('Logout')}</Button>
        </Col>
      </Row>
    </div>
  );
}

export default LogoutPage;
