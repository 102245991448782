import React, { createContext, useState, useContext, useCallback } from 'react';
import ModalVideoCall from '../components/ModalVideoCall/ModalVideoCall';

const ModalVideoCallContext = createContext();

export const ModalVideoCallProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState({ title: '', agents: [] });

  const showModal = useCallback((title, agents) => {
    setModalProps({ title, agents });
    setIsOpen(true);
  }, []);

  const hideModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <ModalVideoCallContext.Provider value={{ showModal, hideModal }}>
      {children}
      <ModalVideoCall isOpen={isOpen} toggle={hideModal} {...modalProps} />
    </ModalVideoCallContext.Provider>
  );
};

export const useModalVideoCall = () => useContext(ModalVideoCallContext);