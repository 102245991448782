import styled, { css } from 'styled-components';

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;

  @media print {
    display: none; // Example: Hide the header when printing
  }
`;

// You can also create a styled component specifically for the printable area
export const PrintableArea = styled.div`
  @media print {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    margin: 20mm;  // Adds margin around the content
    padding: 10mm; // Adds padding inside the content area
    box-sizing: border-box; // Includes padding and border in the element's total width and height
    font-size: 12pt; // Sets a readable font size for printing
    line-height: 1.5; // Improves readability with increased line spacing
  }
`;

// General print settings to hide everything else
export const GlobalPrintStyles = css`
  @media print {
    body * {
      visibility: hidden;
    }
    #printableArea, #printableArea * {
      visibility: visible;
    }
  }
`;
