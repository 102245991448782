import React, { createContext, useState, useContext, useCallback } from 'react';
import ModalWorkflowEditor from '../components/ModalWorkflowEditor/ModalWorkflowEditor';

const ModalWorkflowEditorContext = createContext();

export const ModalWorkflowEditorProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState({ title: '', message: {} });

  const showModal = useCallback((title, message) => {
    setModalProps({ title, message });
    setIsOpen(true);
  }, []);

  const hideModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <ModalWorkflowEditorContext.Provider value={{ showModal, hideModal }}>
      {children}
      <ModalWorkflowEditor isOpen={isOpen} toggle={hideModal} {...modalProps} />
    </ModalWorkflowEditorContext.Provider>
  );
};

export const useModalWorkflowEditor = () => useContext(ModalWorkflowEditorContext);
