import React, { createContext, useState, useContext, useCallback } from 'react';
import CreateWorkflowModal from '../components/CreateWorkflowModal/CreateWorkflowModal';

const ModalCreateWorkflowContext = createContext();
 
export const ModalCreateWorkflowProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState({
    workflow_template_name: '',
    isCustom: false,
    type: 'CUSTOM_WORKFLOW_MANUAL',
    agents: [],
    generation_template_id: null,
  });

  const showModal = useCallback((props) => {
    setModalProps(props);
    setIsOpen(true);
  }, []);
 
  const hideModal = useCallback(() => {
    setIsOpen(false);
    setModalProps({
      workflow_template_name: '',
      isCustom: false,
      type: 'CUSTOM_WORKFLOW_MANUAL',
      agents: [],
      generation_template_id: null
    });
  }, []); 

  return (
    <ModalCreateWorkflowContext.Provider value={{ showModal, hideModal }}>
      {children}
      <CreateWorkflowModal 
        isOpen={isOpen} 
        toggle={hideModal} 
        workflow_template_name={modalProps.workflow_template_name}
        isCustom={modalProps.isCustom}
        type={modalProps.type}
        agents={modalProps.agents}
        generation_template_id={modalProps.generation_template_id}
      />
    </ModalCreateWorkflowContext.Provider>
  );
};

export const useModalCreateWorkflow = () => useContext(ModalCreateWorkflowContext);
