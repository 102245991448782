import React from 'react';
import { Row, Col } from 'reactstrap';
import Agent from '../Agent/Agent'; // Adjust the path as necessary

const AgentsList = ({ agents, workspace, project, handleDelete, handleRemFromTeam, handleEditCoWorker, handleAddToTeam, enableRun = true }) => {
  return (
    <Row>
      {agents && agents.map(agent => (
        <Col sm="12" key={agent.id}>
          {agent.type === 'CUSTOM_AGENT' ? (
            agent.enabled && <Agent agent={agent} workspace={workspace} project={project} handleDelete={handleDelete} handleRemFromTeam={handleRemFromTeam} handleEditCoWorker={handleEditCoWorker} handleAddToTeam={handleAddToTeam} enableRun={enableRun} />
          ) :
          (<Agent agent={agent} workspace={workspace} project={project} handleDelete={handleDelete} handleRemFromTeam={handleRemFromTeam} handleAddToTeam={handleAddToTeam} handleEditCoWorker={handleEditCoWorker} enableRun={enableRun} />)}
        </Col>
      ))}
    </Row>
  );
};

export default AgentsList;
