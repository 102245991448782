import React from 'react';
import { Row, Col } from 'reactstrap';
import Team from '../Team/Team'; // Adjust the path as necessary

const TeamsList = ({ teams, handleDelete }) => {
  return (
    <Row>
      {teams && teams.map((team) => (
        <Col sm="12" key={team.id}>
          <Team
            team={team}
            handleDelete={handleDelete}
          />
        </Col>
      ))}
    </Row>
  );
};

export default TeamsList;
