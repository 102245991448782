// Used by Elevenlabs
const characterToVisemeMapping = {
  'a': 'viseme_aa', 'A': 'viseme_aa',
  'b': 'viseme_PP', 'B': 'viseme_PP',
  'c': 'viseme_SS', 'C': 'viseme_SS',
  'd': 'viseme_DD', 'D': 'viseme_DD',
  'e': 'viseme_E', 'E': 'viseme_E',
  'f': 'viseme_FF', 'F': 'viseme_FF',
  'g': 'viseme_kk', 'G': 'viseme_kk',
  'h': 'viseme_kk', 'H': 'viseme_kk',
  'i': 'viseme_I', 'I': 'viseme_I',
  'j': 'viseme_I', 'J': 'viseme_I',
  'k': 'viseme_kk', 'K': 'viseme_kk',
  'l': 'viseme_DD', 'L': 'viseme_DD',
  'm': 'viseme_PP', 'M': 'viseme_PP',
  'n': 'viseme_DD', 'N': 'viseme_DD',
  'o': 'viseme_O', 'O': 'viseme_O',
  'p': 'viseme_PP', 'P': 'viseme_PP',
  'q': 'viseme_kk', 'Q': 'viseme_kk',
  'r': 'viseme_RR', 'R': 'viseme_RR',
  's': 'viseme_SS', 'S': 'viseme_SS',
  't': 'viseme_DD', 'T': 'viseme_DD',
  'u': 'viseme_U', 'U': 'viseme_U',
  'v': 'viseme_FF', 'V': 'viseme_FF',
  'w': 'viseme_U', 'W': 'viseme_U',
  'x': 'viseme_kk', 'X': 'viseme_kk',
  'y': 'viseme_I', 'Y': 'viseme_I',
  'z': 'viseme_SS', 'Z': 'viseme_SS',
  'å': 'viseme_O', 'Å': 'viseme_O',
  'ä': 'viseme_E', 'Ä': 'viseme_E',
  'ö': 'viseme_O', 'Ö': 'viseme_O',
  ',': 'viseme_sil', '.': 'viseme_sil',
  '?': 'viseme_sil', '!': 'viseme_sil',
  ' ': 'viseme_sil'
};

class Visemes {
  _postProcessVisemes(visemes, maxDuration = 45) {
    return visemes.reduce((acc, curr, index) => {
      if (index === 0) {
        return [curr];
      }

      const prev = acc[acc.length - 1];

      if (prev.key === 'viseme_sil') {
        acc.push(curr);
        return acc;
      }
      if (prev.key !== 'viseme_sil' && curr.key !== 'viseme_sil' && curr.duration < maxDuration) {
        prev.duration += curr.duration;
      } else {
        acc.push(curr);
      }

      return acc;
    }, []);
  }

  processElevenlabsAlignments(alignments) {
    if (!alignments) {
      console.log('Invalid alignments data:', alignments);
      return [];
    }
    const visemes = alignments.characters.map((char, index) => {
      const key = characterToVisemeMapping[char] || 'viseme_sil';
      const time = alignments.character_start_times_seconds[index] * 1000; // convert to milliseconds
      const duration = (alignments.character_end_times_seconds[index] - alignments.character_start_times_seconds[index]) * 1000; // convert to milliseconds
      return { key, time, duration };
    });

    return this._postProcessVisemes(visemes);
  }


  processMillisAlignments(alignments) {
    if (!alignments || !alignments.chars || !alignments.character_start_time_seconds || !alignments.character_end_time_seconds) {
      console.log('Invalid alignments data:', alignments);
      return [];
    }
  
    const currentTime = Date.now();
    const firstCharTime = alignments.character_start_time_seconds[0];
    
    if (this.startingTime === null || firstCharTime < 100) { // Assuming times less than 100ms indicate a new sequence
      this.startingTime = currentTime - firstCharTime;
    }
  
    const visemes = alignments.chars.map((char, index) => {
      const key = characterToVisemeMapping[char] || 'viseme_sil';
      const time = alignments.character_start_time_seconds[index] + (this.startingTime - currentTime);
      const duration = alignments.character_end_time_seconds[index] - alignments.character_start_time_seconds[index] || 1;
      return { key, time, duration };
    }).filter(viseme => viseme.time >= 0);
  
    return this._postProcessVisemes(visemes, 50);
  }

}

export default Visemes;
