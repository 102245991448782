import styled from 'styled-components';
import { Modal, ModalHeader, ModalFooter } from 'reactstrap';

export const StyledModal = styled(Modal)`
  max-width: ${props => props.$isSharedScreenActive ? '98vw' : '90vw'};
  width: ${props => props.$isSharedScreenActive ? '98vw' : '90vw'};
  height: 90vh;
  background-color: #303030;
  color: white;

  .modal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .modal-header, .modal-content {
    background-color: inherit;
    color: inherit;
  }
`;

export const StyledModalHeader = styled(ModalHeader)`
  .btn-close {
    color: #FFF; // Change this to the color you want for the close button
    opacity: 1; // Adjust if you want to change the visibility

    &:hover {
      color: #CCC; // Change this to the color you want on hover
    }
  }
`;

export const StyledModalBody = styled.div`
  display: flex;
  height: ${props => props.$isSharedScreenActive ? '70vh' : '70vh'};
  overflow: hidden;
`;

export const StyledModalFooter = styled(ModalFooter)`
  display: flex;
  justify-content: flex-start; // Align items to the left
  flex-wrap: wrap; // Allow items to wrap onto the next line if needed
  align-items: center; // Align items vertically in the center

  > div {
    flex-grow: 1; // Allow the div to take up any remaining space
  }
`;

export const AgentContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center; // Center when not in shared screen mode
  align-items: center;
`;

export const SharedScreenContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  max-height: 100%;
`;

export const AvatarColumn = styled.div`
  width: 200px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding-top: 1em;
  
  // Make avatars smaller and adjust container
  > div {
    transform: scale(0.5);
    transform-origin: top center;
    margin-top: -100px;
    
    // Center the name text
    .agent-name {
      text-align: center;
      width: 200%;
      margin-left: -50%;
    }
    
    &:first-child {
      margin-top: 0;
    }
  }
`;
