import React, { useState, useMemo} from 'react';
import { useNavigate } from 'react-router-dom'; // Add this import at the top
import { useTranslation } from 'react-i18next';

import { Row, Col, CardBody, Button, Dropdown, DropdownMenu, DropdownItem } from 'reactstrap';
import apiService from '../../utils/apiService';
import { AgentCard, AgentText, CustomDropdownToggle } from './WorkflowAgent.styles.js';

const WorkflowAgent = ({ agent, workspace, project, document, enableRun = true, showDropDown = true}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isRunning = project.is_workflowing;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);
  const [type, setType] = useState(project.type);

  const handleStartAgent = async (projectId, agentId) => {
    if (!projectId || !agentId) {
      console.error('Project ID or Agent ID is missing:', projectId, agentId);
      return;
    }
    try {
      const response = await apiService.post(`/api/workflows/${project.id}/start-workflow/`);
      if (response.success) {
        console.log('Operation successful:', response.message);
      } else {
        console.error('Operation failed:', response.message);
        return;
      }
    } catch (error) {
      console.error('handleStartAgent error', error);
    }
  };

  const handleStopAgent = async (agentId) => {
    if (!agentId) {
      console.error('Agent ID is missing:', agentId);
      return;
    }
    try {
      const response = await apiService.post(`/api/workflows/${project.id}/stop-workflow/`);
      if (!response.success) {
        console.error('Operation failed:', response.message);
        return;
      }
      // Assuming you want to update the agent's status in your local state as well
      // updateAgentStatus(agentId, 'stopped'); // Implement this function based on your state management
    } catch (error) {
      console.error('handleStopAgent error', error);
    }
  };

  const handleSetProjectType = async (newType) => {
    try {
      const response = await apiService.post(`/api/workflows/${project.id}/change-type/`, { type: newType });
      if (!response.success) {
        console.error('Operation failed:', response.message);
        return;
      }
      setType(newType);
    } catch (error) {
      console.error('handleStopAgent error', error);
    }
  };

  const isWaitingForApproval = useMemo(() => {
    if(!document) return false;
    return document.task_status === 'WAITING_FOR_APPROVAL';
  }, [document]);

  return (
    <AgentCard className="card mb-3">
      {/*(handleRemFromTeam || handleEditCoWorker || handleDelete) && showDropDown && (
        <StyledDropdown>
          <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
              <i className="bi bi-three-dots-vertical"></i>
            </DropdownToggle>
            <DropdownMenu end>
              {handleEditCoWorker && <DropdownItem onClick={() => handleEditCoWorker(agent.id)}>{t('Edit')}</DropdownItem>}
              {project && project.id && handleRemFromTeam && <DropdownItem onClick={() => handleRemFromTeam(agent.id)}>{t('Remove')}</DropdownItem>}
              {handleDelete && <DropdownItem onClick={() => handleDelete(agent.id)}>{t('Delete')}</DropdownItem>}
            </DropdownMenu>
          </Dropdown>
        </StyledDropdown>
      )*/}
      <Row className="g-0">
        <Col sm="12" md={{ size: 3 }}>
          <div
            style={{
              backgroundImage: `url(${agent.image_url})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '100%', // Adjust the height as needed
              width: '100%', // Adjust the width as needed
              minHeight: '150px',
              cursor: 'pointer', // Make it visually obvious it's clickable
            }}
            className="rounded-start"
            alt={agent.name}
            onClick={() => {
              navigate(`/project/${project.id}/coworker/${agent.id}/`);
            }}
          ></div>
        </Col>
        <Col>
          <CardBody className='p-3'>
          {isWaitingForApproval && (<h5 className='p-0 mb-2'><span className="bi bi-exclamation-triangle-fill text-warning"></span> {t('Waiting for approval')}</h5>)}
          {!isWaitingForApproval && (<h5 className='p-0 mb-2'>{document.title}</h5>)}
            {isRunning && (<p>
              {agent.name}: {agent.doing}
            </p>)}
            {isWaitingForApproval && (<p>
              {agent.name}:   {t('Would you like to request changes or approve this task?')}
            </p>)}
            <AgentText>
              {isRunning ? (
                <>
                  <Button size="sm" color="danger" onClick={() => handleStopAgent(agent.id)}>
                    <i className="bi bi-stop-fill"></i> {t('Stop')}
                  </Button>
                </>
              ) : (
                <>
                  {project && project.id && enableRun &&
                  <>
                    {document && document.template_type === 'UPLOAD' && (<div className='mb-2'>
                      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                        <CustomDropdownToggle caret>
                          {type === 'CUSTOM_WORKFLOW_MANUAL' ? t('Manual: approve each task') : t('Automatic: run all tasks non stop')}
                        </CustomDropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={()=>{handleSetProjectType('CUSTOM_WORKFLOW_MANUAL')}}>
                            {t('Manual: approve each task')}
                          </DropdownItem>
                          <DropdownItem onClick={()=>{handleSetProjectType('CUSTOM_WORKFLOW_AUTOMATIC')}}>
                            {t('Automatic: run all tasks non stop')}
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>)}
                    <Button size="sm" color="success" onClick={() => handleStartAgent(project.id, agent.id)}>
                      <i className="bi bi-play-fill"></i> {isWaitingForApproval ? t('Approve') : t('Start')}
                    </Button>
                  </>
                  }
                </>
              )}
            </AgentText>
          </CardBody>
        </Col>
      </Row>
    </AgentCard>
  );
};

export default WorkflowAgent;
