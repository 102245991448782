import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Add this import at the top
import { useTranslation } from 'react-i18next';

import { Row, Col, CardBody, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import apiService from '../../utils/apiService';
import { StatusCard, StyledDropdown } from './WorkflowTaskStatus.styles.js';
import ModalConfirm from '../../components/ModalConfirm/ModalConfirm';
import { useModalCreateWorkflow } from '../../contexts/ModalCreateWorkflowContext';

const WorkflowTaskStatus = ({ project, document, showDropDown = true, handleGenerateResource = null, showGenerateResource = false, nextSteps = [] }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  console.log('WorkflowTaskStatus', document)
  // Functions for Confirm Modal
  const [confirmModal, setConfirmModal] = useState(false);
  const toggleConfirmModal = () => {
    setConfirmModal(!confirmModal);
  };

  const { showModal } = useModalCreateWorkflow();

  const allDocuments = useMemo(() => {
    if(!project) return [];
    return project.stages.flatMap(stage => stage.documents);
  }, [project]);

  const activeDocument = useMemo(() => {
    if(!allDocuments.length) return null;
   // Find first document that is not done
    const firstNotDone = allDocuments.find((doc) => !doc.is_done);
    return firstNotDone;
  }, [allDocuments]);

  const [tagAlongActiveDocument, setTagAlongActiveDocument] = useState(false);
  useEffect(() => {
    if(!tagAlongActiveDocument) return;
    if(document && activeDocument && document.id !== activeDocument.id) {
      navigate(`/project/${project.id}/document/${activeDocument.id}`);
      setTagAlongActiveDocument(false);
    }
  }, [document, activeDocument, navigate, project?.id, tagAlongActiveDocument]);
  useEffect(() => {
    if(project && project.super_type === "WORKFLOW" && project.is_workflowing) {
      setTagAlongActiveDocument(true);
    }
  }, [project, project?.is_workflowing]);

  const finalResultDocument = useMemo(() => {
    if(!allDocuments.length) return null;
    return allDocuments.find((doc) => doc.template_type === 'FINAL_RESULT');
  }, [allDocuments]);

  const lastDocumentInWorkflow = useMemo(() => {
    if(!allDocuments.length) return null;
    return allDocuments[allDocuments.length - 1];
  }, [allDocuments]);

  const isLastDocumentInWorkflowAndDone = useMemo(() => {
    if(!document) return false;
    // return lastDocumentInWorkflow && lastDocumentInWorkflow.id === document.id && document.is_done;
    return document.template_type === 'FINAL_RESULT';
  }, [document]);

  const isAfterActiveDocument = useMemo(() => {
    if (!activeDocument || !document) return false;
    const activeIndex = allDocuments.findIndex(doc => doc.id === activeDocument.id);
    const currentIndex = allDocuments.findIndex(doc => doc.id === document.id);
    return currentIndex > activeIndex;
  }, [allDocuments, document, activeDocument]);

  const statusLabel = useMemo(() => {
    if(!document) return ''
    if (document.template_type === 'FINAL_RESULT') {
      return t('This workflow has been completed.');
    }
    if (lastDocumentInWorkflow && lastDocumentInWorkflow.id === document.id && document.task_status === 'DONE') {
      if (document.is_approved) return t('This workflow has been approved.');
      return t('This workflow has been completed.');
    }
    if (document.task_status === 'DONE') {
      if (document.is_approved) return t('This task has been approved.');
      return t('This task has been completed.');
    }
    return t('A prior active task is not yet completed.');
  }, [document, lastDocumentInWorkflow, t]);

  const handleGoToActiveTask = useCallback (() => {
    navigate(`/project/${project.id}/document/${activeDocument.id}`);
  }, [navigate, project?.id, activeDocument?.id]);

  const handleGoToFinalResult = useCallback (() => {
    navigate(`/project/${project.id}/document/${finalResultDocument.id}`);
  }, [navigate, project?.id, finalResultDocument?.id]);

  const handleRevertRequest = useCallback(() => {
    setConfirmModal(true);
  }, []);

  const doRevert = async () => {
    const endpoint = `/api/workflows/revert/${project.id}/${document.id}/`;
    try {
      const response = await apiService.post(endpoint, {});
      if (response.success) {
        console.log('Workflow successfully reset', response.data);
      } else {
        console.error('Operation failed:', response.message);
      }
    } catch (error) {
      console.error('Error during operation:', error);
    }
  };

  const showCreateWorkflowModal = (name) => {
    showModal({
      workflow_template_name: name,
      isCustom: true,
      type: 'CUSTOM_WORKFLOW_MANUAL'
    });
  };

  if (!project || !document) {
    return null;
  }
  return (
    <>
       <StatusCard className={`card mb-3 alert ${isAfterActiveDocument ? 'alert-danger' : 'alert-success'} p-0`}>
        {showDropDown && (
          <StyledDropdown>
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
              <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
                <i className="bi bi-three-dots-vertical"></i>
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem onClick={() => {}}>{t('...')}</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </StyledDropdown>
        )}
        <Row className="g-0">
          <Col>
            <CardBody className='p-3'>
              <div className='p-0 d-flex align-items-center'>
                <div className='flex-grow-1'>
                  {statusLabel}
                  {isLastDocumentInWorkflowAndDone && !showGenerateResource && nextSteps.length > 0 && (
                    <>
                      &nbsp;
                      {t('Suggested next workflows:')}
                      <div className='d-flex flex-column gap-1'>
                        {nextSteps.map((step, index) => (
                          <Button size="sm" color="primary" onClick={() => showCreateWorkflowModal(step)} key={`next-step-${index}`}>
                            {step}
                          </Button>
                        ))}
                        <Button size="sm" color="secondary" onClick={() => showCreateWorkflowModal("")} key="next-step-custom">
                          {t('Define a custom workflow')}
                        </Button>
                      </div>
                    </>
                  )}
                </div>
                <div className='d-flex gap-1 mt-2'>
                  {!isLastDocumentInWorkflowAndDone && !isAfterActiveDocument && 
                    <Button size="sm" color="secondary" onClick={handleRevertRequest}>
                      <i className="bi bi-pencil-fill"></i> {t('Revert')}
                    </Button>}
                  {isLastDocumentInWorkflowAndDone && showGenerateResource && handleGenerateResource &&
                    <Button size="sm" color="primary" onClick={handleGenerateResource}>
                      <i className="bi bi-save2-fill"></i> {t('Save')}
                    </Button>}
                  {activeDocument && 
                    <Button size="sm" color="primary" onClick={handleGoToActiveTask}>
                      <i className="bi bi-fast-forward-fill"></i> {t('Go to active task')}
                    </Button>}
                  {finalResultDocument && finalResultDocument.id !==document.id && (
                    <Button size="sm" color="primary" onClick={handleGoToFinalResult}>
                      <i className="bi bi-fast-forward-fill"></i> {t('View final result')}
                    </Button>
                  )}
                </div>
              </div>
            </CardBody>
          </Col>
        </Row>
      </StatusCard>
      <ModalConfirm isOpen={confirmModal} toggle={toggleConfirmModal} title={t('Revert to this task')} onConfirm={doRevert} confirmButtonColor='danger'>
        <p>{t('Editing this task will reset all subsequent tasks in the workflow.')}</p>
        <strong>{t('This action will:')}</strong>
        <ul>
          <li>{t('Allow you to edit this task')}</li>
          <li>{t('Delete progress in all following tasks')}</li>
          <li>{t('Require you to redo any subsequent tasks')}</li>
        </ul>
        <p>{t('Are you sure you want to proceed?')}</p>
      </ModalConfirm>
    </>
  );
};

export default WorkflowTaskStatus;
