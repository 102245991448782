import React from 'react';
import { useTranslation } from 'react-i18next';
import useStore from '../../store';
import FlexLoader from '../../components/FlexLoader/FlexLoader';

function RestrictedPage() {
  const { t } = useTranslation();
  const { loading } = useStore((state) => ({
    loading: state.loading,
  }));
  if (loading) {
    return <FlexLoader />;
  }
  return (
    <header className="App-header">
      <h2>{t('Restricted Access')}</h2>
      <p>{t("It seems you've stumbled upon a restricted area.")}</p>
    </header>
  );
}

export default RestrictedPage;
