export const demoBrief = `# 📄 Brief

## Resources

- **SWOT Analysis Framework Guide** - Essential document that outlines the methodology for identifying Strengths, Weaknesses, Opportunities and Threats
- **Competitor Benchmarking Report 2023** - Provides detailed comparison data to help identify our competitive advantages and market gaps
- **Market Research & Customer Feedback Summary** - Contains valuable insights about market opportunities and customer pain points for SWOT evaluation

## Team

- **Project Manager** - Leads strategic planning and ensures SWOT analysis aligns with business objectives
- **Market Analyst** - Provides data-driven insights and competitive analysis expertise
- **Product Owner** - Brings deep product knowledge and customer perspective to the analysis`;

export const demoIdeate = `# 💡 Fill the gaps

## Political Factors

Lorem ipsum dolor sit amet, consectetur adipiscing elit. 

## Economic Factors

Lorem ipsum dolor sit amet, consectetur adipiscing elit.`;

export const demoResult = `# 📊 Result

## Political Factors

Lorem ipsum dolor sit amet, consectetur adipiscing elit. 

## Economic Factors

Lorem ipsum dolor sit amet, consectetur adipiscing elit.`;

export const demoResearchData = {
  content: [
    { 
      'id': 1,
      'headline': 'Political Factors',
      'loading': true,
      'questions': [
        {
          'id': 'q1',
          'status': '✅',
          'loading': true,
          'text': 'What is the political landscape of the country?',
          'info': [
            { emoji: '📄', text: 'Documents...', enabled: true },
            { emoji: '🌐', text: 'Internet...', enabled: true },
            { emoji: '🤖', text: 'LLM...', enabled: true },
            { emoji: '💬', text: 'Chat...', enabled: true },
          ],
          'creative': [
            { emoji: '💡', text: 'Idea...', enabled: true },
          ],
          'generated': { text: 'Generated text...', approved: true },
        },
        {
          'id': 'q2',
          'status': '⚠️',
          'loading': false,
          'text': 'What are the main political parties and their platforms?',
          'info': [
            { emoji: '📄', text: 'Documents...', enabled: true },
            { emoji: '🌐', text: 'Internet...' },
            { emoji: '🤖', text: 'LLM...', enabled: true },
            { emoji: '💬', text: 'Chat...', enabled: true },
          ],
          'creative': [
            { emoji: '💡', text: 'Idea...', enabled: true },
          ],
          'generated': { text: 'Generated text...', approved: true },
        },
        {
          'id': 'q3',
          'status': '❌',
          'loading': false,
          'text': 'What are the key political issues and debates?',
          'info': [
            { emoji: '📄', text: 'Documents...' },
            { emoji: '🌐', text: 'Internet...' },
            { emoji: '🤖', text: 'LLM...' },
            { emoji: '💬', text: 'Chat...' },
          ],
          'creative': [
            { emoji: '💡', text: 'Idea...', enabled: true },
          ],
          'generated': { text: 'Generated text...', approved: true },
        }
      ]
    },
    { 
      'id': 2,
      'headline': 'Economic Factors',
      'questions': [
        {
          'id': 'q4',
          'status': '✅',
          'loading': false,
          'text': 'What is the current economic situation?',
          'info': [
            { emoji: '📄', text: 'Documents...' },
            { emoji: '🌐', text: 'Internet...' },
            { emoji: '🤖', text: 'LLM...' },
            { emoji: '💬', text: 'Chat...' },
          ],
          'creative': [
            { emoji: '💡', text: 'Idea...', enabled: true },
          ],
          'generated': { text: 'Generated text...', approved: true },
        },
        {
          'id': 'q5',
          'status': '⚠️',
          'loading': false,
          'text': 'What are the major industries?',
          'info': [
            { emoji: '📄', text: 'Documents...' },
            { emoji: '🌐', text: 'Internet...' },
            { emoji: '🤖', text: 'LLM...' },
            { emoji: '💬', text: 'Chat...' },
          ],
          'creative': [
            { emoji: '💡', text: 'Idea...', enabled: true },
          ],
          'generated': { text: 'Generated text...', approved: true },
        },
        {
          'id': 'q6',
          'status': '❌',
          'loading': false,
          'text': 'How is the job market?',
          'info': [
            { emoji: '📄', text: 'Documents...' },
            { emoji: '🌐', text: 'Internet...' },
            { emoji: '🤖', text: 'LLM...' },
            { emoji: '💬', text: 'Chat...' },
          ],
          'creative': [
            { emoji: '💡', text: 'Idea...', enabled: true },
          ],
          'generated': { text: 'Generated text...', approved: true },
        }
      ]
    },
  ]
};

export default demoResearchData;