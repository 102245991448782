import React, { useState } from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav } from 'reactstrap';
import NavItems from '../NavItems/NavItems';

const TopNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    console.log('toggle', isOpen);
    setIsOpen(!isOpen);
  };

  return (
    <Navbar color="dark" dark expand="md" className='mb-4 d-md-none'>
      <NavbarBrand href="/">{window.brand}</NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="mr-auto" navbar>
          <NavItems toggle={toggle} />
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default TopNavbar;
