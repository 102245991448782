import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import useStore from '../../../store';
import apiService from '../../../utils/apiService';

const DeepgramPollyMic = ({ isOpen, playPollyMessageStream, setTranscript }) => {
  const { t } = useTranslation();
  const [internalTranscript, setInternalTranscript] = useState('');
  const [micOn, setMicOn] = useState(false);
  const { topic, language } = useStore((state) => ({
    topic: state.topic,
    language: state.language,
  }));

  const deepgramRef = useRef({});

  const doTTS = useCallback(async (text) => {
    if(!text || text.length === 0) return;
    let startTime = performance.now(); // Capture start time
    let output = undefined;
    let agent_id = undefined;
    const endpoint = `/api/topic/${topic.id}/stt-polly/`;
    const formData = { user_input: text };
    try {
      const response = await apiService.post(endpoint, formData);
      console.log(`Response time: ${(performance.now() - startTime) / 1000} seconds`);
      console.log('Response:', response);
      if (response.success) {
        output = response.data.data;
        agent_id = response.data.agent_id;
      } else {
        console.error('Operation failed:', response.message);
      }
    } catch (error) {
      console.error('Error during operation:', error);
    }
    if (!output || !agent_id) return;
    startTime = performance.now();
    playPollyMessageStream(agent_id, output);
  }, [playPollyMessageStream, topic.id]);

  const closeDeepgram = useCallback(() => {
    if (deepgramRef.current.mediaStream) { deepgramRef.current.mediaStream.getTracks().forEach((track) => track.stop()); }
    if (deepgramRef.current.mediaRecorder) { deepgramRef.current.mediaRecorder.stop(); }
    if (deepgramRef.current.socketDeepgram) { deepgramRef.current.socketDeepgram.close(); }
    deepgramRef.current = {};
    doTTS(internalTranscript);
    setInternalTranscript('');
    setTranscript('');
  }, [doTTS, internalTranscript, setTranscript]);

  const initDeepgram = useCallback(() => {
    if (deepgramRef.current.socketDeepgram) {
      closeDeepgram();
      return;
    }
    if (!MediaRecorder.isTypeSupported('audio/webm')) {
      alert('Browser not supported');
      return;
    }
    navigator.mediaDevices.getUserMedia({ audio: true }).then(async (stream) => {
      console.log('initDeepgram', language);
      const wsUrl = language === 'en' ? 'wss://api.deepgram.com/v1/listen?model=nova-2-conversationalai&punctuate=true&smart_format=true&interim_results=true&utterance_end_ms=1500&vad_events=true'
      : 'wss://api.deepgram.com/v1/listen?model=nova-2&punctuate=true&smart_format=true&interim_results=true&utterance_end_ms=1000&vad_events=true&language=sv';
      const socketDeepgram = new WebSocket(wsUrl, [
        'token',
        process.env.REACT_APP_DEEPGRAM_API_KEY,
      ]);
      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: 'audio/webm',
      });

      socketDeepgram.onopen = () => {
        mediaRecorder.addEventListener('dataavailable', async (event) => {
          console.log('initDeepgram dataavailable');
          if (event.data.size > 0 && socketDeepgram.readyState === 1) {
            socketDeepgram.send(event.data);
          }
        });
        mediaRecorder.start(1000);
      };

      const completed = [];
      let ongoing = ''; // Track the ongoing interim internalTranscript
      socketDeepgram.onmessage = (message) => {
        const received = JSON.parse(message.data);
        const { is_final, channel } = received;
        const { alternatives } = channel;
        const currTranscript = alternatives ? alternatives[0].transcript.trim() : '';
        console.log('DG message', currTranscript, is_final, received);
        if (currTranscript.length === 0) return;

        if (!is_final) {
          // If it's a new interim update, update the interim internalTranscript
          ongoing = currTranscript; // Update the interim internalTranscript
        } else {
          completed.push(currTranscript);
          ongoing = '';
        }
        const composite = completed.join(' ') + (ongoing ? ` ${ongoing}` : '');
        console.log('DG composite', composite);
        setInternalTranscript(composite);
        setTranscript(composite);
      };

      deepgramRef.current = { socketDeepgram, mediaRecorder, mediaStream: stream };
    }).catch((error) => {
      console.error('Error accessing microphone:', error);
    });
  }, [closeDeepgram, language, setTranscript]);


  const handleMic = useCallback(() => {
    if (deepgramRef.current.socketDeepgram) {
      closeDeepgram();
    } else {
      console.log('initDeepgram handleMic!!');
      initDeepgram();
    }
    // toggle setMicOn
    setMicOn(!micOn);
  }, [closeDeepgram, initDeepgram, micOn]);

  // Ensure closeDeepgram and initDeepgram are stable with useCallback
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && isOpen) {
        handleMic();
      }
    };
    window.addEventListener('keypress', handleKeyPress);
    return () => window.removeEventListener('keypress', handleKeyPress);
  }, [handleMic, isOpen]); // Depends on closeDeepgram and initDeepgram if they are stable

  return (
    <Button color="danger" onClick={handleMic}>
      {micOn ? (<span className="bi bi-mic"></span>) : (<span className="bi bi-mic-mute"></span>)}
      {micOn? t('Stop') : t('Start')}
    </Button>
  );
};

export default DeepgramPollyMic;
