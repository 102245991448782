import React, { useEffect, useState, useMemo } from 'react';
import { Row, Col, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import apiService from '../../utils/apiService';
import useStore from '../../store';
import { StyledCard, StyledDropdown } from './ProjectsPage.styles'; // Import styled components
import WorkflowsWidget from '../../components/WorkflowsWidget/WorkflowsWidget';
import CreateWorkflowModal from '../../components/CreateWorkflowModal/CreateWorkflowModal';

function ProjectsPage({ is_workflow = false }) {
  const { t } = useTranslation();
  const { projects, remProject, addProject, setProject, workspace } = useStore((state) => ({
    projects: state.projects,
    remProject: state.remProject,
    addProject: state.addProject,
    setProject: state.setProject,
    workspace: state.workspace,
  }));
  const navigate = useNavigate();

  const baseFolder = is_workflow ? 'workflow' : 'project';
  const projectsInWorkspace = useMemo(() => projects && workspace && projects.filter((p) => p.workspace_id === workspace?.id), [projects, workspace]);
  const filteredProjects = useMemo(() => projectsInWorkspace && projectsInWorkspace.filter((project) => is_workflow ? project.super_type === "WORKFLOW" : project.super_type === "PROJECT"), [projectsInWorkspace, is_workflow]);

  useEffect(() => {
    setProject(null);
  }, [setProject])

  const handleCreateNewProject = () => {
    navigate(`/${workspace.id}/${baseFolder}s/new`);
  };

  // Navigate to the edit page
  const handleEditProject = (id) => {
    navigate(`/${workspace.id}/${baseFolder}-details/${id}`);
  };

  // Placeholder function for deleting a project
  // TODO: Are you sure? YES/NO
  const handleDeleteProject = async (id) => {
    console.log('Deleting with id:', id);
    try {
      const response = await apiService.delete(`/api/${baseFolder}s/delete/${id}/`);
      if (!response.success) { console.error('Operation failed:', response.message); return; }
      console.log('Project deleted successfully');
      remProject(id);
    } catch (error) {
      console.error('Error during operation:', error);
    }
  };

  // Placeholder function for duplicating a project
  const handleDuplicateProject = async (id) => {
    console.log('Duplicating project with id:', id);
    try {
      const response = await apiService.post(`/api/${baseFolder}s/duplicate/${id}/`);
      if (!response.success) { console.error('Operation failed:', response.message); return; }
      console.log('Project duplicated successfully');
      addProject(response.data.project);
    } catch (error) {
      console.error('Error during operation:', error);
    }
  };

  const handleViewProject = (project) => {
    // <StyledSubNavLink onClick={toggle}to={session.first_document_id ? `/project/${session.id}/document/${session.first_document_id}` : `/project/${session.id}`}>
    navigate(project.first_document_id ? `/${baseFolder}/${project.id}/document/${project.first_document_id}/` : `/${baseFolder}/${project.id}/`);
  };

  // Function to handle dropdown toggle
  const [dropdownOpen, setDropdownOpen] = useState({});
  const toggle = (id) => {
    setDropdownOpen(prevState => ({ ...prevState, [id]: !prevState[id] }));
  };

  // Create workflow modal
  const [createWorkflowModalOpen, setCreateWorkflowModalOpen] = useState(false);
  const [createWorkflowCustom, setCreateWorkflowCustom] = useState(false);
  const [workflowModalName, setWorkflowModalName] = useState('');
  const toggleCreateWorkflowModal = () => setCreateWorkflowModalOpen(!createWorkflowModalOpen);
  const showCreateWorkflowModal = (name, isCustom = false) => {
    setCreateWorkflowModalOpen(true);
    setWorkflowModalName(name);
    setCreateWorkflowCustom(isCustom);
  };

  const marketingWorkflows = [
    t('Write a Blog Post'),
    t('Create Social Media Post'),
    t('Design a Marketing Email'),
    t('Create a Press Release')
  ];

  const salesWorkflows = [
    t('Create a Sales Pitch'),
    t('Write a Sales Proposal'),
    t('Craft a Follow-up Email'),
    t('Develop a Unique Selling Proposition'),
  ];

  const hrWorkflows = [
    t('Write a Job Description'),
    t('Create an Onboarding Checklist'),
    t('Draft an Employee Policy'),
    t('Compose a Performance Review Form'),
  ];

  const itWorkflows = [
    t('Write a Software Requirements Document'),
    t('Create an IT Security Policy'),
    t('Write a Data Management Plan'),
    t('Compose an Incident Response Procedure')
  ];

  const customerServiceWorkflows = [
    t('Write a Customer Service Script'),
    t('Create a Customer Feedback Survey'),
    t('Compose a Customer Service Email Template'),
    t('Draft a Customer Complaint Resolution Guide'),
  ];

  const productDevelopmentWorkflows = [
    t('Write a Product Specification Document'),
    t('Compose a Product Description'),
    t('Create Product Release Notes'),
    t('Write a Product FAQ')
  ];

  const legalWorkflows = [
    t('Draft a Legal Agreement'),
    t('Create a Privacy Policy'),
    t('Write a Compliance Checklist'),
    t('Compose a Terms of Service Document'),
  ];

  const sustainabilityWorkflows = [
    t('Write a Sustainability Policy'),
    t('Create a Sustainability Report'),
    t('Compose a Sustainability Mission Statement'),
    t('Develop an Eco-Friendly Practices Guide')
  ];

  const managementWorkflows = [
    t('Write an Executive Summary'),
    t('Draft a Corporate Policy'),
    t('Compose a Risk Assessment Brief'),
    t('Write a Team Meeting Agenda')
  ];

  /* const boardWorkflows = [
    'Review and Approve Business Strategy',
    'Monitor Financial Performance',
    'Ensure Regulatory Compliance',
    'Evaluate Executive Performance',
    'Oversee Risk Management'
  ];*/


  return (
    <>
      {is_workflow &&
      <div className='mb-4'>
        <h2 className='mb-4'>{t('Workflows')}</h2>
        <Row>
          <Col sm="4">
            <WorkflowsWidget title={t('Marketing')} workflows={marketingWorkflows} showCreateWorkflowModal={showCreateWorkflowModal} className="mb-4" />
            <WorkflowsWidget title={t('Sales')} workflows={salesWorkflows} showCreateWorkflowModal={showCreateWorkflowModal} className="mb-4" />
            <WorkflowsWidget title={t('HR')} workflows={hrWorkflows} showCreateWorkflowModal={showCreateWorkflowModal} className="mb-4" />
          </Col>
          <Col sm="4">
            <WorkflowsWidget title={t('IT')} workflows={itWorkflows} showCreateWorkflowModal={showCreateWorkflowModal} className="mb-4" />
            <WorkflowsWidget title={t('Customer Service')} workflows={customerServiceWorkflows} showCreateWorkflowModal={showCreateWorkflowModal} className="mb-4" />
            <WorkflowsWidget title={t('Product Development')} workflows={productDevelopmentWorkflows} showCreateWorkflowModal={showCreateWorkflowModal} className="mb-4" />
          </Col>
          <Col sm="4">
            <WorkflowsWidget title={t('Legal')} workflows={legalWorkflows} showCreateWorkflowModal={showCreateWorkflowModal} className="mb-4" />
            <WorkflowsWidget title={t('Sustainability')} workflows={sustainabilityWorkflows} showCreateWorkflowModal={showCreateWorkflowModal} className="mb-4" />
            <WorkflowsWidget title={t('Management')} workflows={managementWorkflows} showCreateWorkflowModal={showCreateWorkflowModal} className="mb-4" />
            {/*<WorkflowsWidget title={t('Board')} workflows={boardWorkflows} showCreateWorkflowModal={showCreateWorkflowModal} /> */}
          </Col>
        </Row>

      </div>}
      <div>
        {is_workflow ? <h2>{t('Our Workflows')}</h2> : <h2>{t('Our Sessions')}</h2>}
        {filteredProjects && filteredProjects.length > 0 ? (
          <Row>
            {filteredProjects.map((project) => (
              <Col sm="12" md="6" lg="4" key={project.id}>
                <StyledCard onClick={() => handleViewProject(project)}>
                  <StyledDropdown>
                    <Dropdown isOpen={dropdownOpen[project.id]} toggle={(e) => { e.stopPropagation(); toggle(project.id); }}>
                      <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen[project.id]} onClick={(e) => e.stopPropagation()}>
                        <i className="bi bi-three-dots-vertical"></i>
                      </DropdownToggle>
                      <DropdownMenu end>
                        <DropdownItem onClick={() => handleEditProject(project.id)}>{t('Edit')}</DropdownItem>
                        <DropdownItem onClick={() => handleDeleteProject(project.id)}>{t('Delete')}</DropdownItem>
                        <DropdownItem onClick={() => handleDuplicateProject(project.id)}>{t('Duplicate')}</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </StyledDropdown>
                  <h5><i className={`bi ${project.icon}`}></i> {project.name}</h5>
                  {project.current_stage && (<small>{t('Stage')}: {project.current_stage}</small>)}<br />
                  <small className="text-secondary"><i className="bi bi-calendar-fill"></i> {project.created_at}</small>
                </StyledCard>
              </Col>
            ))}
          </Row>
        ) : (
          is_workflow ? <p>{t('No workflows available.')}</p> : <p>{t('No projects available.')}</p>
        )}
        <div className='d-flex gap-1'>
          {!is_workflow && <Button color="primary" onClick={handleCreateNewProject}>
            {t('Create new project')}
          </Button>}
          {is_workflow && <Button color="primary" onClick={() => showCreateWorkflowModal('', true, 'CUSTOM_WORKFLOW_MANUAL')}>
            {t('Create a custom workflow')}
          </Button>}
        </div>
      </div>
      <CreateWorkflowModal isOpen={createWorkflowModalOpen} toggle={toggleCreateWorkflowModal} workflow_template_name={workflowModalName} isCustom={createWorkflowCustom} />
    </>
  );
}
            

export default ProjectsPage;
