import React, { useRef, useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { StyledTextArea } from './AutoResizeTextarea.styles';

const AutoResizeTextarea = forwardRef(({ initialValue, onChange, ...props }, ref) => {
  const [text, setText] = useState(initialValue || '');
  const internalRef = useRef(null);

  useImperativeHandle(ref, () => internalRef.current);

  useEffect(() => {
    setText(initialValue);
  }, [initialValue]);

  const adjustRows = () => {
    if (internalRef.current) {
      internalRef.current.style.transition = 'height 0.2s ease';
      internalRef.current.style.height = 'auto'; // Reset height to recalculate
      internalRef.current.style.height = `${internalRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustRows();
  }, [text]);

  useEffect(() => {
    const handleResize = () => {
      adjustRows();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (internalRef.current && internalRef.current.offsetParent !== null) {
        adjustRows();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Call adjustRows on mount to ensure initial height is set correctly
    adjustRows();
  }, []);

  const handleChange = (e) => {
    setText(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <StyledTextArea
      {...props}
      ref={internalRef}
      value={text}
      onChange={handleChange}
      className='w-100 p-2'
    />
  );
});

AutoResizeTextarea.propTypes = {
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
};

export default AutoResizeTextarea;
