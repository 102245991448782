import React, { useMemo } from 'react';
import { NavItem } from 'reactstrap';
import { StyledNavLinkDocument, StarButton, SpinnerGrowMini } from './AgentLink.styles.js';

const AgentLink = ({ project, agent, workspace, handleDemoteAgent, onClick }) => {
  const workspacePrefix = useMemo(() => workspace && workspace.id ? `${workspace.id}/` : '', [workspace]);
  const link = useMemo(() => project && project.id ? `/project/${project.id}/coworker/${agent.id}` : `/${workspacePrefix}coworker/${agent.id}`, [project, agent, workspacePrefix]);
  const hasStar = Boolean(handleDemoteAgent);

  return (
    <NavItem>
      <StyledNavLinkDocument to={link} hasStar={hasStar} onClick={onClick}>
        <span className='bi bi-person'></span>&nbsp;
        {agent.name}
        {agent.is_simple_tasking && <span>&nbsp;<SpinnerGrowMini className="spinner-grow" role="status" aria-hidden="true"></SpinnerGrowMini></span>}
        {handleDemoteAgent && (
          <StarButton className="star-button" size="sm" onClick={() => handleDemoteAgent(agent.id)}>
            <span className="bi bi-star-fill"></span>
          </StarButton>
        )}
      </StyledNavLinkDocument>
    </NavItem>
  );
};

export default AgentLink;
