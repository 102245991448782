import React, { createContext, useState, useContext, useCallback } from 'react';
import ModalBasic from '../components/ModalBasic/ModalBasic';
import { Button, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import apiService from '../utils/apiService';
import useStore from '../store';

const ModalToolContext = createContext();

export const ModalToolProvider = ({ children }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [taskData, setTaskData] = useState({
    name: '',
    super_type: 'SIMPLE_TASK',
    type: '',
    prompt: '',
    agent_id: '',
    mode: 'quality',
    generation_template_id: ''
  });
  const [taskError, setTaskError] = useState('');
  const [generatingTask, setGeneratingTask] = useState(false);
  const [showModeSelect, setShowModeSelect] = useState(false);
  const [showGenerationTemplates, setShowGenerationTemplates] = useState(false);
  const [modalConfig, setModalConfig] = useState({
    buttonLabel: 'Query documents',
    questionLabel: 'Do you have any special requests?',
    isPromptRequired: false
  });

  const { workspace, topic, setTopic } = useStore((state) => ({
    workspace: state.workspace,
    topic: state.topic,
    setTopic: state.setTopic
  }));

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setTaskData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  const validateTaskForm = useCallback(() => {
    setTaskError('');
    if (modalConfig.isPromptRequired && !taskData.prompt.trim()) {
      setTaskError(t('This field is required'));
      return false;
    }
    return true;
  }, [modalConfig.isPromptRequired, taskData.prompt, t]);

  const showModal = useCallback((
    title, 
    type, 
    agentId, 
    showModeSelect = false,
    buttonLabel = 'Query documents',
    questionLabel = 'Do you have any special requests?',
    isPromptRequired = false,
    showGenerationTemplates = false
  ) => {
    const firstTemplateId = window.generation_templates_for_select?.[0]?.id || '';
    setTaskData({
      name: title,
      super_type: 'SIMPLE_TASK',
      type: type.toUpperCase().replace(/ /g, '_'),
      prompt: '',
      agent_id: agentId,
      mode: 'quality',
      generation_template_id: showGenerationTemplates ? firstTemplateId : ''
    });
    setModalConfig({
      buttonLabel,
      questionLabel,
      isPromptRequired
    });
    setShowModeSelect(showModeSelect);
    setShowGenerationTemplates(showGenerationTemplates);
    setIsOpen(true);
  }, []);

  const hideModal = useCallback(() => {
    setIsOpen(false);
    setTaskError('');
    setShowModeSelect(false);
  }, []);

  const createSimpleTask = useCallback(() => {
    if (!validateTaskForm()) return;
    if (generatingTask) return;

    setGeneratingTask(true);
    const endpoint = '/api/simple_task/create/';
    const aiMessageId = uuidv4();
    const newTopic = {
      ...topic,
      chat_history: [...topic.chat_history, 
        { type: 'AI', message: '', id: aiMessageId, data: { streaming: true, sub_type: 'SIMPLE_TASK' } }
      ],
    }
    setTopic(newTopic);
    console.log('taskData', taskData);
    apiService.post(endpoint, {
      ...taskData,
      workspace_id: workspace?.id,
      topic_id: topic?.id,
      agent_id: taskData.agent_id,
      ai_message_id: aiMessageId
    })
      .then(response => {
        if (response.success) {
          console.log('Simple task created successfully');
          hideModal();
        } else {
          console.error('Operation failed:', response.message);
          setTaskError(response.message || 'Failed to create simple task');
        }
      })
      .catch(error => {
        console.error('Error during operation:', error);
        setTaskError('An error occurred while creating the task');
      })
      .finally(() => {
        setGeneratingTask(false);
      });
  }, [validateTaskForm, generatingTask, topic, setTopic, taskData, workspace?.id, hideModal]);

  const footerButtons = (
    <Button color="primary" onClick={() => createSimpleTask()} disabled={generatingTask}>
      {generatingTask ? (
        <>
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {' '}{t('Generating')}
        </>
      ) : t(modalConfig.buttonLabel)}
    </Button>
  );

  return (
    <ModalToolContext.Provider value={{ showModal, hideModal, createSimpleTask }}>
      {children}
      <ModalBasic
        isOpen={isOpen}
        toggle={hideModal}
        title={taskData.name}
        footerButtons={footerButtons}
      >
        <Form id="taskForm">
          {showGenerationTemplates && (
            <FormGroup>
              <Label for="generation_template_id" className='fw-bold'>{t('Document Template')}</Label>
              <Input
                type="select"
                name="generation_template_id"
                id="generation_template_id"
                value={taskData.generation_template_id}
                onChange={handleChange}
                disabled={generatingTask}
              >
                {window.generation_templates_for_select?.map(template => (
                  <option key={template.id} value={template.id}>
                    {template.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          )}
          {showModeSelect && (
            <FormGroup>
              <Label for="mode" className='fw-bold'>{t('Processing Mode')}</Label>
              <Input
                type="select"
                name="mode"
                id="mode"
                value={taskData.mode}
                onChange={handleChange}
                disabled={generatingTask}
              >
                <option value="quality">{t('Read through each document thoroughly')}</option>
                <option value="speed">{t('Ask specific questions to your documents')}</option>
              </Input>
            </FormGroup>
          )}
          <FormGroup>
            <Label for="task" className='fw-bold'>{t(modalConfig.questionLabel)}</Label>
            <Input
              type="textarea"
              name="prompt"
              id="prompt"
              placeholder={t('Help me...')}
              value={taskData.prompt}
              onChange={handleChange}
              invalid={!!taskError}
              disabled={generatingTask}
              required={modalConfig.isPromptRequired}
            />
            <FormFeedback>
              {taskError}
            </FormFeedback>
          </FormGroup>
        </Form>
      </ModalBasic>
    </ModalToolContext.Provider>
  );
};

export const useModalTool = () => useContext(ModalToolContext);
