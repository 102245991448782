import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useStore from '../../store'; // Adjust the import path as necessary

const UpdateLocationOnNavigation = () => {
  const location = useLocation();
  const setLocation = useStore((state) => state.setLocation);

  useEffect(() => {
    // console.log('Setting location:', location.pathname);
    setLocation(location.pathname); // Update the location in the store
  }, [location, setLocation]); // React to changes in location

  return null; // This component does not render anything
};

export default UpdateLocationOnNavigation;
