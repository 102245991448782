import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Button, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { StyledDropdown } from '../../../Agent/Agent.styles.js'; // Import the styled component

const SharedAgent = ({ agent, handleDelete, handleRemFromMeeting, handleEditCoWorker, handleAddToMeeting }) => {
  const { t } = useTranslation();
  // Add dropdown state
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  return (
    <Card className="mb-3">
      {/* Add dropdown menu */}
      {(handleEditCoWorker || handleDelete) && (
        <StyledDropdown>
          <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
              <i className="bi bi-three-dots-vertical"></i>
            </DropdownToggle>
            <DropdownMenu end>
              {handleEditCoWorker && (
                <DropdownItem onClick={() => handleEditCoWorker(agent.id)}>
                  <i className="bi bi-pencil me-1"></i>{t('Edit')}
                </DropdownItem>
              )}
              {handleDelete && (
                <DropdownItem onClick={() => handleDelete(agent.id)}>
                  <i className="bi bi-trash me-1"></i>{t('Delete')}
                </DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>
        </StyledDropdown>
      )}
      
      <Row className="g-0">
        <Col sm="12" md={{ size: 3 }}>
          <div
            style={{
              backgroundImage: `url(${agent.image_url})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '100%',
              width: '100%',
              minHeight: '150px',
            }}
            className="rounded-start"
            alt={agent.name}
          />
        </Col>
        <Col>
          <CardBody className="p-3">
            <h5 className="p-0 m-0">{agent.name}</h5>
            <div className="mb-2">
              <small>
                {agent.description?.length > 200 
                  ? `${agent.description.substring(0, 200)}...` 
                  : agent.description}
              </small>
            </div>
            <div className="d-flex gap-2">
              {handleAddToMeeting && (
                <Button color="primary" size="sm" onClick={() => handleAddToMeeting(agent.id)}>
                  <i className="bi bi-plus-circle me-1"></i>{t('Invite')}
                </Button>
              )}
              {handleRemFromMeeting && (
                <Button color="danger" size="sm" onClick={() => handleRemFromMeeting(agent.id)}>
                  <i className="bi bi-dash-circle me-1"></i>{t('Kick')}
                </Button>
              )}
            </div>
          </CardBody>
        </Col>
      </Row>
    </Card>
  );
};

export default SharedAgent;
