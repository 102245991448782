import React, { useEffect, useRef } from 'react';

const AudioVisualization = ({ 
  dimension,
  analyzer,
  enabled = true,
  opacity = 0.7,
  colors = {
    start: 'rgba(0, 255, 255, 0.2)',
    end: 'rgba(0, 255, 255, 0.8)'
  }
}) => {
  const animationFrameRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    const draw = () => {
      if (!enabled) {
        animationFrameRef.current = requestAnimationFrame(draw);
        return;
      }

      const canvas = canvasRef.current;
      if (!canvas) return;

      const ctx = canvas.getContext('2d');
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;
      const radius = Math.min(centerX, centerY) - 10;

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const bufferLength = 128;
      const dataArray = new Uint8Array(bufferLength);

      if (analyzer) {
        analyzer.getByteFrequencyData(dataArray);
        
        const barCount = 180;
        const barWidth = (2 * Math.PI) / barCount;

        ctx.beginPath();
        ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
        ctx.strokeStyle = colors.start;
        ctx.lineWidth = 1;
        ctx.stroke();

        for (let i = 0; i < barCount; i++) {
          const angle = i * barWidth;
          const value = dataArray[Math.floor((i / barCount) * bufferLength)];
          const barHeight = (value / 255.0) * 30;

          const innerRadius = radius;
          const outerRadius = innerRadius + barHeight;

          const startX = centerX + Math.cos(angle) * innerRadius;
          const startY = centerY + Math.sin(angle) * innerRadius;
          const endX = centerX + Math.cos(angle) * outerRadius;
          const endY = centerY + Math.sin(angle) * outerRadius;

          const gradient = ctx.createLinearGradient(startX, startY, endX, endY);
          gradient.addColorStop(0, colors.start);
          gradient.addColorStop(1, colors.end);

          ctx.beginPath();
          ctx.moveTo(startX, startY);
          ctx.lineTo(endX, endY);
          ctx.strokeStyle = gradient;
          ctx.lineWidth = 2;
          ctx.stroke();
        }
      }

      animationFrameRef.current = requestAnimationFrame(draw);
    };

    draw();

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [enabled, analyzer, colors]);

  return (
    <canvas
      ref={canvasRef}
      width={dimension}
      height={dimension}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
        opacity: opacity,
        display: enabled ? 'block' : 'none',
        pointerEvents: 'none',
      }}
    />
  );
};

export default AudioVisualization;