import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import apiService from '../../utils/apiService';
import useStore from '../../store';

function EditCustomAgentModal({ isOpen, toggle, agent }) {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const { updateAgent } = useStore((state) => ({
    updateAgent: state.updateAgent,
  }));

  const agentImages = useMemo(() => {
    const configs = window.agent_configs || [];
    const uniqueIds = new Set();
    const uniqueConfigs = configs.filter((config) => {
      if (config.id === 'CUSTOM') return false;
      if (!uniqueIds.has(config.image_id)) {
        uniqueIds.add(config.image_id);
        return true;
      }
      return false;
    });
    return uniqueConfigs.map((config) => ({
      value: config.id,
      label: config.name,
      // url: `/static/avatars/images/${config.id}.png`
      url: `${window.cdn}/images%2F${config.id}.png`
    }));
  }, []);

  const agentPersonalityChoices = useMemo(() => window.agentPersonalityChoices || [], []);
  const [agentData, setAgentData] = useState({
    name: '',
    description: '',
    type: 'CUSTOM_AGENT',
    personality: agentPersonalityChoices[0].value,
    image_url: agentImages[0].url,
  });
  const [error, setError] = useState('');

  useEffect(() => {
    if (agent) {
      setAgentData({
        name: agent.name,
        description: agent.description,
        type: agent.type,
        personality: agent.personality,
        image_url: agent.image_url,
      });
    }
  }, [agent, agentImages]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAgentData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    if (!agentData.name.trim()) {
      setError('Name is required');
      console.log('Name is required');
      return false;
    }
    setError('');
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const endpoint = agent ? `/api/agents/${agent.id}/edit/` : `/api/agents/${projectId}/design/`;
    console.log('Submitting:', agentData);
    try {
      const response = await apiService.post(endpoint, {
        name: agentData.name,
        description: agentData.description,
        image_id: agentData.image_id,
        personality: agentData.personality,
      });
      if (response.success) {
        console.log(agent ? 'Agent updated successfully!' : 'Agent created successfully', response.data);
        if (agent) {
          updateAgent(agent.id, response.data);
        }
        toggle();
      } else {
        console.error('Operation failed:', response);
      }
    } catch (error) {
      console.error('Error during operation:', error);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{t('Edit Co-worker')}</ModalHeader>
      <ModalBody>
        <Form id="agentForm" onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="name" className='fw-bold'>{t('Name')}</Label>
            <Input
              type="text"
              name="name"
              id="name"
              placeholder={t('Enter agent name')}
              value={agentData.name}
              onChange={handleChange}
              invalid={!!error} // This marks the input as invalid when there is an error
            />
            <FormFeedback>
              {error}
            </FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="description" className='fw-bold'>{t('Description')}</Label>
            <Input
              type="textarea"
              name="description"
              id="description"
              placeholder={t('Enter agent description')}
              value={agentData.description}
              onChange={handleChange}
            />
          </FormGroup>

          <FormGroup>
            <Label for="image" className='fw-bold'>{t('Image')}</Label>
            <div className="image-carousel position-relative">
              {agentImages.length > 0 && (
                <>
                  <Button className="position-absolute top-50 start-0 translate-middle-y" style={{ zIndex: 1 }} onClick={() => setAgentData(prev => ({
                    ...prev,
                    image_id: agentImages[(agentImages.findIndex(img => img.url === agentData.image_url) - 1 + agentImages.length) % agentImages.length].value,
                    image_url: agentImages[(agentImages.findIndex(img => img.url === agentData.image_url) - 1 + agentImages.length) % agentImages.length].url
                  }))}>{"<"}</Button>
                  <img
                    src={agentData.image_url}
                    alt={`Avatar #${agentImages.findIndex(img => img.url === agentData.image_url).id}`}
                    className="w-100"
                    style={{ height: 'auto' }}
                  />
                  <Button className="position-absolute top-50 end-0 translate-middle-y" style={{ zIndex: 1 }} onClick={() => setAgentData(prev => ({
                    ...prev,
                    image_id: agentImages[(agentImages.findIndex(img => img.url === agentData.image_url) + 1) % agentImages.length].value,
                    image_url: agentImages[(agentImages.findIndex(img => img.url === agentData.image_url) + 1) % agentImages.length].url
                  }))}>{">"}</Button>
                </>
              )}
            </div>
          </FormGroup>

          <FormGroup>
            <Label for="personality" className='fw-bold'>{t('Personality')}</Label>
            <Input
              type="select"
              name="personality"
              id="personality"
              value={agentData.personality}
              onChange={handleChange}
            >
              {agentPersonalityChoices.map((option) => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </Input>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
          <Button color="primary" onClick={() => document.getElementById('agentForm').dispatchEvent(new Event('submit', {cancelable: true, bubbles: true}))}>{agent ? t('Update') : t('Create')}</Button>
      </ModalFooter>
    </Modal>
  );
}

export default EditCustomAgentModal;
