import React, { useCallback, useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import useStore from '../../store';
import { PrintStyles, PageBreak, MessageHeader, MarginBottom } from './ModalPrint.styles';


const ModalPrint = ({ isOpen, toggle, title, content, size = 'lg' }) => {
  const { t } = useTranslation();
  const { agents } = useStore((state) => ({
    agents: state.agents,
  }));
  const [paginatedContent, setPaginatedContent] = useState([]);


  const handlePrint = () => {
    const printContents = document.getElementById('modal-content').innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(); // Reload to restore the original content
  };

  /*const getAgentImage = useCallback((agentId) => {
    if (!agents || !agentId) return null;
    const agent = agents.find(agent => agent.id === agentId);
    return agent ? (
      <div style={{ width: '40px', height: '40px', borderRadius: '50%', backgroundColor: '#ccc', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {agent.name.charAt(0)}
      </div>
    ) : null;
  }, [agents]);*/

  const getAgentName = useCallback((agentId) => {
    if (!agents) return null;
    if (!agentId) return null;
    const agent = agents.find(agent => agent.id === agentId);
    return agent ? agent.name : '';
  }, [agents]);

  useEffect(() => {
    const paginateContent = (content) => {
      const pageHeight = 900; // Approximate height of an A4 page in pixels
      let currentPage = [];
      let currentHeight = 0;
      const pages = [];

      content.forEach((item) => {
        const itemHeight = estimateItemHeight(item);
        if (currentHeight + itemHeight > pageHeight) {
          pages.push(currentPage);
          currentPage = [];
          currentHeight = 0;
        }
        currentPage.push(item);
        currentHeight += itemHeight;
      });

      if (currentPage.length > 0) {
        pages.push(currentPage);
      }

      return pages;
    };

    const estimateItemHeight = (item) => {
      // Rough estimation, adjust based on your content
      if (item.element === 'h1') return 50;
      if (item.element === 'h2') return 40;
      if (item.element === 'h3') return 30;
      if (item.element === 'chat') return item.content.length * 100;
      return 20; // Default height for other elements
    };

    setPaginatedContent(paginateContent(content));
  }, [content]);

  const renderContent = useCallback((content) => {
    return content.map((item, index) => {
      if (typeof item === 'object' && item !== null) {
        if (item.element) {
          switch (item.element) {
            case 'h1':
              return <h1 key={index} className='mb-4'>{item.content}</h1>;
            case 'h2':
              return <h2 key={index} className='mb-4'>{item.content}</h2>;
            case 'h3':
              return <h3 key={index} className='mb-4'>{item.content}</h3>;
            case 'h4':
              return <h4 key={index} className='mb-4'>{item.content}</h4>;
            case 'chat':
              return (
                <div key={index} className='chat-container'>
                  {item.content.map((response, messageIndex) => (
                    <div key={`message-${messageIndex}`} className={`message ${response.type.toLowerCase()}-message`}>
                      <MessageHeader>
                        <strong>
                          {response.type === 'USER' ? (response.name || t('You')) : getAgentName(response.agent_id)}:
                        </strong>
                      </MessageHeader>
                      <div className="message-content">
                        <Markdown remarkPlugins={[remarkGfm]}>{response.message}</Markdown>
                      </div>
                      {messageIndex !== item.content.length - 1 && <hr className="message-separator" />}
                    </div>
                  ))}
                </div>
              );
            case 'page-break':
              return <PageBreak key={index} />;
            case 'margin-bottom':
              return <MarginBottom key={index} />;
            default:
              return null;
          }
        } else {
          // Handle objects without an 'element' property
          return <p key={index}>{JSON.stringify(item)}</p>;
        }
      } else { 
        return <p key={index}>{item}</p>; // Fallback for non-object items
      }
    });
  }, [t, getAgentName]);

  return (
    <>
      <PrintStyles />
      <Modal isOpen={isOpen} toggle={toggle} size={size} scrollable>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody id="modal-content">
          {paginatedContent.map((page, pageIndex) => (
            <div key={pageIndex} className="pdf-page">
              {renderContent(page)}
            </div>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handlePrint}>Print</Button>
          <Button color="primary" onClick={toggle}>OK</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalPrint;
