import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Button, Row, Col, Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useStore from '../../store';
import apiService from '../../utils/apiService';
import FlexLoader from '../../components/FlexLoader/FlexLoader';

function BillingPage() {
  const { t } = useTranslation();
  const { loggedIn, organization, setMessages, workspace } = useStore((state) => ({
    loggedIn: state.loggedIn,
    organization: state.organization,
    setMessages: state.setMessages,
    workspace: state.workspace
  }));
  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const sessionId = useMemo(() => queryParams.get('session_id'), [queryParams]);
  const status = queryParams.get('status');

  const [internalLoading, setInternalLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [activeSubscriptions, setActiveSubscriptions] = useState(0);
  const [displayPageMessages, setDisplayPageMessages] = useState(false);

  const fetchUsers = useCallback(async () => {
    const response = await apiService.post('/api/organizations/users/');
    let orgUsers = response.data.users;
    if(orgUsers.length === 0) {   // Add self
      orgUsers = [{id: 0}];
    }
    console.log('*2* fetchUsers', response.data);
    setUsers(orgUsers);
    setActiveSubscriptions(response.data.active_subscriptions_count || 0);
  }, []);

  const fetchSuccess = useCallback(async (sessionId) => {
    // This will create organisation, update name if needed and set stripe customer id
    try {
      const response = await apiService.post('/api/organizations/stripe/handle-success/', {
      session_id: sessionId
    });
      console.log('*1* fetchSuccess response', response);
    } catch (error) {
      console.error('Error fetching success:', error);
    }
  }, []);

  const doFetching = useCallback(async () => {
    if (!loggedIn) return;
    if(sessionId) await fetchSuccess(sessionId);
    await fetchUsers();
    setInternalLoading(false);
  }, [fetchSuccess, fetchUsers, loggedIn, sessionId]);

  useEffect(() => {
    if (!loggedIn) return;
    doFetching();
  }, [loggedIn, doFetching]);

  useEffect(() => {
    if (displayPageMessages) return; // Prevent displaying messages multiple times
    if (status === 'success') setMessages({ "info": [t('Payment was successful!')] });
    if (status === 'cancel') setMessages({ "error": [t('Payment was cancelled.')] });
    setDisplayPageMessages(true);
  }, [setMessages, t, status, displayPageMessages]);

  const openCheckoutSession = useCallback(async (minimum) => {
    if (!loggedIn) return;
    try {
      const response = await apiService.post('/api/organizations/stripe/create-checkout-session/', {
        success_url: `${window.location.origin}/${workspace.id}/billing?status=success&session_id={CHECKOUT_SESSION_ID}`,
        cancel_url: `${window.location.origin}/${workspace.id}/billing?status=cancel`,
        minimum
      });
      if (response.success) {
        window.location.href = response.data.url;
      } else {
        console.error('Failed to create checkout session:', response.message);
      }
    } catch (error) {
      console.error('Error during checkout session creation:', error);
    }
    return null;
  }, [loggedIn, workspace?.id]);

  const openCustomerPortalSession = useCallback(async () => {
    if (!loggedIn || !organization || !organization.stripe_customer_id) return;
    try {
      const response = await apiService.post('/api/organizations/stripe/create-customer-portal-session/', {
        return_url: `${window.location.origin}/${workspace.id}/billing`
      });
      if (response.success) {
        window.location.href = response.data.url;
      } else {
        console.error('Failed to create customer portal session:', response.message);
      }
    } catch (error) {
      console.error('Error during customer portal session creation:', error);
    }
  }, [loggedIn, organization, workspace?.id]);

  // Choose between checkout and customer portal button
  const stripeButton = useMemo(() => {
    return !organization || !organization.stripe_customer_id ?
       <Button color="primary" onClick={() => openCheckoutSession(users.length - activeSubscriptions)}>
           {t('Go to Checkout')}
         </Button>
         :
         <Button color="primary" onClick={openCustomerPortalSession}>
         {t('Go to Customer Portal')}
       </Button>
   }, [activeSubscriptions, openCheckoutSession, openCustomerPortalSession, organization, t, users.length]);

  if(!loggedIn || internalLoading) return (<Row><Col><h2>{t('Billing')}</h2><FlexLoader /></Col></Row>);

  return (
    <Row>
      <Col sm="12" md={{ size: 9 }}>
        <h2>{t('Subscriptions')}</h2>

        {(users.length > activeSubscriptions || !activeSubscriptions) &&
          <>
            <Alert color='warning'>
              <div className='mb-2'>{t('Your organization has {{X}} unpaid subscriptions.', { X: (users.length - activeSubscriptions) || users.length })}</div>
              <div>{stripeButton}</div>
            </Alert>
          </>
        }
        {users.length < activeSubscriptions &&
          <>
            <Alert color='info'>
            {t('Your organization has {{X}} unassigned subscriptions. Visit the members page to assign them.', { X:  activeSubscriptions - users.length })}
            </Alert>
            {stripeButton}
          </>
        }
        {users.length === activeSubscriptions &&
          <>
            <p>{t('You have {{X}} active subscriptions. You can manage all your subscriptions and billing details through our customer portal.', { X:  activeSubscriptions })}</p>
            {stripeButton}
          </>
        }
      </Col>
    </Row>
  );
}

export default BillingPage;
