import React, { useState, useEffect, useCallback } from 'react';
import { useMicVAD } from '@ricky0123/vad-react';
import { useAudioStream } from '../../../hooks/useAudioStream';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import apiService from '../../../utils/apiService';
import useStore from '../../../store';

const VadGoogleMic = ({ isOpen, playGoogleMessage, setTranscript }) => {
  const { t } = useTranslation();
  const { topic } = useStore((state) => ({ topic: state.topic }));
  const [isRecording, setIsRecording] = useState(true);

  const float32ToWav = (samples, sampleRate) => {
    const buffer = new ArrayBuffer(44 + samples.length * 2);
    const view = new DataView(buffer);

    const writeString = (view, offset, string) => {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
      }
    };

    const floatTo16BitPCM = (output, offset, input) => {
      for (let i = 0; i < input.length; i++, offset += 2) {
        const s = Math.max(-1, Math.min(1, input[i]));
        output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
      }
    };

    writeString(view, 0, 'RIFF'); // RIFF identifier
    view.setUint32(4, 32 + samples.length * 2, true); // file length minus RIFF identifier length and file description length
    writeString(view, 8, 'WAVE'); // RIFF type
    writeString(view, 12, 'fmt '); // format chunk identifier
    view.setUint32(16, 16, true); // format chunk length
    view.setUint16(20, 1, true); // sample format (raw)
    view.setUint16(22, 1, true); // channel count
    view.setUint32(24, sampleRate, true); // sample rate
    view.setUint32(28, sampleRate * 2, true); // byte rate (sample rate * block align)
    view.setUint16(32, 2, true); // block align (channel count * bytes per sample)
    view.setUint16(34, 16, true); // bits per sample
    writeString(view, 36, 'data'); // data chunk identifier
    view.setUint32(40, samples.length * 2, true); // data chunk length

    floatTo16BitPCM(view, 44, samples);

    return buffer;
  };


  // onFrameProcessed	(probabilities: {isSpeech: float; notSpeech: float}) => any	Callback to run after each frame.

  const vad = useMicVAD({
    startOnLoad: true,
    additionalAudioConstraints: {
      echoCancellation: true,
      noiseSuppression: true,
      autoGainControl: true,
      sampleRate: 16000,
      channelCount: 1,
    },
    onSpeechStart: async (audio) => {
      console.log('User started talking');
    },
    onFrameProcessed: (probabilities) => {
      console.log('Frame probabilities:', probabilities);
    },
    onSpeechEnd: async (audio) => {
      return;

      console.log('User stopped talking');
      console.log('Audio data length:', audio, typeof audio);
      const wavBuffer = float32ToWav(audio, 16000);
      const blob = new Blob([wavBuffer], { type: 'audio/wav' });
      console.log('Blob size:', blob.size);
      console.log(blob);

      const formData = new FormData();
      formData.append('audio', blob);
      const endpoint = `/api/topic/${topic.id}/tts-google/`;

      let audioContent;
      let transcription;
      try {
        let startTime = performance.now(); // Capture start time
        const response = await apiService.post(endpoint, formData);
        console.log(`Response time: ${(performance.now() - startTime) / 1000} seconds`);
        console.log('Response:', response);
        if (response.success) {
          transcription = response.data.transcription;
          audioContent = response.data.audio_content;
          setTranscript(transcription);
        } else {
          console.error('Operation failed:', response.message);
        }
      } catch (error) {
        console.error('Error during operation:', error);
      }

      if (!audioContent) return;
      console.log('Google TTS', audioContent);

      function base64ToUint8Array(base64) {
        const binaryString = window.atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
      }

      try {
        console.log('Decoding audio content...');
        const audioBytes = base64ToUint8Array(audioContent);
        console.log('Audio bytes:', audioBytes);
        const audioBlob = new Blob([audioBytes], { type: 'audio/wav' });
        const audioUrl = URL.createObjectURL(audioBlob);

        console.log('Playing message', agent_id, audioUrl, visemes);
        playGoogleMessage(agent_id, audioUrl, visemes);
      } catch (error) {
        console.error('Error decoding audio content:', error);
      }
    },
  });

  useEffect(() => {
    if (vad.loading) {
      console.log('VAD is loading...');
    } else if (vad.errored) {
      console.error('VAD failed to load:', vad.errored.message);
    } else {
      console.log('VAD is ready and listening:', vad.listening);
    }
  }, [vad.loading, vad.errored, vad.listening]);



  const sendBlob = async (audio) => {
    if (vad.userSpeaking) {
      console.log('User is speaking');
    } else {
      console.log('User is not speaking');
    }
    return;
    console.log('Sending blob', audio, typeof audio);

    const wavBuffer2 = float32ToWav(audio, 16000);
    const sliceBlob = new Blob([wavBuffer2], { type: 'audio/wav' });
    console.log('Blob size:', sliceBlob.size);
    console.log(sliceBlob);


    const formData = new FormData();
    formData.append('audio', sliceBlob);
    const endpoint = `/api/topic/${topic.id}/tts-google/`;

    try {
      const response = await apiService.post(endpoint, formData);
      if (response.success) {
        const interimTranscription = response.data.transcription;
        setTranscript(interimTranscription);
      } else {
        console.error('Interim operation failed:', response.message);
      }
    } catch (error) {
      console.error('Error during interim operation:', error);
    }
  };
  const { startStream, stopStream } = useAudioStream(sendBlob, 500); // 2000ms interval

  const toggleMic = useCallback(() => {
    if (!isRecording) {
      console.log('startStreamstartStreamstartStreamstartStream');
      vad.start();
      startStream();
      setIsRecording(true);
    } else {
      vad.pause();
      stopStream();
      setIsRecording(false);
    }
  }, [isRecording, vad, startStream, stopStream]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && isOpen) {
        toggleMic();
      }
    };
    window.addEventListener('keypress', handleKeyPress);
    return () => window.removeEventListener('keypress', handleKeyPress);
  }, [toggleMic, isOpen]);

  return (
    <>
      <Button color="danger" onClick={toggleMic}>
        {isRecording ? (<span className="bi bi-mic"></span>) : (<span className="bi bi-mic-mute"></span>)}
        {isRecording ? t('Stop') : t('Start')}
      </Button>
    </>
  );
};

export default VadGoogleMic;
