import React from 'react';
import { useTranslation } from 'react-i18next';

const WorkflowsWidget = ({ workflows, showCreateWorkflowModal, className, title = undefined }) => {
  const { t } = useTranslation();
  if (!workflows || workflows.length === 0) {
    return (
      <div className={`workflows-widget ${className}`}>
        <p><strong>{title ? title : t('Workflows')}</strong></p>
        <p>{t('No workflows found')}</p>
        {/*toggleCreateWorkflowModal && <Button outline size="sm" onClick={toggleCreateWorkflowModal}>{t('Add workflow')}</Button>*/}
      </div>
    );
  }

  return (
    <div className={`workflows-widget ${className}`}>
      <p><strong>{title ? title : t('Workflows')}</strong></p>
      {workflows.map((title, index) => (
        <p
          key={`workflow-${index}`}
          style={{ listStyleType: "none", cursor: 'pointer' }}
          onClick={() => showCreateWorkflowModal(title)}
          className="text-truncate b5"
        >
          <i className="bi bi-gear"></i> {title}
        </p>
      ))}
      {/*toggleCreateWorkflowModal && <Button outline size="sm" onClick={toggleCreateWorkflowModal}>{t('Add workflow')}</Button>*/}
    </div>
  );
};

export default WorkflowsWidget;
