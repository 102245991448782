import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Row, Col
} from 'reactstrap';
import apiService from '../../utils/apiService';
import AgentDropDown from '../../components/AgentDropDown/AgentDropDown';
import { ButtonFlexContainer, StyledActionButton } from '../../components/DocumentTemplate/ChatBotGuided/ChatBotGuided.styles';
import useStore from '../../store';
import { StyledHeader, StarButton } from './AgentPage.styles';
import Agent from '../../components/Agent/Agent';
import FlexLoader from '../../components/FlexLoader/FlexLoader';
import ResourcesWidget from '../../components/ResourcesWidget/ResourcesWidget';
import ToolsWidget from '../../components/ToolsWidget/ToolsWidget';
import Chat from '../../components/Chat/Chat';
import ChatForm from '../../components/ChatForm/ChatForm';
import { useModalConference } from '../../contexts/ModalConferenceContext';
import { useModalVideoCall } from '../../contexts/ModalVideoCallContext';
import { useModalTakeNotes } from '../../contexts/ModalTakeNotesContext';
import ChatTopicsModal from '../../components/ChatTopicsModal/ChatTopicsModal';
import TopicButtons from '../../components/TopicButtons/TopicButtons';
import { getFeatureFlag } from '../../utils/featureFlags';
import { useTranslation } from 'react-i18next';

function AgentPage({ scrollToBottom }) {
  const { t } = useTranslation();
  const { agentId, projectId } = useParams();
  const { topic, agents, workspace, project, loggedIn, resources } = useStore((state) => ({
    topic: state.topic,
    agents: state.agents,
    workspace: state.workspace,
    project: state.project,
    loggedIn: state.loggedIn,
    resources: state.resources,
  }));
  const navigate = useNavigate();

  const agent = useMemo(() => agents && agents.find((agent) => agent.id === agentId), [agentId, agents]);
  const minimalAgents = useMemo(() => {
    if (!agent) return [];
    const configs = window.agent_configs || [];
    const config = configs.find((c) => c.id === agent.image_id);
    return [
      {
        ...agent,
        voice: config.voice === 'M' ? 'Stephen' : 'Ruth',
      }
    ];
  }, [agent]);

  const [chatResponses, setChatResponses] = useState([]);

  const { showModal } = useModalConference();
  const { showModal: showVideoCallModal } = useModalVideoCall();
  const { showModal: showTakeNotesModal } = useModalTakeNotes();
  const [showChatTopicsModal, setShowChatTopicsModal] = useState(false);
  const toggleChatTopicsModal = () => {
    loadChatTopics();
    setShowChatTopicsModal(!showChatTopicsModal);
  };
  const [ topics, setTopics ] = useState([]);
  const loadChatTopics = useCallback(async () => {
      const endpoint = `/api/topic/find/all/`;
      const formData = { agent_id: agentId, type: "AGENT" };
      try {
        const response = await apiService.post(endpoint, formData);
        if (response.success) {
          console.log('Chat topics loaded successfully', response.data);
          setTopics(response.data);
        } else {
          console.error('Operation failed:', response.message);
        }
      } catch (error) {
        console.error('Error during operation:', error);
      }
    }, [agentId]);

  useEffect(() => {
    if (!loggedIn) return;
    loadChatTopics();
  }, [loggedIn, agentId, loadChatTopics]);

  useEffect(() => {
    if (topic && topic.chat_history) {
      setChatResponses(topic.chat_history);
    }
  }, [topic]);

  const chatFormRef = useRef(null);
  const handleActionSubmit = async (action) => {
    chatFormRef.current.handleActionSubmit(action);
  };

  const startMeeting = async () => {
    showModal(t('Meeting'), minimalAgents);
  }
  const startVideoCall = async () => {
    showVideoCallModal(t('Video call'), minimalAgents);
  }
  const startTakeNotes = async () => {
    showTakeNotesModal(t('Notes'), minimalAgents);
  }

  const handlePromoteAgent = async (agentId, isPromote) => {
    if (!agentId) {
      console.error('Agent ID is missing:', agentId);
      return;
    }
    try {
      const response = await apiService.post(`/api/agents/${agentId}/${isPromote ? 'promote' : 'demote'}/`);
      if (!response.success) {
        console.error('Operation failed:', response.message);
        return;
      }
    } catch (error) {
      console.error('handlePromoteAgent error', error);
    }
  };

  const handleChatTopicDelete = async (topicId) => {
    // Optimistically update the UI
    const previousTopics = [...topics];
    setTopics(topics.filter((topic) => topic.id !== topicId));
    try {
      const response = await apiService.delete(`/api/topic/${topicId}/delete/`);
      if (response.success) {
        console.log('Chat topic deleted successfully', response.data);
        console.log(topicId, topic?.id, topicId === topic?.id);
        if (topicId === topic?.id) {
          const targetUrl = `/${workspace.id}/coworker/${agentId}/`;
          if (window.location.pathname === targetUrl) {
            window.location.reload();
          } else {
            navigate(targetUrl);
          }
        }
      } else {
        console.error('Operation failed:', response.message);
        // Revert the UI update if the deletion fails
        setTopics(previousTopics);
      }
    } catch (error) {
      console.error('Error during operation:', error);
      // Revert the UI update if an error occurs
      setTopics(previousTopics);
    }
  };

  const handleChatTopicSelect = async (topicId) => {
    navigate(`/${workspace.id}/coworker/${agentId}/${topicId}/`);
    toggleChatTopicsModal();
  };

  const handleNewChatTopic = useCallback(async () => {
    try {
      const payload = { agent_id: agentId };
      const response = await apiService.post(`/api/topic/new/`, payload);
      if (response.success) {
        console.log('New chat topic created successfully', response.data);
        // Add to setTopics
        setTopics([...topics, response.data]);
        navigate(`/${workspace.id}/coworker/${agentId}/${response.data.id}/`);
      } else {
        console.error('Operation failed:', response.message);
      }
    } catch (error) {
      console.error('Error during operation:', error);
    }
  }, [agentId, navigate, topics, workspace?.id]);

  const availableResources = useMemo(() => {
    if (!agent) return null;
    if (!resources) return null;
    return [...agent.resources, ...resources];
  }, [resources, agent]);


  if(projectId && !project) return (<FlexLoader />);
  if(!agents) return (<FlexLoader />);
  
  console.log('AgentPage: agent', agent);

  return (
    <>
      <Row className='gx-0'>
        <Col sm="12" md={{ size: 9 }}>
          <StyledHeader className='d-flex align-items-center justify-content-between mb-2'>
            <h1>
              {agent?.name}
              &nbsp;
              {getFeatureFlag("CO-WORKER PROMOTE") &&
                <StarButton className="star-button" size="sm" onClick={() => handlePromoteAgent(agent.id, !agent.promoted)}>
                  {agent?.promoted ? (
                    <span className="bi bi-star-fill"></span>
                  ) : (
                    <span className="bi bi-star"></span>
                  )}
                </StarButton>}
            </h1>
            {agent &&
              <div className='d-flex'>
                <TopicButtons newChatTopic={handleNewChatTopic} viewChatTopics={toggleChatTopicsModal} />
                {getFeatureFlag("CO-WORKER DROPDOWN") && <AgentDropDown agent={agent} startMeeting={startMeeting} startVideoCall={startVideoCall} startTakeNotes={startTakeNotes} newChatTopic={handleNewChatTopic} viewChatTopics={toggleChatTopicsModal} />}
              </div>}
          </StyledHeader>
        </Col>
      </Row>
      <Row className='gx-0'>
        <Col sm="12" md={{ size: 9 }}>
          {agent && <Agent agent={agent} enableRun={false} showHeader={false} showDropDown={false} handleStartMeeting={startMeeting} />}
          <Chat scrollToBottom={scrollToBottom} handleActionSubmit={handleActionSubmit} />
          {topic && topic.actions && (
            <ButtonFlexContainer className='mb-3'>
              {topic.actions.map((action, index) => (
                <StyledActionButton key={`action-btn-${index}`} color="primary" outline size="sm" onClick={(e) => handleActionSubmit(e, action)}>{action.label}</StyledActionButton>
              ))}
            </ButtonFlexContainer>
          )}
          <ChatForm ref={chatFormRef} key={topic?.id} />
        </Col>
        <Col sm="0" md="3" className="d-none d-md-block ps-md-4">
          {agent && !project && getFeatureFlag("CO-WORKER RESOURCES") && <div className="mb-3"><ResourcesWidget resources={availableResources} /></div>}
          {getFeatureFlag("CO-WORKER TOOLS") && <ToolsWidget resources={availableResources} />}
        </Col>
      </Row>
      <ChatTopicsModal
        isOpen={showChatTopicsModal}
        toggle={toggleChatTopicsModal}
        topics={topics}
        onDelete={handleChatTopicDelete}
        onSelect={handleChatTopicSelect}
        topicId={topic?.id}
      />
    </>
  );
}

export default AgentPage;
