import React, { useState, useMemo } from 'react';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
// import { CustomDropdownMenu, CustomDropdownToggle } from './ResourcesWidget.styles.js';
import ModalBasic from '../../components/ModalBasic/ModalBasic';
import ResourcesTable from '../../components/ResourcesTable/ResourcesTable';
import useStore from '../../store';

const ResourcesWidget = ({ resources, className, enableSelectedResources = false, selectedResources=[], toggleResourceSelection=() => {} }) => {
  const { t } = useTranslation();
  const { project } = useStore((state) => ({ project: state.project }));

  // Functions for Basic Modal
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  const visibleResources = useMemo(() => {
    if (!enableSelectedResources || !project || !resources) return resources;
    return resources.filter((resource) => project.selected_resources.includes(resource.id));
  }, [resources, enableSelectedResources, project]);

  return (
    <>
      <div className={className}>
        <div className='mb-2'><strong>{t('Resources')}</strong></div>
        {/*(!resources || resources.length === 0) && <p>{t('No files found')}</p>*/}
        {visibleResources && visibleResources.slice(0, 3).map(({ title, status }, index) => (
          <p key={`resource-${index}`} style={{ listStyleType: "none" }} className="text-truncate b5">
            {['PENDING', 'UPLOADING', 'SUMMARIZING', 'ANALYZING'].includes(status) && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
            {status === 'COMPLETE' && <i className="bi bi-file-text"></i>}
            {['FAILED', 'IRRELEVANT'].includes(status) && <i className="bi bi-exclamation-triangle-fill"></i>}
            &nbsp;
            {title}
          </p>
        ))}
        {visibleResources && visibleResources.length > 3 && (
          <p className="text-muted"><small>+{visibleResources.length - 3} {t('more')}</small></p>
        )}
        <Button outline size="sm" onClick={toggleModal}>{t('Edit resources')}</Button>
      </div>
      <ModalBasic
        isOpen={modal}
        toggle={toggleModal}
        title={t('Edit resources')}
      >
        {resources && <ResourcesTable resources={resources} enableSelectedResources={enableSelectedResources} selectedResources={selectedResources} toggleResourceSelection={toggleResourceSelection} />}
      </ModalBasic>
    </>
  );
};

export default ResourcesWidget;
