import styled from 'styled-components';

export const StatusCard = styled.div`
  margin-bottom: 1rem;
  .row {
    margin-right: 0;
    margin-left: 0;
  }
`;

export const StyledDropdown = styled.div`
  position: absolute;
  top: 0.25em;
  right: 0.25em;
  transform: translate(-10%, 10%);
  cursor: pointer;
  font-weight: smaller;
`;