import React, { useImperativeHandle, forwardRef, useRef, useEffect, useState } from 'react';
import { AvatarContainer, Emoji } from './AvatarAgent.styles';
import Visemes from './Visemes';

const AVATAR_DIMENSION = 300;
const MOUTH_SMILE = 0.15;
const MOUTH_OPEN = 0.15;

const AvatarAgent = forwardRef(({
    agent,
    onTalkDone,
    backgroundImage = 'office1-300x300.png',
  }, ref) => {
  const [showHand, setShowHand] = useState(false);
  const threeJSRef = useRef(null);
  const avatarContainerRef = useRef(null);
  const audioElementRef = useRef(null);
  const visemesRef = useRef(new Visemes());

  const canvasRef = useRef(null);
  const analyzerRef = useRef(null);
  const animationFrameRef = useRef(null);

  const setupAnalyzerRef = useRef(null);

  // Add visualization state
  const [visualizationEnabled, setVisualizationEnabled] = useState(true);
  const [visualizationOpacity, setVisualizationOpacity] = useState(0.7);
  const [visualizationColor, setVisualizationColor] = useState({
    start: 'rgba(0, 255, 255, 0.2)',
    end: 'rgba(0, 255, 255, 0.8)'
  });

  useEffect(() => {
    const initThreeJS = async () => {
      if (!avatarContainerRef.current) return;
      const config = {
        id: agent.id,
        className: 'avatar-xyz',
        type: 'gltf',
        //url: `${window.location.origin}/static/avatars/models/${agent.image_id}.glb`,
        url: `${window.cdn}/models%2F${agent.image_id}.glb`,
        bg: `/static/js/talk-head/bg/${backgroundImage}`,
        version: '1',
        canvasWidth: AVATAR_DIMENSION,
        canvasHeight: AVATAR_DIMENSION,
        container: avatarContainerRef.current,
      };

      // Assuming startApplication is a function that initializes Three.js and returns an instance
      // eslint-disable-next-line no-undef
      threeJSRef.current = await startApplication(config);
    };

    initThreeJS();
    return () => {
      // Cleanup Three.js or other resources when the component unmounts
      if (threeJSRef.current && threeJSRef.current.dispose) {
        threeJSRef.current.dispose();
      }
    };
  }, [backgroundImage, agent.id, agent.image_id]);

  useEffect(() => {
    // Create analyzer node when audio element is created
    const setupAnalyzer = () => {
      if (!audioElementRef.current) return;
      
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const source = audioContext.createMediaElementSource(audioElementRef.current);
      const analyzer = audioContext.createAnalyser();
      analyzer.fftSize = 256;
      
      source.connect(analyzer);
      analyzer.connect(audioContext.destination);
      analyzerRef.current = analyzer;
      
      // Remove this line - don't start visualization here
      // startVisualization();
    };

    // Store setupAnalyzer in a ref so it can be called from playPollyMessageStream
    setupAnalyzerRef.current = setupAnalyzer;

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, []);

  // Update startVisualization to maintain circular pattern in both idle and active states
  const startVisualization = () => {
    const draw = () => {
      if (!visualizationEnabled) {
        animationFrameRef.current = requestAnimationFrame(draw);
        return;
      }

      const canvas = canvasRef.current;
      if (!canvas) return;

      const ctx = canvas.getContext('2d');
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;
      const radius = Math.min(centerX, centerY) - 10;

      // Clear the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Get frequency data
      const bufferLength = 128;
      const dataArray = new Uint8Array(bufferLength);

      if (analyzerRef.current) {
        analyzerRef.current.getByteFrequencyData(dataArray);
        
        // Draw circular visualization
        const barCount = 180;
        const barWidth = (2 * Math.PI) / barCount;

        // Draw base circle
        ctx.beginPath();
        ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
        ctx.strokeStyle = visualizationColor.start;
        ctx.lineWidth = 1;
        ctx.stroke();

        // Draw bars in a circle
        for (let i = 0; i < barCount; i++) {
          const angle = i * barWidth;
          const value = dataArray[Math.floor((i / barCount) * bufferLength)];
          const barHeight = (value / 255.0) * 30;

          const innerRadius = radius;
          const outerRadius = innerRadius + barHeight;

          const startX = centerX + Math.cos(angle) * innerRadius;
          const startY = centerY + Math.sin(angle) * innerRadius;
          const endX = centerX + Math.cos(angle) * outerRadius;
          const endY = centerY + Math.sin(angle) * outerRadius;

          const gradient = ctx.createLinearGradient(startX, startY, endX, endY);
          gradient.addColorStop(0, visualizationColor.start);
          gradient.addColorStop(1, visualizationColor.end);

          ctx.beginPath();
          ctx.moveTo(startX, startY);
          ctx.lineTo(endX, endY);
          ctx.strokeStyle = gradient;
          ctx.lineWidth = 2;
          ctx.stroke();
        }
      }

      animationFrameRef.current = requestAnimationFrame(draw);
    };
    draw();
  };

  const playGoogleMessage = (audioUrl, visemes) => {
    return new Promise((resolve, reject) => {
      const audioElement = new Audio(audioUrl);
      audioElementRef.current = audioElement; // Store the audio element in the ref
      audioElement.play();
      audioElement.addEventListener('playing', (event) => {
        threeJSRef.current.avatar.morphAnimatorSpeech.animateQueue(visemes, MOUTH_SMILE, MOUTH_OPEN);;
      });
      audioElement.addEventListener('ended', () => {
        resolve();
      });
      audioElement.addEventListener('error', (err) => {
        console.error('Audio playback error:', err);
        reject(err); // Reject the promise if there's an error
      });
    });
  };

  const playElevenlabsAlignments = async (alignments) => {
    const playElevenlabsAlignments = (alignments) => {
      const processedVisemes = visemesRef.current.processElevenlabsAlignments(alignments);
      threeJSRef.current.avatar.morphAnimatorSpeech.animateQueue(processedVisemes, MOUTH_SMILE, MOUTH_OPEN);
    };
    playElevenlabsAlignments(alignments);
  }

  const playElevenlabsAlignmentsMillis = async (alignments) => {
    const playElevenlabsAlignments = (alignments) => {
      const processedVisemes = visemesRef.current.processElevenlabsAlignments(alignments);
      threeJSRef.current.avatar.morphAnimatorSpeech.animateQueue(processedVisemes, MOUTH_SMILE, MOUTH_OPEN);
    };
    playElevenlabsAlignments(alignments);
  }

  const playMillisMessage = (alignments) => {
    const processedVisemes = visemesRef.current.processMillisAlignments(alignments);
    threeJSRef.current.avatar.morphAnimatorSpeech.animateQueue(processedVisemes, MOUTH_SMILE, MOUTH_OPEN);
  }

  const stopTalking = () => {
    if (audioElementRef.current) {
      audioElementRef.current.pause();
      audioElementRef.current.currentTime = 0; // Reset the audio to the beginning
    }
    threeJSRef.current.avatar.morphAnimatorSpeech.stop(); // Clear the animation queue
    onTalkDone(); // Call onTalkDone to handle any cleanup
  };

  // Add visualization control methods
  const visualizationControls = {
    enable: () => setVisualizationEnabled(true),
    disable: () => setVisualizationEnabled(false),
    toggle: () => setVisualizationEnabled(prev => !prev),
    setOpacity: (opacity) => setVisualizationOpacity(opacity),
    setColors: (startColor, endColor) => {
      setVisualizationColor({
        start: startColor,
        end: endColor
      });
    },
    getState: () => ({
      enabled: visualizationEnabled,
      opacity: visualizationOpacity,
      colors: visualizationColor
    }),
    setAnalyzer: (analyzer) => {
      if (analyzerRef.current) {
        analyzerRef.current.disconnect();
      }
      analyzerRef.current = analyzer;
      // Start visualization only after analyzer is set
      startVisualization();
    },
  };

  // Update useImperativeHandle to include visualization controls
  useImperativeHandle(ref, () => ({
    playGoogleMessage,
    stopTalking,
    setShowHand,
    playElevenlabsAlignmentsMillis,
    playElevenlabsAlignments,
    playMillisMessage,
    visualization: visualizationControls
  }));

  return (
    <>
      <AvatarContainer id={`agent-container-${agent.id}`}>
        <canvas
          ref={canvasRef}
          width={AVATAR_DIMENSION}
          height={AVATAR_DIMENSION}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1, // Move above the avatar
            opacity: visualizationOpacity,
            display: visualizationEnabled ? 'block' : 'none',
            pointerEvents: 'none', // Allow clicking through to avatar
          }}
        />
        <div ref={avatarContainerRef} style={{ position: 'relative', zIndex: 0 }}></div>
        <Emoji show={showHand ? "true" : "false"}>
            ✋
        </Emoji>
        <div className='text-center my-2'><strong>{agent.name}</strong></div>
      </AvatarContainer>
    </>
  );
});

export default AvatarAgent;
