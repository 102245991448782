import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, FormGroup, Input, Row, Col, Label } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import ModalBasic from '../../components/ModalBasic/ModalBasic';
import apiService from '../../utils/apiService';
import useStore from '../../store';

function RegisterPinPage() {
  const { t } = useTranslation();
  const [pin, setPin] = useState({ pin1: '', pin2: '', pin3: '', pin4: '' });
  const [tosAccepted, setTosAccepted] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const firstInputRef = useRef(null);
  const navigate = useNavigate();
  const loginEmail = useStore((state) => state.loginEmail);
  const {
    setLoggedIn,
    setIsSuperUser,
    setIsVerified,
    setUserId,
    language,
    setCsrfToken,
    setEmail,
    setUsername,
    setOrganization,
    setSubscribed,
    setDaysLeftOnTrial,
    setLanguage
  } = useStore(state => ({
    setLoggedIn: state.setLoggedIn,
    setIsSuperUser: state.setIsSuperUser,
    setIsVerified: state.setIsVerified,
    setUserId: state.setUserId,
    language: state.language,
    setCsrfToken: state.setCsrfToken,
    setEmail: state.setEmail,
    setUsername: state.setUsername,
    setOrganization: state.setOrganization,
    setSubscribed: state.setSubscribed,
    setDaysLeftOnTrial: state.setDaysLeftOnTrial,
    setLanguage: state.setLanguage
  }));
  // Functions for Basic Modal
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const toggleModal = () => setModal(!modal);

  useEffect(() => {
    if (firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, []);

  const handlePinChange = (e) => {
    const { name, value } = e.target;
    setPin({ ...pin, [name]: value });

    // Construct the pin code including the latest change
    const updatedPin = { ...pin, [name]: value };
    const pinCode = Object.values(updatedPin).join('');

    if (value.length === 1 && name !== 'pin4') {
      const nextSibling = document.querySelector(`input[name=pin${parseInt(name.charAt(3)) + 1}]`);
      if (nextSibling !== null) {
        nextSibling.focus();
      }
    } else if (name === 'pin4') {
      // Use the updated pinCode that includes the 4th pin
      handleSubmit(pinCode); // Automatically submit when the last pin is filled
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const paste = e.clipboardData.getData('text');
    const pins = paste.split('').slice(0, 4);
    const pinCode = pins.join(''); // Directly construct pinCode from pasted value

    // Update the state with the pasted values
    setPin({ pin1: pins[0] || '', pin2: pins[1] || '', pin3: pins[2] || '', pin4: pins[3] || '' });

    // Check if all pins are filled
    if (pins.length === 4 && pins.every(value => value !== '')) {
      // Only submit if both checkboxes are checked
      if (tosAccepted && privacyAccepted) {
        handleSubmit(pinCode); // Use a direct submit function
      }
    } else {
      // Focus the next empty input
      const nextEmptyIndex = pins.findIndex((value, index) => !value);
      if (nextEmptyIndex !== -1) {
        document.querySelector(`input[name=pin${nextEmptyIndex + 1}]`).focus();
      }
    }
  };

  // Create a new function to handle direct submission with a given pinCode
  const handleSubmit = async (pinCode) => {
   try {
      console.log('RegisterPinPage sending data to API:', { email: loginEmail, pin_code: pinCode, language: language});
      const response = await apiService.post('/api/register_pin/', { email: loginEmail, pin_code: pinCode, language: language});
      if (response.success) {
        console.log('RegisterPinPage login successful:', response);
        const {
          csrf_token,
          is_authenticated: isLoggedIn,
          user_id: userId,
          is_superuser: isSuperUser,
          is_verified: isVerified,
          email,
          username,
          language,
          organization,
          is_subscribed,
          days_left_on_trial: daysLeftOnTrial,
          redirect
        } = response.data;
        setCsrfToken(csrf_token);
        setLoggedIn(isLoggedIn);
        setUserId(userId);
        setIsSuperUser(isSuperUser);
        setIsVerified(isVerified);
        setEmail(email);
        setUsername(username);
        setOrganization(organization);
        setSubscribed(is_subscribed);
        setDaysLeftOnTrial(daysLeftOnTrial);
        if (language)  setLanguage(language);
        navigate(redirect || '/');
      } else {
        console.error('Login failed:', response.message);
      }
    } catch (error) {
      console.error('Error during pin login:', error);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const pinCode = Object.values(pin).join('');
    handleSubmit(pinCode);
  };

  const handleShowTOS = async (e) => {
    e.preventDefault();
    setModalTitle('Terms of Service'); // Set modal title

    try {
      const response = await fetch(window.link_tos); // Replace with your TOS URL
      let body = await response.text();
      if (body.startsWith('```markdown')) {
        body = body.substring(11, body.length - 3);
      }
      setModalBody(body);
    } catch (error) {
      console.error('Error fetching TOS:', error);
      setModalBody('Failed to load Terms of Service. Please try again later.');
    }

    setModal(true); // Display the modal
  };

  const handleShowPP = async (e) => {
    e.preventDefault();
    setModalTitle('Privacy Policy'); // Set modal title

    try {
      const response = await fetch(window.link_pp); // Replace with your TOS URL
      let body = await response.text();
      if (body.startsWith('```markdown')) {
        body = body.substring(11, body.length - 3);
      }
      setModalBody(body);
    } catch (error) {
      console.error('Error fetching TOS:', error);
      setModalBody('Failed to load Terms of Service. Please try again later.');
    }

    setModal(true); // Display the modal
  };

  return (
    <div>
      <h2>{t('Login with Pin')}</h2>
      <Row>
        <Col sm="12" md={{ size: 6 }}>
          <Form onSubmit={handleFormSubmit}>
            <FormGroup>
              {Object.keys(pin).map((key, index) => (
                <Input
                  key={key}
                  type="text"
                  name={key}
                  id={key}
                  maxLength="1"
                  value={pin[key]}
                  onChange={handlePinChange}
                  onPaste={handlePaste}
                  innerRef={index === 0 ? firstInputRef : null}
                  style={{ width: '60px', display: 'inline-block', marginRight: '5px' }}
                />
              ))}
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  id="tosAccept"
                  checked={tosAccepted}
                  onChange={(e) => setTosAccepted(e.target.checked)}
                />{' '}
                {t('I accept the')}
                &nbsp;
                <Button color="link" onClick={handleShowTOS} className="p-0 align-baseline">
                  {t('Terms of Service')}
                </Button>
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  id="privacyAccept"
                  checked={privacyAccepted}
                  onChange={(e) => setPrivacyAccepted(e.target.checked)}
                />{' '}
                {t('I accept the')}
                &nbsp;
                <Button color="link" onClick={handleShowPP} className="p-0 align-baseline">
                  {t('Privacy Policy')}
                </Button>
              </Label>
            </FormGroup>
            <Button color="primary" type="submit" className="mt-4">{t('Submit')}</Button>
          </Form>
        </Col>
      </Row>
      {/* Modals for Privacy Policy and Terms of Service */}
      <ModalBasic isOpen={modal} toggle={toggleModal} title={modalTitle}>
        <div className="modal-body overflow-auto" style={{ maxHeight: '400px' }}>
          <ReactMarkdown remarkPlugins={[remarkGfm]}>
            {modalBody}
          </ReactMarkdown>
        </div>
      </ModalBasic>
    </div>
  );
}

export default RegisterPinPage;
