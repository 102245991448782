import { useEffect, useState } from 'react';
import useSWR from 'swr';
import '../../i18n';
import { useTranslation } from 'react-i18next';
import useStore from '../../store'; // Path to your Zustand store

const fetcher = (...args) => fetch(...args).then(res => res.json()); // Define the fetcher function

function RealTimeData() {
  const { i18n } = useTranslation();
  const [isI18nInitialized, setIsI18nInitialized] = useState(false);
  const [initializedState, setInitializedState] = useState(false);
  const {
    setCsrfToken,
    setLoggedIn,
    setUserId,
    // setProjects,
    setIsSuperUser,
    setConfig,
    setLoading,
    setIsVerified,
    setEmail,
    setUsername,
    setLanguage,
    language,
    setSubscribed,
    setOrganization,
    setWorkspace,
    workspace,
    setDaysLeftOnTrial,
    setBackgroundColor,
    setIcon,
  } = useStore((state) => ({
    setCsrfToken: state.setCsrfToken,
    setLoggedIn: state.setLoggedIn,
    setUserId: state.setUserId,
   // setProjects: state.setProjects,
    setIsSuperUser: state.setIsSuperUser,
    setConfig: state.setConfig,
    setLoading: state.setLoading,
    setIsVerified: state.setIsVerified,
    setEmail: state.setEmail,
    setUsername: state.setUsername,
    setLanguage: state.setLanguage,
    language: state.language,
    setSubscribed: state.setSubscribed,
    setOrganization: state.setOrganization,
    setWorkspace: state.setWorkspace,
    workspace: state.workspace,
    setDaysLeftOnTrial: state.setDaysLeftOnTrial,
    setBackgroundColor: state.setBackgroundColor,
    setIcon: state.setIcon,
  }));
  // const { loggedIn } = useStore((state) => ({ loggedIn: state.loggedIn }));
  // const { data: csrfToken } = useSWR('/api/csrf/', fetcher);
  const { data: config } = useSWR('/api/config/', fetcher);
  const { data: status } = useSWR('/api/login_status/', fetcher);
  // const { data: projects, error } = useSWR('/api/projects/', fetcher);

  useEffect(() => {
    // Check if all SWR data fetching is done
    if (config && status ) {
      setLoading(false); // Set loading to false when all data is fetched
    }
  }, [config, status, setLoading]);

  useEffect(() => {
    if (config && config.data) {
      setConfig(config.data);
    }
  }, [config, setConfig]);

  useEffect(() => {
    if (!initializedState && status && status.data) {
      // NOTE: any changes here must be made to LoginPinPage and ResiterPinPage
      const {
        csrf_token,
        is_authenticated: isLoggedIn,
        user_id: userId,
        is_superuser: isSuperUser,
        is_verified: isVerified,
        email,
        username,
        language,
        organization,
        is_subscribed,
        days_left_on_trial: daysLeftOnTrial,
        background_color: backgroundColor,
        icon,
      } = status.data;

      setCsrfToken(csrf_token);
      setLoggedIn(isLoggedIn);
      setUserId(userId);
      setIsSuperUser(isSuperUser);
      setIsVerified(isVerified);
      setEmail(email);
      setUsername(username);
      setOrganization(organization);
      setSubscribed(is_subscribed);
      setDaysLeftOnTrial(daysLeftOnTrial);
      if (language)  setLanguage(language);
      setBackgroundColor(backgroundColor);
      setIcon(icon);
      setInitializedState(true); // Only run once
    }
  }, [status, setLoggedIn, setIsSuperUser, setIsVerified, setUserId, setEmail, setUsername, setLanguage, setOrganization, setSubscribed, setCsrfToken, setWorkspace, workspace, initializedState, setDaysLeftOnTrial, setBackgroundColor, setIcon]);

  useEffect(() => {
    if (language && i18n) {
      // console.log('Language changed to:', language);
      i18n.changeLanguage(language).then(() => {
        setIsI18nInitialized(true);
      });
    }
  }, [language, i18n]);

  /*i18n.on('languageChanged', (lng) => {
    console.log('Language changed to:', lng);
  });*/

 /* i18n.on('loaded', (loaded) => {
    console.log('Loaded namespaces:', loaded);
  }); */
  /*
  useEffect(() => {
    if (projects && projects.data && projects.data.projects) {
      setProjects(projects.data.projects);
    }
  }, [loggedIn, projects, error, setProjects]);*/

  if (!isI18nInitialized) {
    return null; // or a loading spinner
  }

  return null;
}

export default RealTimeData;
