import React, { useRef, useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { StyledTextArea } from './AutoResizeTextareaOneLine.styles';

const AutoResizeTextarea = forwardRef(({ initialValue, onChange, autoFocus, ...props }, ref) => {
  const { t } = useTranslation();
  const [text, setText] = useState(initialValue || '');
  const [singleLineHeight, setSingleLineHeight] = useState(0);
  const internalRef = useRef(null);

  useImperativeHandle(ref, () => internalRef.current);

  useEffect(() => {
    setText(initialValue);
    if (initialValue === '') {
      // Reset the height to a single line when the input is empty
      internalRef.current.style.height = `${singleLineHeight}px`;
    }
  }, [initialValue, singleLineHeight]);

  const adjustRows = () => {
    if (internalRef.current) {
      // Directly adjust the height based on the scrollHeight to fit the content.
      // This approach avoids the intermediate 'auto' height, which can cause an increase to two rows.
      const borderTop = parseInt(window.getComputedStyle(internalRef.current).borderTopWidth, 10);
      const borderBottom = parseInt(window.getComputedStyle(internalRef.current).borderBottomWidth, 10);
      const verticalBorderHeight = borderTop + borderBottom;

      // Adjust the height directly based on scrollHeight, accounting for any border height.
      internalRef.current.style.height = `${internalRef.current.scrollHeight + verticalBorderHeight}px`;
    }
  };

  useEffect(() => {
    adjustRows(); // Adjust height on initial render
  }, [text]);

  const handleChange = (e) => {
    setText(e.target.value);
    if (e.target.value === '') {
      // Reset the height to a single line when the input is empty
      internalRef.current.style.height = `${singleLineHeight}px`;
    }
    if (onChange) {
      onChange(e);
    }
  };

  useEffect(() => {
    if (internalRef.current) {
      const style = window.getComputedStyle(internalRef.current);
      const fontSize = parseInt(style.fontSize, 10);
      const lineHeight = parseInt(style.lineHeight, 10) || fontSize * 1.2; // Use lineHeight if available, else default to fontSize * 1.2
      const paddingTop = parseInt(style.paddingTop, 10);
      const paddingBottom = parseInt(style.paddingBottom, 10);
      const borderTopWidth = parseInt(style.borderTopWidth, 10);
      const borderBottomWidth = parseInt(style.borderBottomWidth, 10);

      // Calculate the height of a single line of text based on the line height and add vertical padding and border width
      const slh = lineHeight + paddingTop + paddingBottom + borderTopWidth + borderBottomWidth;
      setSingleLineHeight(slh);

      // Set the textarea height to match the height of a single line of text including padding and border width
      internalRef.current.style.height = `${slh}px`;
      internalRef.current.style.minHeight = `${slh}px`;
    }
  }, []);

  useEffect(() => {
    if (autoFocus && internalRef.current) {
      internalRef.current.focus();
    }
  }, [autoFocus]);

  return (
    <StyledTextArea
      {...props}
      ref={internalRef}
      value={text}
      onChange={handleChange}
      className='w-100 p-2'
      placeholder={t('Type your message here')}
    />
  );
});

AutoResizeTextarea.propTypes = {
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool, // Add autoFocus prop type
};

export default AutoResizeTextarea;
