import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Button, Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import useStore from '../../../store';
import useElevenLabsJSON from '../../../hooks/useElevenLabsJSON';

const DeepgramElevenLabsMic = ({ isOpen, playElevenlabsAlignments, setTranscript }) => {
  const { t } = useTranslation();
  const [internalTranscript, setInternalTranscript] = useState('');
  const [initialized, setInitialized] = useState(false);
  const [micOn, setMicOn] = useState(false);
  const { topic, language } = useStore((state) => ({
    topic: state.topic,
    language: state.language,
  }));

  const deepgramRef = useRef({});
  const audioRef = useRef(null);
  const { respond, isPlaying, isLoading } = useElevenLabsJSON(audioRef);

  const doTTS = useCallback(async (text) => {
    if(!text || text.length === 0) return;
    // respond(topic.id, text, playElevenlabsAlignments);
  }, [playElevenlabsAlignments, respond, topic.id]);

  const closeDeepgram = useCallback(() => {
    if (deepgramRef.current.mediaStream) { deepgramRef.current.mediaStream.getTracks().forEach((track) => track.stop()); }
    if (deepgramRef.current.mediaRecorder) { deepgramRef.current.mediaRecorder.stop(); }
    if (deepgramRef.current.socketDeepgram) { deepgramRef.current.socketDeepgram.close(); }
    deepgramRef.current = {};
    doTTS(internalTranscript);
    setInternalTranscript('');
    setTranscript('');
  }, [doTTS, internalTranscript, setTranscript]);

  const initDeepgram = useCallback(() => {
    console.log('!!!! initDeepgram');
    if (deepgramRef.current.socketDeepgram) {
      closeDeepgram();
      return;
    }
    if (!MediaRecorder.isTypeSupported('audio/webm')) {
      alert('Browser not supported');
      return;
    }
    navigator.mediaDevices.getUserMedia({ audio: true }).then(async (stream) => {
      console.log('initDeepgram');
      const wsUrl = language === 'en' ? 'wss://api.deepgram.com/v1/listen?model=nova-2-conversationalai&punctuate=true&smart_format=true&interim_results=true&utterance_end_ms=1500&vad_events=true'
      : 'wss://api.deepgram.com/v1/listen?model=nova-2&punctuate=true&smart_format=true&interim_results=true&utterance_end_ms=1000&vad_events=true&language=sv';
      const socketDeepgram = new WebSocket(wsUrl, [
        'token',
        process.env.REACT_APP_DEEPGRAM_API_KEY,
      ]);
      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: 'audio/webm',
      });

      socketDeepgram.onopen = () => {
        mediaRecorder.addEventListener('dataavailable', async (event) => {
          console.log('initDeepgram dataavailable');
          if (event.data.size > 0 && socketDeepgram.readyState === 1) {
            socketDeepgram.send(event.data);
          }
        });
        mediaRecorder.start(1000);
      };

      const completed = [];
      let ongoing = ''; // Track the ongoing interim internalTranscript
      socketDeepgram.onmessage = (message) => {
        const received = JSON.parse(message.data);
        const { type, channel, is_final } = received;
        const { alternatives } = channel;
        const currTranscript = alternatives ? alternatives[0].transcript.trim() : '';
        console.log('DG message', type, is_final, received);
        if (currTranscript.length === 0) return;

        if (!is_final) {
          ongoing = currTranscript; // Update the interim internalTranscript
          setInternalTranscript(currTranscript);
          setTranscript(currTranscript);
        } else {
          // completed.push(currTranscript);
          console.log('!!!! DG final', currTranscript);
          ongoing = '';
          setInternalTranscript(`FINAL: ${currTranscript}`);
          setTranscript(`FINAL: ${currTranscript}`);
        }
         // eslint-disable-next-line no-unused-vars
         const composite = completed.join(' ') + (ongoing ? ` ${ongoing}` : '');
        // if(!isPlaying) doTTS(composite);
      };

      deepgramRef.current = { socketDeepgram, mediaRecorder, mediaStream: stream };
    }).catch((error) => {
      console.error('Error accessing microphone:', error);
    });
  }, [closeDeepgram, doTTS, isPlaying, language, setTranscript]);


  const handleMic = useCallback(() => {
    if (deepgramRef.current.socketDeepgram) {
      closeDeepgram();
    } else {
      initDeepgram();
    }
    // toggle setMicOn
    setMicOn(!micOn);
  }, [closeDeepgram, initDeepgram, micOn]);

  // Ensure closeDeepgram and initDeepgram are stable with useCallback
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && isOpen) {
        handleMic();
      }
    };
    window.addEventListener('keypress', handleKeyPress);
    return () => window.removeEventListener('keypress', handleKeyPress);
  }, [handleMic, isOpen]); // Depends on closeDeepgram and initDeepgram if they are stable

  /**/
  useEffect(() => {
    if(!initialized) {
      setInitialized(true);
      setMicOn(true);
      if (!deepgramRef.current.socketDeepgram) initDeepgram();
    }
  }, [initDeepgram, initialized]);

  return (
    <>
      <audio ref={audioRef} />
      <div className="d-flex justify-content-center align-items-center w-100">
        {isLoading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
        {isPlaying && <Spinner color="primary" type="grow"></Spinner>}
        {!isPlaying && !isLoading && <Button color="danger" onClick={handleMic}>
          {micOn ? (<span className="bi bi-mic"></span>) : (<span className="bi bi-mic-mute"></span>)}
          {micOn? t('Stop') : t('Start')}
        </Button>}
      </div>
    </>
  );
};

export default DeepgramElevenLabsMic;
