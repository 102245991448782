const PHASES = {
  PENDING: 1,
  PLAYING: 2,
  PLAYING_WAITING: 3,
  STOPPED: 4,
}

class PlayerCue {
  phase = PHASES.PENDING;
  isPlaying = false; // if audio is currently playing
  cue = [];
  index = undefined; // Current playing index of cue
  stoppedFN = undefined;
  audioElementRef = new Audio();
  previousUrl = undefined;

  constructor(stoppedFN) {
    this.stoppedFN = stoppedFN;
  }
  playPhraseAudio(id, text, audioUrl, alignment) {
    if (this.phase !== PHASES.PENDING) {console.log('[PlayerCue.playPhraseAudio] OOPS! We are not pending!');return;}
    if (this.isPlaying) {console.log('[PlayerCue.playPhraseAudio] OOPS! We are already playing!');return;}
    this.cue.push({
      id,
      text,
      audioUrl,
      alignment
    });
    this.next(); // Should start playing
  }
  setupCompletionAudios(audios) {
    // All item.audio are undefined and wait for setCompletionAudio()
    this.cue = [
      ...this.cue,
      ...audios
    ]
  }
  setCompletionAudio(id, audioUrl, alignment) {
    // console.log('1 setCompletionAudio', id, audioUrl);
    const index = this.cue.findIndex((cue) => cue.id === id);
    if (index === -1) return;
    this.cue[index].audioUrl = audioUrl;
    this.cue[index].alignment = alignment;
    // console.log('2 setCompletionAudio', id, index ,this.cue);
    this.next(); // Attempt to play next!
  }
  stop() {
    this.phase = PHASES.STOPPED;
    this.audioElementRef.pause();
  }
  next() {
    if (this.isPlaying) {console.log('[PlayerCue.next] We are already playing!');return;}
    if (this.cue.length === 0) return;
    // Decide current index
    if (this.index === undefined) {
      this.index = 0;
    } else {
      // If we are waiting then index is not incremented
      if (this.phase !== PHASES.PLAYING_WAITING) this.index++;
    }
    // Did we reach the end?
    if (this.index >= this.cue.length) {
      // Take into account we might not have completion yet
      if (this.cue.length === 1){
        console.log('next() END, waiting for completion');
        this.phase = PHASES.PLAYING_WAITING;
        return;
      } 
      console.log('next() END');
      this.phase = PHASES.STOPPED;
      this.stoppedFN();
      return;
    }
    // Is next item pending?
    if (this.cue[this.index].audioUrl === undefined) {
      console.log('next() WAITING', this.index, this.cue[this.index]);
      this.phase = PHASES.PLAYING_WAITING;
      // We might want to play hmmm
      return;
    }
    console.log('next() PLAY', this.index);
    this.play();
  }
  play() {
    if (this.cue.length === 0) {console.log('[PlayerCue] OOPS!The cue is empty!');return;}
    if (this.index === undefined) {console.log('[PlayerCue] OOPS!The index is undefined!');return;}
    if (this.index >= this.cue.length) {console.log('[PlayerCue] OOPS!The index is out of bounds!');return;}
    if (this.cue[this.index].audioUrl === undefined) {console.log('[PlayerCue] OOPS!The audio is undefined!');return;}
    if (this.phase === PHASES.STOPPED) {console.log('[PlayerCue] OOPS! Already STOPPED should only play once!');return;}
    
    this.phase = PHASES.PLAYING;
    
    // Revoke the previous URL if it exists, to avoid memory leaks
    if (this.previousUrl) {
      URL.revokeObjectURL(this.previousUrl); 
    }
    
    const currIndex = this.index;
    this.audioElementRef.src = this.cue[this.index].audioUrl;
    this.previousUrl = this.cue[this.index].audioUrl; 
    console.log('[PlayerCue] Playing', currIndex, this.cue[currIndex].text);
    
    // Remove existing event listeners before adding new ones
    this.audioElementRef.removeEventListener('ended', this.handleAudioEnded);
    this.audioElementRef.removeEventListener('error', this.handleAudioError);
    this.audioElementRef.removeEventListener('play', this.handleAudioPlay);
    
    // Define event handlers
    this.handleAudioEnded = () => {
      console.log(`[PlayerCue] ${currIndex} Audio ended`);
      this.isPlaying = false;
      this.next();
    };
    
    this.handleAudioError = (e) => {
      console.log('[PlayerCue] OOPS! Audio error!', e);
      this.isPlaying = false;
      this.next(); // Not sure if this is the best thing to do?
    };
    
    this.handleAudioPlay = () => {
      console.log(`[PlayerCue] ${currIndex} Audio started`);
      this.isPlaying = true;
    };
    
    // Add new event listeners
    this.audioElementRef.addEventListener('ended', this.handleAudioEnded);
    this.audioElementRef.addEventListener('error', this.handleAudioError);
    this.audioElementRef.addEventListener('play', this.handleAudioPlay);
    
    // Play the audio
    this.audioElementRef.play().catch(error => {
      console.log('[PlayerCue] Play error:', error);
      this.isPlaying = false;
      this.next();
    });
    // And visemes...
  }
}

module.exports = PlayerCue;