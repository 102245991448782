import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, ListGroup, Badge } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { StyledListGroupItem, TopicNameContainer } from './ChatTopicsModal.styles.js';

function ChatTopicsModal({ isOpen, toggle, topics, onDelete, onSelect, topicId }) {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{t('Previous Chats')}</ModalHeader>
      <ModalBody>
        {topics && topics.length > 0 && (
          <ListGroup>
            {topics.map((topic) => (
              <StyledListGroupItem
                key={topic.id}
                onClick={() => onSelect(topic.id)}
                className={`p-1 ${topic.id === topicId ? 'selected' : ''}`} // Add conditional class
              >
                <TopicNameContainer>
                  {topic.name || t('Unnamed Chat')}
                  <Badge color="primary" pill>{topic.num_messages}</Badge>
                </TopicNameContainer>
                <Button color="link" size="sm" className="trash-button py-1 px-2" onClick={(e) => {e.stopPropagation(); onDelete(topic.id)}}>
                  <i className="bi bi-trash"></i>
                </Button>
              </StyledListGroupItem>
            ))}
          </ListGroup>
        )}
        {(!topics || topics.length === 0) && <p>{t('No chat topics found')}</p>}
      </ModalBody>
      <ModalFooter className='d-flex justify-content-end'>
        <Button color="primary" onClick={toggle}>{t('OK')}</Button>
      </ModalFooter>
    </Modal>
  );
}
export default ChatTopicsModal;
