import styled from 'styled-components';
import { DropdownToggle } from 'reactstrap';

export const AgentCard = styled.div`
  margin-bottom: 1rem;
  .row {
    margin-right: 0;
    margin-left: 0;
  }
`;

export const AgentProgress = styled.div`
  border: 1px solid darkmagenta;
  background-color: cornsilk;
  height: 0.8em;
  margin: 0.5em 0;
  display: flex;
`;

export const AgentProgressSegment = styled.div`
  height: 0.8em;
  border: 1px solid lightgray;
  background-color: lightgray;
  flex: 1;
`;

export const AgentProgressSegmentDone = styled(AgentProgressSegment)`
  background-color: darkmagenta;
`;

export const AgentText = styled.div`
  small {
    font-size: smaller !important;
  }
`;

export const CustomDropdownToggle = styled(DropdownToggle)`
  background-color: transparent;
  border: none;
  color: var(--bs-heading-color);
  padding: 0;
  font-size: small;
  &:hover {
    color: orange;
    background-color: transparent;
  }
`;
