import styled from 'styled-components';
import { Button } from 'reactstrap';

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}`;

export const StarButton = styled(Button)`
  border: none;
  background: transparent;
  color: inherit;
  &:hover, &:focus {
    outline: none;
    box-shadow: none;
  }
`;
