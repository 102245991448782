import styled from 'styled-components';

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;

  @media print {
    display: none; // Example: Hide the header when printing
  }
`;
