import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import apiService from '../../utils/apiService';
import useStore from '../../store';

function LoginDemoPage() {
  const { t } = useTranslation();
  const [loginEmail, setLoginEmail] = useState('');
  const [loginOrganization, setLoginOrganization] = useState('');
  const [website, setWebsite] = useState('');
  const [location, setLocation] = useState('');
  const [other, setOther] = useState('');
  const navigate = useNavigate();
  const {
    setLoggedIn,
    setIsSuperUser,
    setIsVerified,
    setUserId,
    setCsrfToken,
    setEmail,
    setUsername,
    setOrganization,
    setSubscribed,
    setDaysLeftOnTrial,
    setLanguage
  } = useStore(state => ({
    setLoggedIn: state.setLoggedIn,
    setIsSuperUser: state.setIsSuperUser,
    setIsVerified: state.setIsVerified,
    setUserId: state.setUserId,
    setCsrfToken: state.setCsrfToken,
    setEmail: state.setEmail,
    setUsername: state.setUsername,
    setOrganization: state.setOrganization,
    setSubscribed: state.setSubscribed,
    setDaysLeftOnTrial: state.setDaysLeftOnTrial,
    setLanguage: state.setLanguage
  }));

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await apiService.post('/api/login-demo/', {
        email: loginEmail,
        language: 'sv',
        organization: loginOrganization,
        website: website,
        location: location,
        other: other
      });
      if (response.success) {
        console.log('LoginDemoPage login successful:', response);
        const {
          csrf_token,
          is_authenticated: isLoggedIn,
          user_id: userId,
          is_superuser: isSuperUser,
          is_verified: isVerified,
          email,
          username,
          language,
          organization,
          is_subscribed,
          days_left_on_trial: daysLeftOnTrial,
          redirect
        } = response.data;
        setCsrfToken(csrf_token);
        setLoggedIn(isLoggedIn);
        setUserId(userId);
        setIsSuperUser(isSuperUser);
        setIsVerified(isVerified);
        setEmail(email);
        setUsername(username);
        setOrganization(organization);
        setSubscribed(is_subscribed);
        setDaysLeftOnTrial(daysLeftOnTrial);
        if (language)  setLanguage(language);
        navigate(redirect || '/');
      } else {
        console.error('Login failed:', response.message);
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  return (
    <div>
      <h2>Demo Login</h2>
      <p>{t('This is a demo login page. Enter details below to login and see your suggested AI-coworkers.')}</p>
      <Row>
        <Col sm="12" md={{ size: 6 }}>
          <Form onSubmit={handleLogin}>
            <FormGroup>
              <Label for="email">{t('Email')}</Label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder={t('Enter your email')}
                value={loginEmail}
                onChange={(e) => setLoginEmail(e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="organization">{t('Organization')} *</Label>
              <Input
                type="text"
                name="organization"
                id="organization"
                placeholder={t('Enter your organization')}
                value={loginOrganization}
                onChange={(e) => setLoginOrganization(e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="website">{t('Website')}</Label>
              <Input
                type="url"
                name="website"
                id="website"
                placeholder={t('Enter your website')}
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="location">{t('Location')}</Label>
              <Input
                type="text"
                name="location"
                id="location"
                placeholder="Linköping, Sweden"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="other">{t('Additional information')}</Label>
              <Input
                type="textarea"
                name="other"
                id="other"
                placeholder={t('Sells drones to the Swedish army')}
                value={other}
                onChange={(e) => setOther(e.target.value)}
              />
            </FormGroup>
            <Button color="primary" type="submit">{t('Submit')}</Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default LoginDemoPage;
