import React, { useState } from 'react';
import { Table, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Container, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { StyledRow } from './SubscriptionsTable.styles';

function SubscriptionsTable({ users, addUser, remUser, activeSubscriptions, enabled }) {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [newEmail, setNewEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [emailError, setEmailError] = useState('');


  const toggleDropdown = (id) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    if (validateEmail(newEmail)) {
      const response = await addUser(newEmail);
      if (response.success) {
        setNewEmail('');
        setIsEmailValid(true);
        setEmailError('');
      } else {
        setEmailError(response.message);
        setIsEmailValid(false);
      }
    } else {
      setIsEmailValid(false);
    }
  };

  const handleRemUser = (e, user) => {
    e.preventDefault();
    remUser(user);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleAddUser(e);
    }
  };

  return (
    <Container className="p-0">
      <Table className="mt-2 w-100">
        <thead>
          <tr>
            <th>{t('E-mail')}</th>
            <th className="text-end"><i className="bi bi-three-dots-vertical"></i></th>
          </tr>
        </thead>
        <tbody>
          {users.map((resource, index) => (
            <StyledRow key={`resource-${index}`} className={activeSubscriptions > index ? '' : 'table-danger'}>
              <td>{resource.email} {resource.invited ? t('(invited)') : ''}</td>
              <td className="text-end">
                <Dropdown isOpen={dropdownOpen[resource.id] || false} toggle={() => toggleDropdown(resource.id)}>
                  <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen[resource.id] || false}>
                    <i className="bi bi-three-dots-vertical"></i>
                  </DropdownToggle>
                  <DropdownMenu end>
                    <DropdownItem onClick={(e) => handleRemUser(e, resource)}>{t('Delete')}</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </td>
            </StyledRow>
          ))}
          <tr className='table-light'>
            <td colSpan="2">
              <Row>
                <Col xs="8">
                  {enabled ? <input
                    type="email"
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder={t('Enter email')}
                    className={`form-control ${isEmailValid ? '' : 'is-invalid'}`}
                    disabled={!enabled}
                  /> : <div className='d-flex h-100 align-items-center'><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></div>}
                  <div className="invalid-feedback">{emailError}</div>
                </Col>
                <Col xs="4" className="text-end">
                  <Button color="primary" onClick={(e) => handleAddUser(e)} disabled={!enabled}>
                    {t('Send invite')}
                  </Button>
                </Col>
              </Row>
            </td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
}

export default SubscriptionsTable;
