import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Button, Row, Col, Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useStore from '../../store';
import apiService from '../../utils/apiService';
import FlexLoader from '../../components/FlexLoader/FlexLoader';
import SubscriptionsTable from '../../components/DocumentTemplate/SubscriptionsTable/SubscriptionsTable';

function MembersPage() {
  const { t } = useTranslation();
  const { loggedIn, organization, setMessages, email, workspace } = useStore((state) => ({
    loggedIn: state.loggedIn,
    organization: state.organization,
    setMessages: state.setMessages,
    email: state.email,
    workspace: state.workspace
  }));
  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const sessionId = useMemo(() => queryParams.get('session_id'), [queryParams]);
  const status = queryParams.get('status');

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [activeSubscriptions, setActiveSubscriptions] = useState(0);
  const [displayPageMessages, setDisplayPageMessages] = useState(false);
  const [enabled, setEnabled] = useState(true);

  const fetchUsers = useCallback(async () => {
    const response = await apiService.post('/api/organizations/users/');
    console.log('response', response.data);
    let orgUsers = response.data.users;
    if(orgUsers.length === 0) {   // Add self
      orgUsers = [{id: 0, email, status: 'active', invited: false, is_admin: true, is_members_contact: true}];
    }
    setUsers(orgUsers);
    console.log('response.data.active_subscriptions_count', response.data.active_subscriptions_count);
    setActiveSubscriptions(response.data.active_subscriptions_count || 0);
  }, [email]);
  const fetchSuccess = useCallback(async (sessionId) => {
    // This will create organisation, update name if needed and set stripe customer id
    try {
      const response = await apiService.post('/api/organizations/stripe/handle-success/', {
        session_id: sessionId
      });
      console.log('fetchSuccess response', response);
    } catch (error) {
      console.error('Error fetching success:', error);
    }
  }, []);

  const doFetching = useCallback(async () => {
    if (!loggedIn) return;
    if(sessionId) await fetchSuccess(sessionId);
    await fetchUsers();
    setLoading(false);
  }, [fetchSuccess, fetchUsers, loggedIn, sessionId]);

  useEffect(() => {
    if (!loggedIn) return;
    doFetching();
  }, [loggedIn, doFetching]);
  useEffect(() => {
    if (displayPageMessages) return; // Prevent displaying messages multiple times
    if (status === 'success') setMessages({ "info": [t('Payment was successful!')] });
    if (status === 'cancel') setMessages({ "error": [t('Payment was cancelled.')] });
    setDisplayPageMessages(true);
  }, [setMessages, t, status, displayPageMessages]);

  const openCheckoutSession = useCallback(async (minimum) => {
    if (!loggedIn) return;
    console.log('openCheckoutSession', minimum);

    try {
      const response = await apiService.post('/api/organizations/stripe/create-checkout-session/', {
        success_url: `${window.location.origin}/${workspace.id}/members?status=success&session_id={CHECKOUT_SESSION_ID}`,
        cancel_url: `${window.location.origin}/${workspace.id}/members?status=cancel`,
        minimum
      });
      if (response.success) {
        window.location.href = response.data.url;
      } else {
        console.error('Failed to create checkout session:', response.message);
      }
    } catch (error) {
      console.error('Error during checkout session creation:', error);
    }
    return null;
  }, [loggedIn, workspace?.id]);

  const openCustomerPortalSession = useCallback(async () => {
    if (!loggedIn || !organization || !organization.stripe_customer_id) return;
    try {
      const response = await apiService.post('/api/organizations/stripe/create-customer-portal-session/', {
        return_url: `${window.location.origin}/${workspace.id}/members`
      });
      if (response.success) {
        window.location.href = response.data.url;
      } else {
        console.error('Failed to create customer portal session:', response.message);
      }
    } catch (error) {
      console.error('Error during customer portal session creation:', error);
    }
  }, [loggedIn, organization, workspace?.id]);

  const addUser = async (email) => {
    try {
      setEnabled(false);
      const response = await apiService.post('/api/organizations/add-user/', { email }, false);
      if (response.success) {
        // setUsers([...users, response.data.user]);
        fetchUsers();
        setMessages(response.messages);
        return { success: true };
      }
      const errorMessage = response.messages && response.messages.error && response.messages.error.length > 0 ? response.messages.error[0] : '';
      return { success: false, message: errorMessage };
    } catch (error) {
      const errorMessage = error.message || "Error adding user";
      console.log('Error adding user', errorMessage);
      return { success: false, message: errorMessage };
    } finally {
      setEnabled(true);
    }
  };

  const remUser = async (user) => {
    try {
      setEnabled(false);
      const response = await apiService.post('/api/organizations/remove-user/', { email: user.email, invited: !!user.invited });
      if (response.success) {
        fetchUsers();
        return;
      }
    } catch (error) {
      const errorMessage = error.message || "Error removing user";
      console.log('Error removing user', errorMessage);
    }finally {
      setEnabled(true);
    }
  };

  // Choose between checkout and customer portal button
  const stripeButton = useMemo(() => {
   // console.log('stripeButton', users.length, activeSubscriptions, users.length - activeSubscriptions);
    return !organization || !organization.stripe_customer_id ?
      <Button color="primary" onClick={() => openCheckoutSession(users.length - activeSubscriptions)}>
          {t('Go to Checkout')}
        </Button>
        :
        <Button color="primary" onClick={openCustomerPortalSession}>
        {t('Go to Customer Portal')}
      </Button>
  }, [activeSubscriptions, openCheckoutSession, openCustomerPortalSession, organization, t, users.length]);


  if(!loggedIn || loading) return (<Row><Col><h2>{t('Billing')}</h2><FlexLoader /></Col></Row>);


  return (
    <Row>
      <Col sm="12" md={{ size: 9 }}>
        <h2 className='mb-4'>{t('Members')}</h2>
        <p>{t('You can invite your team members to your organization. They will receive an email with instructions on how to join.')}</p>
        <SubscriptionsTable users={users} addUser={addUser} remUser={remUser} activeSubscriptions={activeSubscriptions} enabled={enabled} />

        {(users.length > activeSubscriptions || !activeSubscriptions) &&
          <>
            <Alert color='warning'>
              <div className='mb-2'>{t('Your organization has {{X}} unpaid subscriptions.', { X: (users.length - activeSubscriptions) || users.length })}</div>
              <div>{stripeButton}</div>
            </Alert>
          </>
        }
        {/*users.length < activeSubscriptions &&
          <>
            <Alert color='info'>
            {t('Your organization has {{X}} unassigned subscriptions. Enter an email to invite a user.', { X:  activeSubscriptions - users.length })}
            </Alert>
            {stripeButton}
          </>
      */}
        {/*users.length === activeSubscriptions && organization?.stripe_customer_id &&
          <>
            <p>{t('You can manage all your subscriptions and members details through our customer portal.')}</p>
            {stripeButton}
          </>
        }*/}
      </Col>
    </Row>
  );
}

export default MembersPage;
