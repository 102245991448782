import ElevenLabsAudioStreamer from './ElevenLabsAudioStreamer';
import {
  fetchLLMPhrase,
} from './PlayerHelpers';

const PHASES = {
  PREDICTING: 1,
  COMPLETING: 2,
}

class PlayerStream {
  isPlaying = false; // As soon as we move to prediction phase we are considered playing
  phase = PHASES.PREDICTING; //  1: predicting, 2: completing
  topicId = null;
  sessionId = null; 
  orderNr= 0;
  latestUtterance = ''
  latestPhrase = '' // Latest starting Phrase from LLM
  latestPhraseOrderNr = 0;
  latestFinalizedUterrance = '';
  streamer = undefined;
  
  constructor(topicId, playElevenlabsAlignments, stopTalking, onFinalizeResponse) {
    this.topicId = topicId;
    // Set up ElevenLabsAudioStreamer
    const apiKey = 'sk_8bdc2915bb59a3f77f29443f6a5e7cd61db2f479cdd75e0b';
    const voiceId = '29vD33N1CtxCmqQRPOHJ';
    const onAlignment = (alignment) => {
      playElevenlabsAlignments(alignment);
    };
    const onStop = () => {
      console.log('PlayerStream.onStop called');
      stopTalking();
      this.isPlaying = false;
    };
    this.streamer = new ElevenLabsAudioStreamer(apiKey, voiceId, onAlignment, onStop);
    this.onFinalizeResponse = onFinalizeResponse;
    console.log('PlayerStream initialized');
  }
  newSession(sessionId) {
    this.phase = PHASES.PREDICTING;
    this.sessionId = sessionId;
    this.orderNr = 0;
    this.latestUtterance = '';
    this.latestPhrase = '';
    this.latestPhraseOrderNr = 0;
    this.latestUtterance = '';
  }
  getSnapshot() {
    return {
      phase: this.phase,
      sessionId: this.sessionId,
      orderNr: this.orderNr,
      latestUtterance: this.latestUtterance,
      latestPhrase: this.latestPhrase,
      latestPhraseOrderNr: this.latestPhraseOrderNr,
    }
  }
  verifySnapshot(snapshot) {
    if (snapshot.phase !== this.phase) return false;
    if (snapshot.sessionId !== this.sessionId) return false;
    return true;
  }
  // -----------------------------------------
  // Phase 1: Predicting phrase
  // -----------------------------------------
  // We received new words from DG
  onWord(uterrance) {
    if(!uterrance) return; 
    if(this.latestUtterance === uterrance) return; // Same utterance as last time
    // We may want to start when there are at least 2 words
    this.latestUtterance = uterrance;
    this.orderNr++;
    const snapshot = this.getSnapshot();
    this.fetchPhrase(snapshot, uterrance);
  }
  async fetchPhrase(snapshot, uterrance) {
    if(!this.verifySnapshot(snapshot)) return;
    // Call LLM for phrase prediction
    try {
      console.log('fetchLLMPhrase', this.sessionId, this.latestPhrase, uterrance);
      const { phrase } = await fetchLLMPhrase(this.topicId, this.sessionId, this.latestPhrase, uterrance);
      if(!this.verifySnapshot(snapshot)) return;
      this.onPhrase(snapshot, phrase);
    } catch (error) {
      console.error('Error in fetchPhrase:', error);
    }
  }
  // We received a phrase prediction from LLM
  onPhrase(snapshot, phrase) {
    if(!this.verifySnapshot(snapshot)) return;
    if(snapshot.orderNr <= this.latestPhraseOrderNr) {
      console.log('outdated prediction, a later beat us to it');
      return; // outdated prediction, a later beat us to it
    }
    this.latestPhrase = phrase;
    this.latestPhraseOrderNr = snapshot.orderNr;
  }

  // -----------------------------------------
  // Phase 2: Completing phrase
  // -----------------------------------------

  startCompletion(composite) {
    if(this.isPlaying) return;
    this.phase = PHASES.COMPLETING; 
    this.isPlaying = true;
    this.latestFinalizedUterrance = composite;
    const snapshot = this.getSnapshot();
    // Two options
    // 1. We have a phrase
    // 3. We have no phrase
    if (this.latestPhrase) {
      // We have a phrase and audio, lets complete the phrase
      console.log('------WE HAVE A PHRASE------', this.sessionId);
      console.log(this.latestPhrase);
      this.fetchCompletion(snapshot, this.latestFinalizedUterrance);
    } else {
      // We dont have anything, lets fetch a full response
      this.fetchFullResponse(snapshot, this.latestFinalizedUterrance);
    }
  }
  // ALT 1: Fetch completion to our initial phrase
  async fetchCompletion(snapshot, composite) {
    if(!this.verifySnapshot(snapshot)) return;
    this.streamer.stream(this.latestPhrase);
    if(this.onFinalizeResponse) this.onFinalizeResponse(composite, this.latestPhrase);
  }
  // ALT 2: Fetch full response
  async fetchFullResponse(snapshot, composite) {
    if(!this.verifySnapshot(snapshot)) return;
    // Fetch iniial phrase
    const { phrase: initialPhrase } = await fetchLLMPhrase(this.topicId, this.sessionId, this.latestPhrase, composite);
    if(!this.verifySnapshot(snapshot)) return;
    this.streamer.stream(initialPhrase);
    /*if(!this.verifySnapshot(snapshot)) return;
    // Fetch completion from LLM
    const { phrase: endPhrase } = await fetchLLMCompletion(this.topicId, this.sessionId, initialPhrase, composite);
    if(!this.verifySnapshot(snapshot)) return;
    this.streamer.stream(endPhrase);*/
    console.log('-----FINALIZED FULL RESPONSE-----', this.sessionId);
    if(this.onFinalizeResponse) this.onFinalizeResponse(composite, this.latestPhrase);
  }

  stop() {
    if (this.streamer) this.streamer.stop();
  }

  stream(text) {
    if (this.streamer) {
      this.streamer.stream(text);
    } else {
      console.error('ElevenLabsAudioStreamer not initialized');
    }
  }
}

module.exports = PlayerStream;