import React, { createContext, useContext, useState, useCallback, useMemo, useRef, useEffect } from 'react';
import useStore from '../../store';
import { getClientSetting } from '../../utils/featureFlags';
import apiService from '../../utils/apiService';

const MeetingContext = createContext(null);

const initializeAgentVoice = (agent, configs) => {
  const config = configs.find((c) => c.id === agent.image_id);
  if (!config) return;

  const conferenceType = getClientSetting('MODAL_CONFERENCE');
  
  switch (conferenceType) {
    case 'VAD_OPENAI_STREAM':
      // ['alloy', 'ash', 'ballad', 'coral', 'echo', 'sage', 'shimmer', 'verse']
      const MALES = ["alloy", "ash", "ballad", "echo"];
      const FEMALES = ["shimmer", "verse", "sage", "coral"];
      const voices = config.voice === 'M' ? MALES : FEMALES;
      agent.voice = voices[Math.floor(Math.random() * voices.length)];
      console.log('Initialized agent voice:', agent.voice, agent.name);
      break;
    default:
      console.error('Unknown conference type:', conferenceType);
      break;
  }
};

export const MeetingProvider = ({ children }) => {
  const { agents, meeting, resources } = useStore();
  const [transcript, setTranscript] = useState('');
  const [activeSpeakerId, setActiveSpeakerId] = useState(null);
  const [initializedActiveSpeakerId, setInitializedActiveSpeakerId] = useState(null);
  const [isSharedScreenActive, setIsSharedScreenActive] = useState(false);
  const [activeSharedView, setActiveSharedView] = useState('content');
  const [sharedContent, setSharedContent] = useState(null);
  const [sharedContentVersion, setSharedContentVersion] = useState(0);
  const audioAnalyzerRef = useRef(null);
  const modalRefs = useRef({});

  const agentsInMeeting = useMemo(() => {
    if (!agents || !meeting?.agent_ids) return [];
    return agents.filter(a => a && meeting.agent_ids.includes(a.id));
  }, [agents, meeting]);

  const activeSpeakerAgent = useMemo(() => {
    return agentsInMeeting.find(a => a.id === activeSpeakerId);
  }, [agentsInMeeting, activeSpeakerId]);

  // If speaker changes, update shared content
  useEffect(() => {
    if (!meeting || !activeSpeakerAgent) return;
    if (sharedContent?.agent_id === activeSpeakerAgent.id) return;
    const agentSharedContents = meeting.shared_screens.filter((sc) => sc.agent_id === activeSpeakerAgent.id);
    if (agentSharedContents.length > 0) {
      console.log('📕 Setting shared content for agent:', activeSpeakerAgent.id);
      setSharedContent(agentSharedContents[agentSharedContents.length - 1]);
      setSharedContentVersion(0);
    } else {
      // If agent does not have any resources, we can use any in workspace
      const firstResource = resources[0];
      if (firstResource) {
        console.log('📕 Setting shared WORKSPACE content for agent:', activeSpeakerAgent.id);
        setSharedContent(firstResource);
        setSharedContentVersion(0);
      }
    }
  }, [meeting, activeSpeakerAgent, sharedContent?.agent_id, resources]);


  // Detect if our current shared content in meeting changes
  useEffect(() => {
    if (!meeting || !sharedContent) return;
    const agentSharedContent = meeting.shared_screens.find((sc) => sc.id === sharedContent.id);
    if (agentSharedContent && JSON.stringify(agentSharedContent) !== JSON.stringify(sharedContent)) {
      setSharedContent(agentSharedContent);
      // Version change will motify speaker that their content has been updated
      console.log('📕 Notifying speaking agent that their content has been updated', sharedContentVersion);
      setSharedContentVersion(sharedContentVersion + 1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSpeakerAgent?.id, meeting, sharedContent]);

  // Dynamic agent management
  useEffect(() => {
    const configs = window.agent_configs || [];
    
    Object.keys(modalRefs.current).forEach(agentId => {
      if (!agentsInMeeting.find(a => a.id === agentId)) {
        delete modalRefs.current[agentId];
      }
    });
    
    let mostRecentlyAddedAgent = null;
    agentsInMeeting.forEach(agent => {
      if (!modalRefs.current[agent.id]) {
        modalRefs.current[agent.id] = React.createRef();
        initializeAgentVoice(agent, configs);
        mostRecentlyAddedAgent = agent;
      }
    });

    if (mostRecentlyAddedAgent) {
      console.log('\n\n🙌🙌🙌🙌 mostRecentlyAddedAgent:', mostRecentlyAddedAgent.id);
      setActiveSpeakerId(mostRecentlyAddedAgent.id);
    }

  }, [agentsInMeeting]);

  const nextSharedScreenResource = useCallback(() => {
    if (!meeting?.shared_screens || !activeSpeakerAgent || !sharedContent) {
      console.warn('Missing required data for nextSharedScreenResource');
      return;
    }
    // First agent resources
    const agentResources = meeting.shared_screens.filter((sc) => sc.agent_id === activeSpeakerAgent.id);
    // Then all other agents in call
    const otherAgentResources = meeting.shared_screens.filter((sc) => sc.agent_id !== activeSpeakerAgent.id);
    // Then all other resources
    // NOTE! Workspace resources do not have loaded content, making them impossible to use
    /* const allResources = [...agentResources, ...otherAgentResources, ...(resources || [])];
    if (allResources.length === 0) {
      console.warn('No resources available');
      return;
    }*/
    const allResources = [...agentResources, ...otherAgentResources];
    // Find index of sharedContent.id in allResources
    const index = allResources.findIndex((sc) => sc.id === sharedContent.id);
    // Return next resource
    const nextResource = allResources[index + 1] || allResources[0];
    console.log('CURRENT RESOURCE:', sharedContent.id, 'NEXT RESOURCE:', nextResource.id, 'ALL RESOURCES:', allResources);
    setSharedContent(nextResource);
    setSharedContentVersion(0);
    return nextResource;
  }, [meeting?.shared_screens, activeSpeakerAgent, sharedContent]);

  const handleCreateWebApp = useCallback(async (instruction) => {
    if (!meeting) {
      console.error('Meeting is missing');
      return;
    }
    if (!activeSpeakerAgent?.id) {
      console.error('No active speaker agent');
      return;
    }
    if (!sharedContent?.id) {
      console.error('No shared content');
      return;
    }

    try {
      const data = {
        meeting_id: meeting.id,
        shared_resource_id: sharedContent.id,
        agent_id: activeSpeakerAgent.id,
        instruction: instruction || ''
      };
      
      console.log('Sending request with data:', data); // Debug log
      
      const response = await apiService.post(`/api/meetings/${meeting.id}/execute-shared-screen/`, data);
      if (!response.success) {
        console.error('Operation failed:', response.message);
      }
      return response;
    } catch (error) {
      console.error('handleCreateWebApp error:', error.response?.data || error);
      throw error;
    }
  }, [activeSpeakerAgent?.id, meeting, sharedContent?.id]);

  const value = {
    agentsInMeeting,
    modalRefs: modalRefs.current,
    activeSpeakerId,
    setActiveSpeakerId,
    activeSpeakerAgent,
    initializedActiveSpeakerId,
    setInitializedActiveSpeakerId,
    isSharedScreenActive,
    activeSharedView,
    sharedContent,
    setIsSharedScreenActive,
    setActiveSharedView,
    handleCreateWebApp,
    transcript,
    setTranscript,
    audioAnalyzerRef,
    nextSharedScreenResource
  };

  return <MeetingContext.Provider value={value}>{children}</MeetingContext.Provider>;
};

export const useMeeting = () => {
  const context = useContext(MeetingContext);
  if (!context) {
    throw new Error('useMeeting must be used within a MeetingProvider');
  }
  return context;
};