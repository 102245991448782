import React, { useState, useEffect, useMemo } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, FormFeedback, Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import apiService from '../../utils/apiService';
import useStore from '../../store';

function CreateWorkspaceModal({ isOpen, toggle, isEditing = false }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setWorkspace, workspace, userId } = useStore((state) => ({
    setWorkspace: state.setWorkspace,
    workspace: state.workspace,
    userId: state.userId,
  }));

  const [workspaceData, setWorkspaceData] = useState({
    name: '',
    is_organization: false, // can be private or organization  
  });

  useEffect(() => {
    if (isOpen) {
      if (isEditing && workspace) {
        setWorkspaceData({
          name: workspace.name,
          is_organization: workspace.visibility.toUpperCase() === 'PRIVATE' ? false : true,
        });
      } else {
        setWorkspaceData({
          name: '',
          is_organization: false,
        });
      }
      setError('');
    }
  }, [isOpen, isEditing, workspace]);

  const [error, setError] = useState('');
  const [saving, setSaving] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setWorkspaceData((prevData) => ({
        ...prevData,
        [name]: name === 'is_organization' ? value === 'organization' : value
    }));
};

  const validateForm = () => {
    if (!workspaceData.name.trim()) {
      setError('Name is required');
      return false;
    }
    setError('');
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return; // Stop submission if validation fails
    setSaving(true);
    try {
      const data = { ...workspaceData };
      let response;
      if (isEditing) {
        response = await apiService.put(`/api/workspaces/${workspace.id}/edit/`, data);
      } else {
        response = await apiService.post('/api/workspaces/new/', data);
      }
      if (response.success) {
        console.log(`Workspace ${isEditing ? 'updated' : 'created'} successfully:`, response.data);
        const workspaceJson = response.data.workspace;
        if (isEditing) {
          setWorkspace({
            ...workspace,
            ...workspaceJson,
          });
        } else {
          navigate(`/${workspaceJson.id}/resources`);
        }
        toggle();
      } else {
        console.error('Operation failed:', response.message);
        setError(response.message || 'An error occurred');
      }
    } catch (error) {
      console.error('Error during operation:', error);
      setError('An unexpected error occurred');
    } finally {
      setSaving(false);
    }
  };

  const canEdit = useMemo(() => {
    if (!isEditing) return true; // If not editing, we can always edit
    if (workspace && !workspace.creator_id) return true; // If no creator, we can edit
    return userId === workspace?.creator_id;
  }, [isEditing, userId, workspace]);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{isEditing ? t('Edit Workspace') : t('Create Workspace')}</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="name" className='fw-bold'>{t('Name')}</Label>
            <Input
              type="text"
              name="name"
              id="name"
              placeholder={t('Workspace Name')}
              value={workspaceData.name}
              onChange={handleChange}
              invalid={!!error}
              disabled={!canEdit} 
            />
            <FormFeedback>
              {error}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="is_organization" className='fw-bold'>{t('Share with')}</Label>
            <Input disabled={!canEdit} type="select" name="is_organization" id="is_organization" value={workspaceData.is_organization ? 'organization' : 'private'} onChange={handleChange}>
                  {[{value: 'private', label: t('Private - only me')}, {value: 'organization', label: t('Everyone in my organization')}].map((option) => (
                      <option key={option.value} value={option.value}>{option.label}</option>
                  ))}
              </Input>
          </FormGroup>
        </Form>
        {!canEdit && <Alert color="warning">{t('Only the workspace creator can edit this workspace.')} {workspace?.creator_username} {workspace?.creator_email ? `(${workspace?.creator_email})` : ''}</Alert>}
      </ModalBody>
      <ModalFooter className='d-flex justify-content-end'>
        {canEdit ? <Button color="primary" disabled={saving} onClick={handleSubmit}>
          {saving && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
          {isEditing ? t('Update workspace') : t('Create workspace')}
        </Button> : <Button color="primary" onClick={toggle}>{t('OK')}</Button>}
      </ModalFooter>
    </Modal>
  );
}

export default CreateWorkspaceModal;
