import React, { useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useStore from '../../store';

const Messages = () => {
  const { messages, clearMessages } = useStore((state) => ({
    messages: state.messages,
    clearMessages: state.clearMessages,
  }));

  const toastIdRef = useRef({});

  useEffect(() => {
    // Display each message as a toast
    Object.keys(messages).forEach((type) => {
      messages[type].forEach((message) => {
        const messageText = message.message;
        const messageId = `${type}-${messageText}`;

        if (!toast.isActive(toastIdRef.current[messageId])) {
          toastIdRef.current[messageId] = toast(messageText, { type: toastType(type) });
        }
      });
    });

    // Clear messages after displaying toasts
    if (Object.keys(messages).length > 0) {
      clearMessages();
    }
  }, [messages, clearMessages]);

  return <ToastContainer limit={3} autoClose={3000} />;
};

// Helper function to map message types to toast types
const toastType = (type) => {
  switch (type) {
    case 'info':
      return 'info';
    case 'success':
      return 'success';
    case 'warning':
      return 'warning';
    case 'error':
      return 'error'; // Directly use 'error' for toasts
    default:
      return 'default';
  }
};

export default Messages;
