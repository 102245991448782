import { base64ToArrayBuffer } from '../Players/PlayerHelpers';

class ElevenLabsAudioStreamer {
  constructor(apiKey, voiceId, onAlignment, onStop) {
    this.apiKey = apiKey;
    this.voiceId = typeof voiceId === 'string' ? voiceId : JSON.stringify(voiceId);
    this.audio = new Audio();
    this.onAlignment = onAlignment;
    this.onStop = onStop;
    this.abortController = null;
    this.mediaSource = null;
    this.sourceBuffer = null;
    this.reader = null;
    this.audioQueue = [];
    this.isMediaSourceOpen = false;
    this.isSourceBufferValid = true;
    this.isEnded = false;
    this.audioUrl = null;
    this.eventListeners = {};
    this.isStreaming = false;
    this.isStopping = false;
    this.isPlaying = false;
    this.isWaitingForData = false;
    this.bufferThreshold = 0.2; // Start playback after 200ms of audio
    this.minBufferSize = 0.1; // Minimum 100ms buffer
    this.isBuffering = true;
    this.onAudioEnded = this.onAudioEnded.bind(this);
    this.hasEndedNaturally = false;
  }

  resetState() {
    this.isMediaSourceOpen = false;
    this.isSourceBufferValid = true;
    this.isEnded = false;
    this.audioQueue = [];
    this.isStreaming = false;
    this.isPlaying = false;
    this.isWaitingForData = false;

    this.cleanupEventListeners();

    if (this.audioUrl) {
      URL.revokeObjectURL(this.audioUrl);
      this.audioUrl = null;
    }

    this.mediaSource = null;
    this.sourceBuffer = null;
    this.reader = null;

    if (this.abortController) {
      this.abortController.abort();
      this.abortController = null;
    }

    this.isBuffering = true;
    this.audio = new Audio();
  }

  stream(text) {
    if (this.isStreaming) {
      console.warn('Streaming already in progress. Stopping current stream before starting new one.');
      this.stop().then(() => {
        this.streamText(text);
      });
    } else {
      this.streamText(text);
    }
  }

  async streamText(text) {
    this.resetState();
    this.isStreaming = true;
    this.setupAbortHandler();

    try {
      this.createMediaSource();
      this.addAudioEventListener('ended', this.onAudioEnded);
      await this.fetchAndProcessAudio(text);
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('Error: Unable to stream audio.', error);
      }
      this.resetState();
    }
  }

  setupAbortHandler() {
    if (!this.abortController) {
      this.abortController = new AbortController();
    }

    this.abortController.signal.addEventListener(
      'abort',
      () => {
        this.resetAudioElement();

        if (this.mediaSource && this.mediaSource.readyState === 'open') {
          this.mediaSource.endOfStream();
          this.isMediaSourceOpen = false;
        }

        if (this.reader) {
          this.reader.cancel().catch((error) => {
            console.error('Error cancelling reader:', error);
          });
        }

        this.cleanupEventListeners();

        if (this.audioUrl) {
          URL.revokeObjectURL(this.audioUrl);
          this.audioUrl = null;
        }

        this.abortController = null;
      },
      { once: true }
    );
  }

  resetAudioElement() {
    console.log('Audio element has been reset.');
    this.audio.pause();
    this.audio.currentTime = 0;
    this.audio.src = '';
    this.handlePlaybackEnd();  // Ensure we handle the end of playback here too
  }

  addAudioEventListener(event, handler) {
    this.audio.addEventListener(event, handler);
    this.eventListeners[event] = handler;
  }

  removeAudioEventListener(event) {
    const handler = this.eventListeners[event];
    if (handler) {
      this.audio.removeEventListener(event, handler);
      delete this.eventListeners[event];
    }
  }

  addSourceBufferEventListener(event, handler) {
    if (this.sourceBuffer) {
      this.sourceBuffer.addEventListener(event, handler);
      this.eventListeners[event] = handler;
    }
  }

  removeSourceBufferEventListener(event) {
    const handler = this.eventListeners[event];
    if (handler && this.sourceBuffer) {
      this.sourceBuffer.removeEventListener(event, handler);
      delete this.eventListeners[event];
    }
  }

  createMediaSource() {
    if (this.mediaSource) {
      // Only end the stream if it's open
      if (this.mediaSource.readyState === 'open') {
        try {
          this.mediaSource.endOfStream();
        } catch (e) {
          console.warn('Error ending previous MediaSource stream:', e);
        }
      }
      URL.revokeObjectURL(this.audioUrl);
    }
    this.mediaSource = new MediaSource();
    this.audioUrl = URL.createObjectURL(this.mediaSource);
    this.audio.src = this.audioUrl;
    this.mediaSource.addEventListener('sourceopen', this.onSourceOpen);
  }

  onSourceOpen = () => {
    if (this.sourceBuffer) {
      try {
        this.mediaSource.removeSourceBuffer(this.sourceBuffer);
      } catch (e) {
        console.warn('Error removing previous SourceBuffer:', e);
      }
    }
    try {
      this.sourceBuffer = this.mediaSource.addSourceBuffer('audio/mpeg');
      this.sourceBuffer.addEventListener('updateend', this.onSourceBufferUpdateEnd);
      this.isMediaSourceOpen = true;
      this.isSourceBufferValid = true;
      this.appendNextAudioBuffer();
    } catch (e) {
      console.error('Error creating SourceBuffer:', e);
      this.isSourceBufferValid = false;
    }
  }

  async fetchAndProcessAudio(text) {
    const baseUrl = 'https://api.elevenlabs.io/v1/text-to-speech';
    const headers = {
      'Content-Type': 'application/json',
      'xi-api-key': this.apiKey,
    };
    const requestBody = {
      text,
      model_id: 'eleven_multilingual_v2',
      voice_settings: { stability: 0.5, similarity_boost: 0.5 },
    };

    console.log('Fetching audio from ElevenLabs API...');

    const response = await fetch(
      `${baseUrl}/${this.voiceId}/stream/with-timestamps?output_format=mp3_22050_32&optimize_streaming_latency=3`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify(requestBody),
        signal: this.abortController.signal,
      }
    );

    if (response.status === 200) {
      this.reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let buffer = '';

      const processChunk = async ({ done, value }) => {
        console.log('Processing chunk...', done, value);
        if (done) {
          console.log('Finished receiving audio stream.');
          this.isEnded = true;
          if (!this.sourceBuffer.updating && this.audioQueue.length === 0) {
            this.endStream();
          } else {
            // If the sourceBuffer is still updating or there's data in the queue,
            // we need to wait for it to finish before ending the stream
            this.addSourceBufferEventListener('updateend', () => {
              if (this.audioQueue.length === 0) {
                this.endStream();
              }
            });
          }
          return;
        }

        const chunkValue = decoder.decode(value, { stream: true });
        buffer += chunkValue;

        let lines = buffer.split('\n');
        buffer = lines.pop();

        for (let line of lines) {
          if (line.trim()) {
            try {
              const responseData = JSON.parse(line);
              const { audio_base64, alignment } = responseData;

              if (audio_base64) {
                const audioBuffer = base64ToArrayBuffer(audio_base64);
                console.log(
                  `Received audio chunk of size: ${audioBuffer.byteLength}`
                );
                this.appendNextAudioBuffer(audioBuffer);
              }

              if (alignment && typeof this.onAlignment === 'function') {
                console.log('onAlignment callback called.');
                this.onAlignment(alignment);
              }
            } catch (e) {
              console.error('Error parsing JSON:', e);
            }
          }
        }

        this.readNextChunk();
      };

      // Function to read the next chunk
      this.readNextChunk = () => {
        this.reader
          .read()
          .then(processChunk)
          .catch((error) => {
            if (this.abortController.signal.aborted) {
              // Handle abort silently
            } else {
              console.error('Error reading stream:', error);
            }
          });
      };

      // Start the reading process
      this.readNextChunk();
    } else {
      const errorMessage = await response.text();
      throw new Error(
        `Error fetching audio: ${response.status} - ${errorMessage}`
      );
    }
  }

  appendNextAudioBuffer(buffer = null) {
    if (!this.isMediaSourceOpen || !this.isSourceBufferValid || !this.sourceBuffer) {
      console.warn('Cannot append buffer: MediaSource is not open or SourceBuffer is not valid.');
      if (buffer) {
        this.audioQueue.push(buffer);
      }
      return;
    }
    
    if (buffer) {
      this.audioQueue.push(buffer);
    }

    this.processAudioQueue();
  }

  processAudioQueue() {
    while (!this.sourceBuffer.updating && this.audioQueue.length > 0 && this.isSourceBufferValid) {
      const nextBuffer = this.audioQueue.shift();
      try {
        this.sourceBuffer.appendBuffer(nextBuffer);
      } catch (e) {
        console.error('Error appending buffer:', e);
        this.isSourceBufferValid = false;
        this.audioQueue.unshift(nextBuffer); // Put the buffer back in the queue
        this.endStream();
        break;
      }
    }

    this.checkBufferState();
  }

  checkBufferState() {
    if (this.audio.buffered.length === 0) return;

    const currentTime = this.audio.currentTime;
    const bufferedEnd = this.audio.buffered.end(this.audio.buffered.length - 1);
    const bufferedAmount = bufferedEnd - currentTime;

    if (this.isBuffering && bufferedAmount >= this.bufferThreshold) {
      this.isBuffering = false;
      this.startPlayback();
    } else if (!this.isBuffering && bufferedAmount < this.minBufferSize) {
      // Instead of pausing, we'll log a warning
      console.warn('Buffer running low:', bufferedAmount.toFixed(3), 's');
    }
  }

  onSourceBufferUpdateEnd = () => {
    if (!this.isMediaSourceOpen || !this.isSourceBufferValid) return;

    this.processAudioQueue();
  }

  startPlayback() {
    if (!this.isPlaying) {
      this.isPlaying = true;
      this.audio.play()
        .catch(error => {
          console.error('Error playing audio:', error);
          this.handlePlaybackEnd();
        });
    }
  }

  endStream() {
    if (this.mediaSource && this.mediaSource.readyState === 'open' && !this.sourceBuffer.updating) {
      try {
        this.mediaSource.endOfStream();
        console.log('MediaSource endOfStream called.');
      } catch (e) {
        console.error('Error ending MediaSource stream:', e);
      }
      this.isMediaSourceOpen = false;
      this.isSourceBufferValid = false;
      
      // Add event listener for when playback actually ends
      this.audio.addEventListener('ended', this.onAudioEnded, { once: true });
    } else if (this.sourceBuffer && this.sourceBuffer.updating) {
      this.addSourceBufferEventListener('updateend', () => {
        this.endStream();
      });
    } else {
      console.warn('Unable to end stream: MediaSource not in correct state');
      this.handlePlaybackEnd();  // Only call here if we can't set up proper ending
    }
    this.removeSourceBufferEventListener('updateend');
  }

  onAudioEnded = () => {
    console.log('Audio playback actually ended');
    this.handlePlaybackEnd();
  }

  handlePlaybackEnd() {
    if (this.isPlaying) {
      console.log('Handling playback end');
      this.isPlaying = false;
      this.stop().then(() => {
        if (typeof this.onStop === 'function') {
          console.log('Calling onStop callback');
          this.onStop();
        }
      });
    }
  }

  cleanupEventListeners() {
    this.removeAudioEventListener('ended');
    this.removeSourceBufferEventListener('updateend');
  }

  stop() {
    return new Promise((resolve) => {
      if (this.isStopping) {
        console.log('Stop already in progress, ignoring duplicate call.');
        return resolve();
      }

      console.log('Stopping audio stream');
      this.isStopping = true;
      this.isPlaying = false;
      this.isStreaming = false;

      this.resetAudioElement();
      this.cleanupEventListeners();
      
      if (this.audioUrl) {
        URL.revokeObjectURL(this.audioUrl);
        this.audioUrl = null;
      }

      if (this.mediaSource && this.mediaSource.readyState === 'open') {
        try {
          this.mediaSource.endOfStream();
          console.log('MediaSource endOfStream called');
        } catch (e) {
          console.warn('Error ending MediaSource stream:', e);
        }
      }

      if (this.abortController) {
        this.abortController.abort();
        this.abortController = null;
      }

      console.log('Streaming stopped.');

      this.isStopping = false;
      this.resetState();
      
      if (!this.hasEndedNaturally && typeof this.onStop === 'function') {
        console.log('Calling onStop callback from stop method');
        this.onStop();
      }
      
      this.hasEndedNaturally = false;
      resolve();
    });
  }

  getBufferedRangeString() {
    if (this.audio.buffered.length === 0) return "[]";
    return `[${this.audio.buffered.start(0).toFixed(2)}s - ${this.audio.buffered.end(0).toFixed(2)}s]`;
  }
}

module.exports = ElevenLabsAudioStreamer;