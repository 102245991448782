import styled from 'styled-components';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export const StyledModal = styled(Modal)`
  max-width: ${props => props.$isSharedScreenActive ? '98vw' : '90vw'};
  width: ${props => props.$isSharedScreenActive ? '98vw' : '90vw'};
  height: 90vh;
  background-color: #303030;
  color: white;

  .modal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .modal-header, .modal-content {
    background-color: inherit;
    color: inherit;
  }
`;

export const StyledModalHeader = styled(ModalHeader)`
  .btn-close {
    color: #FFF; // Change this to the color you want for the close button
    opacity: 1; // Adjust if you want to change the visibility

    &:hover {
      color: #CCC; // Change this to the color you want on hover
    }
  }
`;

export const StyledModalBody = styled(ModalBody)`
  max-height: 80vh;
  overflow-y: auto;
  border-radius: 0.5rem;
  flex: 1;
  display: flex;
  flex-direction: ${props => props.isSharedScreenActive ? 'row' : 'column'};
`;

export const StyledModalFooter = styled(ModalFooter)`
  display: flex;
  justify-content: flex-start; // Align items to the left
  flex-wrap: wrap; // Allow items to wrap onto the next line if needed
  align-items: center; // Align items vertically in the center

  > div {
    flex-grow: 1; // Allow the div to take up any remaining space
  }
`;

export const AgentContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-around; // Use space-around to spread items evenly with space around them
  align-items: center;
`;

export const SharedScreenContainer = styled.div`
  width: 80%;
  height: 100%;
  background-color: #404040;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AvatarColumn = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;

  // Reduce the size of AvatarAgent components when in shared screen mode
  > * {
    transform: scale(0.5);
    transition: transform 0.3s ease;
  }
`;
