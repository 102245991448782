import React, { createContext, useState, useContext, useCallback, useMemo, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import apiService from '../utils/apiService';
import AgentCard from '../components/AgentCard/AgentCard';
import { useAgentRoleLabel } from '../utils/agentRoles';
import useStore from '../store';

const ModalSelectRoleContext = createContext();

export const ModalSelectRolesProvider = ({ children }) => {
  const { workspace, project, agents } = useStore((state) => ({ 
    workspace: state.workspace, 
    project: state.project,
    agents: state.agents
  }));
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState({ roles: [] });
  // Add state for added roles
  const [addedRoles, setAddedRoles] = useState([]);

  
  const handleSelectRole = useCallback(async (role) => {
    setAddedRoles(prevAddedRoles => [...prevAddedRoles, role.id]);
    // Use apiService to add the role to the team
    try {
      const url = project ? `/api/agents/${project.id}/add-suggested/` : `/api/agents/add-suggested/`;
      const data = { 
        workspace_id: workspace.id, 
        name: role.name, 
        description: role.description,
        image_id: role.image_id,
        roles: [role.type],
        data: { custom_id: role.id } // Used to identify if the role has been added by the user to the team
      };
      const response = await apiService.post(url, data);
      if (response.success) { 
        // add the role.id to the addedRoles state
        setAddedRoles(prevAddedRoles => [...prevAddedRoles, role.id]);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.error('Error fetching references:', error);
    }
  }, [workspace, project]);

  const [randomizedImageOrder, setRandomizedImageOrder] = useState([]);

  const allAgentImages = useMemo(() => {
    const configs = window.agent_configs || [];
    const uniqueConfigs = configs.filter((config, index, self) =>
      index === self.findIndex((t) => t.image_id === config.image_id)
    );
    return uniqueConfigs.map((config) => ({
      image_id: config.id,
      image_url: `${window.cdn}/images%2F${config.id}.png`
    }));
  }, []);

  useEffect(() => {
    // Randomize the order of all agent images once when the component mounts
    setRandomizedImageOrder(allAgentImages.map(img => img.image_id).sort(() => Math.random() - 0.5));
  }, [allAgentImages]);

  const availableAgentImages = useMemo(() => {
    return randomizedImageOrder
      // .filter(imageId => !currentlyUsedAgentImages.has(imageId))
      .map(imageId => allAgentImages.find(img => img.image_id === imageId))
      .filter(Boolean);
  }, [randomizedImageOrder, allAgentImages]);

  const getAgentImage = useCallback((index) => {
    if (availableAgentImages.length === 0) return null;
    return availableAgentImages[index % availableAgentImages.length];
  }, [availableAgentImages]);


  const getAgentRoleLabel = useAgentRoleLabel();
  const categorizedRoles = useMemo(() => {
    return modalProps.roles.reduce((acc, role) => {
      const categoryLabel = getAgentRoleLabel(role.type);
      acc[categoryLabel] = acc[categoryLabel] || [];
      acc[categoryLabel].push(role);
      return acc;
    }, {});
  }, [modalProps?.roles, getAgentRoleLabel]);

  const isRoleAdded = useCallback((roleId) => {
    if(addedRoles.includes(roleId)) return true;
    return agents.some(agent => agent.data?.custom_id === roleId);
  }, [addedRoles, agents]);

  const showModal = useCallback((roles) => {
    setRandomizedImageOrder(allAgentImages.map(img => img.image_id).sort(() => Math.random() - 0.5));
    setModalProps({ roles });
    setIsOpen(true);
  }, [allAgentImages]);

  const hideModal = useCallback(() => {
    setIsOpen(false);
  }, []);


  return (
    <ModalSelectRoleContext.Provider value={{ showModal, hideModal }}>
      {children}
      <Modal isOpen={isOpen} toggle={hideModal} size="lg">
        <ModalHeader toggle={hideModal}>{t('Add co-workers to the team')}</ModalHeader>
        <ModalBody>
          {Object.entries(categorizedRoles).map(([category, roles], categoryIndex) => (
            <React.Fragment key={category}>
              <h3 className='mb-3'>{category}</h3>
              {roles.map((role, roleIndex) => (
                <React.Fragment key={role.id}>
                  <AgentCard 
                    role={role}
                    image={getAgentImage(categoryIndex * roles.length + roleIndex)} 
                    handleAddToTeam={handleSelectRole} 
                    isRoleAdded={isRoleAdded(role.id)}
                    maxDescriptionLength={1000}
                  />
                  {roleIndex < roles.length - 1 && <hr />}
                </React.Fragment>
              ))}
              {categoryIndex < Object.keys(categorizedRoles).length - 1 && <hr />}
            </React.Fragment>
          ))}
        </ModalBody>
      </Modal>
    </ModalSelectRoleContext.Provider>
  );
};

export const useModalSelectRoles = () => useContext(ModalSelectRoleContext);
