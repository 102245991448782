import styled from 'styled-components';

export const CustomCard = styled.div`
  margin-bottom: 1rem;
  .row {
    margin-right: 0;
    margin-left: 0;
  }
`;

export const AgentText = styled.div`
  small {
    font-size: smaller !important;
  }
`;

export const AgentImage = styled.div`
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  min-height: 75px;
  cursor: pointer;
`;
