import React from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { MarkdownCustom } from '../Chat.styles';

function SystemMessage({ message }) {
  return (
    <MarkdownCustom className='alert alert-success d-grid'>
      <Markdown remarkPlugins={[remarkGfm]}>
        {message.message}
      </Markdown>
    </MarkdownCustom>
  );
}

export default SystemMessage;