import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from '../../pages/HomePage/HomePage';
import LoginPage from '../../pages/LoginPage/LoginPage';
import LoginPinPage from '../../pages/LoginPinPage/LoginPinPage';
import LogoutPage from '../../pages/LogoutPage/LogoutPage';
import LoginDemoPage from '../../pages/LoginDemoPage/LoginDemoPage';
import RegisterPinPage from '../../pages/RegisterPinPage/RegisterPinPage';
import ProjectsPage from '../../pages/ProjectsPage/ProjectsPage';
import ProjectDetailsPage from '../../pages/ProjectDetailsPage/ProjectDetailsPage';
import ProjectPage from '../../pages/ProjectPage/ProjectPage';
import StagePage from '../../pages/StagePage/StagePage';
import DocumentPage from '../../pages/DocumentPage/DocumentPage';
import AgentsPage from '../../pages/AgentsPage/AgentsPage';
import AgentPage from '../../pages/AgentPage/AgentPage';
import TeamPage from '../../pages/TeamPage/TeamPage';
import RestrictedPage from '../../pages/RestrictedPage/RestrictedPage';
import ProjectDataSync from '../../datasync/ProjectDataSync';
import DocumentDataSync from '../../datasync/DocumentDataSync';
import TopicDataSync from '../../datasync/TopicDataSync';
import SubTopicDataSync from '../../datasync/SubTopicDataSync';
import WorkspacesDataSync from '../../datasync/WorkspacesDataSync.js';
import WorkspaceDataSync from '../../datasync/WorkspaceDataSync.js';
import MeetingDataSync from '../../datasync/MeetingDataSync.js';
import Messages from '../Messages/Messages';
import useStore from '../../store';
import TeamsPage from '../../pages/TeamsPage/TeamsPage';
import BillingPage from '../../pages/BillingPage/BillingPage.js';
import ResourcesPage from '../../pages/ResourcesPage/ResourcesPage.js';
import MembersPage from '../../pages/MembersPage/MembersPage.js';
import SelectWorkflowPage from '../../pages/SelectWorkflowPage/SelectWorkflowPage';

const WorkspaceRoutes = ({ scrollToBottom }) => { 
  return (
  <>
    <Route path="/:workspaceId/coworkers" element={<ProviderWrapper><AgentsPage /></ProviderWrapper>} />
    <Route path="/:workspaceId/coworker/:agentId" element={<ProviderWrapper><AgentPage scrollToBottom={scrollToBottom} /></ProviderWrapper>} />
    <Route path="/:workspaceId/coworker/:agentId/:topicId" element={<ProviderWrapper><AgentPage scrollToBottom={scrollToBottom} /></ProviderWrapper>} />
    <Route path="/:workspaceId/projects" element={<ProviderWrapper><ProjectsPage /></ProviderWrapper>} />
    <Route path="/:workspaceId/projects/new" element={<ProviderWrapper><ProjectDetailsPage /></ProviderWrapper>} />
    <Route path="/:workspaceId/workflows/select" element={<ProviderWrapper><SelectWorkflowPage /></ProviderWrapper>} />
    <Route path="/:workspaceId/project-details/:id" element={<ProviderWrapper><ProjectDetailsPage /></ProviderWrapper>} />
    <Route path="/project/:projectId" element={<ProviderWrapper><ProjectPage /></ProviderWrapper>} />
    <Route path="/project/:projectId/stage/:stageId" element={<ProviderWrapper><StagePage /></ProviderWrapper>} />
    <Route path="/project/:projectId/document/:documentId/:templateType?" element={<ProviderWrapper><DocumentPage scrollToBottom={scrollToBottom} /></ProviderWrapper>} />
    <Route path="/project/:projectId/coworkers" element={<ProviderWrapper><AgentsPage /></ProviderWrapper>} />
    <Route path="/project/:projectId/coworker/:agentId" element={<ProviderWrapper><AgentPage scrollToBottom={scrollToBottom} /></ProviderWrapper>} />
    <Route path="/project/:projectId/coworker/:agentId/:topicId" element={<ProviderWrapper><AgentPage scrollToBottom={scrollToBottom} /></ProviderWrapper>} />
    <Route path="/:workspaceId/workflows" element={<ProviderWrapper><ProjectsPage is_workflow={true} /></ProviderWrapper>} />
    <Route path="/:workspaceId/workflows/new" element={<ProviderWrapper><ProjectDetailsPage is_workflow={true} /></ProviderWrapper>} />
    <Route path="/:workspaceId/workflow-details/:id" element={<ProviderWrapper><ProjectDetailsPage is_workflow={true} /></ProviderWrapper>} />
    <Route path="/workflow/:projectId" element={<ProviderWrapper><ProjectPage /></ProviderWrapper>} />
    <Route path="/workflow/:projectId/stage/:stageId" element={<ProviderWrapper><StagePage /></ProviderWrapper>} />
    <Route path="/workflow/:projectId/document/:documentId/:templateType?" element={<ProviderWrapper><DocumentPage /></ProviderWrapper>} />
    <Route path="/workflow/:projectId/team" element={<ProviderWrapper><AgentsPage /></ProviderWrapper>} />
    <Route path="/workflow/:projectId/team/:agentId" element={<ProviderWrapper><AgentPage scrollToBottom={scrollToBottom} /></ProviderWrapper>} />
    <Route path="/workflow/:projectId/team/:agentId/:topicId" element={<ProviderWrapper><AgentPage scrollToBottom={scrollToBottom} /></ProviderWrapper>} />
    <Route path="/:workspaceId/team" element={<ProviderWrapper><TeamsPage /></ProviderWrapper>} />
    <Route path="/:workspaceId/team/:teamId" element={<ProviderWrapper><TeamPage scrollToBottom={scrollToBottom} /></ProviderWrapper>} />
    <Route path="/:workspaceId/team/:teamId/:topicId" element={<ProviderWrapper><TeamPage scrollToBottom={scrollToBottom} /></ProviderWrapper>} />
    <Route path="/:workspaceId/resources" element={<ProviderWrapper><ResourcesPage /></ProviderWrapper>} />
    <Route path="/:workspaceId/members" element={<ProviderWrapper><MembersPage /></ProviderWrapper>} />
    <Route path="/:workspaceId/billing" element={<ProviderWrapper><BillingPage /></ProviderWrapper>} />
  </>
)};

const ProviderWrapper = ({ children }) => {
  return (
    <>
      <TopicDataSync />
      <SubTopicDataSync />
      <ProjectDataSync />
      <DocumentDataSync />
      <WorkspacesDataSync />
      <WorkspaceDataSync />
      <MeetingDataSync />
      {children}
    </>
  );
};
const MainContent = ({scrollToBottom}) => {
  const loggedIn = useStore((state) => state.loggedIn);
  const isVerified = useStore((state) => state.isVerified);
  return (
    <>
      <Messages />
        {!loggedIn || !isVerified ? (
          <Routes>
            <Route path="/" element={<ProviderWrapper><HomePage /></ProviderWrapper>} />
            <Route path="/login" element={<ProviderWrapper><LoginPage /></ProviderWrapper>} />
            <Route path="/demo" element={<ProviderWrapper><LoginDemoPage /></ProviderWrapper>} />
            <Route path="/login-pin" element={<ProviderWrapper><LoginPinPage /></ProviderWrapper>} />
            <Route path="/register-pin" element={<ProviderWrapper><RegisterPinPage /></ProviderWrapper>} />
            <Route path="/logout" element={<ProviderWrapper><LogoutPage /></ProviderWrapper>} />
            <Route path="*" element={<ProviderWrapper><RestrictedPage /></ProviderWrapper>} />
          </Routes>
        ) :
        (<Routes>
          <Route path="/" element={<ProviderWrapper><HomePage /></ProviderWrapper>} />
          <Route path="/login" element={<ProviderWrapper><LoginPage /></ProviderWrapper>} />
          <Route path="/demo" element={<ProviderWrapper><LoginDemoPage /></ProviderWrapper>} />
          <Route path="/login-pin" element={<ProviderWrapper><LoginPinPage /></ProviderWrapper>} />
          <Route path="/register-pin" element={<ProviderWrapper><RegisterPinPage /></ProviderWrapper>} />
          <Route path="/logout" element={<ProviderWrapper><LogoutPage /></ProviderWrapper>} />
          {WorkspaceRoutes({ scrollToBottom })}
          <Route path="*" element={<ProviderWrapper><div>Page not found</div></ProviderWrapper>} />
        </Routes>)}
    </>
    );
}

export default MainContent;
