import React, { useCallback, useMemo } from 'react';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useStore from '../../store';
import { useModalTool } from '../../contexts/ModalToolContext';
import { getFeatureFlag } from '../../utils/featureFlags';
import { useModalWorkflowEditor } from '../../contexts/ModalWorkflowEditorContext';

const ToolsWidget = ({ resources }) => {
  const { t } = useTranslation();
  const { agentId } = useParams();
  const { agents } = useStore((state) => ({
    agents: state.agents
  }));

  const agent = useMemo(() => agents && agents.find((agent) => agent.id === agentId), [agentId, agents]);

  const { showModal } = useModalTool();
  const { showModal: showWorkflowEditor } = useModalWorkflowEditor();

  const openModal = useCallback((
    title, 
    type, 
    showModeSelect = false, 
    buttonLabel = 'Query documents', 
    questionLabel = 'Do you have any special requests?', 
    isPromptRequired = false,
    showGenerationTemplates = false
  ) => {
    showModal(title, type, agent?.id, showModeSelect, buttonLabel, questionLabel, isPromptRequired, showGenerationTemplates);
  }, [showModal, agent?.id]);

  const shouldShowCoWorkers = agent?.roles?.includes('ORGANIZER');
  const shouldShowQueryDocs = resources?.length > 0;
  const shouldShowInternetSearch = true;
  const shouldShowGenerationTemplates = resources?.length > 0;

  const openModalSuggestCoWorkers = useCallback(() => {
    const title = t('Suggest co-workers');
    const type = 'SUGGEST_CO_WORKERS';
    const showModeSelect = false;
    const buttonLabel = t('Suggest co-workers');  
    const questionLabel = t('Do you have any special requests?');
    const isPromptRequired = false;
    openModal(title, type, showModeSelect, buttonLabel, questionLabel, isPromptRequired);
  }, [openModal, t]);
  
  const openModalQueryDocuments = useCallback(() => {
    const title = t('Query documents');
    const type = 'QUERY_DOCUMENTS';
    const showModeSelect = true;
    const buttonLabel = t('Query documents');
    const questionLabel = t('What do you want to know?');
    const isPromptRequired = true;
    openModal(title, type, showModeSelect, buttonLabel, questionLabel, isPromptRequired);
  }, [openModal, t]);

  const openModalInternetSearch = useCallback(() => {
    const title = t('Internet search');
    const type = 'INTERNET_SEARCH';
    const showModeSelect = false;
    const buttonLabel = t('Internet search');
    const questionLabel = t('What do you want to search for?');
    const isPromptRequired = true;
    openModal(title, type, showModeSelect, buttonLabel, questionLabel, isPromptRequired);
  }, [openModal, t]);

  const openModalGenerationTemplates = useCallback(() => {
    const title = t('Generate document');
    const type = 'QUERY_DOCUMENTS';
    const showModeSelect = true;
    const buttonLabel = t('Generate document');
    const questionLabel = t('Any special requests? (not required)');
    const isPromptRequired = false;
    const showGenerationTemplates = true;
    openModal(title, type, showModeSelect, buttonLabel, questionLabel, isPromptRequired, showGenerationTemplates);
  }, [openModal, t]);

  const openModalWorkflowEditor = useCallback(() => {
    const title = t('Workflow editor');
    showWorkflowEditor(title, {});
  }, [showWorkflowEditor, t]);

  return (
    <div className="d-flex flex-column gap-1">
      <div className='mb-2'><strong>{t('Actions')}</strong></div>
      {shouldShowCoWorkers && <Button outline size="sm" onClick={openModalSuggestCoWorkers} className='mb-2'>{t('Suggest co-workers')}</Button>}
      {shouldShowQueryDocs && <Button outline size="sm" onClick={openModalQueryDocuments} className='mb-2'>{t('Query documents')}</Button>}
      {shouldShowInternetSearch && <Button outline size="sm" onClick={openModalInternetSearch} className='mb-2'>{t('Internet search')}</Button>}
      {getFeatureFlag('GENERATION_TEMPLATES') && shouldShowGenerationTemplates && <Button outline size="sm" onClick={openModalGenerationTemplates} className='mb-2'>{t('Generate document')}</Button>}
      {getFeatureFlag('WORKFLOW_EDITOR') && <Button outline size="sm" onClick={openModalWorkflowEditor} className='mb-2'>{t('Workflow editor')}</Button>}
    </div>
  );
};

export default ToolsWidget;
