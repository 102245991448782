import React, { useMemo } from 'react';
import { NavItem } from 'reactstrap';
import { StyledNavLinkDocument, StarButton } from './TeamLink.styles.js';

const TeamLink = ({ team, workspace, handleDemoteTeam, onClick }) => {
  const workspacePrefix = useMemo(() => workspace && workspace.id ? `${workspace.id}/` : '', [workspace]);
  return (
    <NavItem>
      <StyledNavLinkDocument to={`/${workspacePrefix}team/${team.id}`} onClick={onClick}>
        <span className='bi bi-people'></span>&nbsp;
        {team.name}
        {handleDemoteTeam && (
          <StarButton className="star-button" size="sm" onClick={() => handleDemoteTeam(team.id)}>
            <span className="bi bi-star-fill"></span>
          </StarButton>
        )}
      </StyledNavLinkDocument>
    </NavItem>
  );
};

export default TeamLink;
