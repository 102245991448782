import styled, { css } from 'styled-components';

export const AvatarContainer = styled.div`
  position: relative;
  width: ${props => props.$dimension}px;
  height: ${props => props.$dimension}px;
  max-width: 100%;
  max-height: 100%;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  transition: all 0.3s ease;
  border-radius: 50%;
  
  ${props => props.$isActive && css`
    &::before {
      content: '';
      position: absolute;
      top: -4px;
      left: -4px;
      right: -4px;
      bottom: -4px;
      border-radius: 50%;
      background: rgba(0, 255, 255, 0.4);
      z-index: 0;
    }
  `}
`;

export const Emoji = styled.div`
  position: absolute;
  top: -20px;
  right: -20px;
  font-size: 24px;
  opacity: ${props => props.$show === "true" ? 1 : 0};
  transition: opacity 0.3s ease;
  z-index: 3;
`;
