import React, { useImperativeHandle, forwardRef, useRef, useEffect, useState } from 'react';
import { AvatarContainer, Emoji } from './AvatarAgent.styles';
import Visemes from './Visemes';
import usePolly from './usePolly';
import AudioVisualization from './AudioVisualization';
// import useElevenLabsJSON from '../../../hooks/useElevenLabsJSON';

const AVATAR_DIMENSION = 300;
const MOUTH_SMILE = 0.15;
const MOUTH_OPEN = 0.15;

const AvatarAgent = forwardRef(({
    agent,
    onTalkDone,
    backgroundImage = 'office1-300x300.png',
  }, ref) => {
  const [showHand, setShowHand] = useState(false);
  const threeJSRef = useRef(null);
  const avatarContainerRef = useRef(null);
  const audioElementRef = useRef(null);
  const visemesRef = useRef(new Visemes());
  const audioAnalyzerRef = useRef(null);
  const { fetchTextToSpeech, fetchVisemeInformation } = usePolly();
  // const { respond, stop } = useElevenLabsJSON(audioRef); // , isLoading, isPlaying

  // Visualization state
  const [visualizationEnabled, setVisualizationEnabled] = useState(true);
  const [visualizationOpacity, setVisualizationOpacity] = useState(0.7);
  const [visualizationColor, setVisualizationColor] = useState({
    start: 'rgba(0, 255, 255, 0.2)',
    end: 'rgba(0, 255, 255, 0.8)'
  });

  useEffect(() => {
    const initThreeJS = async () => {
      if (!avatarContainerRef.current) return;
      const config = {
        id: agent.id,
        className: 'avatar-xyz',
        type: 'gltf',
        //url: `${window.location.origin}/static/avatars/models/${agent.image_id}.glb`,
        url: `${window.cdn}/models%2F${agent.image_id}.glb`,
        bg: `/static/js/talk-head/bg/${backgroundImage}`,
        version: '1',
        canvasWidth: AVATAR_DIMENSION,
        canvasHeight: AVATAR_DIMENSION,
        container: avatarContainerRef.current,
      };

      // Assuming startApplication is a function that initializes Three.js and returns an instance
      // eslint-disable-next-line no-undef
      threeJSRef.current = await startApplication(config);
    };

    initThreeJS();
    return () => {
      // Cleanup Three.js or other resources when the component unmounts
      if (threeJSRef.current && threeJSRef.current.dispose) {
        threeJSRef.current.dispose();
      }
    };
  }, [backgroundImage, agent.id, agent.image_id]);

  const playPollyMessageStream = (message) => {
    const speechParams = {
      Engine: 'neural',
      OutputFormat: 'mp3',
      SampleRate: '16000',
      Text: message,
      TextType: 'text',
      VoiceId: agent.voice,
      // Only one Swedish voice:
      // VoiceId: 'Elin',
      // LanguageCode: 'sv-SE',
    };
    const visemeParams = {
      ...speechParams,
      OutputFormat: 'json',
      SpeechMarkTypes: ['viseme'],
    };
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-undef
      Promise.all([fetchTextToSpeech(speechParams), fetchVisemeInformation(visemeParams)])
        .then(([audioBlob, visemeDataArray]) => {
          const procVisemes = visemesRef.current.processPollyVisemes(visemeDataArray);
          const audioUrl = URL.createObjectURL(audioBlob);
          const audioElement = new Audio(audioUrl);
          audioElementRef.current = audioElement; // Store the audio element in the ref
          
          audioElement.play();
          audioElement.addEventListener('playing', (event) => {
            threeJSRef.current.avatar.morphAnimatorSpeech.animateQueue(procVisemes, MOUTH_SMILE, MOUTH_OPEN);
          });
          audioElement.addEventListener('ended', () => {
            onTalkDone();
            resolve();
          });
          audioElement.addEventListener('error', (err) => {
            console.error('Audio playback error:', err);
            reject(err); // Reject the promise if there's an error
          });
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  const playGoogleMessage = (audioUrl, visemes) => {
    return new Promise((resolve, reject) => {
      const audioElement = new Audio(audioUrl);
      audioElementRef.current = audioElement; // Store the audio element in the ref
      audioElement.play();
      audioElement.addEventListener('playing', (event) => {
        threeJSRef.current.avatar.morphAnimatorSpeech.animateQueue(visemes, MOUTH_SMILE, MOUTH_OPEN);;
      });
      audioElement.addEventListener('ended', () => {
        resolve();
      });
      audioElement.addEventListener('error', (err) => {
        console.error('Audio playback error:', err);
        reject(err); // Reject the promise if there's an error
      });
    });
  };

  const playElevenlabsAlignments = async (alignments) => {
    const playElevenlabsAlignments = (alignments) => {
      const processedVisemes = visemesRef.current.processElevenlabsAlignments(alignments);
      threeJSRef.current.avatar.morphAnimatorSpeech.animateQueue(processedVisemes, MOUTH_SMILE, MOUTH_OPEN);
    };
    playElevenlabsAlignments(alignments);
  }

  const playElevenlabsAlignmentsMillis = async (alignments) => {
    const playElevenlabsAlignments = (alignments) => {
      const processedVisemes = visemesRef.current.processElevenlabsAlignments(alignments);
      threeJSRef.current.avatar.morphAnimatorSpeech.animateQueue(processedVisemes, MOUTH_SMILE, MOUTH_OPEN);
    };
    playElevenlabsAlignments(alignments);
  }

  const playMillisMessage = (alignments) => {
    const processedVisemes = visemesRef.current.processMillisAlignments(alignments);
    threeJSRef.current.avatar.morphAnimatorSpeech.animateQueue(processedVisemes, MOUTH_SMILE, MOUTH_OPEN);
  }

  const stopTalking = () => {
    if (audioElementRef.current) {
      audioElementRef.current.pause();
      audioElementRef.current.currentTime = 0; // Reset the audio to the beginning
    }
    threeJSRef.current.avatar.morphAnimatorSpeech.stop(); // Clear the animation queue
    onTalkDone(); // Call onTalkDone to handle any cleanup
  };

  // Visualization controls
  const visualizationControls = {
    enable: () => setVisualizationEnabled(true),
    disable: () => setVisualizationEnabled(false),
    toggle: () => setVisualizationEnabled(prev => !prev),
    setOpacity: (opacity) => setVisualizationOpacity(opacity),
    setColors: (startColor, endColor) => {
      setVisualizationColor({
        start: startColor,
        end: endColor
      });
    },
    getState: () => ({
      enabled: visualizationEnabled,
      opacity: visualizationOpacity,
      colors: visualizationColor
    }),
    setAnalyzer: (analyzer) => {
      if (audioAnalyzerRef.current) {
        audioAnalyzerRef.current.disconnect();
      }
      audioAnalyzerRef.current = analyzer;
    },
  };

  // Update useImperativeHandle to include visualization controls
  useImperativeHandle(ref, () => ({
    playPollyMessageStream,
    playGoogleMessage,
    stopTalking,
    setShowHand,
    playElevenlabsAlignmentsMillis,
    playElevenlabsAlignments,
    playMillisMessage,
    visualization: visualizationControls
  }));

  return (
    <>
      <AvatarContainer id={`agent-container-${agent.id}`}>
        <AudioVisualization
          dimension={AVATAR_DIMENSION}
          analyzer={audioAnalyzerRef.current}
          enabled={visualizationEnabled}
          opacity={visualizationOpacity}
          colors={visualizationColor}
        />
        <div ref={avatarContainerRef} style={{ position: 'relative', zIndex: 0 }}></div>
        <Emoji show={showHand ? "true" : "false"}>
            ✋
        </Emoji>
        <div className='text-center my-2'><strong>{agent.name}</strong></div>
      </AvatarContainer>
    </>
  );
});

export default AvatarAgent;
