import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useStore from '../../../store';
import { 
  StyledModalBody, 
  ContentColumn,
  SideColumn,
} from './Content.styles';


import Chat from '../../Chat/Chat';
import ChatForm from '../ChatForm';

const Research = ({ content, loading = false }) => {
  const { t } = useTranslation();
  const [expandedQuestions, setExpandedQuestions] = useState({});
  const chatContainerRef = useRef(null);
  const { subTopic } = useStore((state) => ({ 
    subTopic: state.subTopic
  }));

  const toggleQuestion = (questionId) => {
    setExpandedQuestions(prev => ({
      ...prev,
      [questionId]: !prev[questionId]
    }));
  };

  const scrollChatToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };


  return (
    <StyledModalBody>
      <ContentColumn>
        <div className="workflow-content p-2">
          <h3>🔍 {t('Research')}</h3>
          {content.map((section) => (
          <div key={section.id} className="section mb-4">
            <div className='d-flex align-items-center'><h2>{section.headline}</h2> {section.loading && (<span className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true" />)}</div>
            
            <div className="questions">
              {section.questions.map((question) => (
                <div key={question.id} className="question">
                  <div className="question-container" style={{ display: 'grid', gridTemplateColumns: '24px 1fr', gap: '4px' }}>
                    {/* Column 1: Caret */}
                    <div className="caret-column">
                      <span className="caret">
                        {expandedQuestions[question.id] ? '▼' : '▶'}
                      </span>
                    </div>
                    
                    {/* Column 2: Content */}
                    <div className="content-column">
                      {/* Question Header */}
                      <div 
                        className="question-header mb-2"
                        onClick={() => toggleQuestion(question.id)}
                        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                      >
                        <span className="status" style={{ marginRight: '8px' }}>{question.status}</span>
                        <span className="text">{question.text}</span>
                        {question.loading && (
                          <span className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true" />
                        )}
                      </div>

                      {/* Question Details */}
                      {expandedQuestions[question.id] && (
                        <div className="question-details mt-2">
                          <div className="info-section">
                            {question.info.map((item, index) => (
                              <div 
                                key={index} 
                                className={`info-item ${item.enabled ? 'enabled' : 'disabled'}`}
                                style={{ display: 'flex', alignItems: 'center' }}
                              >
                                <span>{item.emoji}</span>
                                &nbsp;
                                <span>{item.text}</span>
                              </div>
                            ))}
                          </div>

                          <div className="creative-section">
                            {question.creative.map((item, index) => (
                              <div 
                                key={index}
                                className={`creative-item ${item.enabled ? 'enabled' : 'disabled'}`}
                                style={{ display: 'flex', alignItems: 'center' }}
                              >
                                <span>{item.emoji}</span>
                                &nbsp;
                                <span>{item.text}</span>
                              </div>
                            ))}
                          </div>

                          {question.generated && (
                            <div className="generated-section">
                              <div className={`generated-item ${question.generated.approved ? 'approved' : ''}`}>
                                {question.generated.text}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      </ContentColumn>
      <SideColumn>
        <div 
          ref={chatContainerRef} 
          className="modal-chat-container"
          style={{
            height: '100%',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {loading ? (
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
          ) : (
            <>
              <div className='flex-grow-1'>
                {subTopic && <Chat topic={subTopic} scrollToBottom={scrollChatToBottom} />}
              </div>
              <ChatForm 
                message={null} 
                partial={null}
                updatePartialFn={() => {}}
                setLoadingElement={() => {}}
                selectedElementId={null}
              />
            </>
          )}
        </div>
      </SideColumn>
    </StyledModalBody>
  );
};

export default Research;
