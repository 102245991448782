import React, { useState } from 'react';
import {
  Form,
  FormGroup,
  Label,
} from 'reactstrap';
import AutoResizeTextarea from '../../AutoResizeTextarea/AutoResizeTextarea';
import apiService from '../../../utils/apiService';
import { StyledButton } from '../DocumentTemplate.styles';
import DocumentFormButton from '../../DocumentFormButton/DocumentFormButton';

function QuestionsAndAnswers({ projectId, document:doc }) {
  const documentId=doc.id;
  const questions=doc.questions;
  const [localQuestions, setLocalQuestions] = useState(questions); // Step 1: Initialize local state with questions
  const [processingQuestions, setProcessingQuestions] = useState({});

  /*
  TODO: Swap to SSE. Skipping that for now
  useChannel(`project-${projectId}`, (message) => {
    if (message.name === 'UPDATE_DOCUMENT_ANSWER' && message.data.document_id === documentId) {
      const { question_id, answer } = message.data;
      setLocalQuestions(currentQuestions =>
        currentQuestions.map(question =>
          question.id === question_id ? { ...question, answer: answer } : question
        )
      );
      setProcessingQuestions(prev => ({ ...prev, [question_id]: false })); // Mark as not processing
    }
  });*/

  if (!questions) {
    return null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    doc.is_generating = true; // Set generating state to true at the start of the operation
    const endpoint = `/api/document/${documentId}/generate/`;
    // Specific to Q&A:
    const formData = localQuestions.map(question => ({
      question_id: question.id,
      answer: document.getElementById(`question-${question.id}`).value
    }));
    try {
      const response = await apiService.post(endpoint, formData);
      if (response.success) {
        console.log('Document generation initiated successfully');
      } else {
        console.error('Operation failed:', response.message);
        doc.is_generating = false;
      }
    } catch (error) {
      console.error('Error during operation:', error);
      doc.is_generating = false;
    }
  };

  const handleHelpMeAnswer = async (e, questionId) => {
    e.preventDefault();
    setProcessingQuestions(prev => ({ ...prev, [questionId]: true })); // Mark as processing

    try {
      const formData = {
        question_id: questionId,
        answer: document.getElementById(`question-${questionId}`).value
      }
      const endpoint = `/api/document/${documentId}/generate-answer/`;
      try {
        const response = await apiService.post(endpoint, formData);
        if (response.success) {
          console.log('Question help generation initiated successfully');
        } else {
          console.error('Operation failed:', response.message);
          doc.is_generating = false;
        }
      } catch (error) {
        console.error('Error during operation:', error);
        doc.is_generating = false;
      }
    } catch (error) {
      console.error(error);
      setProcessingQuestions(prev => ({ ...prev, [questionId]: false })); // Mark as not processing
    }
  };

  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      {localQuestions.map((questionObj) => (
        <FormGroup key={questionObj.id}>
          <div className='d-flex justify-content-between mb-1'>
            <Label for={`question-${questionObj.id}`} className="fw-bold">{questionObj.question}</Label>
            <StyledButton onClick={(e)=>{handleHelpMeAnswer(e, questionObj.id)}}>
              {processingQuestions[questionObj.id] ? (
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              ) : (
                <i className="bi bi-lightbulb"></i>
              )}
            </StyledButton>
          </div>
          <AutoResizeTextarea
            id={`question-${questionObj.id}`}
            name={`question-${questionObj.id}`}
            initialValue={questionObj.answer}
            onChange={(e) => console.log(e.target.value)} // Handle change as needed
          />
        </FormGroup>
      ))}
      <DocumentFormButton document={doc} />
    </Form>
  );
}

export default QuestionsAndAnswers;
