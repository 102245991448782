import { useEffect, useCallback, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useWSManager } from '../contexts/WSManagerContext';
import apiService from '../utils/apiService';
import useStore from '../store';

export const fetchWorkspaces = async () => {
  const loggedIn = useStore.getState().loggedIn;
  const setWorkspaces = useStore.getState().setWorkspaces;
  if (!loggedIn) return;
    try {
      const response = await apiService.get(`/api/workspaces/`);
      if (response.success) {
        const newWorkspaces = response.data.workspaces;
        // Only update workspaces if they've changed
        if (JSON.stringify(newWorkspaces) !== JSON.stringify(useStore.getState().workspaces)) {
          setWorkspaces(newWorkspaces);
        }
        return newWorkspaces;
      }
    } catch (error) {
      console.error('Error during Load Workspaces:', error);
    }
    return [];
};

export const WorkspacesDataSync = () => {
  const {  userId, loggedIn } = useStore((state) => ({
    userId: state.userId,
    loggedIn: state.loggedIn,
  }));
  const { manageSubscriptions } = useWSManager();
  const [previousUserId, setPreviousUserId] = useState(null);
  const componentId = useRef(uuidv4()); // Unique ID for this component instance

  const fetchWorkspacesCallback = useCallback(async () => {
    await fetchWorkspaces();
  }, []);

  useEffect(() => {
    if (!loggedIn) return;
    fetchWorkspacesCallback();
  }, [fetchWorkspacesCallback, loggedIn]);

  useEffect(() => {
    if (!userId) return;
    if (userId === previousUserId) return;
    const handleMessage = (event) => {
      const obj = JSON.parse(event.data);
      const type = obj.type;
      if (type === 'UPDATE_WORKSPACES') {
        console.log('New SSE stream [WorkspacesDataSync]:', type, obj);
        fetchWorkspacesCallback();
      }
    };

    const subscriptionChanges = [];
    if (previousUserId) subscriptionChanges.push({ channelName: `user-${previousUserId}`, componentId: componentId.current });
    subscriptionChanges.push({ channelName: `user-${userId}`, handleMessage, componentId: componentId.current });
    manageSubscriptions(subscriptionChanges);
    setPreviousUserId(userId);
  }, [userId, manageSubscriptions, previousUserId, fetchWorkspacesCallback]);

  return null;
};

export default WorkspacesDataSync;
