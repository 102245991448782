import React, { createContext, useContext, useState } from 'react';

const FilePickerContext = createContext();

export const useFilePicker = () => useContext(FilePickerContext);

export const FilePickerProvider = ({ children }) => {
  const [showPicker, setShowPicker] = useState(false);
  const [metadata, setMetadata] = useState({});

  const value = {
    showPicker,
    setShowPicker,
    metadata,
    setMetadata,
  };

  return <FilePickerContext.Provider value={value}>{children}</FilePickerContext.Provider>;
};
