import React from 'react';
import UserMessage from './UserMessage';
import AIMessage from './AIMessage';
import SystemMessage from './SystemMessage';

function MessageItem({ message, index, isLastMessage, expandedReferences, toggleReferences, handleShowReferences, handleActionSubmit }) {
  return (
    <React.Fragment>
      <div className={`message ${message.type}`}>
        <div className="d-flex">
          {message.type === 'USER' && (
            <UserMessage message={message} />
          )}
          {message.type === 'AI' && (
            <AIMessage
              message={message}
              expandedReferences={expandedReferences}
              toggleReferences={toggleReferences}
              handleShowReferences={handleShowReferences}
              handleActionSubmit={handleActionSubmit}
              index={index}
            />
          )}
          {message.type === 'SYSTEM' && (
            <SystemMessage message={message} />
          )}
        </div>
      </div>
      {!isLastMessage && <hr />}
    </React.Fragment>
  );
}

export default MessageItem;