import create from 'zustand';

const useStore = create((set) => ({
  loading: true,
  setLoading: (loading) => set({ loading: loading }),
  csrfToken: '',
  setCsrfToken: (token) => set({ csrfToken: token }),
  config: {},
  setConfig: (config) => set({ config: config }),

  location: '',
  setLocation: (location) => set({ location }),

  loggedIn: false,
  setLoggedIn: (status) => set({ loggedIn: status }),
  isSuperUser: false,
  setIsSuperUser: (status) => set({ isSuperUser: status }),
  isVerified: false,
  setIsVerified: (status) => set({ isVerified: status }),
  userId: '',
  setUserId: (userId) => set({ userId }),
  email: '',
  setEmail: (email) => set({ email }),
  loginEmail: '',
  setLoginEmail: (email) => set({ loginEmail: email }),
  username: '',
  setUsername: (username) => set({ username }),
  language: window.default_language || 'en',
  setLanguage: (language) => set({ language }),
  subscribed: false,
  setSubscribed: (subscribed) => set({ subscribed }),
  organization: null,
  setOrganization: (organization) => set({ organization }),
  daysLeftOnTrial: 0,
  setDaysLeftOnTrial: (daysLeftOnTrial) => set({ daysLeftOnTrial }),
  backgroundColor: '#cccccc',
  setBackgroundColor: (backgroundColor) => set({ backgroundColor }),
  icon: 'bi-person',
  setIcon: (icon) => set({ icon }),

  workspaces: null,
  setWorkspaces: (workspaces) => set({ workspaces }),
  workspace: null,
  setWorkspace: (workspace) => set({ workspace }),
  addWorkspace: (workspace) => set((state) => ({ workspaces: [...state.workspaces, workspace] })),
  remWorkspace: (workspaceId) => set((state) => ({
    workspaces: state.workspaces.filter(workspace => workspace.id !== workspaceId)
  })),
  resources: null, // resources for a workspace
  setResources: (resources) => set({ resources }),
  remResource: (resourceId) => set((state) => ({
    resources: state.resources.filter(resource => resource.id !== resourceId)
  })),

  topic: null,
  setTopic: (topic) => set({ topic }),
  setTopicChatHistory: (updater) => set((state) => {
    const updatedChatHistory = updater(state.topic?.chat_history || []);
    return { topic: { ...state.topic, chat_history: updatedChatHistory } };
  }),

  messages: {},
  setMessages: (newMessages) => set((state) => {
    const updatedMessages = { ...state.messages };
    Object.keys(newMessages).forEach((key) => {
      if (newMessages[key].length > 0) {
        updatedMessages[key] = (updatedMessages[key] || []).concat(newMessages[key].map(message => ({
          shownOn: undefined,
          message: message,
          displayed: false  // Add this line
        })));
      }
    });
    return { messages: updatedMessages };
  }),
  clearMessages: () => set({ messages: {} }),
  updateMessageShownOn: (location) => set((state) => {
    const updatedMessages = { ...state.messages };
    Object.keys(updatedMessages).forEach((key) => {
      // Filter out messages where shownOn is not undefined and not equal to the current location
      updatedMessages[key] = updatedMessages[key].filter(message =>
        message.shownOn === undefined || message.shownOn === location
      ).map(message => {
        // For messages that haven't been shown yet, update shownOn to the current location
        if (message.shownOn === undefined) {
          return { ...message, shownOn: location };
        }
        return message;
      });
    });
    return { messages: updatedMessages };
  }),

  projects: [],
  addProject: (project) => set((state) => ({ projects: [...state.projects, project] })),
  setProjects: (projects) => set({ projects }),
  updateProject: (projectId, projectData) => set((state) => {
    const updatedProjects = state.projects.map(project =>
      project.id === projectId ? { ...project, ...projectData } : project
    );
    return { projects: updatedProjects };
  }),
  remProject: (projectId) => set((state) => ({
    projects: state.projects.filter(project => project.id !== projectId)
  })),

  project: null,
  setProject: (project) => set({ project }),

  agents: null,
  setAgents: (agents) => set({ agents }),
  remAgent: (agentId) => set((state) => ({
    agents: state.agents.filter(agent => agent.id !== agentId)
  })),
  addAgent: (agent) => set((state) => ({ agents: [...state.agents, agent] })),
  updateAgent: (agentId, agentData) => set((state) => {
    const updatedAgents = state.agents.map(agent =>
      agent.id === agentId ? { ...agent, ...agentData } : agent
    );
    return { agents: updatedAgents };
  }),

  teams: null,
  addTeam: (team) => set((state) => ({ teams: [...state.teams, team] })),
  setTeams: (teams) => set({ teams }),
  remTeam: (teamId) => set((state) => ({
    teams: state.teams.filter(team => team.id !== teamId)
  })),
  updateTeam: (teamId, teamData) => set((state) => {
    const updatedTeams = state.teams.map(team =>
      team.id === teamId ? { ...team, ...teamData } : team
    );
    return { teams: updatedTeams };
  }),

  members: null,
  setMembers: (members) => set({ members }),

  document: null,
  setDocument: (document) => set({ document }),

  meeting: null,
  setMeeting: (meeting) => set({ meeting }),
}));

export default useStore;
