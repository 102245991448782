import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
// import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import apiService from '../../utils/apiService';
import { StyledDropdown } from './DocumentDropDown.styles';
import ModalBasic from '../../components/ModalBasic/ModalBasic';
import ModalPrint from '../../components/ModalPrint/ModalPrint';
import useStore from '../../store';
import { useModalConference } from '../../contexts/ModalConferenceContext';


function DocumentDropDown({ document:doc, agents }) {
  const { t } = useTranslation();
  // const { templateType } = useParams();
  // const outputTemplateType = templateType || doc.template_type;
  const documentId=doc.id;
  const { project, isSuperUser, remProject } = useStore((state) => ({
    project: state.project,
    isSuperUser: state.isSuperUser,
    remProject: state.remProject
  }));
  const { showModal } = useModalConference();

  // Function to handle dropdown toggle
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => { setDropdownOpen(prevState => !prevState); };
  // Functions for Basic Modal
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const toggleModal = () => setModal(!modal);
  // Functions for Print Modal
  const [printModal, setPrintModal] = useState(false);
  const [printTitle, setPrintModalTitle] = useState('');
  const [printBody, setPrintModalBody] = useState('');
  const togglePrintModal = () => setPrintModal(!printModal);

  const handleShowPrompt = async (documentId) => {
    const endpoint = `/api/document/${documentId}/prompt/`;
    try {
      const response = await apiService.post(endpoint, {});
      if (response.success) {
        const loadedContent = response.data.content;
        console.log('Document prompt fetched successfully', loadedContent);
        setModalTitle('Prompt'); // Set modal title
        setModalBody(loadedContent); // Set modal body content
        setModal(true); // Display the modal
      } else {
        console.error('Operation failed:', response.message);
      }
    } catch (error) {
      console.error('Error during operation:', error);
    }
  };
  const handleShowSummary = async (documentId) => {
    const endpoint = `/api/document/${documentId}/summary/`;
    try {
      const response = await apiService.post(endpoint, {});
      if (response.success) {
        const loadedContent = response.data.content.replace(/\n/g, '<br>');
        console.log('Document summary fetched successfully', loadedContent);
        setModalTitle('Summary'); // Set modal title
        setModalBody(loadedContent); // Set modal body content
        setModal(true); // Display the modal
      } else {
        console.error('Operation failed:', response.message);
      }
    }
    catch (error) {
      console.error('Error during operation:', error);
    }
  };

  const handleShowInput = async (documentId) => {
    const endpoint = `/api/document/${documentId}/input/`;
    try {
      const response = await apiService.post(endpoint, {});
      if (response.success) {
        const loadedContent = response.data.input;
        console.log('Document input fetched successfully', loadedContent);
        setModalTitle('Input'); // Set modal title
        setModalBody(loadedContent); // Set modal body content
        setModal(true); // Display the modal
      } else {
        console.error('Operation failed:', response.message);
      }
    } catch (error) {
      console.error('Error during operation:', error);
    }
  };

  const handleShowObjective = async (documentId) => {
    const endpoint = `/api/document/${documentId}/objective/`;
    try {
      const response = await apiService.post(endpoint, {});
      if (response.success) {
        const loadedContent = response.data.objective;
        console.log('Document objective fetched successfully', loadedContent);
        setModalTitle('Objective'); // Set modal title
        setModalBody(loadedContent); // Set modal body content
        setModal(true); // Display the modal
      } else {
        console.error('Operation failed:', response.message);
      }
    } catch (error) {
      console.error('Error during operation:', error);
    }
  };

  const handleShowTaskData = async (documentId) => {
    const endpoint = `/api/document/${documentId}/task_data/`;
    try {
      const response = await apiService.post(endpoint, {});
      if (response.success) {
        const loadedContent = response.data.task_data;
        console.log('Document task data fetched successfully', loadedContent);
        setModalTitle('Task data'); // Set modal title
        setModalBody(loadedContent); // Set modal body content
        setModal(true); // Display the modal
      } else {
        console.error('Operation failed:', response.message);
      }
    } catch (error) {
      console.error('Error during operation:', error);
    }
  };

  const navigate = useNavigate();
  const handleDeleteDocument = async (documentId) => {
    doc.is_generating = true;
    const endpoint = `/api/document/${documentId}/delete/`;
    try {
      const response = await apiService.post(endpoint, {});
      if (response.success) {
        const loadedContent = response.data;
        console.log('Document deleted successfully', loadedContent);
        navigate(`/project/${project.id}`, { replace: true });
      } else {
        console.error('Operation failed:', response.message);
      }
    } catch (error) {
      console.error('Error during operation:', error);
    }
  }


  const handleStartMeeting = async () => {
    showModal(t('Meeting'), agents);
  }

  const handleResetWorkflow = async () => {
    const endpoint = `/api/workflows/reset/${project.id}/`;
    try {
      const response = await apiService.post(endpoint, { });
      if (response.success) {
        console.log('Workflow successfully reset', response.data);
      } else {
        console.error('Operation failed:', response.message);
      }
    } catch (error) {
      console.error('Error during operation:', error);
    }
  };

  const handleResetTask = async () => {
    const endpoint = `/api/workflows/reset/${project.id}/${doc.id}/`;
    try {
      const response = await apiService.post(endpoint, { });
      if (response.success) {
        console.log('Task successfully reset', response.data);
      } else {
        console.error('Operation failed:', response.message);
      }
    } catch (error) {
      console.error('Error during operation:', error);
    }
  };

  const handleViewResults = async () => {
    const endpoint = `/api/workflows/${project.id}/results/`;
    try {
      const response = await apiService.post(endpoint, {});
      if (response.success) {
        const loadedContent = response.data.content;
        console.log('Results fetched successfully', loadedContent);
        setPrintModalTitle('Results');
        setPrintModalBody(loadedContent);
        setPrintModal(true);
      } else {
        console.error('Operation failed:', response.message);
      }
    } catch (error) {
      console.error('Error during operation:', error);
    }
  };
  const handleDeleteProject = async () => {
    console.log('Deleting with id:', project.id);
    try {
      const baseFolder = project.super_type === "WORKFLOW" ? 'workflow' : 'project';
      const response = await apiService.delete(`/api/${baseFolder}s/delete/${project.id}/`);
      if (!response.success) { console.error('Operation failed:', response.message); return; }
      console.log('Project deleted successfully');
      remProject(project.id);
      navigate(`/${project.workspace_id}/${baseFolder}s`);
    } catch (error) {
      console.error('Error during operation:', error);
    }
  };

  /*
  const handleFetchResourceText = async () => {
    const endpoint = `/api/resource/565f5b60a20a4666aa28ca16db1ac014/test/`;
    try {
      const response = await apiService.post(endpoint, { });
      if (response.success) {
        console.log('Chat successfully by backend', response.data);
      } else {
        console.error('Operation failed:', response.message);
      }
    } catch (error) {
      console.error('Error during operation:', error);
    }
  };*/

  return (
    <>
      <StyledDropdown>
        <Dropdown isOpen={dropdownOpen} toggle={(e) => { e.stopPropagation(); toggle(); }}>
          <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen} onClick={(e) => e.stopPropagation()}>
            <i className="bi bi-three-dots-vertical"></i>
          </DropdownToggle>
          <DropdownMenu end>
            {isSuperUser && <>
              <DropdownItem onClick={() => handleShowPrompt(documentId)}>{t('Show prompt')}</DropdownItem>
              <DropdownItem onClick={() => handleShowSummary(documentId)}>{t('Show summary')}</DropdownItem>
              <DropdownItem onClick={() => handleShowInput(documentId)}>{t('Show input')}</DropdownItem>
              <DropdownItem onClick={() => handleShowObjective(documentId)}>{t('Show objective')}</DropdownItem>
              {project && project.super_type === "WORKFLOW" && <DropdownItem onClick={() => handleShowTaskData(documentId)}>{t('Show task data')}</DropdownItem>}
              </> 
            }
            {/*<DropdownItem onClick={() =>   handleGenerateExercise(documentId, 'FILL_IN_THE_BLANKS')}>{t('Generate: fill in the blanks')}</DropdownItem>
            <DropdownItem onClick={() => handleShowPrompt(documentId)}>{t('Show prompt')}</DropdownItem>*/}
            {/* doc.template_type !== 'QUESTIONS_AND_ANSWERS' && outputTemplateType !== 'QUESTIONS_AND_ANSWERS' &&  <DropdownItem onClick={() => handleDisplayAsForm()}>{t('Display as form')}</DropdownItem> */}
            {/* doc.template_type !== 'QUESTIONS_AND_ANSWERS' && outputTemplateType === 'QUESTIONS_AND_ANSWERS' &&  <DropdownItem onClick={() => handleDisplayAsChat()}>{t('Display as chat')}</DropdownItem> */}
            
            {project && project.super_type === "WORKFLOW" && <DropdownItem onClick={handleDeleteProject}>{t('Delete workflow')}</DropdownItem>}
            {project && !project.super_type === "WORKFLOW" && <DropdownItem onClick={handleDeleteProject}>{t('Delete project')}</DropdownItem>}
            {doc.type === 'REPORT' && <DropdownItem onClick={() => handleDeleteDocument(documentId)}>{t('Delete report')}</DropdownItem>}

            {doc.agent_ids && <DropdownItem onClick={() => handleStartMeeting(documentId)}>{t('Start meeting')}</DropdownItem>}
            <DropdownItem onClick={handleResetWorkflow}>{t('Reset workflow')}</DropdownItem>
            <DropdownItem onClick={handleResetTask}>{t('Reset task')}</DropdownItem>
            <DropdownItem onClick={handleViewResults}>{t('View results')}</DropdownItem>
            {/*<DropdownItem onClick={handleFetchResourceText}>{t('Test Fetch Resource Text')}</DropdownItem>*/}
            {/* isSuperUser && <DropdownItem onClick={() => handleGenerateExercise(documentId)}>{t('Generate exercise')}</DropdownItem> */}
          </DropdownMenu>
        </Dropdown>
      </StyledDropdown>

      <ModalBasic isOpen={modal} toggle={toggleModal} title={modalTitle}>
        {modalBody}
      </ModalBasic>
      {printModal && <ModalPrint isOpen={printModal} toggle={togglePrintModal} title={printTitle} content={printBody} />}
    </>
  );
}

export default DocumentDropDown;
