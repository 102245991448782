import { useEffect, useCallback, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import apiService from '../utils/apiService';
import { useWSManager } from '../contexts/WSManagerContext';
import useStore from '../store';

export const SubTopicDataSync = () => {
  const { loggedIn, subTopicId, setSubTopic } = useStore((state) => ({
    subTopicId: state.subTopicId,
    setSubTopic: state.setSubTopic,
    loggedIn: state.loggedIn,
  }));
  // const topic = useMemo(() => subTopic, [subTopic]);

  const { manageSubscriptions } = useWSManager();
  const [previousTopicId, setPreviousTopicId] = useState(null);
  const componentId = useRef(uuidv4()); // Unique ID for this component instance

  const fetchTopic = useCallback(async (topicId) => {
    if (!loggedIn || !topicId) return;
    const endpoint = `/api/topic/find/`;
    const formData = {};
    if (topicId) formData.id = topicId;
    try {
      const response = await apiService.post(endpoint, formData);
      if (response.success) {
        console.log('Subtopic loaded successfully', response.data, formData);
        setSubTopic(response.data);
      } else {
        console.error('Operation failed:', response.message);
      }
    } catch (error) {
      console.error('Error during operation:', error);
    }
  }, [loggedIn, setSubTopic]);

  /*
  useEffect(() => {
    if (subTopicId && subTopicId !== subTopic?.id) {
      fetchTopic(subTopicId);
    }
  }, [subTopicId, fetchTopic, subTopic?.id]);*/

  const setSubTopicChatHistory = useStore((state) => state.setSubTopicChatHistory);

  const updateChatHistory = useCallback((message) => {
    console.log('Updating chat history with message:', message);
    setSubTopicChatHistory((currentChatHistory) => {
      const updatedChatHistory = [...currentChatHistory];
      const existingIndex = updatedChatHistory.findIndex(m => m.id === message.id);
      
      if (existingIndex !== -1) {
        updatedChatHistory[existingIndex] = { ...updatedChatHistory[existingIndex], ...message };
      } else {
        updatedChatHistory.push(message);
      }

      return updatedChatHistory;
    });
  }, [setSubTopicChatHistory]);

  useEffect(() => {
    if (subTopicId === previousTopicId) return;
    
    const handleMessage = (event) => {
      const obj = JSON.parse(event.data);
      const type = obj.type;
      const data = obj.data;
      if (type === 'UPDATE_MESSAGE_STREAM') {
        console.log('New SSE stream [SubTopicDataSync]:', type, obj);
        updateChatHistory(data);
      } else if (type === 'UPDATE_TOPIC') {
        console.log('New SSE stream [SubTopicDataSync]:', type, obj);
        fetchTopic(subTopicId);
      }
    };

    const subscriptionChanges = [];
    if (previousTopicId) {
      subscriptionChanges.push({ channelName: `topic-${previousTopicId}`, componentId: componentId.current });
    }
    
    if (subTopicId) {
      subscriptionChanges.push({ channelName: `topic-${subTopicId}`, handleMessage, componentId: componentId.current });
    }

    manageSubscriptions(subscriptionChanges);
    setPreviousTopicId(subTopicId);
  }, [subTopicId, manageSubscriptions, previousTopicId, fetchTopic, updateChatHistory]);

  return null;
};

export default SubTopicDataSync;
