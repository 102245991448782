import React, { useState, useRef, useCallback, useEffect } from 'react';

import { StyledModal, StyledModalHeader, StyledModalBody, StyledModalFooter } from './ModalTakeNotes.styles';
import DeepgramElevenLabsMic from './Mics/DeepgramElevenLabsMic';
import DeepgramCombinedAudioMic from './Mics/DeepgramCombinedAudioMic';
import apiService from '../../utils/apiService';
import useStore from '../../store';

const DISPLAY_DEEPGRAM_ELEVENLABS = false;
const DISPLAY_DEEPGRAM_COMBINED_AUDIO = true;

const ModalTakeNotes = ({ isOpen, toggle, title, children, size = 'lg', footerButtons }) => {
  const [transcript, setTranscript] = useState('');
  const audioElementRef = useRef(null);
  const { workspace } = useStore((state) => ({ workspace: state.workspace }));
  const [meetingResourceId, setMeetingResourceId] = useState('');
  const meetingResourceIdRef = useRef(meetingResourceId);
  useEffect(() => {
      meetingResourceIdRef.current = meetingResourceId; // Update ref whenever meetingResourceId changes
  }, [meetingResourceId]);

  const playElevenlabsAlignments = useCallback(() => {
  }, []);

  const stopTalking = useCallback(() => {
      if (audioElementRef.current) {
        audioElementRef.current.pause();
        audioElementRef.current.currentTime = 0; // Reset the audio to the beginning
    };
  }, []);

  const handleNewMeeting = useCallback(async () => {
    const endpoint = `/api/resource/meeting/new/`;
    try {
      const formData = new FormData();
      formData.append("metadata", JSON.stringify({ workspace_id: workspace.id }));
      const response = await apiService.post(endpoint, formData);
      if (response.success) {
          setMeetingResourceId(response.data.resource_id);
          console.log('Meeting resource created', response.data.resource_id);
      } else {
        console.error('Operation failed:', response.message);
      }
    }
    catch (error) {
      console.error('Error during operation:', error);
    }
  }, [workspace?.id]);

  const handleNewFinal = useCallback(async (content) => {
    if(!meetingResourceIdRef.current) {
      console.error('Meeting resource ID is missing');
      return;
    }
    const formData = new FormData();
    formData.append("content", content);
    const endpoint = `/api/resource/meeting/${meetingResourceIdRef.current}/append/`;
    try {
      const response = await apiService.post(endpoint, formData);
      if (response.success) {
        console.log('Meeting notes updated');
      } else {
        console.error('Operation failed:', response.message);
      }
    }
      catch (error) {
        console.error('Error during operation:', error);  
      }
    }, []);

  useEffect(() => {
    if (handleNewMeeting && isOpen) {
      handleNewMeeting();
    }
  }, [handleNewMeeting, isOpen]);

  return (
    <StyledModal isOpen={isOpen} toggle={toggle} size={size}>
      <StyledModalHeader toggle={toggle}>{title}</StyledModalHeader>
      <StyledModalBody id="modal-content">
        {children}
      </StyledModalBody>
      <StyledModalFooter>
        {DISPLAY_DEEPGRAM_ELEVENLABS && <DeepgramElevenLabsMic isOpen={isOpen} setTranscript={setTranscript} stopTalking={stopTalking} playElevenlabsAlignments={playElevenlabsAlignments} handleNewFinal={handleNewFinal} />}
        {DISPLAY_DEEPGRAM_COMBINED_AUDIO && <DeepgramCombinedAudioMic isOpen={isOpen} setTranscript={setTranscript} stopTalking={stopTalking} playElevenlabsAlignments={playElevenlabsAlignments} handleNewFinal={handleNewFinal} />}
        <div className='d-flex w-100 justify-content-center align-items-center text-center'>{transcript}</div>
      </StyledModalFooter>
    </StyledModal>
  );
};

export default ModalTakeNotes;