import { ListGroupItem } from 'reactstrap';
import styled from 'styled-components';

export const StyledListGroupItem = styled(ListGroupItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: #f8f9fa;
    cursor: pointer;

    .trash-button {
      visibility: visible; /* Show the button on hover */
    }
  }

  .trash-button {
    color: gray;
    background-color: transparent;
    border: 1px solid transparent; /* Transparent border to maintain size */
    visibility: hidden; /* Hide the button by default */
    padding: 0;

    &:hover {
      border: 1px solid gray;
      color: white;
      background-color: gray;
    }
  }

  &.selected {
    background-color: #e9ecef; /* Change background color for selected item */
  }
`;

export const TopicNameContainer = styled.div`
  display: flex;
  align-items: center;

  .badge {
    margin-left: 8px; /* Add space between topic name and badge */
  }
`;
