import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import apiService from '../../utils/apiService';
import useStore from '../../store';
import { Button } from 'reactstrap';

import { demoBrief, demoResearchData, demoIdeate, demoResult } from './DemoData';
import Research from './steps/Research';
import Create from './steps/Create';
import Content from './steps/Content';
import Ideate from './steps/Content';

import { 
  StyledModal, 
  StyledModalHeader, 
  StyledModalFooter,
} from './ModalWorkflowEditor.styles';

const ModalWorkflowEditor = ({ isOpen, toggle, message, size = 'lg' }) => {
  const { t } = useTranslation();
  // const [loading, setLoading] = useState(true);
  const { setSubTopicId, setSubTopic } = useStore((state) => ({ 
    setSubTopicId: state.setSubTopicId,
    setSubTopic: state.setSubTopic,
  }));

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const steps = [t('Create'), t('Brief'), t('Research'), t('Ideate'), t('Result')];
  const stepIcons = ['✨', '📄', '🔍', '💡', '📊'];

  const handlePrev = () => {
    setCurrentStepIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNext = () => {
    setCurrentStepIndex((prevIndex) => Math.min(prevIndex + 1, steps.length - 1));
  };

  const handleStepClick = (index) => {
    setCurrentStepIndex(index);
  };

  useEffect(() => {
    const fetchTopic = async () => {
      try {
        const response = await apiService.get(`/api/messages/${message.id}/topic/`);
        console.log('fetchTopic response', response);
        if (response.success) {
          setSubTopic(response.data);
          setSubTopicId(response.data.id);
        }
      } catch (error) {
        console.error('Error fetching topic:', error);
      } finally {
        // setLoading(false);
      }
    };
    
    if (!isOpen) {
      setSubTopic(null);
      setSubTopicId(null);
      // setLoading(false);
    } else {
      if (message.id) {
        fetchTopic();
      }
    }
  }, [isOpen, message.id, setSubTopic, setSubTopicId]);


  return (
    <StyledModal isOpen={isOpen} toggle={toggle} size={size}>
      <StyledModalHeader toggle={toggle}>{t('Workflow')}</StyledModalHeader>
      {currentStepIndex === 0 && <Create content={demoBrief} />}
      {currentStepIndex === 1 && <Content content={demoBrief} />}
      {currentStepIndex === 2 && <Research content={demoResearchData.content} />}
      {currentStepIndex === 3 && <Ideate content={demoIdeate} />}
      {currentStepIndex === 4 && <Content content={demoResult} />}
      <StyledModalFooter>
        <Button 
          color="primary" 
          size="sm" 
          onClick={handlePrev}
          style={{ visibility: currentStepIndex === 0 ? 'hidden' : 'visible' }}
        >
          <span className="bi bi-chevron-double-left" />{t('Previous')}
        </Button>
        {currentStepIndex > 0 && <div className="steps-indicator">
          {steps.map((step, index) => (
            <div key={step} className="step-item">
              <div 
                className={`step-circle ${index === currentStepIndex ? 'active' : ''}`} 
                title={t(step)}
                onClick={() => handleStepClick(index)}
                style={{ cursor: 'pointer' }}
              />
              {index < steps.length - 1 && <div className="step-line" />}
            </div>
            ))}
          </div>
        }
        <Button color="primary" size="sm" onClick={handleNext}>
          {t('Next')} <span className="bi bi-chevron-double-right" />
        </Button>
      </StyledModalFooter>
    </StyledModal>
  );
};

export default ModalWorkflowEditor;