import React, { useState, useMemo, useCallback } from 'react';
import { Row, Col, Carousel, CarouselItem, CarouselControl, CarouselIndicators, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { StyledCarouselCaption } from './TeamsPage.styles.js'; // Import styled components
import apiService from '../../utils/apiService';
import useStore from '../../store';
import FlexLoader from '../../components/FlexLoader/FlexLoader';
import TeamsList from '../../components/TeamsList/TeamsList';


function TeamsPage() {
  const { t } = useTranslation();
  const { addTeam, remTeam, teams, workspace } = useStore((state) => ({
    addTeam: state.addTeam,
    remTeam: state.remTeam,
    teams: state.teams,
    workspace: state.workspace
  }));
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const team_configs = useMemo(() => window.team_configs.map((a) => ({
    ...a,
    id: a.id,
    type: a.type || 'PREDEFINED_AGENT',
    // image_url: `/static/teams/${a.image_id}.jpg`
    image_url: `${window.cdn}/teams%2F${a.image_id}.jpg`,
  })), []);

  const next = () => {
    console.log('next', animating, activeIndex, team_configs.length);
    if (animating) return;
    const nextIndex = activeIndex === team_configs.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? team_configs.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = useCallback((newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }, [animating]);


  const handleAddPredefined = useCallback(async (teamId) => {
    try {
      let response;
      response = await apiService.post(`/api/teams/add-predefined/${teamId}/`, { workspace_id: workspace?.id });
      if (!response.success) {
        console.error('Operation failed:', response.message);
        return;
      }
      const team = response.data.team;
      addTeam(team);
    } catch (error) {
      console.error(error);
    }
  }, [addTeam, workspace?.id]);

  // Function to handle button click using fetch
  const handleDelete  = useCallback( async (agentId) => {
    console.log('Deleting agent with id:', agentId);
    try {
      const response = await apiService.delete(`/api/teams/${agentId}/delete/`);
      if (!response.success) {
        console.error('Operation failed:', response.message);
        return;
      }
      console.log('Agent deleted successfully');
      // Remove the agent from the list of agents
      remTeam(agentId);
    } catch (error) {
      console.error('Error during operation:', error);
    }
  }, [remTeam]);


  const slides = useMemo(() => team_configs.map((item, index) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={`carousel-item-${index}`}
      >
        { item.image_anim_url ?
        <video src={item.image_anim_url} alt={item.name} className="d-block w-100" autoPlay loop muted />
        : <img src={item.image_url} alt={item.name} className="d-block w-100" /> }
        <StyledCarouselCaption className="text-center gradient-bg">
          <h5>{t(`${item.name}`)}</h5>
          <p>{t(item.description)}</p>
          <Button className="add-member-button btn-primary btn-sm mb-4" onClick={() => handleAddPredefined(item.id)}>
            <i className="bi bi-person-plus-fill"></i> {t('Add team')}
          </Button>
        </StyledCarouselCaption>
      </CarouselItem>
    );
  }), [team_configs, t, handleAddPredefined]);

  const teamsInWorkspace = useMemo(() => teams && workspace && teams.filter(team => team.workspace_id === workspace.id), [teams, workspace]);

  if(!teams) return (<FlexLoader />);


  return (<>
    <Row>
      <Col sm="12">
        <h1>{t('Add team')}</h1>
        <Carousel
          activeIndex={activeIndex}
          next={next}
          previous={previous}
          className='mb-4'
          ride="carousel" // This prop controls the auto-play behavior. Setting it to "carousel" enables auto-play, which we want to disable.
          interval={false} // Setting interval to false disables auto-play animation.
        >
          <CarouselIndicators items={team_configs} activeIndex={activeIndex} onClickHandler={goToIndex} />
          {slides}
          <CarouselControl direction="prev" directionText={t("Previous")} onClickHandler={previous} />
          <CarouselControl direction="next" directionText={t("Next")} onClickHandler={next} />
        </Carousel>
      </Col>
    </Row>
    <div className='d-flex'>
      <h2 className='flex-grow-1'>{t('Our teams')}</h2>
      {/*<Button className="add-member-button btn-primary btn-sm mb-4" onClick={() => handleDesignCoWorker()}>
        <i className="bi bi-person-plus-fill"></i> {t('Design team')}
      </Button>*/}
    </div>
    {teamsInWorkspace && teamsInWorkspace.length === 0 && (<p>{t('No teams created yet.')}</p>)}

    <TeamsList teams={teamsInWorkspace} handleDelete={handleDelete} />
  </>);
}

export default TeamsPage;
