import styled from 'styled-components';
import { InputGroup } from 'reactstrap';

export const InputGroupFlex = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-end; // Ensure items are aligned to the bottom
`;

export const ChatContainer = styled.div`
  bottom: 0;
  position: sticky;
`;

export const StyledInputGroup = styled(InputGroup)`
  border: 1px solid #dee2e6;
  border-radius: 0.75em;
  background-color: #eee;
`;
